<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div  *ngIf="!VoucherComplete && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div class="text-900 font-medium text-xl mb-3"><h3>Sell a Gift Voucher
    </h3></div>
    <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span>
        Sell a gift voucher to a customer.
    </p>

    <div class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px">
        <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
                
                <label style="margin-bottom: 20px;" class="font-medium">Amount</label>
                <div>
                    <p-toggleButton id="toggle5" [(ngModel)]="toggle5" [style]="{'float': 'left', 'margin': '10px 1% 10px 0px', 'width': '32%', 'height': '80px' }" onLabel="£5" offLabel="£5" (onChange)="selectValue(5)"></p-toggleButton>

                    <p-toggleButton id="toggle10" [(ngModel)]="toggle10" [style]="{'float': 'left', 'margin': '10px 1% 10px 0px', 'width': '32%', 'height': '80px', 'margin-right': '1%'}" onLabel="£10" offLabel="£10" (onChange)="selectValue(10)"></p-toggleButton>

                    <p-toggleButton id="toggle20" [(ngModel)]="toggle20" [style]="{'float': 'left', 'margin': '10px 0% 10px 0px', 'width': '32%', 'height': '80px'}" onLabel="£20" offLabel="£20" (onChange)="selectValue(20)"></p-toggleButton>

                    <p-toggleButton id="toggle25" [(ngModel)]="toggle25" [style]="{'float': 'left', 'margin': '10px 1% 10px 0px', 'width': '32%', 'height': '80px'}" onLabel="£25" offLabel="£25" (onChange)="selectValue(25)"></p-toggleButton>

                    <p-toggleButton id="toggle50" [(ngModel)]="toggle50" [style]="{'float': 'left', 'margin': '10px 1% 10px 0px', 'width': '32%', 'height': '80px', 'margin-right': '1%'}" onLabel="£50" offLabel="£50" (onChange)="selectValue(50)"></p-toggleButton>

                    <p-toggleButton id="toggle100" [(ngModel)]="toggle100" [style]="{'float': 'left', 'margin': '10px 1% 10px 0px', 'width': '32%', 'height': '80px'}" onLabel="£100" offLabel="£100" (onChange)="selectValue(100)"></p-toggleButton>
                
                    <div style="clear:both">&nbsp;</div>
                    <span  style="margin-right:15px; margin-right: 15px; margin-top: 2px !important;float: left;;">Custom Amount</span>
                    <p-inputSwitch id="customAmount" [(ngModel)]="Voucher.customamount" (onChange)="customAmountToggle()"></p-inputSwitch>

                    <div *ngIf="Voucher.customamount">
                        <p-inputNumber [(ngModel)]="customAmountValue" [style]="{'margin-top' : '20px'}" [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" mode="currency" currency="GBP" locale="en-GB" [min]="0" [max]="9999" (onBlur)="setValue()" (onInput)="setValue()"></p-inputNumber>
                    </div>
                    <div *ngIf="!ValueValid" class="text-danger" Style="margin-left:10px; margin-top: 0px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Value must be greater than 0</div>
                </div>
            </div>
        </div>
    </div>

    <div class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px">
        <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
                <label style="margin-bottom: 20px;" class="font-medium">Customer Information</label>
                <div>
                    <div class="field mb-4 col-12">
                        <label for="lastname" class="font-medium">Your Name</label>
                        <input id="lastname" type="text" pInputText autocomplete="family-name" [(ngModel)]="Voucher.customername">
                    </div>
                    <div *ngIf="!NameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a last name.</div>
                    <div style="width:100%">
                        <div class="field mb-4 col-12">
                            <label for="email" class="font-medium">Email</label>
                            <input id="email" type="email" pInputText autocomplete="email" [(ngModel)]="Voucher.emailaddress">
                        </div>
                     
                        <div *ngIf="!EmailValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an email address.</div>
                        <div *ngIf="!EmailFormatValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a valid email address.</div>
                    </div>
                    <div class="field mb-4 col-12">
                        <label for="voucherForSomeoneElse" class="font-medium">Is this voucher is for someone else?</label>
                        <p-selectButton id="voucherForSomeoneElse" [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Voucher.alternativerecipient"></p-selectButton>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="Voucher.alternativerecipient" class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px">
        <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
                <label style="margin-bottom: 20px;" class="font-medium">Recipient Information</label>
                <div>
                    <div class="field mb-4 col-12">
                        <label for="lastname" class="font-medium">Recipient's Name</label>
                        <input id="lastname" type="text" pInputText autocomplete="family-name" [(ngModel)]="Voucher.recipientsname">
                    </div>
                    <div *ngIf="!RecipientNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a last name.</div>
                    <div style="width:100%">
                        <div class="field mb-4 col-12">
                            <label for="email" class="font-medium">Recipient's Email</label>
                            <input id="email" type="email" pInputText autocomplete="email" [(ngModel)]="Voucher.recipientsemailsaddress">
                        </div>
                     
                        <div *ngIf="!RecipientEmailValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an email address.</div>
                        <div *ngIf="!RecipientEmailFormatValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a valid email address.</div>
                    </div>

                    <div class="field mb-4 col-12">
                        <label for="venueclosingstartdate">Message</label>
                        <textarea pInputText type="text" style="width:100%; height: 100px;" [(ngModel)]="Voucher.message"></textarea>
                    </div>
                    <div *ngIf="!messageValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a message.</div>

                </div>
            </div>
        </div>
    </div>
    <div *ngIf="Voucher.initialvalue > 0 || Voucher.customamount" class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px">
        <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
                <label style="margin-bottom: 20px;" class="font-medium">Payment</label>
                <div>
                    <div class="surface-card p-4 shadow-2 border-round">
        
                        <div class="grid formgrid p-fluid">
                            <div style="font-weight:bold; font-size:20px; margin-bottom:20px;">
                                Total: {{(Voucher.initialvalue) |  currency:CurrencySymbol}} 
                            </div>
                            
                            <div *ngIf="VoucherPayments.length > 0" class="surface-card p-4 shadow-2 border-round" style="width:100%; margin-bottom: 20px;">
                                <label for="extras" class="font-medium">Payments already made</label>   
                                <div>
                                    <p-table [value]="BookingPayments">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th></th>
                                                <th style="width:90%">Date</th>
                                                <th style="width:10%">Method</th>
                                                <th style="width:20%">Amount</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-payment let-index>
                                            <tr>
                                                <td></td>
                                                <td>{{payment.paymentDate | date:'dd/MM/yyyy'}}</td>
                                                <td>{{payment.method}}</td>
                                                <td>{{(payment.amount) | currency:CurrencySymbol}}</td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="footer">
                                        <tr>
                                            <td></td>
                                            <td>&nbsp;</td>
                                            <td>Total:</td>
                                            <td>{{totalPayments | currency:CurrencySymbol}}</td>
                                        </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                                <div *ngIf="NewBooking.payments == null">
                                    No payments have been made against this booking
                                </div>
                            </div>
        
                            <div class="field mb-4 col-12">
                                <label for="venueclosingname" class="font-medium">Payment Value</label>
                                <p-inputNumber [(ngModel)]="Voucher.initialvalue" mode="currency" currency="{{CurrencySymbol}}" locale="en-GB" [min]="0" [max]="9999"></p-inputNumber>
                            </div>
                            <div *ngIf="!paymentValueValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a payment amount.</div>
                            <div *ngIf="!paymentValueExceededValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You cannot pay more than the booking value.</div>
                            <div *ngIf="!paymentDepositValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Your payment must be at least {{MinimumValidPayment | currency:CurrencySymbol}}.</div>
        
                            <!--<div class="field mb-4 col-12">
                                <label for="paymentType" class="font-medium">Payment Type</label>
                                <p-dropdown [options]="PaymentTypes" [(ngModel)]="selectedPayment" optionLabel="name" placeholder="Select a Type" [showClear]="true"></p-dropdown>
                            </div>
                            <div *ngIf="!paymentTypeValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select a payment type.</div>-->
                        </div>
        
                        <button *ngFor="let p of PaymentTypes" pButton type="button" style="height: 50px; width: 221px;margin-top:0px;margin-right:15px;margin-bottom:10px;" class="p-button p-button" (click)="donePayment(p.id, p.name)">
                            <span *ngIf="p.name=='Cash'" class="pi pi-pound" style="margin-right:8px;"></span>
                            <span *ngIf="p.name=='Card'" class="pi pi-credit-card" style="margin-right:8px;"></span>
                            <span *ngIf="p.name!='Cash' && p.name!='Card'" class="pi pi-money-bill" style="margin-right:8px;"></span>
                            <span>Pay With {{p.name}}</span>
                        </button>
                        <button pButton type="button" style="height: 50px; width: 221px;margin-top:0px;margin-right:15px;margin-bottom:10px;" class="p-button p-button-danger" (click)="deferPayment()">
                            <span class="pi pi-clock" style="margin-right:8px;"></span>
                            <span>Defer Payment</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>

<div *ngIf="VoucherComplete">
    <div class="p-3 flex flex-column flex-auto">
        <h3 style="margin-bottom:20px;" i18n>Voucher Summary</h3>

        <div style="width:100%; margin-bottom: 15px;">
            <span class="pi pi-ticket" style="margin-right: 10px;"></span><b>Voucher Code</b> : {{Voucher.vouchercode}}
        </div>
        <div style="width:100%; margin-bottom: 15px;">
            <span class="pi pi-th-large" style="margin-right: 10px;"></span><b>Voucher PIN</b> : {{Voucher.voucherpin}}
        </div>
        <div style="width:100%; margin-bottom: 15px;">
            <span class="pi pi-user" style="margin-right: 10px;"></span><b>Customer Name</b> : {{Voucher.customername }}
        </div>
        <div style="width:100%; margin-bottom: 15px;">
            <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Voucher Value</b> : {{Voucher.initialvalue | currency:CurrencySymbol }}
        </div>
        <div style="width:100%; margin-bottom: 15px;">
            <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>Expiry Date</b> : {{Voucher.expiry | date:'dd/MM/yyyy' : 'UTC'}}
        </div>
        <div style="width:100%; margin-bottom: 15px;">
            <span class="pi pi-user" style="margin-right: 10px;"></span><b>Recipient</b> : {{Voucher.recipientsname}}
        </div>
        <div style="width:100%; margin-bottom: 15px;">
            <span class="pi pi-envelope" style="margin-right: 10px;"></span><b>Recipient Email</b> : {{Voucher.recipientsemailsaddress}}
        </div>
        <div style="width:100%; margin-bottom: 15px;">
            <span class="pi pi-heart-fill" style="margin-right: 10px;"></span><b>Message</b> : {{Voucher.message}}
        </div>

    </div>

        <div style="margin-top: 25px;">
            <button pButton pRipple type="button" class="p-button-success p-element p-button w-auto mt-3" (click)="sendEmailConfirmation()">
            <span class="pi pi-envelope" style="margin-right:8px;"></span>
            Send Voucher Email</button>
            <!-- &nbsp;
            <button pButton pRipple type="button" class="p-button-success p-element p-button w-auto mt-3" (click)="sendSMSConfirmation()">
            <span class="pi pi-mobile" style="margin-right:8px;"></span>
            Send Confirmation Text</button> -->
            &nbsp;
            <button pButton pRipple type="button" class="p-button-info p-element p-button w-auto mt-3" (click)="printConfirmation()">
            <span class="pi pi-print" style="margin-right:8px;"></span>
            Print Voucher</button>
            &nbsp;
            <button pButton pRipple type="button" class="p-button p-element p-button w-auto mt-3" (click)="returnHome()">
            <span class="pi pi-calendar-plus" style="margin-right:8px;"></span>
            Buy Another Voucher</button>
        </div>
    </div>

