import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { timesets_returndata } from 'src/types/timesets_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cTimesets, timesets } from 'src/types/timesets';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';
import { holidayopenings } from 'src/types/holidayopenings';
import { ThisReceiver } from '@angular/compiler';
import { v4 } from 'uuid';
import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-timesetview',
  templateUrl: './timesetview.component.html',
  styleUrls: ['./timesetview.component.css']
})
export class TimesetviewComponent implements OnInit {

  VenueID: string = localStorage.getItem("SmartVenueID")!;
  TimeSet: cTimesets;
  httpResponse: any;
  id: string;
  stateOptions: any;
  holidayOpeningStartDate: Date;
  holidayOpeningEndDate: Date;
  isdefault: any;
  pageReady: boolean = false;

  timesetNameValid: boolean = true;
  
  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 
    this.stateOptions = [{label: 'No', value: false}, {label: 'Yes', value: true}];

    this.TimeSet = {
      id: null,
      venueid: this.VenueID,
      isdefault: false,
      name: "",
      disabled: false,
      holidayopenings: [],
      isactive: false
    }


  }


  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("PACKAGE", user)){
          this.AccessAllowed = true;
          this.id = this.route.snapshot.paramMap.get('id');
          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "timesets/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as timesets_returndata)
              if (this.httpResponse.succeeded)
              {
                this.TimeSet = {
                  id: (this.httpResponse.data as cTimesets).id,
                  venueid: (this.httpResponse.data as cTimesets).venueid,
                  isdefault: (this.httpResponse.data as cTimesets).isdefault,
                  name:(this.httpResponse.data as cTimesets).name,
                  disabled:(this.httpResponse.data as cTimesets).disabled,
                  holidayopenings: (this.httpResponse.data as cTimesets).holidayopenings,
                  isactive: (this.httpResponse.data as cTimesets).isactive
                };
      
                this.pageReady = true;
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Time Sets'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Time Sets, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
          else{
            this.pageReady = true;
          }
        }

        this.CompletedLoading = true;


      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  returnToList()
  {
    this.router.navigateByUrl("/timesets");
  }


}
