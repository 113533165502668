<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed" class="p-3 flex flex-column flex-auto">
    <h3 style="margin-top: 18px;" i18n>Sales for period: </h3>
    <div style="width: calc(100vw - 607px); float: left; margin-left: 272px; margin-bottom:20px;margin-top:-71px;">
        
        <div style="float:left; width: 200px;margin-right: 20px;">
            <span>From Date:</span>
            <p-calendar [selectOtherMonths]="true" [(ngModel)]="FromDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>  
        </div> 
       &nbsp;
        <div style="float:left; width: 200px;">
            <span>To Date:</span>
            <p-calendar [selectOtherMonths]="true" [(ngModel)]="ToDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>  
        </div> 
        &nbsp;

        <div style="float:left; width: 400px;margin-left:24px;">
            <span>Tags:</span><br>
            <p-multiSelect [options]="tags" optionLabel="name" [(ngModel)]="chosentags" display="chip" style="float:left;">
                <ng-template let-value pTemplate="selectedItems">
                  <span *ngFor="let tag of chosentags">
                    <p-chip label="{{tag.name}}" styleClass="mr-2" [style]="{'background': tag.colour, 'color': 'white', 'border-radius': '5px' }">
                    </p-chip>
                  </span>
                  <div *ngIf="!chosentags || chosentags.length === 0" class="country-placeholder"> Select Tag(s) </div>
                </ng-template>
              </p-multiSelect>

              <div style="width:180px; float:left;margin-top:-16px;">

                <div style="float:left; width: 90px;">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" style="background-color: #22C55E;border-color: #22C55E;margin-left: 12px;" (click)="doneDate()">
                        Go
                      </button>
                </div> 
                <div style="float:left; width: 90px;margin-top:16px;">
                    <button *ngIf="ListData"
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-file-excel"
                        (click)="salesTable.exportCSV()"
                        class="mr-2"
                        pTooltip="Export to CSV"
                        tooltipPosition="bottom">
                    </button>
                </div>
              </div>
        </div>
        <!-- <div style="float:left; width: 90px;">
            <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" style="background-color: #22C55E;border-color: #22C55E;margin-top: 24px !important;margin-left: 12px;" (click)="doneDate()">
                Go
              </button>
        </div>  -->
        <!-- <div style="float:left; width: 90px;margin-top:24px;">
        <button *ngIf="ListData"
            type="button"
            pButton
            pRipple
            icon="pi pi-file-excel"
            (click)="salesTable.exportCSV()"
            class="mr-2"
            pTooltip="Export to CSV"
            tooltipPosition="bottom">
        </button>
        </div> -->
    </div>

    <div class="surface-card p-4 shadow-2 border-round">
     <p-table  #salesTable 
        [columns]="cols"
        [value]="ListData"
        responsiveLayout="scroll"
        style="display:none;">

        <ng-template pTemplate="header">
            <tr>
                <th width="10%">Start Date
                   
                </th>
                <th width="8%">Start
                   
                </th>
                <th width="15%">Package
                    
                </th>
                <th>Sales ID
                    
                </th>
                <th>Name
                    
                </th>
                <th>
                    Discount
                </th>
                <th>
                    Discount Value
                </th>
                <th>Guests
                
                </th>
                <th>Total
                
                </th>
                <th>O/S
                    
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td>{{rowData.startTime | date:'dd/MM/yy'}}</td>
                <td>{{rowData.startTime | date:'HH:mm' : 'UTC'}}</td>
                <td>{{rowData.packageName}}</td>
                <td>{{rowData.bookingIdHR}}</td>
                <td>{{rowData.name}}</td>
                <td>{{rowData.discount}}</td>
                <td>{{rowData.totaldiscount | currency: CurrencySymbol}}</td>
                <td>{{rowData.guests}}</td>
                <td>{{rowData.total | currency: CurrencySymbol}}</td>
                <td *ngIf="rowData.outstanding <=0">{{rowData.outstanding | currency: CurrencySymbol}}</td>
                <td *ngIf="rowData.outstanding >0"><span style="color: Red; font-weight: bold;">{{rowData.outstanding | currency: CurrencySymbol}}</span></td>
            </tr>
        </ng-template>
    </p-table>
       
    
        <p-table
            [columns]="cols"
            [value]="ListDataFormatted"
            responsiveLayout="scroll">

            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td style="font-weight: bold;">
                        <button style="width: 25px;height: 25px;border-radius: 4px;border: 2px solid #999999;background-color: transparent;" type="button" (click)="collapseDiv('DIV-' + rowData.DID, rowData.DID)">
                            <span style="margin-left: 1px;font-size: 10px;float: left;" [class]="divClass[rowData.DID]"></span>
                        </button>&nbsp;&nbsp;{{rowData.method}}<span *ngIf="divTotal[rowData.DID]">&nbsp;&nbsp;-&nbsp;&nbsp;Total: {{rowData.total | currency: CurrencySymbol}}</span>
                    </td>
                </tr>
                <tr>
                    <td id="DIV-{{rowData.DID}}">
                        <p-table
                        [columns]="cols"
                        [value]="rowData.data"
                        responsiveLayout="scroll">

                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th style="text-align: left; width: 10%">
                                    Date
                                </th>
                                <th style="text-align: left; width: 10%">
                                    Start Time
                                </th>
                                <th style="text-align: left; width: 25%">
                                    Sales ID
                                </th>
                                <th style="text-align: left; width: 15%">
                                    Name
                                </th>
                                <th style="text-align: left; width: 10%">
                                    Discount
                                </th>
                                <th style="text-align: left; width: 5%">
                                    Discount Value
                                </th>
                                <th style="text-align: right; width: 10%">
                                    Guests
                                </th>
                                <th style="text-align: right; width: 10%">
                                    Total
                                </th>
                                <th style="text-align: right; width: 10%">
                                    Outstanding
                                </th>
                            </tr>
                        </ng-template>
            
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>{{rowData.startTime | date:'dd/MM/yy'}}</td>
                                <td>{{rowData.startTime | date:'HH:mm' : 'UTC'}}</td>
                                <td>{{rowData.bookingIdHR}}</td>
                                <td>{{rowData.name}}</td>
                                <td>{{rowData.discount}}</td>
                                <td style="text-align:right;">{{rowData.totaldiscount | currency: CurrencySymbol}}</td>
                                <td style="text-align:right;">{{rowData.guests}}</td>
                                <td style="text-align:right;">{{rowData.total | currency: CurrencySymbol}}</td>
                                <td style="text-align:right;" *ngIf="rowData.outstanding <=0">{{rowData.outstanding | currency: CurrencySymbol}}</td>
                                <td style="text-align:right;" *ngIf="rowData.outstanding >0"><span style="color: Red; font-weight: bold;">{{rowData.outstanding | currency: CurrencySymbol}}</span></td>
                            </tr>
                        </ng-template>

                        <ng-template  pTemplate="footer">
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style="text-align:right;">{{rowData.totaltotdiscount | currency: CurrencySymbol}}</td>
                                <td style="text-align:right;">{{rowData.guesttotal}}</td>
                                <td style="text-align:right;">{{rowData.total | currency: CurrencySymbol}}</td>
                                <td style="text-align:right;">{{rowData.ostotal | currency: CurrencySymbol}}</td>
                            </tr>   
                        </ng-template>
            
                        </p-table>
                    </td>
                </tr>
            </ng-template>
            <ng-template  pTemplate="footer">
                <tr *ngIf="ListDataFormatted.length == 0">
                    <td style="text-align: center;" colspan="8">
                        There is no data for the selected date range.
                    </td>
                </tr>
                <tr *ngIf="TotalAmount > 0">
                    <td>Total: {{TotalAmount | currency: CurrencySymbol}} &nbsp;-&nbsp;Total Discounts: {{TotalDiscounts | currency: CurrencySymbol}} &nbsp;-&nbsp;Total O/S: {{TotalOS | currency: CurrencySymbol}}&nbsp;-&nbsp;Total Guests: {{TotalGuests}}</td>
                </tr>   
            </ng-template>
        </p-table>
    
    </div>
</div>
