<div  class="p-3 flex flex-column flex-auto">
    <h3>Reports </h3>

    <div class="surface-card p-4 shadow-2 border-round">
        <div style="width:50%; float:left;margin-right:2%;margin-bottom:30px;">
            
            <div style="border: 2px solid #F6F6F6;border-radius: 7px;height: 76px; padding: 5px; margin-bottom:6px;" (mouseenter)="showPaymentText()" onmouseover="this.style.background='#f6f6f6';" onmouseout="this.style.background='white';" (click)="doPaymentsReport()">
                <div style="width:10%;float:left;min-width:70px;">
                    <img style="width:70px;" src="assets/images/payments.png">
                </div>
                <div style="width: 85%; float: left; font-size: 18px; margin-top: 20px; margin-left: 10px;cursor: pointer;">Payments Report</div>
            </div>
            <div style="clear: both;"></div>
            <div style="border: 2px solid #F6F6F6;border-radius: 7px;height: 76px; padding: 5px; margin-bottom:6px;" (mouseenter)="showCuwtomerText()" onmouseover="this.style.background='#f6f6f6';" onmouseout="this.style.background='white';" (click)="doCustomerReport()">
                <div style="width:10%;float:left;min-width:70px;">
                    <img style="width:70px;" src="assets/images/customerreport.png">
                </div>
                <div style="width: 85%; float: left; font-size: 18px; margin-top: 20px; margin-left: 10px;cursor: pointer;">Customer Report</div>
            </div>
            <div style="clear: both;"></div>
            <div style="border: 2px solid #F6F6F6;border-radius: 7px;height: 76px; padding: 5px; margin-bottom:6px;" (mouseenter)="showExtrasText()" onmouseover="this.style.background='#f6f6f6';" onmouseout="this.style.background='white';" (click)="doExtrasReport()">
                <div style="width:10%;float:left;min-width:70px;">
                    <img style="width:70px;" src="assets/images/extrasreport.png">
                </div>
                <div style="width: 85%; float: left; font-size: 18px; margin-top: 20px; margin-left: 10px;cursor: pointer;">Extras Report</div>
            </div>
            <div style="clear: both;"></div>
            <div style="border: 2px solid #F6F6F6;border-radius: 7px;height: 76px; padding: 5px; margin-bottom:6px;" (mouseenter)="showBQText()" onmouseover="this.style.background='#f6f6f6';" onmouseout="this.style.background='white';" (click)="doBQReport()">
                <div style="width:10%;float:left;min-width:70px;">
                    <img style="width:70px;" src="assets/images/bookingquestions.png">
                </div>
                <div style="width: 85%; float: left; font-size: 18px; margin-top: 20px; margin-left: 10px;cursor: pointer;">Booking Questions Report</div>
            </div>
            <div style="clear: both;"></div>
            <div style="border: 2px solid #F6F6F6;border-radius: 7px;height: 76px; padding: 5px; margin-bottom:6px;" (mouseenter)="showBLText()" onmouseover="this.style.background='#f6f6f6';" onmouseout="this.style.background='white';" (click)="doBookingListReport()">
                <div style="width:10%;float:left;min-width:70px;">
                    <img style="width:70px;" src="assets/images/bookinglist.png">
                </div>
                <div style="width: 85%; float: left; font-size: 18px; margin-top: 20px; margin-left: 10px;cursor: pointer;">Booking List Report</div>
            </div>
            <div style="clear: both;"></div>
            <div style="border: 2px solid #F6F6F6;border-radius: 7px;height: 76px; padding: 5px; margin-bottom:6px;" (mouseenter)="showSalesText()" onmouseover="this.style.background='#f6f6f6';" onmouseout="this.style.background='white';" (click)="doSalesReport()">
                <div style="width:10%;float:left;min-width:70px;">
                    <img style="width:70px;" src="assets/images/salesreport.png">
                </div>
                <div style="width: 85%; float: left; font-size: 18px; margin-top: 20px; margin-left: 10px;cursor: pointer;">Sales Report</div>
            </div>
            <div style="clear: both;"></div>
        
        </div>

        <div style="width:48%; float:left;">
            <div style="border: 2px solid #F6F6F6;border-radius: 7px; padding: 10px; margin-bottom:6px;width:100%; padding-bottom:30px;">
                <div [innerHTML]="ReportInfo" ></div>
                <br>
                <button *ngIf="ShowPaymentReportButton" pButton type="button" value="Last Year" class="p-button" style="width:31%;margin-bottom: 20px;margin-right:3%;float: left;height: 75px;" (click)="doPaymentsReport()">
                    <div style="width:100%; margin: auto;">
                        <div style="width:100%;">Run Report<span style="margin-left: 8px;" class="pi pi-angle-right"></span></div>
                    </div>
                </button>
    
                <button *ngIf="ShowCustomerReportButton" pButton type="button" value="Last Year" class="p-button" style="width:31%;margin-bottom: 20px;margin-right:3%;float: left;height: 75px;" (click)="doCustomerReport()">
                    <div style="width:100%; margin: auto;">
                        <div style="width:100%;">Run Report<span style="margin-left: 8px;" class="pi pi-angle-right"></span></div>
                    </div>
                </button>
    
                <button *ngIf="ShowExtrasReportButton" pButton type="button" value="Last Year" class="p-button" style="width:31%;margin-bottom: 20px;margin-right:3%;float: left;height: 75px;" (click)="doExtrasReport()">
                    <div style="width:100%; margin: auto;">
                        <div style="width:100%;">Run Report<span style="margin-left: 8px;" class="pi pi-angle-right"></span></div>
                    </div>
                </button>
    
                <button *ngIf="ShowBQReportButton" pButton type="button" value="Last Year" class="p-button" style="width:31%;margin-bottom: 20px;margin-right:3%;float: left;height: 75px;" (click)="doBQReport()">
                    <div style="width:100%; margin: auto;">
                        <div style="width:100%;">Run Report<span style="margin-left: 8px;" class="pi pi-angle-right"></span></div>
                    </div>
                </button>
    
                <button *ngIf="ShowBookingListReportButton" pButton type="button" value="Last Year" class="p-button" style="width:31%;margin-bottom: 20px;margin-right:3%;float: left;height: 75px;" (click)="doBookingListReport()">
                    <div style="width:100%; margin: auto;">
                        <div style="width:100%;">Run Report<span style="margin-left: 8px;" class="pi pi-angle-right"></span></div>
                    </div>
                </button>
    
                <button *ngIf="ShowSalesReportButton" pButton type="button" value="Last Year" class="p-button" style="width:31%;margin-bottom: 20px;margin-right:3%;float: left;height: 75px;" (click)="doSalesReport()">
                    <div style="width:100%; margin: auto;">
                        <div style="width:100%;">Run Report<span style="margin-left: 8px;" class="pi pi-angle-right"></span></div>
                    </div>
                </button>

                <div style="clear: both;"></div>
            </div>


            <!-- <div style="width: 100%; margin-left: auto; margin-right: auto; text-align: center;">
                <div style="margin-top:30px;margin-left: auto; margin-right: auto;">
                    <button pButton type="button" value="Last Year" class="p-button" style="width:31%;margin-bottom: 20px;margin-right:3%;float: left;height: 75px;">
                        <div style="width:100%; margin: auto;">
                            <div style="width:100%;">Last Year<span style="margin-left: 8px;" class="pi pi-angle-right"></span></div>
                        </div>
                    </button>
                    <button pButton type="button" value="Year To Date" class="p-button" style="width:31%;margin-bottom: 20px;margin-right:3%;float: left;height: 75px;">
                        <div style="width:100%; margin: auto;">
                            <div style="width:100%;">Year to Date
                                <span style="margin-left: 8px;" class="pi pi-angle-right"></span>
                            </div>
                            
                        </div>
                    </button>
                    <button pButton type="button" value="Last Month" class="p-button" style="width:31%;margin-bottom: 20px;float: left;height: 75px;">
                        <div style="width:100%; margin: auto;">
                            <div style="width:100%;">Last Month<span style="margin-left: 8px;" class="pi pi-angle-right"></span></div>
                        </div>
                    </button>

                    <button pButton type="button" value="Last Week" class="p-button" style="width:31%;margin-bottom: 20px;margin-right:3%;float: left;height: 75px;">
                        <div style="width:100%; margin: auto;">
                            <div style="width:100%;">Last Week <span style="margin-left: 8px;" class="pi pi-angle-right"></span></div>
                        
                        </div>
                    </button>
                    <button pButton type="button" value="Month to Date" class="p-button" style="width:31%;margin-bottom: 20px;margin-right:3%;float: left;height: 75px;">
                        <div style="width:100%; margin: auto;">
                            <div style="width:100%;">Month to Date<span style="margin-left: 8px;" class="pi pi-angle-right"></span></div>
                            
                        </div>
                    </button>
                    <button pButton type="button" value="Today" class="p-button" style="width:31%;margin-bottom: 20px;float: left;height: 75px;">
                        <div style="width:100%; margin: auto;">
                            <div style="width:100%;">Today<span style="margin-left: 8px;" class="pi pi-angle-right"></span></div>
                            
                        </div>
                    </button>

                    <button pButton type="button" value="Next Week" class="p-button" style="width:31%;margin-bottom: 20px;margin-right:3%;float: left;height: 75px;">
                        <div style="width:100%; margin: auto;">
                            <div style="width:100%;">Next Week<span style="margin-left: 8px;" class="pi pi-angle-right"></span></div>
                            
                        </div>
                    </button>
                    <button pButton type="button" value="Next Month" class="p-button" style="width:31%;margin-bottom: 20px;margin-right:3%;float: left;height: 75px;">
                        <div style="width:100%; margin: auto;">
                            <div style="width:100%;">Next Month<span style="margin-left: 8px;" class="pi pi-angle-right"></span></div>
                            
                        </div>
                    </button>
                    <button pButton type="button" value="Next Year" class="p-button" style="width:31%;margin-bottom: 20px;float: left;height: 75px;">
                        <div style="width:100%; margin: auto;">
                            <div style="width:100%;">Next Year<span style="margin-left: 8px;" class="pi pi-angle-right"></span></div>
                            
                        </div>
                    </button>
                </div>
            </div> -->
        </div>
    </div>
</div>
