<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed" class="p-3 flex flex-column flex-auto">
    <h3 style="margin-top: 18px;" i18n>Payments for period: </h3>
    <div style="width: calc(100vw - 607px); float: left; margin-left: 272px; margin-bottom:20px;margin-top:-71px;">
        
        <div style="float:left; width: 200px;margin-right: 20px;">
            <span>From Date:</span>
            <p-calendar [selectOtherMonths]="true" [(ngModel)]="FromDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>  
        </div> 
       &nbsp;
        <div style="float:left; width: 200px;">
            <span>To Date:</span>
            <p-calendar [selectOtherMonths]="true" [(ngModel)]="ToDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>  
        </div> 
        &nbsp;
        <div style="float:left; width: 90px;">
            <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" style="background-color: #22C55E;border-color: #22C55E;margin-top: 24px !important;margin-left: 12px;" (click)="doneDate()">
                Go
              </button>
        </div> 
        <div style="float:left; width: 90px;margin-top:24px;">
        <button *ngIf="ListData"
            type="button"
            pButton
            pRipple
            icon="pi pi-file-excel"
            (click)="paymnentsTable.exportCSV()"
            class="mr-2"
            pTooltip="Export to CSV"
            tooltipPosition="bottom">
        </button>
        </div>
    </div>



    <div class="surface-card p-4 shadow-2 border-round">
        <p-table  #paymnentsTable style="display:none;"
        [columns]="cols"
        [value]="ListDataGlobal" sortField="name" 
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
        [globalFilterFields]="['name', 'bookingId', 'bookingIdHR', 'packageName']"
        responsiveLayout="scroll">
        <ng-template pTemplate="caption">
            <div class="flex">
            <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-excel"
                (click)="paymnentsTable.exportCSV()"
                class="mr-2"
                pTooltip="Export to CSV"
                tooltipPosition="bottom">
            </button>
            </div>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td>{{rowData.bookingIdHR}}</td>
                <td>{{rowData.method}}</td>
                <td *ngIf="rowData.amount >= 0" style="text-align:right;">{{rowData.amount | currency: CurrencySymbol}}</td>
                <td *ngIf="rowData.amount < 0" style="text-align:right;color:red;font-weight:bold;">{{rowData.amount | currency: CurrencySymbol}}</td>

                <td>{{rowData.paymentDate | date:'dd/MM/yy HH:mm'}}</td>
                <td *ngIf="rowData.isRefund"><span style="color: #22C55E;font-size: 20px;font-weight: bold;" class="pi pi-check"></span></td>
                <td *ngIf="!rowData.isRefund">&nbsp;</td>
                
                <td *ngIf="rowData.isOnline"><span style="color: #22C55E;font-size: 20px;font-weight: bold;" class="pi pi-check"></span></td>
                <td *ngIf="!rowData.isOnline">&nbsp;</td>
                
                <td *ngIf="rowData.isVoucher"><span style="color: #22C55E;font-size: 20px;font-weight: bold;" class="pi pi-check"></span></td>
                <td *ngIf="!rowData.isVoucher">&nbsp;</td>
                
                <td>{{rowData.notes}}</td>
            </tr>
        </ng-template>

        <ng-template  pTemplate="footer">
            <tr *ngIf="ListDataGlobal.length == 0">
                <td style="text-align: center;" colspan="8">
                    There is no data for the selected date range.
                </td>
            </tr>
            <tr *ngIf="TotalAmount > 0">
                <td>Total</td>
                <td></td>
                <td style="text-align:right;">{{TotalAmount | currency: CurrencySymbol}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>   
        </ng-template>
        </p-table>


        <p-table
            [columns]="cols"
            [value]="ListData"
            responsiveLayout="scroll">

            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td style="font-weight: bold;">
                        <button style="width: 25px;height: 25px;border-radius: 4px;border: 2px solid #999999;background-color: transparent;" type="button" (click)="collapseDiv('DIV-' + rowData.DID, rowData.DID)">
                            <span style="margin-left: 1px;font-size: 10px;float: left;" [class]="divClass[rowData.DID]"></span>
                        </button>&nbsp;&nbsp;{{rowData.method}}<span *ngIf="divTotal[rowData.DID]">&nbsp;&nbsp;-&nbsp;&nbsp;Total: {{rowData.total | currency: CurrencySymbol}}</span>
                    </td>
                </tr>
                <tr>
                    <td id="DIV-{{rowData.DID}}">
                        <p-table
                        [columns]="cols"
                        [value]="rowData.data"
                        responsiveLayout="scroll">

                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th style="text-align: left; width: 20%;">
                                    Sales ID
                                </th>
                                <th style="text-align: left;width: 20%;">
                                    Method
                                </th>
                                <th style="text-align: right;width: 10%;" >
                                    Amount
                                </th>
                                <th style="text-align: left;width: 10%;">
                                    Date
                                </th>
                                <th style="text-align: left;width: 10%;">
                                    Refund
                                </th>
                                <th style="text-align: left;width: 10%;">
                                    Online
                                </th>
                                <th style="text-align: left;width: 10%;">
                                    Voucher
                                </th>
                                <th style="text-align: left;width: 10%;">
                                    Notes
                                </th>
                            </tr>
                        </ng-template>
            
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>{{rowData.bookingIdHR}}</td>
                                <td>{{rowData.method}}</td>
                                <td *ngIf="rowData.amount >= 0" style="text-align:right;">{{rowData.amount | currency: CurrencySymbol}}</td>
                                <td *ngIf="rowData.amount < 0" style="text-align:right;color:red;font-weight:bold;">{{rowData.amount | currency: CurrencySymbol}}</td>
                
                                <td>{{rowData.paymentDate | date:'dd/MM/yy'}}</td>
                                <td *ngIf="rowData.isRefund"><span style="color: #22C55E;font-size: 20px;font-weight: bold;" class="pi pi-check"></span></td>
                                <td *ngIf="!rowData.isRefund">&nbsp;</td>
                                
                                <td *ngIf="rowData.isOnline"><span style="color: #22C55E;font-size: 20px;font-weight: bold;" class="pi pi-check"></span></td>
                                <td *ngIf="!rowData.isOnline">&nbsp;</td>
                                
                                <td *ngIf="rowData.isVoucher"><span style="color: #22C55E;font-size: 20px;font-weight: bold;" class="pi pi-check"></span></td>
                                <td *ngIf="!rowData.isVoucher">&nbsp;</td>
                                
                                <td>{{rowData.notes}}</td>
                            </tr>
                        </ng-template>

                        <ng-template  pTemplate="footer">
                            <tr *ngIf="TotalAmount > 0">
                                <td>Total</td>
                                <td></td>
                                <td style="text-align:right;">{{rowData.total | currency: CurrencySymbol}}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>   
                        </ng-template>
            
                        </p-table>
                    </td>
                </tr>
            </ng-template>
            <ng-template  pTemplate="footer">
                <tr *ngIf="ListData.length == 0 || ListData == null">
                    <td style="text-align: center;" colspan="8">
                        There is no data for the selected date range.
                    </td>
                </tr>
                <tr *ngIf="TotalAmount > 0">
                    <td>Grand Total: {{TotalAmount | currency: CurrencySymbol}}</td>
                </tr>   
            </ng-template>
        </p-table>
    </div>
</div>
