import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-bookingfee',
  templateUrl: './bookingfee.component.html',
  styleUrls: ['./bookingfee.component.css']
})
export class BookingfeeComponent implements OnInit {

  constructor(private globalService: GlobalService) { }

  ngOnInit(): void {
    this.globalService.setSmallScreenMenu(false);
  }

}
