import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { departments_returndata } from 'src/types/departments_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';

import { cDepartments } from 'src/types/departments';
import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';


@Component({
  selector: 'app-tilldepartmentsedit',
  templateUrl: './tilldepartmentsedit.component.html',
  styleUrls: ['./tilldepartmentsedit.component.css']
})
export class TilldepartmentseditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  Department: cDepartments;
  httpResponse: any;
  id: string;
  pageReady: boolean = true;

  departmentNameValid: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 
    this.Department = {
      id: null,
      name: "",
      venueid: this.VenueID,
      disabled: false,
      colour: ""
    }


  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("TILLDEPARTMENTS", user)){
          this.AccessAllowed = true;
          this.id = this.route.snapshot.paramMap.get('id');
          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "tilldepartments/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as departments_returndata)
              if (this.httpResponse.succeeded)
              {
                this.Department = {
                  id: (this.httpResponse.data as cDepartments).id,
                  name: (this.httpResponse.data as cDepartments).name,
                  venueid: (this.httpResponse.data as cDepartments).venueid,
                  disabled: (this.httpResponse.data as cDepartments).disabled,
                  colour: (this.httpResponse.data as cDepartments).colour
                };
      
                this.pageReady = true;
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Department'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Department, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
        }

        this.CompletedLoading = true;


      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  updateData()
  {
    if (this.DoValidCheck())
    {
      if(this.id == "0")
      {
        this.http.post<cDepartments>(bmxurl + "/tilldepartments", this.Department).subscribe(response => {
          this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              this.messageService.add({severity:'success', summary:'Saved', detail:'Department successfully updated'});
              this.router.navigateByUrl("/tilldepartments");
            }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
          })
      }
      else
      {
        this.Department.disabled = false;
        
        this.http.put<cDepartments>(bmxurl + "/tilldepartments", this.Department).subscribe(response => {
        this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Department successfully updated'});
            this.router.navigateByUrl("/tilldepartments");
          }
          }, error => {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.Department.name == null || this.Department.name == "")
    {
      AllValid = false;
      this.departmentNameValid = false;
    }
    else
    {
      this.departmentNameValid = true;
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/tilldepartments");
  }
}
