<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
  </div>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Package Rule</h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            Give your package rule a name and then define how it works.  You will need to assign this to a package for the rule to be used.
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="agegroupname" class="font-medium">What name should we give to this rule?</label>
                    <input [(ngModel)]="PackageRule.name" id="agegroupname" type="text" [ngStyle]="{'border-color': (pacakgeRuleNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!pacakgeRuleNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Package Rule name.</div>

                    <div class="field mb-4 col-12">
                        <label for="MaxConcurrent" class="font-medium">Do you want to restrict the number of bookings you have at anyone time? For example you only want 2 parties at the same time.<br>
                            if so, how many bookings do you want?
                            </label>
                        <p-inputNumber id="MaxConcurrent" [style]="{'width': '300px'}" [(ngModel)]="PackageRule.maxConcurrent == null ? 0: PackageRule.maxConcurrent" [showButtons]="true" max="100000" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                    </div>

                    <div class="field mb-4 col-12">
                        <label for="RestrictExactSimiltaneousStartTimes" class="font-medium">Is this restricted to just the start time for each booking or is this for the duration of the booking?
                            </label>
                        <p-selectButton id="RestrictExactSimiltaneousStartTimes" [style]="{'width': '350px'}" [options]="stateOptions" [(ngModel)]="PackageRule.restrictExactSimiltaneousStartTimes" ></p-selectButton>
                    </div>

                    
                    
                    

                    <p-accordion style="background-color: white !important; margin-top: 5px; border: none;width:100%">
                        <p-accordionTab style="background-color: white !important; margin-top: 5px; border: none;;width:100%">
                          <p-header>
                            <div style="width:100%">
                                <span class="pi pi-cog" style="margin-right: 10px;"></span>Advanced
                            </div>
                          </p-header>
                          <div class="field mb-4 col-12">
                            <label for="RestrictGlobal" class="font-medium">Check this package rule against all of the venues resources. The default value is to check any bookings taking place with this package rule which are using any resources.  
                                If you switch this off then the package rule only checks bookings which use the resources contained within the package.
                                </label>
                            <p-selectButton id="RestrictGlobal" [style]="{'width': '350px'}" [options]="stateOptions2" [(ngModel)]="PackageRule.restrictGlobal" ></p-selectButton>
                        </div>


                        </p-accordionTab>
                      </p-accordion>














                    <div class="field mb-4 col-12" style="display:none;">
                        <label for="OnlyRunThisPackageAtSameTime" class="font-medium">Only run bookings with this package rule at the same time</label>
                        <p-selectButton id="OnlyRunThisPackageAtSameTime" [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="PackageRule.onlyRunThisPackageAtSameTime" ></p-selectButton>
                    </div>



                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>