import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { class_returndata } from 'src/types/class_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cClass, classes } from 'src/types/class';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';
import { cDepositpolicy } from 'src/types/depositpolicies';
import { depositpolicy_returndata } from 'src/types/depositpolicy_returndata';

import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';


@Component({
  selector: 'app-depositpoliciesedit',
  templateUrl: './depositpoliciesedit.component.html',
  styleUrls: ['./depositpoliciesedit.component.css']
})
export class DepositpolicieseditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  DepositPolicy: cDepositpolicy;
  httpResponse: any;
  id: string;
  pageReady: boolean = true;
  DepositPolicyNameValid: boolean = true;
  stateOptions:any;
  stateOptions2:any;
  alternativepercentage:boolean = false;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;


  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 
    this.DepositPolicy = {
      id: null,
      name: "",
      venueid: this.VenueID,
      disabled: false,
      alternativeamount: 0,
      alternativeamountthreshold:0,
      type: "",
      amount:0
    }
    this.stateOptions = [{ label: 'No Deposit', value: 'NoDeposit' }, { label: 'Fixed', value: 'Fixed' }, { label: 'Percentage', value: 'Percentage' }, { label: 'Per Person', value: 'PerPerson' }];
    this.stateOptions2 = [{ label: 'Yes', value: true }, { label: 'No', value: false}];

    
  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("DEPOSITPOLICIES", user)){
          this.AccessAllowed = true;

          this.id = this.route.snapshot.paramMap.get('id');
          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "depositpolicy/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as depositpolicy_returndata)
              if (this.httpResponse.succeeded)
              {
                this.DepositPolicy = {
                  id: (this.httpResponse.data as cDepositpolicy).id,
                  name: (this.httpResponse.data as cDepositpolicy).name,
                  venueid: (this.httpResponse.data as cDepositpolicy).venueid,
                  disabled: (this.httpResponse.data as cDepositpolicy).disabled,
                  alternativeamount: (this.httpResponse.data as cDepositpolicy).alternativeamount,
                  alternativeamountthreshold:(this.httpResponse.data as cDepositpolicy).alternativeamountthreshold,
                  type: (this.httpResponse.data as cDepositpolicy).type,
                  amount: (this.httpResponse.data as cDepositpolicy).amount
                };
      
                if( this.DepositPolicy.alternativeamount > 0)
                  this.alternativepercentage = true;
                else
                  this.alternativepercentage = false;
      
                this.pageReady = true;
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting classes'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting classes, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
        }

        this.CompletedLoading = true;


      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  updateData()
  {
    if (this.DoValidCheck())
    {

      if(this.DepositPolicy.type == "NoDeposit")
        this.DepositPolicy.amount = 0;

      if (!this.alternativepercentage)
      {
        this.DepositPolicy.alternativeamount = 0;
        this.DepositPolicy.alternativeamountthreshold = 0;
      }
        
      if(this.id == "0")
      {
        this.http.post<cClass>(bmxurl + "/depositpolicy", this.DepositPolicy).subscribe(response => {
          this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              this.messageService.add({severity:'success', summary:'Saved', detail:'Deposit Policy successfully updated'});
              this.router.navigateByUrl("/depositpolicies");
            }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
          })
      }
      else
      {
        this.DepositPolicy.disabled = false;
        
        this.http.put<cClass>(bmxurl + "/depositpolicy", this.DepositPolicy).subscribe(response => {
        this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Depost Policy successfully updated'});
            this.router.navigateByUrl("/depositpolicies");
          }
          }, error => {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.DepositPolicy.name == null || this.DepositPolicy.name == "")
    {
      AllValid = false;
      this.DepositPolicyNameValid = false;
    }
    else
    {
      this.DepositPolicyNameValid = true;
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/depositpolicies");
  }

}
