import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { class_returndata } from 'src/types/class_returndata'; 
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { classes } from 'src/types/class';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  ListUsers: any = [];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  BusinessID: string = localStorage.getItem("SmartBusinessID")!;
  httpResponse: any;
  DisabledClass: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  

  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService,private globalService: GlobalService) { 


      
    }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("USERMANAGEMENT", user)){
          this.AccessAllowed = true;
          this.loadgrid(false);
        }

        this.CompletedLoading = true;
       
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(UserID)
  {
    this.http.delete(bmxurl + "User/" + UserID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded == true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'User successfully disabled'});
        this.router.navigateByUrl("/users");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this User'});
    })
  }
  
  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "UserList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.ListUsers = (response as users_returndata);
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error getting Users, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail: 'Error getting Users, error: ' + error.messages});
    });
  }

  editData(EditData)
  {
    let UserID = EditData;
    this.router.navigateByUrl("/useredit/" + UserID);
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "User/" + element.userid).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.loadgrid(false);
      } 
      else{
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error disabling Users, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error disabling Users, error: ' + error.messages});
    })
    });
    
  }
  }

