import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppComponent } from '../app.component';
import { bmxurl, getAccess } from 'src/globals';
import { cPackage, packageObj } from 'src/types/package';
import { cExtra } from 'src/types/extra';
import { extra_returndata } from 'src/types/extra_returndata';
import { package_returndata } from 'src/types/package_returndata';
import { cPackageExtra } from 'src/types/packageExtra';
import { v4 } from 'uuid';

import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-packageextras',
  templateUrl: './packageextras.component.html',
  styleUrls: ['./packageextras.component.css']
})
export class PackageextrasComponent implements OnInit {
  pageReady: boolean = true;
  httpResponse: any;
  id: string;
  ListExtras: any;
  ListSelectedExtras: any;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  PackageObj: cPackage;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  

  constructor(private route: ActivatedRoute, private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService, private globalService: GlobalService) {

    
   }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("MAKEABOOKING", user)){
          this.AccessAllowed = true;
          this.id = this.route.snapshot.paramMap.get('id');

          this.getExtras();
      
          this.getPackage();
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }
  
  getExtras()
  {
    this.http.get(bmxurl + "ExtraList/" + this.VenueID + "/false").subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.ListExtras = ((response as extra_returndata).data as cExtra[]);
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error getting Extras, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail: 'Error getting Extras, error: ' + error.messages});
    });
  }

  RemoveFromList(product){
    this.PackageObj.extras = this.PackageObj.extras.filter(obj => obj.id !== product.id);
    
    this.ListExtras.push(product);

    var sortedArray: [] = this.ListExtras.sort((n1,n2) => {
      if (n1.name > n2.name) {
          return 1;
      }
  
      if (n1.name < n2.name) {
          return -1;
      }
    });

    this.ListExtras = sortedArray;
  }
  
  AddToList(product){

    if(product.max <= 0)
      product.max = 1;

    if(this.PackageObj.extras == null)
    {
      let newId = v4();
      let newpeId = v4();

      this.PackageObj.extras = [];
      let newExtra: cPackageExtra = {
        id: newId,
        packageExtraId: newpeId,
        extraId: product.extraId,
        name: product.name,
        description: product.description,
        venueId: product.venueId,
        monday: product.monday,
        tuesday: product.tuesday,
        wednesday: product.wednesday,
        thursday: product.thursday,
        friday: product.friday,
        saturday: product.saturday,
        sunday: product.sunday,
        extraOptions: null,
        price: product.price,
        perPerson: product.perPerson,
        min: product.min,
        max: product.max,
        qty: product.qty,
        question: product.question,
        answer: product.answer
      }

      this.PackageObj.extras.push(newExtra);
    }
    else{
      let newId = v4();
      let newpeId = v4();

      let newExtra: cPackageExtra = {
        id: newId,
        packageExtraId: newpeId,
        extraId: product.extraId,
        name: product.name,
        description: product.description,
        venueId: product.venueId,
        monday: product.monday,
        tuesday: product.tuesday,
        wednesday: product.wednesday,
        thursday: product.thursday,
        friday: product.friday,
        saturday: product.saturday,
        sunday: product.sunday,
        extraOptions: null,
        price: product.price,
        perPerson: product.perPerson,
        min: product.min,
        max: product.max,
        qty: product.qty,
        question: product.question,
        answer: product.answer
      }

      this.PackageObj.extras.push(newExtra);
    }

    this.ListExtras = this.ListExtras.filter(obj => obj.id !== product.id);
  }

  updateData()
  {
    //this.PackageObj.extras = [];
    //this.ListSelectedExtras.forEach(element => {
    //  this.PackageObj.extras.push(element);
    //});

    this.http.put<packageObj>(bmxurl + "/package", this.PackageObj).subscribe(response => {
      this.httpResponse = response
      if (this.httpResponse.succeeded) {
        this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Package successfully updated' });
        this.router.navigateByUrl("/package");
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Not Saved', detail: error.messages });
    })
  }

  returnToList()
  {
    this.router.navigateByUrl("/package");
  }

  getPackage() {
    if (this.id != "0") {
      this.http.get(bmxurl + "package/" + this.VenueID + "/" + this.id).subscribe(response => {
        this.httpResponse = (response as package_returndata)
        this.PackageObj = {
          id: (this.httpResponse.data as cPackage).id,
          packageId: (this.httpResponse.data as cPackage).packageId,
          name: (this.httpResponse.data as cPackage).name,
          eventid: (this.httpResponse.data as cPackage).eventid,
          description: (this.httpResponse.data as cPackage).description,
          imageurl: (this.httpResponse.data as cPackage).imageurl,
          message: (this.httpResponse.data as cPackage).message,
          disabled: (this.httpResponse.data as cPackage).disabled,
          venueID: (this.httpResponse.data as cPackage).venueID,
          resources: (this.httpResponse.data as cPackage).resources,
          startTimes: (this.httpResponse.data as cPackage).startTimes,
          admissions: (this.httpResponse.data as cPackage).admissions,
          packageLength: (this.httpResponse.data as cPackage).packageLength,
          questionnaire: (this.httpResponse.data as cPackage).questionnaire,
          min: (this.httpResponse.data as cPackage).min,
          max: (this.httpResponse.data as cPackage).max,
          visibility: (this.httpResponse.data as cPackage).visibility,
          tags: (this.httpResponse.data as cPackage).tags,
          startDate: new Date((this.httpResponse.data as cPackage).startDate),
          endDate: new Date((this.httpResponse.data as cPackage).endDate),
          colour: (this.httpResponse.data as cPackage).colour,
          packageRule: (this.httpResponse.data as cPackage).packageRule,
          packageRestriction: (this.httpResponse.data as cPackage).packageRestriction,
          extras: (this.httpResponse.data as cPackage).extras,
          publicDepositPolicy: (this.httpResponse.data as cPackage).publicDepositPolicy,
          venueDepositPolicy: (this.httpResponse.data as cPackage).venueDepositPolicy,
          additionalresources: (this.httpResponse.data as cPackage).additionalresources,
          confirmation:  (this.httpResponse.data as cPackage).confirmation,
          warning:  (this.httpResponse.data as cPackage).warning,
          originatingid:  (this.httpResponse.data as cPackage).originatingid,
          bookingscannotbemadewithinxminutes: (this.httpResponse.data as cPackage).bookingscannotbemadewithinxminutes,
          bookingscannotbemadeafterxminutes: (this.httpResponse.data as cPackage).bookingscannotbemadeafterxminutes,
          cannotmakebookingstoday: (this.httpResponse.data as cPackage).cannotmakebookingstoday,
          discounttype: (this.httpResponse.data as cPackage).discounttype,
          discountamount: (this.httpResponse.data as cPackage).discountamount,
          discountcode: (this.httpResponse.data as cPackage).discountcode,
          discountstart: (this.httpResponse.data as cPackage).discountstart,
          discountend: (this.httpResponse.data as cPackage).discountend,
          earlybirddiscount: (this.httpResponse.data as cPackage).earlybirddiscount,
          discountrangetype: (this.httpResponse.data as cPackage).discountrangetype,
          discountdaysbefore: (this.httpResponse.data as cPackage).discountdaysbefore,
          imageguid: (this.httpResponse.data as cPackage).imageguid,
          bookingtimeout: (this.httpResponse.data as cPackage).bookingtimeout

        }

        this.PackageObj.extras.forEach(item => {
          this.ListExtras = this.ListExtras.filter(obj => obj.extraId !== item.extraId);
        });

      }, error => {
      }
      );
    }
  }


}
