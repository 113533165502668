
<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<p-dialog header="Refund Payment" [(visible)]="displayModalRefund" [style]="{width: '50vw'}" [baseZIndex]="10000"  [modal]="true">
    <div class="surface-card p-4 shadow-2 border-round" style="margin-top:5px;">
    <div class="field mb-4 col-12">
        <label for="venueclosingname" class="font-medium">Refund Value</label>&nbsp;
        <br>
        <p-inputNumber [(ngModel)]="RefundPayment" mode="currency" currency="{{CurrencySymbol}}" locale="en-GB" [min]="0" [max]="9999"></p-inputNumber>
    </div>
    <div *ngIf="!RefundPaymentValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Refund value.</div>

    <div class="field mb-4 col-12">
        <label for="reason" class="font-medium">Refund Reason</label>&nbsp;
        <br>
        <textarea pInputText type="text" [(ngModel)]="RefundReason" style="width:100%; height: 100px;"></textarea>
    </div>
    <div *ngIf="!RefundTextValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Reason.</div>

    <p><b>Items will be refunded to the same payment type as the payment was originally made in.</b></p>
</div>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" label="Refund" styleClass="p-button p-button-success" (click)="doRefund()"></p-button>
      &nbsp;
      <p-button icon="pi pi-times" (click)="displayModalRefund=false" label="Cancel" styleClass="p-button p-button-danger" (click)="closeRefund()"></p-button>
  </ng-template>

</p-dialog>

<p-dialog header="Disable Reason" [(visible)]="showDisableReason" [style]="{width: '50vw', height: '50vh'}" [baseZIndex]="10000" [modal]="true">
    <div class="field mb-4 col-12">
        <div>
            <textarea pInputText type="text" style="width:100%; height: 15vw;" [(ngModel)]="disableNotes"></textarea>
        </div>
    </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-pencil" label="Disable Booking" styleClass="p-button p-button-success" (click)="disableBooking()"></p-button>
  </ng-template>
  
</p-dialog>

<div *ngIf="AccessAllowed" style="padding: 1rem;">
    <h3 style="margin-bottom:10px;">Edit Booking <span *ngIf="NewBooking.disabled" style="color:red;">&nbsp;- This Booking is Disabled</span></h3>
    <div class="surface-card p-4 shadow-2 border-round" style="width:70%; float:left">
        <div *ngIf="showReturnToSearch">
            <button  pButton type="button" style="height: 50px;margin-top: 20px;" class="p-button p-button p-button-danger" (click)="returnToSearch()">
                <span class="pi pi-search" style="margin-right:8px;"></span>
                <span>Return to Search Results</span>            
            </button>
        </div>

        <div *ngIf="!showReturnToSearch">
            <button  pButton type="button" style="height: 50px;margin-top: 20px;" class="p-button p-button p-button-danger" (click)="returnToList()">
                <span class="pi pi-list" style="margin-right:8px;"></span>
                <span>Return to List</span>            
            </button>
        </div>

        <div *ngIf="FromEvents">
            <button  pButton type="button" style="height: 50px;margin-top: 20px;" class="p-button p-button p-button-danger" (click)="returnToEvents()">
                <span class="pi pi-ticket" style="margin-right:8px;"></span>
                <span>Return to Events</span>            
            </button>
        </div>

        <p-tabView style="width:100%" [(activeIndex)]="activeIndex" (onChange)="checkTab()" >
            <p-tabPanel header="Details">
                <ng-template pTemplate="header">
                    <i class="pi pi-list" style="margin-right: 5px;"></i>
                    <span>Details</span>
                </ng-template>
                <ng-template pTemplate="body">
                    <div *ngIf="SelectedDate">
                        <div><label for="ddlPackage" class="font-medium">Date</label></div>
                        <div>
                            <p-calendar [selectOtherMonths]="true" [(ngModel)]="SelectedDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" (onSelect)="doneDate()"></p-calendar>  
                            
                            <span style="margin-left:20px;">Time: {{NewBooking.startTime | date:'HH:mm' : 'UTC'}}</span> &nbsp; 
                            <!--<button pButton type="button" style="height: 50px;margin-top: 20px;" class="p-button p-button">
                                <span class="pi pi-clock" style="margin-right:8px;"></span>
                                <span>Change Booking Time</span>            
                            </button>-->
                            &nbsp;
                            <button *ngIf="!NewBooking.disabled" pButton type="button" style="height: 50px;margin-top: 20px;" class="p-button p-button p-button-danger" (click)="showDisableMessage()">
                                <span class="pi pi-times" style="margin-right:8px;"></span>
                                <span>Disable this Booking</span>            
                            </button>

                            <button *ngIf="NewBooking.disabled" pButton type="button" style="height: 50px;margin-top: 20px;" class="p-button p-button p-button-success" (click)="enableBooking()">
                                <span class="pi pi-check" style="margin-right:8px;"></span>
                                <span>Enable this Booking</span>            
                            </button>
                        </div>
                    </div>

                    <div *ngIf="packages" style="margin-top:20px">
                        <label for="ddlPackage" class="font-medium">Package</label><br>
                        <p-dropdown [options]="packages" [(ngModel)]="SelectedPackage" placeholder="Select a Package" [showClear]="true" optionLabel="name" (onChange)="packageChanged()"></p-dropdown>
                    </div>

                    <div *ngIf="ShowAdmissions" style="margin-top:20px">
                        <label for="ddlPackage" class="font-medium">Admissions</label>
                        <div class="packageSelection clr" *ngFor="let a of (SelectedAdmissions)">
                            <div class="PackageSelectLabel" style="margin-bottom: 8px;">
                            {{a.bookingwizardtext}}:
                            </div>
                            <div class="packageSelect" style="max-width: 1000px; margin-bottom:20px;">
                            <!-- <p-inputNumber [(ngModel)]="SelectedAdmissions[a.index].noofpeople" [disabled]="SelectedAdmissions[a.index].fixed" [id]="'ad'+a.name" [showButtons]="true" max="{{a.max}}" min="{{a.min}}" buttonLayout="horizontal" spinnerMode="horizontal" (onInput)="sumAdmissions()" (onBlur)="sumAdmissions()"></p-inputNumber> -->
                            <p-inputNumber [(ngModel)]="SelectedAdmissions[a.index].noofpeople" [disabled]="a.fixed" [id]="'ad'+a.name" [showButtons]="true" [max]="a.max" [min]="a.min" buttonLayout="horizontal" spinnerMode="horizontal"  (onInput)="admissionsChanged()" (onBlur)="admissionsChanged()"></p-inputNumber>
                            </div>
                        </div>
                    </div>
<!--AdmissionEnabled-->
                    <div *ngIf="partyroomsVisible" class="field mb-4 col-12">
                        <label for="ddlPackage" class="font-medium">Additional Resources</label>

                        <div class="grid formgrid p-fluid">
                            <div class="field mb-4 col-12">
                                
                                <div class="packageSelection clr" *ngFor="let a of (PossibleResources)">
                                    <div *ngIf="a.mode == 'COMPLEX' && a.selectionmode != 'SYSTEMANY' && a.selectionmode != 'SYSTEMLIST' && a.selectionmode != ''"  class="PackageSelectLabel" style="margin-bottom: 8px;">
                                        <div style="margin-bottom:10px;" *ngIf="!a.optional"><b>Please select one of these {{a.name}}:</b></div>
                                        
                                        <div style="margin-bottom:10px;" *ngIf="a.optional">
                                            <div style="width:55px; float:left;"><p-inputSwitch [(ngModel)]="OptionalResources[a.orderindex]"></p-inputSwitch></div>
                                            <div *ngIf="!a.isconstructed == true" style="width:400px;float:left"><b>Would you like a {{a.name}}?</b></div>
                                            <div *ngIf="a.isconstructed == true" style="width:400px;float:left"><b>Would you like to choose a {{a.name}}?</b></div>
                                            <div style="clear:both;"></div>
                                        </div>
            
                                        <div *ngIf="a.optional && OptionalResources[a.orderindex] && a.isconstructed == true" >
                                            <div style="margin-bottom: 5px; max-width:400px;" class="packageSelection clr" *ngFor="let r of (a.selectableresources)">
                                                <!--<p-radioButton style="margin-top:5px; margin-bottom: 5px; margin-left: 10px" name="{{a.groupname}}" value="{{r}}" label="{{r.name}}" [(ngModel)]="SelectedSeletableResource[a.orderindex][r.orderindex]" (onClick)="donePartyRooms()"></p-radioButton>-->
                                                <p-toggleButton [(ngModel)]="r.ignore" onLabel="{{r.name}} - Selected" offLabel="{{r.name}}" (onChange)="toggleButton(a,r)"></p-toggleButton>
                                            </div>
                                            <div style="clear:both;"></div>
                                        </div>
        
                                        <div *ngIf="!a.optional" >
                                            <div style="margin-bottom: 5px; max-width:400px;" class="packageSelection clr" *ngFor="let r of (a.selectableresources)">
                                                <!--<p-radioButton style="margin-top:5px; margin-bottom: 5px; margin-left: 10px" name="{{a.groupname}}" value="{{r}}" label="{{r.name}}" [(ngModel)]="SelectedSeletableResource[a.orderindex][r.orderindex]" (onClick)="donePartyRooms()"></p-radioButton>-->
                                                <p-toggleButton [(ngModel)]="r.ignore" onLabel="{{r.name}} - Selected" offLabel="{{r.name}}" (onChange)="toggleButton(a,r)"></p-toggleButton>
                                            </div>
                                        </div>

                                        <div *ngIf="!validselection[a.orderindex] && validselection[a.orderindex] != null" class="text-danger" Style="margin-left:10px;">
                                            <div style="clear:both;"></div>
                                            <span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select from the list above.
                                        </div>
        
                                        <div *ngIf="a.quantityselector == 'CUSTOM' && a.optional" Style="margin-left:10px;">
                                            <div class="PackageSelectLabel" style="margin-bottom: 8px;">
                                                How many of the above resource would you like?
                                            </div>
                                            <div class="packageSelect" style="max-width: 500px; margin-bottom:20px;">
                                            <p-inputNumber [(ngModel)]="a.quantity" [showButtons]="true" max="100" min="1" buttonLayout="horizontal" spinnerMode="horizontal" (onInput)="donePartyRooms()"></p-inputNumber>
                                            </div>
                                        </div>
        
                                        <hr>
                                    </div>
        
                                    <div *ngIf="a.mode == 'COMPLEX'
                                        && a.optional && a.selectionmode == ''"  class="PackageSelectLabel" style="margin-bottom: 8px;">
                                        <div style="margin-bottom:10px;" *ngIf="!a.optional"><b>Please select one of these {{a.name}}:</b></div>
                                        <div style="margin-bottom:10px;" *ngIf="a.optional">
                                            <div style="width:55px; float:left;"><p-inputSwitch [(ngModel)]="OptionalResources[a.orderindex]"></p-inputSwitch></div>
                                            <div *ngIf="!a.isconstructed == true" style="width:400px;float:left"><b>Would you like a {{a.name}}?</b></div>
                                            <div *ngIf="a.isconstructed == true" style="width:400px;float:left"><b>Would you like to choose a {{a.name}}?</b></div>
                                            <div style="clear:both;"></div>
                                        </div>
        
                                        <div *ngIf="OptionalResources[a.orderindex] && a.quantityselector == 'CUSTOM'" Style="margin-left:10px;">
                                            <div class="PackageSelectLabel" style="margin-bottom: 8px;">
                                                How many of the above resource would you like?
                                            </div>
                                            <div class="packageSelect" style="max-width: 500px; margin-bottom:20px;">
                                            <p-inputNumber [(ngModel)]="a.quantity" [showButtons]="true" max="100" min="1" buttonLayout="horizontal" spinnerMode="horizontal" (onInput)="donePartyRooms()"></p-inputNumber>
                                            </div>
                                        </div>
        
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-top:20px">
                        <div *ngIf="startTimesFiltered">
                            <div *ngIf="!disgnosticsMode">    
                                <div *ngFor="let st of (startTimesFiltered)" class="times">
                                    <button pButton type="button" style="height: 70px;margin-top: 20px;font-size: 19px;" class="p-button p-button" (click)="bookSlot(st, false)">
                                        <span class="pi pi-clock" style="margin-right:8px;"></span>
                                        <span>{{ st.time | date:'HH:mm' : 'UTC' }}</span>            
                                    </button>
                            
                                </div>
                            </div>
                
                            <div *ngIf="disgnosticsMode">  
                                <div *ngFor="let st of (startTimes)">
                                    <div style="width:100%; margin-bottom: 10px;">
                                    
                                        <div *ngIf='st.primaryResource == true && st.timeAvailable == true'>
                                            <div style="float:left;">
                                                <div>
                                                    <button  pButton type="button" style="height: 70px;margin-top: 20px;font-size: 19px;" class="p-button p-button" (click)="bookSlot(st)">
                                                        <span class="pi pi-clock" style="margin-right:8px;"></span>
                                                        <span>{{ st.time | date:'HH:mm'  : 'UTC'}}</span>            
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                
                                        <div *ngIf='st.primaryResource == true && st.timeAvailable == false' style="width:100%;">
                                            <div style="float:left;">
                                                <!--<p-button class="p-button" label="Click" disabled="true" icon="pi pi-clock" iconPos="left" label="{{ st.time | date:'HH:mm' }}">
                                                </p-button>-->
                                                <div>
                                                    <button pButton type="button" disabled="true" style="height: 70px;margin-top: 20px;font-size: 19px;" class="p-button p-button" (click)="bookSlot(st)">
                                                        <span class="pi pi-clock" style="margin-right:8px;"></span>
                                                        <span>{{ st.time | date:'HH:mm'  : 'UTC'}}</span>            
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                
                                        <div  *ngIf='st.primaryResource == true' style="font-size: 17px;margin-top: 25px;width: 200px;float: left;margin-left: 20px;">Quantity Remaining: {{ st.quantityRemaining }}</div>
                                        
                                        <div *ngIf='st.primaryResource == true && st.reason != ""' style="font-size: 17px;margin-top: 15px;width: 400px;float: left;margin-left: 20px;background-color: pink;padding:10px;border-radius:40px;text-align:center;">Reason: {{ st.reason }}</div>
                                        <div *ngIf='st.primaryResource == true && st.reason == ""' style="font-size: 17px;margin-top: 15px;width: 400px;float: left;margin-left: 20px;background-color: #91ff91;padding:10px;border-radius:40px;text-align:center;">No issues found</div>
                                    
                                    </div>
                                    <div style="clear:both;"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button *ngIf="ShowCheckButton" pButton type="button" style="height: 70px;margin-top: 20px;" (click)="checkchanges(NewBooking)">
                        <span class="pi pi-search" style="margin-right:8px;"></span>
                        <span>Check availability for these settings</span>            
                    </button>

                    <!--<button *ngIf="ShowSaveButton" pButton type="button" style="height: 70px;margin-top: 20px;" (click)="bookExistingSlotTime(NewBooking.startTime)">
                        <span class="pi pi-check" style="margin-right:8px;"></span>
                        <span>Save these details for this Booking</span>            
                    </button>-->
                    <hr>
                    <div>
                        <div class="field mb-4 col-12">
                            <label for="" class="font-medium">Send this email to this booking: </label><br>
                            <p-dropdown [options]="EmailTemplates" [style]="{'width': '300px', 'float': 'left'}"  placeholder="Select a template" [showClear]="true" [(ngModel)]="selectedEmailTemplate" optionLabel="name"></p-dropdown>
                        </div>
                        <div style="width: 100px; float: left; margin-top: -32px; margin-left: 10px;">
                            <p-button icon="pi pi-send" label="Send" styleClass="p-button p-button-success" (click)="sendEmailToBooking()"></p-button>
                        </div>
                    </div>
                    <div *ngIf="!templateValid" class="text-danger" Style="clear:both; width:100%"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select a Template.</div>
                
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Customer">
                <ng-template pTemplate="header">
                    <i class="pi pi-user" style="margin-right: 5px;"></i>
                    <span>Customer</span>
                </ng-template>
                <ng-template pTemplate="body">
                    <label for="ddlPackage" class="font-medium">Customer Name</label>
                    <div style="max-width: 1000px;margin-bottom: 36px;margin-top:12px;">
                        <div class="surface-card p-4 shadow-2 border-round">
        
                            <div class="grid formgrid p-fluid">
            
                                <div  *ngIf="NewBooking.customer != null" class="field mb-4 col-12">
                                    <p-selectButton id="exclusive" [style]="{'width': '100%', 'max-width' : '600px'}" [options]="stateOptions" [(ngModel)]="ProvideMinimalPersonalData" ></p-selectButton>
                                </div>
            
                                <div style="width:100%;margin-bottom:15px;" *ngIf="NewBooking.customer == null">
                                    <div>There is no customer on this booking!</div>
                                </div>
                                <div *ngIf="NewBooking.customer != null">
                                    <div *ngIf="!ProvideMinimalPersonalData">
                                        <div class="field mb-4 col-12" >
                                            <label for="firstName" class="font-medium">First Name</label>
                                            <input id="firstName" type="text" [(ngModel)]="NewBooking.customer.firstName" pInputText autocomplete="given-name">
                                        </div>
                                        <div *ngIf="!firstNameEditValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a first name.</div>
                                    </div>
                                    
                                    <div class="field mb-4 col-12">
                                        <label for="lastname" class="font-medium">Last Name</label>
                                        <input id="lastname" type="text" [(ngModel)]="NewBooking.customer.lastName" pInputText autocomplete="family-name">
                                    </div>
                                    <div *ngIf="!lastNameEditValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a last name.</div>
                
                                    <div class="field mb-4 col-12" *ngIf="!ProvideMinimalPersonalData">
                                        <label for="address1" class="font-medium">Address 1</label>
                                        <input id="address1" type="text" [(ngModel)]="NewBooking.customer.address1" pInputText autocomplete="address-line1">
                                    </div>
                                    <div *ngIf="!Address1EditValid && !ProvideMinimalPersonalData" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an address.</div>
                
                                    <div class="field mb-4 col-12" *ngIf="!ProvideMinimalPersonalData">
                                        <label for="address2" class="font-medium">Address 2</label>
                                        <input id="address2" type="text" [(ngModel)]="NewBooking.customer.address2" pInputText autocomplete="address-line2">
                                    </div>
                                
                                    <div class="field mb-4 col-12" *ngIf="!ProvideMinimalPersonalData">
                                        <label for="town" class="font-medium">Town / City</label>
                                        <input id="town" type="text" [(ngModel)]="NewBooking.customer.townCity" pInputText autocomplete="address-line3">
                                    </div>
                                    <div *ngIf="!TownEditValid && !ProvideMinimalPersonalData" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a town/city.</div>
                
                                    <div class="field mb-4 col-12" *ngIf="!ProvideMinimalPersonalData">
                                        <label for="postcode" class="font-medium">Post Code</label>
                                        <input id="postcode" type="text" [(ngModel)]="NewBooking.customer.postCode" pInputText autocomplete="postal-code">
                                    </div>
                                    <div *ngIf="!PostCodeEditValid && !ProvideMinimalPersonalData" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a postcode.</div>
                
                                    <div class="field mb-4 col-12">
                                        <label for="email" class="font-medium">Email</label>
                                        <input id="email" type="email" [(ngModel)]="NewBooking.customer.email" pInputText autocomplete="email">
                                    </div>
                                    <div *ngIf="!EmailEditValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an email address.</div>
                                    <div *ngIf="!EmailFormatEditValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a valid email address.</div>
                
                                    <div class="field mb-4 col-12">
                                        <label for="mobile" class="font-medium">Mobile</label>
                                        <input id="mobile" type="tel" [(ngModel)]="NewBooking.customer.mobile" pInputText autocomplete="mobile">
                                    </div>
                                    <div *ngIf="!MobileEditValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a mobile number.</div>
                                    <div *ngIf="!MobileFormatEditValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a valid mobile number.<div style="clear:both;"></div></div>
                
                                    <div style="clear:both;width: 100%;height: 20px;"></div>
                                    <button pButton type="button" style="height: 50px; width: 200px;margin-top:0px" class="p-button p-button" (click)="updateCustomer()">
                                        <span class="pi pi-save" style="margin-right:8px;"></span>
                                        <span>Save Customer</span>
                                    </button>
                                    &nbsp;
                                </div>
                                <div *ngIf="!ShowCustomerSearch" style="width:100%; margin-top:15px;">
                                    <button pButton type="button" style="width: 250px;height: 50px;font-size: 16px;" class="p-button-sm p-element p-ripple p-button p-component" (click)="showCustomerSearch()">
                                        <span class="pi pi-search" style="margin-right:8px;"></span>
                                        <span>Search for a Customer</span>
                                    </button>
                                </div>
            
                            </div>
                        </div>

                    </div>

                    <div *ngIf="ShowCustomerSearch" style="margin-bottom: 40px;">
                        <span class="p-input-icon-left ml-auto" style="margin-right:20px; float:left;"> 
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" [(ngModel)]="searchCriteria" buttonLayout="horizontal" style="height: 50px;padding-left:33px;" />
                        </span>
                        
                        <button pButton type="button" style="float:left;height: 50px;" class="p-button-sm p-element p-ripple p-button p-component" (click)="searchCustomer()">
                        <span class="pi pi-search" style="margin-right:8px;"></span>
                        <span i18>Search</span>
                        </button>
                        <p style="float: left;width: 376px;font-size: 15px;color: #ccc;margin-top: 10px;margin-left: 10px;">(Lastname/Mobile Number/Post Code)</p>
                        <div style="clear:both;"></div>
                        <div style="margin-top:20px;" class="surface-card p-4 shadow-2 border-round" *ngIf="customersResult">
                        <p-table [value]="customersResult.data">
                        <ng-template pTemplate="header">
                            <tr style="color:Black;background-color:White;font-weight:bold;">
                            <th scope="col">Name</th>
                            <th scope="col">Address</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Post Code</th>
                            <th width="5%">&nbsp;</th>
                            <th width="5%">&nbsp;</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-data>
                            <tr onmouseover="this.originalstyle=this.style.backgroundColor;this.style.backgroundColor='#dddddd'" onmouseout="this.style.backgroundColor=this.originalstyle;" style="background-color: rgb(255, 255, 255); cursor: pointer;">
                                <td (click)="addselectedCustomer(data)">{{data.fullName}}</td>
                                <td>{{data.address1}}</td>
                                <td>{{data.mobile}}</td>
                                <td>{{data.postCode}}</td>
                                <td>
                                    <button pButton pRipple type="button" class="p-button p-element p-button-danger" (click)="addselectedCustomer(data)">
                                    <span class="pi pi-check" style="margin-right:8px;"></span>
                                    Select</button>
                                </td>
                                <td>
                                    <button pButton pRipple type="button" class="p-button p-element p-button-success" (click)="applyCustomer(data)">
                                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                                    Edit</button>
                                </td>
                            </tr>  
                        </ng-template>  
                        </p-table>
                        <button *ngIf="customersResult" style="margin-top:15px;" pButton pRipple type="button" class="p-button p-element p-button-success" (click)="addCustomer(data)">
                            <span class="pi pi-pencil" style="margin-right:8px;"></span>
                            Add Customer</button>
                        </div>
                    </div>
        
                    <div *ngIf="ShowCustomerDetails" class="surface-card p-4 shadow-2 border-round">
        
                        <div class="grid formgrid p-fluid">
        
                            <div class="field mb-4 col-12">
                                <p-selectButton id="exclusive" [style]="{'width': '100%', 'max-width' : '600px'}" [options]="stateOptions" [(ngModel)]="ProvideMinimalPersonalData" ></p-selectButton>
                            </div>
        
                            <div *ngIf="!ProvideMinimalPersonalData">
                                <div class="field mb-4 col-12" >
                                    <label for="firstName" class="font-medium">First Name</label>
                                    <input id="firstName" type="text" [(ngModel)]="firstName" pInputText autocomplete="given-name">
                                </div>
                                <div *ngIf="!firstNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a first name.</div>
                            </div>
                            
                            <div class="field mb-4 col-12">
                                <label for="lastname" class="font-medium">Last Name</label>
                                <input id="lastname" type="text" [(ngModel)]="lastName" pInputText autocomplete="family-name">
                            </div>
                            <div *ngIf="!lastNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a last name.</div>
        
                            <div class="field mb-4 col-12" *ngIf="!ProvideMinimalPersonalData">
                                <label for="address1" class="font-medium">Address 1</label>
                                <input id="address1" type="text" [(ngModel)]="address1" pInputText autocomplete="address-line1">
                            </div>
                            <div *ngIf="!Address1Valid && !ProvideMinimalPersonalData" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an address.</div>
        
                            <div class="field mb-4 col-12" *ngIf="!ProvideMinimalPersonalData">
                                <label for="address2" class="font-medium">Address 2</label>
                                <input id="address2" type="text" [(ngModel)]="address2" pInputText autocomplete="address-line2">
                            </div>
                        
                            <div class="field mb-4 col-12" *ngIf="!ProvideMinimalPersonalData">
                                <label for="town" class="font-medium">Town / City</label>
                                <input id="town" type="text" [(ngModel)]="town" pInputText autocomplete="address-line3">
                            </div>
                            <div *ngIf="!TownValid && !ProvideMinimalPersonalData" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a town/city.</div>
        
                            <div class="field mb-4 col-12" *ngIf="!ProvideMinimalPersonalData">
                                <label for="postcode" class="font-medium">Post Code</label>
                                <input id="postcode" type="text" [(ngModel)]="postCode" pInputText autocomplete="postal-code">
                            </div>
                            <div *ngIf="!PostCodeValid && !ProvideMinimalPersonalData" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a postcode.</div>
        
                            <div class="field mb-4 col-12">
                                <label for="email" class="font-medium">Email</label>
                                <input id="email" type="email" [(ngModel)]="email" pInputText autocomplete="email">
                            </div>
                            <div *ngIf="!EmailValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an email address.</div>
                            <div *ngIf="!EmailFormatValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a valid email address.</div>
        
                            <div class="field mb-4 col-12">
                                <label for="mobile" class="font-medium">Mobile</label>
                                <input id="mobile" type="tel" [(ngModel)]="mobile" pInputText autocomplete="mobile">
                            </div>
                            <div *ngIf="!MobileValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a mobile number.</div>
                            <div *ngIf="!MobileFormatValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a valid mobile number.<div style="clear:both;"></div></div>
        
                            <div style="clear:both;width: 100%;height: 20px;"></div>
                            <button pButton type="button" style="height: 50px; width: 221px;margin-top:0px" class="p-button p-button" (click)="doneCustomer()">
                                <span>Add this Customer</span>
                                <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                            </button>
        
                        </div>
                    </div>
        
                    <button *ngIf="CustomerHasBeenEdited" style="margin-top:15px;" pButton pRipple type="button" class="p-button p-element p-button-success" (click)="saveCustomer()">
                        <span class="pi pi-check" style="margin-right:8px;"></span>
                        Save Customer
                    </button>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Extras">
                <ng-template pTemplate="header">
                    <i class="pi pi-star" style="margin-right: 5px;"></i>
                    <span>Extras</span>
                </ng-template>
                <ng-template pTemplate="body">
                    <div *ngIf="packageExtras != null">
                        <p-table [value]="packageExtras" style="width:100%">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:70%">Extra</th>
                                <th style="width:10%">Price</th>
                                <th style="width:10%">Quantity</th>
                                <th style="width:10%">Cost</th>
                            </tr>
                        </ng-template>
                            <ng-template pTemplate="body" let-product let-index>
                                <tr>
                                    <td>{{product.name}}</td>
                                    <td>{{product.price | currency:CurrencySymbol}}</td>
                                    <td><p-inputNumber [(ngModel)]="product.qty" [disabled]="ExtraEnabled" [showButtons]="true" max="{{product.max}}" min="{{product.min}}" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber></td>
                                    <td [align]="right">{{(product.qty * product.price) | currency:CurrencySymbol}}</td>
                                </tr>
                                <tr style="border:none;" *ngIf="(product.question!='' && product.question != null) && product.qty > 0">
                                    <td style="border:none;" colspan="4">
                                        {{product.question}}
                                    </td>
                                </tr>
                                <tr style="border:none;" *ngIf="(product.question!='' && product.question != null) && product.qty > 0">
                                    <td style="border-top:none;" colspan="4">
                                        <input style="width: 100%" type="text" [(ngModel)]="product.answer" pInputText>
                                    </td>
                                </tr>
                            </ng-template>
        
                        </p-table>
                    </div>
                    <div *ngIf="packageExtras == null">
                        There are no extras for this booking.

                        <div class="col-12">
                            <p-table [value]="packageExtrasNew">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:70%">Extra</th>
                                    <th style="width:10%">Price</th>
                                    <th style="width:10%">Quantity</th>
                                    <th style="width:10%">Cost</th>
                                </tr>
                            </ng-template>
                                <ng-template pTemplate="body" let-product let-index>
                                    <tr>
                                        <td>{{product.name}}</td>
                                        <td>{{product.price | currency:CurrencySymbol}}</td>
                                        <td><p-inputNumber [(ngModel)]="product.qty" [disabled]="ExtraEnabled" [showButtons]="true" max="{{product.max}}" min="{{product.min}}" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber></td>
                                        <td>{{(product.qty * product.price) | currency:CurrencySymbol}}</td>
                                    </tr>
                                </ng-template>
                                </p-table>
                        </div>

                    </div>

                    <div *ngIf="!allQuestionsValid" class="text-danger" Style="margin-left:10px; margin-top: 15px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer all questions.</div>
                    

                    <button style="margin-top:15px;" pButton pRipple type="button" class="p-button p-element p-button-success" (click)="saveExtras()">
                        <span class="pi pi-pencil" style="margin-right:8px;"></span>
                        Save Extras</button>
                </ng-template>
            </p-tabPanel>

            <p-tabPanel header="Booking Questions">
                <ng-template pTemplate="header">
                    <i class="pi pi-question-circle" style="margin-right: 5px;"></i>
                    <span>Questions</span>
                </ng-template>
                <ng-template pTemplate="body">
                    <div class="surface-card p-4 shadow-2 border-round">
                        <div *ngIf="NewBooking.questionnaire== null">
                            There are no booking questions for this booking.
                        </div>

                        <div *ngIf="NewBooking.questionnaire!= null" class="surface-card p-4 shadow-2 border-round">
                            <div class="grid formgrid p-fluid">
                                <div *ngFor="let bq of NewBooking.questionnaire.bookingquestions" class="field mb-4 col-12">
                                    <div *ngIf="bq.questiontype=='FREETEXT'">
                                        <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                                        <input id="bq{{bq.id}}" type="text" [(ngModel)]="bq.answers.freetextanswer" pInputText>
                                        <div *ngIf="!mustanswerfreetext[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must this question.</div>
                                    </div>
                                    
                                    <div *ngIf="bq.questiontype=='BIRTHDAY'">
                                        <div style="width:100%; float:left;"><label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label></div>                      
                                        <div *ngFor="let ibq of birthdayitems" style="width: 100%;">
                                            <div style="width:100%">
                                                <div>
                                                    <div style="min-width: 200px; width:25%; float:left; margin-right:1%;margin-bottom:10px;"><input id="bq{{bq.id}}" type="text" placeholder="Child's Name" [(ngModel)]="ibq.name" pInputText></div>
                                                    <div *ngIf="bq.showage" style="min-width: 300px; width:31%; float:left; margin-right:1%;margin-bottom:10px;">
                                                        <p-dropdown [options]="dobage" [(ngModel)]="ibq.age" placeholder="Select age" optionLabel="name" optionValue="value"></p-dropdown>
                                                    </div>
                                                    <div *ngIf="bq.showdob" style="min-width: 300px; width:37%; float:left;margin-bottom:10px;">
                                                        <p-dropdown [style]="{'float': 'left', 'width': '96px' }" optionLabel="name" optionValue="value" [options]="caldays" [(ngModel)]="ibq.dobday" placeholder="--"></p-dropdown>
                                                        <span style="float:left;margin-top: 12px;">&nbsp;/&nbsp;</span>
                                                        <p-dropdown [style]="{'float': 'left', 'width': '96px' }"  optionLabel="name" optionValue="value" [options]="calmonths" [(ngModel)]="ibq.dobmonth" placeholder="--"></p-dropdown>
                                                        <span style="float:left;margin-top: 12px;">&nbsp;/&nbsp;</span>
                                                        <p-dropdown [style]="{'float': 'left', 'width': '115px' }"  optionLabel="name" optionValue="value" [options]="calyears" [(ngModel)]="ibq.dobyear" placeholder="----"></p-dropdown>
                                                    </div>
                                                </div>
                                                <div style="width:100%">
                                                    <div *ngIf="bq.showgender" style="min-width: 300px; width:48%; float:left; margin-right:1%;margin-bottom:10px;margin-top: 5px;">
                                                        Child's Gender<br>
                                                        <p-selectButton id="useclasses" [style]="{'min-width' : '300px'}" [options]="stateGenderOptions" [(ngModel)]="ibq.gender" ></p-selectButton>
                                                    </div>
                                                    
                                                    <div *ngIf="bq.showinterests" style="min-width: 300px; width:48%; float:left; margin-right:1%;margin-bottom:10px;margin-top: 5px;">
                                                        Child's Interests<br>
                                                        <input id="bq{{bq.id}}" type="text" [(ngModel)]="ibq.interests" pInputText>
                                                    </div>
                                                </div>

                                                <div *ngIf="birthdayitems.length > 1 && !bq.linkedtoparticipants">
                                                    <button pButton type="button" style="height: 40px; margin-bottom:10px; width:121px;" class="p-button-danger p-button" (click)="deleteBirthdayRow(ibq.id)">
                                                        <span class="pi pi-trash" style="margin-right:8px"></span>
                                                        <span>Delete</span>
                                                    </button>
                                                </div>
                                            </div>
                                           
                                            <div style="clear:both;"></div>
                                        </div>
                                        <div style="clear:both;"></div>
                                        <div style="width:100%" *ngIf="!bq.linkedtoparticipants">
                                            <button pButton type="button" style="height: 50px; width: 225px;margin-top:20px;" class="p-button p-button" (click)="addBirthdayRow()">
                                                <span>Add another child</span>
                                                <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                                            </button>
                                        </div>
                                         <div *ngIf="!mustanswerbirthdays[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must provide all details for each child.</div>
                                    </div>
                                    
                                    <div *ngIf="bq.questiontype=='ALLERGY'">
                                        <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                                        <p-selectButton id="allergy" [style]="{'width': '100%', 'max-width' : '230px'}" [options]="stateOptionAllergy" [(ngModel)]="bq.answers.allergyanswer.allergypresent"></p-selectButton>
                                        <div *ngIf="bq.answers.allergyanswer.allergypresent==true">
                                            <label for="bq{{bq.id}}" style="margin-top:20px;" class="font-medium">Please specify any allergy details below</label>
                                            <input id="bq{{bq.id}}" type="text" [(ngModel)]="bq.answers.allergyanswer.allergytext" pInputText>
                                        </div>
                                        <div *ngIf="!mustanswerallergies[bq.listorder] && bq.answers.allergyanswer.allergypresent==true" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must provide allergy details.</div>
                                    </div>
                                    
                                    <div *ngIf="bq.questiontype=='MULTICHOICESINGLE'">
                                        <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                                        <div>
                                            <p-dropdown [options]="bq.listitems" placeholder="Please select an option" optionLabel="listitemtext" [(ngModel)]="bq.answers.multichoicesingleanswer"></p-dropdown>
                                        </div>
                                        <div *ngIf="!mustanswermultichoicesingleanswer[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer this question and choose an item.</div>
                                    </div>
                                    
                                    <div *ngIf="bq.questiontype=='MULTICHOICEMULTIPLE'">
                                        <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                                        <div>
                                            <p-multiSelect [options]="bq.listitems" placeholder="Please select one or more options" optionLabel="listitemtext" [(ngModel)]="bq.answers.multichoicemultianswer"></p-multiSelect>
                                        </div>
                                        <div *ngIf="!mustanswermultichoicemultianswer[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer this question and choose one or more items.</div>
                                    </div>
                                    
                                    <div *ngIf="bq.questiontype=='DATE'">
                                        <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                                        <p-calendar [selectOtherMonths]="true" firstDayOfWeek="1" [(ngModel)]="dateAnswer[bq.listorder]" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>  
                                        <div *ngIf="!mustanswerdate[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer this question and supply a date.</div>
                                    </div>
                                    
                                    <div *ngIf="bq.questiontype=='NUMBER'">
                                        <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                                        <p-inputNumber [showButtons]="true" buttonLayout="horizontal" [(ngModel)]="bq.answers.numberanswer" spinnerMode="horizontal"></p-inputNumber>
                                        <div *ngIf="!mustanswernumber[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer this question and supply a number.</div>
                                    </div>
                                    <hr style="margin-bottom:0px;margin-top:20px">
                                </div>
                            </div>
            
                            <div style="clear:both;">
                                <button pButton type="button" style="height: 50px; width: 155px;margin-top:20px;" class="p-button p-button" (click)="saveBookingQuestions()">
                                    <span>Next</span>
                                    <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                                </button> &nbsp;
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-tabPanel>

            <p-tabPanel header="Payments">
                <ng-template pTemplate="header">
                    <i class="pi pi-pound" style="margin-right: 5px;"></i>
                    <span>Payments</span>
                </ng-template>
                <ng-template pTemplate="body">
                    <div class="surface-card p-4 shadow-2 border-round">
                        <label for="refunds" class="font-medium">Payments already made</label>   
                        <div *ngIf="NewBooking.payments != null">
                            <p-table [value]="NewBooking.payments">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:1%"></th>
                                        <th style="width:1%"></th>
                                        <th style="width:30%">Date</th>
                                        <th style="width:30%">User</th>
                                        <th style="width:7%">Method</th>
                                        <th style="width:7%">Reason</th>
                                        <th style="width:5%">Amount</th>
                                        <th style="width:5%">Balance</th>
                                        <th style="width:9%">&nbsp;</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-payment let-index>
                                    <tr *ngIf="payment.amount > 0">
                                        <td><img alt="Payment" src="https://dev.wannabook.online/assets/images/paymentin.png" style="width: 25px; height: 25px;"/></td> 
                                        <!-- <td><img alt="Info" src="https://dev.wannabook.online/assets/images/info.png" style="width: 25px; height: 25px;" (click)="op.toggle($event)" /></td> 
                                         -->

                                         <td><img alt="Info" src="https://dev.wannabook.online/assets/images/info.png" style="width: 25px; height: 25px;" (click)="opMoreInfo.toggle($event)" />
                                            <p-overlayPanel #opMoreInfo>
                                                <div class="flex flex-column gap-3 w-25rem">
                                                    <div>
                                                        <span class="font-medium text-900 block mb-2" style="font-size:25px;">Payment Informtation</span>
                                                        <span style="font-weight:bold; width:155px;float:left;">Payment Date:</span><span style="float:left;">{{payment.paymentDate | date:'dd/MM/yyyy HH:mm:ss'}}</span><br>
                                                        <span style="font-weight:bold; width:155px;float:left;">Username:</span><span style="float:left;">{{payment.username}}</span><br>
                                                        <span style="font-weight:bold; width:155px;float:left;">Payment Method:</span><span style="float:left;">{{payment.method}}</span><br>
                                                        <span style="font-weight:bold; width:155px;float:left;">Reason for Refund:</span><span style="float:left;">{{payment.refundreason}}</span><br>
                                                        <span style="font-weight:bold; width:155px;float:left;">Payment ID:</span><span style="float:left;">{{payment.intent}}</span><br>
                                                        <span style="font-weight:bold; width:155px;float:left;">Amount:</span><span style="float:left;">{{(payment.amount) | currency:CurrencySymbol}}</span><br>
                                                        <span style="font-weight:bold; width:155px;float:left;">Balance:</span><span style="float:left;">{{(payment.balance) | currency:CurrencySymbol}}</span><br>
                                                    </div>
                                                </div>
                                            </p-overlayPanel>
                                        </td> 
                                        <td>{{payment.paymentDate | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                                        <td>{{payment.username}}</td>
                                        <td>{{payment.method}}</td>
                                        <td>{{payment.refundreason}}</td>
                                        <td>{{(payment.amount) | currency:CurrencySymbol}}</td>
                                        <td>{{(payment.balance) | currency:CurrencySymbol}}</td>
                                        <td *ngIf="payment.balance > 0">
                                            <button pButton type="button" style="height: 36px; width: 100px;margin-top:0px" class="p-button p-button p-button-danger" (click)="setupRefund(payment.paymentId)">
                                                <span>Refund</span>
                                            </button>
                                        </td>

                                        <td *ngIf="payment.balance <= 0">
                                            <button pButton type="button" style="height: 36px; width: 100px;margin-top:0px; border-color: #cccccc; background-color: #cccccc !important;" [disabled]="true" class="p-button p-button">
                                                <span>Refund</span>
                                            </button>
                                        </td>
                                    </tr>

                                    <tr *ngIf="payment.amount <= 0">
                                        <td><img alt="Refund"  src="https://dev.wannabook.online/assets/images/paymentout.png" style="width: 25px; height: 25px;"/></td>
                                        <td><img alt="Info" src="https://dev.wannabook.online/assets/images/info.png" style="width: 25px; height: 25px;"/></td> 
                                        
                                        <td style="color:red;" >{{payment.paymentDate | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                                        <td style="color:red;" >{{payment.username}}</td>
                                        <td style="color:red;" >{{payment.method}}</td>
                                        <td style="color:red;" >{{payment.refundreason}}</td>
                                        <td style="color:red;" >{{(payment.amount) | currency:CurrencySymbol}}</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div *ngIf="NewBooking.payments == null">
                            No payments have been made against this booking
                        </div>
                        <div *ngIf="NewBooking.discounts != null">
                            <br>
                            <label for="refunds" class="font-medium">Discounts applied</label>   

                            <p-table [value]="NewBooking.discounts">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th></th>
                                        <th style="width:35%">Date</th>
                                        <th style="width:30%">User</th>
                                        <th style="width:20%">Discount Name</th>
                                        <th style="width:10%">Amount</th>
                                        <th style="width:5%">&nbsp;</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-discount let-index>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>{{discount.discountdate | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                                        <td>{{discount.username}}</td>
                                        <td>{{discount.name}}</td>
                                        <td>{{(discount.totaldiscount) | currency:CurrencySymbol}}</td>
                                        <td>
                                            <button pButton type="button" style="height: 36px; width: 100px;margin-top:0px" class="p-button p-button p-button-danger" (click)="removeDiscount()">
                                                <span>Remove</span>
                                            </button>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <br>
                    <!-- <div *ngIf="NewBooking.outstanding == 0 && NewBooking.discounts" class="surface-card p-4 shadow-2 border-round">
                        <button pButton type="button" style="height: 50px; width: 221px;margin-top:0px" class="p-button p-button-success" (click)="removeDiscount()">
                            <span>Remove Discount</span>
                            <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                        </button>
                    </div> -->
                    <div *ngIf="NewBooking.outstanding != 0" class="surface-card p-4 shadow-2 border-round">
                        <div style="font-weight:bold; font-size:20px; margin-bottom:20px;">
                            Value of Booking: {{(NewBooking.totaladmissions) |  currency:CurrencySymbol}} 
                            <span *ngIf="NewBooking.totaldiscounts > 0">(- Discount: {{NewBooking.totaldiscounts | currency:CurrencySymbol}})</span>
                            <span *ngIf="NewBooking.totalbookingfees> 0"> + Booking Fee: {{NewBooking.totalbookingfees | currency:CurrencySymbol}}</span>
                            <span *ngIf="NewBooking.totalextras> 0"> + Extras Value: {{NewBooking.totalextras | currency:CurrencySymbol}}</span>
                            <span> (Total Value: {{(NewBooking.total) | currency:CurrencySymbol}})</span>
                        </div>

                        <div class="field mb-4 col-12">
                            <label for="venueclosingname" class="font-medium">Payment Value</label><br>
                            <p-inputNumber [(ngModel)]="validPaymentAmount" mode="currency" currency="{{CurrencySymbol}}" locale="en-GB" [min]="0" [max]="9999"></p-inputNumber>
                        </div>
                        <div *ngIf="!paymentValueValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a payment amount.</div>
                        <div *ngIf="!paymentValueExceededValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You cannot pay more than the booking value.</div>
                        <div *ngIf="!paymentDepositValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Your payment must be at least {{validPaymentAmount | currency:CurrencySymbol}}.</div>

                        <div class="field mb-4 col-12">
                            <label for="paymentType" class="font-medium">Payment Type</label><br>
                            <p-dropdown [options]="PaymentTypes" [(ngModel)]="selectedPayment" optionLabel="name" placeholder="Select a Type" [showClear]="true" (onChange)="resetlock()"></p-dropdown>
                        </div>
                        <div *ngIf="!paymentTypeValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select a payment type.</div>

                        <div *ngIf="showDiscount" class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px;">

                            <p-selectButton id="allergy" [style]="{'width': '100%', 'max-width' : '430px', 'margin-bottom' : '20px'}" [options]="stateOptionOpenDiscount" [(ngModel)]="openDiscount"></p-selectButton>

                            <div *ngIf="openDiscount" style="margin-bottom:30px;">
                                <label for="discountAmount" class="font-medium">Enter Discount Amount</label><br>
                                <p-inputNumber [(ngModel)]="chosenDiscountAmount" mode="currency" currency="{{CurrencySymbol}}" [min]="0" max="{{validPaymentAmount}}"></p-inputNumber>
                                <!-- <p-inputNumber [(ngModel)]="chosenDiscountAmount" mode="currency" currency="{{CurrencySymbol}}" locale="en-GB" [min]="0" [max]="9999"></p-inputNumber>-->
                            </div>

                            <div *ngIf="!openDiscount" class="field mb-4 col-12">
                                <label for="discountCode" class="font-medium">Enter Discount Code</label><br>
                                <input id="discountCode" [(ngModel)]="discountCode" pInputText>
                            </div>

                            <button *ngIf="openDiscount" pButton type="button" style="height: 50px; width: 221px;margin-top:0px" class="p-button p-button" (click)="doneOpenDiscount()">
                                <span>Apply Discount</span>
                                <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                            </button>

                            <button *ngIf="!openDiscount" pButton type="button" style="height: 50px; width: 221px;margin-top:0px" class="p-button p-button" (click)="doneDiscount()">
                                <span>Apply Discount</span>
                                <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                            </button>
                        </div>

                        
                        <button pButton type="button" [disabled]="paymentButtonLocked" style="height: 50px; width: 221px;margin-top:0px" class="p-button p-button" (click)="makeNewPayment()">
                            <span>{{PaymentButton}}</span>
                            <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                        </button>
                        &nbsp;
                        <button *ngIf="!showDiscount" pButton type="button" style="height: 50px; width: 221px;margin-top:0px" class="p-button p-button-success" (click)="displayDiscount()">
                            <span>Enter Discount</span>
                            <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                        </button>
                        <button *ngIf="showDiscount" pButton type="button" style="height: 50px; width: 221px;margin-top:0px" class="p-button p-button-success" (click)="displayDiscount()">
                            <span>Hide Discount</span>
                            <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                        </button>
                        &nbsp;
                        <button *ngIf="NewBooking.discounts" pButton type="button" style="height: 50px; width: 221px;margin-top:0px" class="p-button p-button-success" (click)="removeDiscount()">
                            <span>Remove Discount</span>
                            <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                        </button>
                        <button pButton type="button" style="height: 50px; width: 221px;margin-top:0px;margin-right:15px;" class="p-button p-button-warning" (click)="sendPaymentLink()">
                            <span class="pi pi-envelope" style="margin-right:8px;"></span>
                            <span>Send Payment Link</span>
                        </button>
                    </div> 

                    
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="History">
                <ng-template pTemplate="header">
                    <i class="pi pi-align-justify" style="margin-right: 5px;"></i>
                    <span>History</span>
                </ng-template>
                <ng-template pTemplate="body">
                    <div style="width:100%; margin-bottom:10px;">
                        <div style="width:10%; float:left;min-width:145px">
                            <b>Date/Time</b>
                        </div>
                        <div style="width: 73%;float: left;">
                            <b>History Item</b>
                        </div>
                        <div style="width:10%; float: left;;min-width:130px">
                            <b>User</b>
                        </div>
                        <div style="clear:both;"></div>
                    </div>
                    <div *ngFor="let hi of historyItems" style="width:100%;">
                        <div style="width:10%; float:left;min-width:145px">
                            {{hi.itemdatetime | date:'dd/MM/yyyy HH:mm'   }}
                        </div>
                        <div style="width: 73%;float: left;">
                            {{hi.historyitem}}
                        </div>
                        <div style="width:10%; float: left;;min-width:130px">
                            {{hi.user.name}}
                        </div>
                        <div style="clear:both;"></div>
                    </div>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Notes" >
                <ng-template pTemplate="header">
                    <i class="pi pi-copy" style="margin-right: 5px;"></i>
                    <span *ngIf="NewBooking.notes == null">Notes</span>
                    <span *ngIf="NewBooking.notes != null" style="color:red;">Notes</span>
                </ng-template>
                <ng-template pTemplate="body">
                    <div style="width:100%; margin-bottom:10px;">
                        <b>Notes</b>
                    </div>
                    <div style="width:100%;">
                        <textarea pInputTextarea [(ngModel)]="NewBooking.notes" id="notesName" type="text"  style="width:100%;"></textarea>
                    </div>

                    <div style="clear:both;">
                        <button pButton type="button" style="height: 50px; width: 164px;margin-top:20px;" class="p-button p-button p-button-success" (click)="saveNotes()"> 
                            <span class="pi pi-save" style="margin-right:8px;"></span>
                            <span>Save Notes</span>
                        </button> &nbsp;
                    </div>
                </ng-template>

            </p-tabPanel>

            <!-- <p-tabPanel header="Tasks" >
                <ng-template pTemplate="header">
                    <i class="pi pi-paperclip" style="margin-right: 5px;"></i>
                    <span>Tasks</span>
                </ng-template>
                <ng-template pTemplate="body">
                    <div style="width:100%; margin-bottom:10px;">
                        <b>Tasks</b>
                    </div>
                    <div *ngIf="NewBooking.customer.email != ''">
                        <p-table [value]="allTasks">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:55%">Task Name</th>
                                    <th style="width:20%">Status</th>
                                    <th style="width:20%">Send Date</th>
                                    <th style="width:5%">&nbsp;</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-tasks let-index>
                                <tr>
                                    <td>{{tasks.bookingtasktypename}}</td>
                                    <td *ngIf="tasks.sent == false"><span class="pi pi-clock" style="margin-right:8px;"></span>Not Sent</td>
                                    <td *ngIf="tasks.sent == true"><span class="pi pi-send" style="margin-right:8px;"></span>Sent</td>
                                    <td>{{tasks.senddate | date:'dd/MM/yyyy HH:mm' : 'UTC'}}</td>
                                    <td>
                                        <button pButton pRipple type="button" class="p-button p-element p-button-danger" (click)="deleteTask(tasks)">
                                        <span class="pi pi-trash" style="margin-right:8px;"></span>
                                        Delete</button>
                                    </td>
                                    
                                </tr>
                            </ng-template>
                        </p-table>

                        <div style="clear:both; margin-top:20px;" >
                            <label for="ddlPackage" class="font-medium">Select a Task from the list below to add to the booking.</label><br>
                            <p-dropdown [style]="{'width': '300px'}" [options]="bookingTasks" [(ngModel)]="SelectedTask" placeholder="Select a Task" [showClear]="true" optionLabel="name"></p-dropdown>
                            <button pButton type="button" style="height: 50px; width: 164px;margin-top:20px;margin-left:10px" class="p-button p-button p-button-success" (click)="saveTask()"> 
                                <span class="pi pi-save" style="margin-right:8px;"></span>
                                <span>Add Task</span>
                            </button> &nbsp;
                            <div *ngIf="showTaskError" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select a Task to add.</div>
                        </div>
                    </div>
                    <div *ngIf="NewBooking.customer.email == ''">
                        There are no tasks associated with this booking as it is a walk-in booking.
                    </div>
                </ng-template>

            </p-tabPanel> -->
            
        </p-tabView>
    </div>
    <div style="margin-left:1%; width:29%; float:left;">
        <div class="surface-card p-4 shadow-2 border-round" style="width:100%;margin-bottom:16px;">
           <div style="font-weight: bold; margin-bottom: 10px;font-size: 20px;">Booking Summary</div>
           
            <div *ngIf="BookingSummary">
                <div style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-user" style="margin-right: 10px;"></span><b>Customer</b> : {{BookingSummary.customerAddress}}
                </div>
                <div *ngIf="BookingSummary.outstanding > 0" style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Outstanding</b> : <span style="color: red; font-weight: bold;">{{BookingSummary.outstanding | currency:CurrencySymbol }}</span>
                </div>
                <div *ngIf="BookingSummary.outstanding <= 0" style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Outstanding</b> : {{BookingSummary.outstanding | currency:CurrencySymbol }}
                </div>
               
                <div style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-users" style="margin-right: 10px;"></span><b>Admissions</b> : {{BookingSummary.admissions}}
                </div>
                <div style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>Start Date/Time</b> : {{BookingSummary.startTime | date:'dd/MM/yyyy HH:mm' : 'UTC'}}
                </div>
                 <div style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-box" style="margin-right: 10px;"></span><b>Package</b> : {{BookingSummary.packageName}}
                </div>
                <div style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>Sales ID</b> : {{BookingSummary.bookingIdHR}}
                </div>

                <div style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Sales Value</b> : {{BookingSummary.total | currency:CurrencySymbol }}
                </div>

                <div *ngIf="BookingSummary.totaldiscounts > 0" style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Discounts</b> : {{BookingSummary.totaldiscounts | currency:CurrencySymbol }}
                </div>

                <div *ngIf="BookingSummary.totalbookingfees > 0" style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Booking Fee</b> : {{BookingSummary.totalbookingfees | currency:CurrencySymbol }}
                </div>

                <div *ngIf="BookingSummary.outstanding > 0" style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Paid</b> : <span>{{BookingSummary.paid | currency:CurrencySymbol }}</span>
                </div>
                <div *ngIf="BookingSummary.outstanding <= 0" style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Paid</b> : {{BookingSummary.paid | currency:CurrencySymbol }}
                </div>

                <div style="width:100%; margin-bottom: 15px;">

                <div *ngIf="SummaryItinerary" >
                    <span class="pi pi-list" style="margin-right: 10px;"></span><b>Itinerary</b>:

                    <div  style="margin-top:5px; margin-bottom:10px;">
                            <table [cellPadding]="3" >
        
                                <tr style="border-bottom: 1px solid #dddddd" *ngFor="let itin of (NewBooking.itineraryEX)">
                                    <td>&nbsp;</td>
                                    <td>
                                    <b>{{itin.resource}}</b>
                                    </td>
                                    <td>
                                        {{itin.starttime}}
                                    </td>
                                    <td >
                                        {{itin.people}}&nbsp;people
                                    </td>

                                </tr>
        
                                <tr style="border-bottom: 1px solid #dddddd">
                                    <td>&nbsp;</td>
                                    <td>
                                        <b>End:</b>
                                    </td>
                                    <td>
                                        {{addMs(BookingSummary.endTime, 1)| date:'HH:mm' : 'UTC'}}
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </table>
                    </div>
                </div>

                <div *ngIf="BookingSummary.extras != ''" style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-star" style="margin-right: 10px;"></span><b>Extras</b> : {{BookingSummary.extras}}
                </div>


                <div style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-envelope" style="margin-right: 10px;"></span><b>Email</b> : {{BookingSummary.customerEmail}}
                    <button pButton type="button" style="height: 25px; margin-right: 5px;width: 25px; float:right;" class="p-button-sm p-element p-ripple p-button p-component" (click)="sendanemail(bookingFromClick.bookingdata.bookingid)">
                        <span class="pi pi-envelope" style="margin-left: -8px; font-size: 15px;"></span>
                    </button>
                </div>
                <div style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-mobile" style="margin-right: 10px;"></span><b>Mobile</b> : {{BookingSummary.customerMobile}}
                    <button pButton type="button" style="height: 25px; margin-right: 5px;width: 25px; float:right;" class="p-button-sm p-element p-ripple p-button p-component" (click)="makeacall(bookingFromClick.bookingdata.bookingid)">
                        <span class="pi pi-mobile" style="margin-left: -8px; font-size: 15px;"></span>
                    </button>
                </div>

                <div style="width:100%; margin-bottom: 15px;">
                    <span class="pi pi-file" style="margin-right: 10px;"></span><b>Notes</b> : {{BookingSummary.notes}}
                </div>

                <button *ngIf="BookingSummary.customerEmail != ''" pButton type="button" style="height: 50px;margin-top: 20px; width:100%;" class="p-button p-button p-button-warning" (click)="sendEmailConfirmation()">
                    <span class="pi pi-envelope" style="margin-right:8px;"></span>
                    <span>Resend Email Confirmation</span>            
                </button>

                <button *ngIf="BookingSummary.customerMobile != ''" disabled="true" pButton type="button" style="height: 50px;margin-top: 20px; width:100%;" class="p-button p-button p-button-warning" (click)="sendSMSConfirmation()">
                    <span class="pi pi-mobile" style="margin-right:8px;"></span>
                    <span>Resend SMS Confirmation</span>            
                </button>

            </div>
        </div>

    </div>
</div>
