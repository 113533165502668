import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';
import { datasets } from 'src/types/event';
@Component({
  selector: 'app-marketingrevenue',
  templateUrl: './marketingrevenue.component.html',
  styleUrls: ['./marketingrevenue.component.css']
})
export class MarketingrevenueComponent implements OnInit{
  basicOptions: any;
  chartData: any;
  pTableData: any[] = [];
  pTableInnerData: any[] = [];
  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;
  viewInnerData: boolean = false;

  viewData()
  {
    this.viewInnerData = !this.viewInnerData;
  }
  
  createData()
  {

 let dataRow = {
  "datetimesent": new Date("2024-05-01"),
  "value": 12
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-02"),
  "value": 10
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-03"),
  "value": 6
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-04"),
  "value": 7
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-05"),
  "value": 3
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-06"),
  "value": 1
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-07"),
  "value": 9
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-08"),
  "value": 1
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-09"),
  "value": 4
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-10"),
  "value": 15
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-11"),
  "value": 23
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-12"),
  "value": 22
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-13"),
  "value": 1
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-14"),
  "value": 12
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-15"),
  "value": 14
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-16"),
  "value": 0
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-17"),
  "value": 10
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-18"),
  "value": 7
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-19"),
  "value": 3
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-20"),
  "value": 1
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-21"),
  "value": 2
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-22"),
  "value": 6
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-23"),
  "value": 8
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-24"),
  "value": 9
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-25"),
  "value": 12
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-26"),
  "value": 14
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-27"),
  "value": 11
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-28"),
  "value": 17
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-29"),
  "value": 2
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-30"),
  "value": 11
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("2024-05-31"),
  "value": 21
}
this.pTableData.push(dataRow);



let dataRowInner = {
  "bookingid": "LBM-121511-FHRTWB",
  "campaign": "Birthdays 1 - 5",
  "value": 4.50
}
this.pTableInnerData.push(dataRowInner);
dataRowInner = {
  "bookingid": "LBM-875364-OLHEJC",
  "campaign": "Birthdays 6 - 10",
  "value": 3.50
}
this.pTableInnerData.push(dataRowInner);
dataRowInner = {
  "bookingid": "LBM-863752-WGFJEM",
  "campaign": "Birthdays 1 - 5",
  "value": 4.00
}
this.pTableInnerData.push(dataRowInner);



  }

  
  constructor(private globalService: GlobalService) {
      //this.isRowSelectable = this.isRowSelectable.bind(this);
    }



  ngOnInit() {

    this.globalService.setSmallScreenMenu(false);

    this.createData();

    this.basicOptions = {

      plugins: {
          // legend: {
          //     display: false
          // }
      },
      responsive: true,
      // scales: {
      //     y: {
      //         ticks: {
      //             display: false,
      //         },
      //         grid: {
      //             display: false
      //         }
      //     },
      //     x: {
      //         ticks: {
      //             display: false,
      //         },
      //         grid: {
      //             display: false
      //         }
      //     }
      // }
    };

    let newLabels: string[] = [];
    newLabels.push("1st");
    newLabels.push("2nd");
    newLabels.push("3rd");
    newLabels.push("4th");
    newLabels.push("5th");
    newLabels.push("6th");
    newLabels.push("7th");
    newLabels.push("8th");
    newLabels.push("9th");
    newLabels.push("10th");
    newLabels.push("11th");
    newLabels.push("12th");
    newLabels.push("13th");
    newLabels.push("14th");
    newLabels.push("15th");
    newLabels.push("16th");
    newLabels.push("17th");
    newLabels.push("18th");
    newLabels.push("19th");
    newLabels.push("20th");
    newLabels.push("21st");
    newLabels.push("22nd");
    newLabels.push("23rd");
    newLabels.push("24th");
    newLabels.push("25th");
    newLabels.push("26th");
    newLabels.push("27th");
    newLabels.push("28th");
    newLabels.push("29th");
    newLabels.push("30th");
    newLabels.push("31st");

    // let newData: number[] = [];
    // newData.push(46);
    // newData.push(63);
    // newData.push(53);
    // newData.push(89);
    // newData.push(92);
    // newData.push(24);
    // newData.push(42);
    // newData.push(56);
    // newData.push(44);
    // newData.push(62);
    // newData.push(77);
    // newData.push(102);
    // newData.push(35);
    // newData.push(37);
    // newData.push(44);
    // newData.push(25);
    // newData.push(49);
    // newData.push(88);
    // newData.push(75);
    // newData.push(32);
    // newData.push(52);
    // newData.push(32);
    // newData.push(73);
    // newData.push(52);
    // newData.push(106);
    // newData.push(133);
    // newData.push(33);
    // newData.push(72);
    // newData.push(54);
    // newData.push(63);
    // newData.push(84);

    // let newData1: number[] = [];
    // newData1.push(35);
    // newData1.push(55);
    // newData1.push(34);
    // newData1.push(67);
    // newData1.push(63);
    // newData1.push(12);
    // newData1.push(23);
    // newData1.push(30);
    // newData1.push(32);
    // newData1.push(36);
    // newData1.push(55);
    // newData1.push(85);
    // newData1.push(12);
    // newData1.push(34);
    // newData1.push(34);
    // newData1.push(18);
    // newData1.push(43);
    // newData1.push(66);
    // newData1.push(59);
    // newData1.push(33);
    // newData1.push(45);
    // newData1.push(28);
    // newData1.push(51);
    // newData1.push(33);
    // newData1.push(78);
    // newData1.push(99);
    // newData1.push(25);
    // newData1.push(52);
    // newData1.push(21);
    // newData1.push(35);
    // newData1.push(62);

    let newData2: number[] = [];
    newData2.push(12);
    newData2.push(10);
    newData2.push(6);
    newData2.push(7);
    newData2.push(3);
    newData2.push(1);
    newData2.push(9);
    newData2.push(1);
    newData2.push(4);
    newData2.push(15);
    newData2.push(23);
    newData2.push(22);
    newData2.push(1);
    newData2.push(12);
    newData2.push(14);
    newData2.push(0);
    newData2.push(10);
    newData2.push(7);
    newData2.push(3);
    newData2.push(1);
    newData2.push(2);
    newData2.push(6);
    newData2.push(8);
    newData2.push(9);
    newData2.push(12);
    newData2.push(4);
    newData2.push(11);
    newData2.push(17);
    newData2.push(2);
    newData2.push(11);
    newData2.push(21);

    // let newDataSet: datasets = {
    //   label: "Emailed",
    //   data: newData,
    //   backgroundColor: '#64748b',
    //   borderColor: '#64748b',
    // }

    // let newDataSet1: datasets = {
    //   label: "Read",
    //   data: newData1,
    //   backgroundColor: '#f59e0b',
    //   borderColor: '#f59e0b',
    // }

    let newDataSet2: datasets = {
      label: "Revenue",
      data: newData2,
      backgroundColor: '#22c55e',
      borderColor: '#22c55e',
    }

    this.chartData = {
      labels: newLabels,
      datasets: [
        newDataSet2
      ]
    }
  }
}

