import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { class_returndata } from 'src/types/class_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cClass, classes } from 'src/types/class';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';

import { cConfimration } from 'src/types/confirmations';
import { GlobalService } from '../_services/global.service';
import { users_returndata } from 'src/types/user_returndata';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';


@Component({
  selector: 'app-confirmationeditor',
  templateUrl: './confirmationeditor.component.html',
  styleUrls: ['./confirmationeditor.component.css']
})
export class ConfirmationeditorComponent implements OnInit {

  VenueID: string = localStorage.getItem("SmartVenueID")!;
  Confirmation: cConfimration;
  httpResponse: any;
  id: string;
  pageReady: boolean = true;
  YesNo: any;
  PaymentMessage: any;
  HowToFindUs: any;

  confirmationNameValid: boolean = true;
  paymentmessageValid: boolean = true;
  howtofindustextValid: boolean = true;
  webaddressformapValid: boolean = true;
  emailtextValid: boolean = true;
  phonetextValid: boolean = true;
  VenueName: string;
  confirmationMessageValid: boolean = true;
  subjectValid: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

  PublicImageURL: string = "";
  PublicImageWidth: number = 0;
  PublicImageHeight: number = 0;
  
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 
    this.YesNo = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
    this.PaymentMessage = [{ label: 'None', value: "NONE" }, { label: 'Text Only', value: "TEXTONLY" }, { label: "Text with button (the message and button only show if there is an outstanding balance.)", value: "TEXTONLYOB" }];
    this.HowToFindUs = [{ label: 'None', value: "NONE" }, { label: 'Text Only', value: "TEXTONLY" }, { label: "Text Only and link to map", value: "TEXTONLYLINK" }];
  
    this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
        this.httpResponse =  (response as venuesettings_returndata)
        if(this.httpResponse.succeeded)
        {
          //Set Venue Settings ID
          localStorage.setItem("VenueSettingsID", this.httpResponse.data.id);
  
          this.httpResponse.data.venueSettings.forEach(element => {
            if (element.name == "PublicImgURL") {
              this.PublicImageURL = element.value;
            }
            if (element.name == "PublicImageWidth") {
              this.PublicImageWidth = element.value;
            }
            if (element.name == "PublicImageheight") {
              this.PublicImageHeight = element.value;
            }
          });
        }
        else{
          this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
        }
      }, error => {
        this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
      });

    this.Confirmation = {
      id: null,
      confirmationid: null,
      name: "",
      subject: "",
      disabled: false,  
      venueid: this.VenueID,  
      emailsubject:"",
      showconfirmationmessage: false,
      confirmationmessage: "",
      paymentmessagetype: "",
      paymentmessage:"",
      howtofindustype:"",
      howtofindustext:"",
      webaddressformap:"",
      showcustomerservices:false,
      showemail:false,
      emailtext:"",
      showphone:false,
      phonetext:"",
      showtandcslink:false,
      showendtime: false,
      originatingid: null
    }
  }
  
  public ngAfterViewInit(): void {
    let imgButton = document.getElementsByClassName('ql-image');

    for (let i = 0; i < imgButton.length; i++) {
      imgButton[i].remove();
    }
  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("CONFIRMATIONS", user)){
          this.AccessAllowed = true;

          this.id = this.route.snapshot.paramMap.get('id');
          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "confirmations/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as class_returndata)
              if (this.httpResponse.succeeded)
              {
                this.Confirmation = {
                  id: (this.httpResponse.data as cConfimration).id,
                  confirmationid: (this.httpResponse.data as cConfimration).confirmationid,
                  name: (this.httpResponse.data as cConfimration).name,
                  subject: (this.httpResponse.data as cConfimration).subject,
                  disabled: (this.httpResponse.data as cConfimration).disabled,
                  venueid:(this.httpResponse.data as cConfimration).venueid,
                  emailsubject:(this.httpResponse.data as cConfimration).emailsubject,
                  showconfirmationmessage: (this.httpResponse.data as cConfimration).showconfirmationmessage,
                  confirmationmessage: (this.httpResponse.data as cConfimration).confirmationmessage,
                  paymentmessagetype: (this.httpResponse.data as cConfimration).paymentmessagetype,
                  paymentmessage:(this.httpResponse.data as cConfimration).paymentmessage,
                  howtofindustype:(this.httpResponse.data as cConfimration).howtofindustype,
                  howtofindustext:(this.httpResponse.data as cConfimration).howtofindustext,
                  webaddressformap:(this.httpResponse.data as cConfimration).webaddressformap,
                  showcustomerservices:(this.httpResponse.data as cConfimration).showcustomerservices,
                  showemail:Boolean((this.httpResponse.data as cConfimration).showemail),
                  emailtext:(this.httpResponse.data as cConfimration).emailtext,
                  showphone:Boolean((this.httpResponse.data as cConfimration).showphone),
                  phonetext:(this.httpResponse.data as cConfimration).phonetext,
                  showtandcslink: Boolean((this.httpResponse.data as cConfimration).showtandcslink),
                  showendtime: Boolean((this.httpResponse.data as cConfimration).showendtime),
                  originatingid: null
                };
      
                this.pageReady = true;
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting confirmation'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting confirmation, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
      
          this.VenueName = localStorage.getItem("SmartVenueName");
        }

        this.CompletedLoading = true;


      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  updateData()
  {
    if (this.DoValidCheck())
    {
      if(this.Confirmation.paymentmessagetype == "NONE")
      {
        this.Confirmation.paymentmessage = "";
      }

      if(!this.Confirmation.showconfirmationmessage)
      {
        this.Confirmation.confirmationmessage = "";
      }
    
      if(this.Confirmation.howtofindustype == "NONE")
      {
        this.Confirmation.howtofindustext = "";
        this.Confirmation.webaddressformap = "";
      }

      if(this.Confirmation.howtofindustype == "TEXTONLY")
      {
        this.Confirmation.webaddressformap = "";
      }

      if(this.Confirmation.showcustomerservices == false)
      {
        this.Confirmation.emailtext = "";
        this.Confirmation.phonetext = "";
      }

      if(this.Confirmation.showemail == false)
      {
        this.Confirmation.emailtext = "";
      }

      if(this.Confirmation.showphone == false)
      {
        this.Confirmation.phonetext = "";
      }

      if(this.id == "0")
      {
        this.http.post<cConfimration>(bmxurl + "/Confirmations", this.Confirmation).subscribe(response => {
          this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              this.messageService.add({severity:'success', summary:'Saved', detail:'Confirmation successfully updated'});
              this.router.navigateByUrl("/confirmations");
            }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
          })
      }
      else
      {
        this.Confirmation.disabled = false;
        
        this.http.put<cClass>(bmxurl + "/Confirmations", this.Confirmation).subscribe(response => {
        this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Confirmation successfully updated'});
            this.router.navigateByUrl("/confirmations");
          }
          }, error => {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.Confirmation.subject == null || this.Confirmation.subject == "")
    {
      AllValid = false;
      this.subjectValid = false;
    }
    else
    {
      this.subjectValid = true;
    }

    if(this.Confirmation.name == null || this.Confirmation.name == "")
    {
      AllValid = false;
      this.confirmationNameValid = false;
    }
    else
    {
      this.confirmationNameValid = true;
    }

    if(this.Confirmation.showconfirmationmessage && this.Confirmation.confirmationmessage == "")
    {
      AllValid = false;
      this.confirmationMessageValid = false;
    }
    else
    {
      this.confirmationMessageValid = true;
    }

    if(this.Confirmation.paymentmessagetype !="NONE" && (this.Confirmation.paymentmessage == null || this.Confirmation.paymentmessage == ""))
    {
      AllValid = false;
      this.paymentmessageValid = false;
    }
    else
    {
      this.paymentmessageValid = true;
    }

    if(this.Confirmation.howtofindustype == "TEXTONLYLINK" && (this.Confirmation.webaddressformap == null || this.Confirmation.webaddressformap == ""))
    {
      AllValid = false;
      this.webaddressformapValid = false;
    }
    else
    {
      this.webaddressformapValid = true;
    }

    if((this.Confirmation.howtofindustype == "TEXTONLY" || this.Confirmation.howtofindustype == "TEXTONLYLINK") && (this.Confirmation.howtofindustext == null || this.Confirmation.howtofindustext == ""))
    {
      AllValid = false;
      this.howtofindustextValid = false;
    }
    else
    {
      this.howtofindustextValid = true;
    }

    if(this.Confirmation.showcustomerservices == true)
    {
      if(this.Confirmation.showemail == true && (this.Confirmation.emailtext == null || this.Confirmation.emailtext == ""))
      {
        AllValid = false;
        this.emailtextValid = false;
      }
      else
      {
        this.emailtextValid = true;
      }

      if(this.Confirmation.showphone == true && (this.Confirmation.phonetext == null || this.Confirmation.phonetext == ""))
      {
        AllValid = false;
        this.phonetextValid = false;
      }
      else
      {
        this.phonetextValid = true;
      }
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/confirmations");
  }
}
