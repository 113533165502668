import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { class_returndata } from 'src/types/class_returndata'; 
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { classes } from 'src/types/class';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';


@Component({
  selector: 'app-class',
  templateUrl: './class.component.html',
  styleUrls: ['./class.component.css']
})
export class ClassComponent implements OnInit {
  ListClasses: any;
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  DisabledClass: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;


  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 

    }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("CLASSES", user)){
          this.AccessAllowed = true;
          this.loadgrid(false);
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(ClassID)
  {
    this.http.delete(bmxurl + "Class/" + this.VenueID + "/" + ClassID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded == true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Class successfully disabled'});
        this.router.navigateByUrl("/class");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Class'});
    })
  }
  
  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "ClassList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.ListClasses = (response as class_returndata);

        if ((response as class_returndata).messages.length > 0)
        {
          (response as class_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error getting Classes, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Classes, error: ' + error.messages});
    });
  }

  editData(EditData)
  {
    let ClassID = EditData;
    this.router.navigateByUrl("/classedit/" + ClassID);
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "Class/" + this.VenueID + "/" + element.classID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.loadgrid(false);
      } 
      else{
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error disabling Classes, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error disabling Classes, error: ' + error.messages});
    })
    });
    
  }

  
  }
