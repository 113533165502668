import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { departments_returndata } from 'src/types/departments_returndata';
import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';



@Component({
  selector: 'app-tilldepartments',
  templateUrl: './tilldepartments.component.html',
  styleUrls: ['./tilldepartments.component.css']
})
export class TilldepartmentsComponent implements OnInit {
  ListDepartments: any;
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  DisabledClass: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService,private globalService: GlobalService) { 
      //this.isRowSelectable = this.isRowSelectable.bind(this);


    }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("TILLDEPARTMENTS", user)){
          this.AccessAllowed = true;
          this.loadgrid(false);
        }

        this.CompletedLoading = true;
        
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(ClassID)
  {
    this.http.delete(bmxurl + "tilldeptartmentedit/" + this.VenueID + "/" + ClassID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded == true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Department successfully disabled'});
        this.router.navigateByUrl("/tilldeptartments");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Departmnent'});
    })
  }
  
  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "TillDepartmentsList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.ListDepartments = (response as departments_returndata);

        if ((response as departments_returndata).messages.length > 0)
        {
          (response as departments_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error getting Departments, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Departmnemts, error: ' + error.messages});
    });
  }

  editData(EditData)
  {
    let DeptID = EditData;
    this.router.navigateByUrl("/tilldeptartmentedit/" + DeptID);
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "tilldepartments/" + this.VenueID + "/" + element.classID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.loadgrid(false);
      } 
      else{
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error disabling Departments, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error disabling Departments, error: ' + error.messages});
    })
    });
    
  }

  }
