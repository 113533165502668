import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { resource_returndata } from 'src/types/resource_returndata';
import { Router, RouterLink } from '@angular/router';
import { packagerule } from 'src/types/packagerule';
import { returnmessage } from 'src/types/returnmessage';
import { MessageService } from 'primeng/api';
import { cResource, resource } from 'src/types/resource';
import { NumberValueAccessor } from '@angular/forms';
import { class_returndata } from 'src/types/class_returndata';
import { resourceClass } from 'src/types/class';

import { resourcegroupname } from 'src/types/resourcegroupname';
import { resourcegroupname_returndata } from 'src/types/resourcegroupname_returndata';
import { cPackageResource } from 'src/types/package';
import { v4 as uuidv4  } from 'uuid';
import { tag_returndata } from 'src/types/tag_returndata';
import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';


@Component({
  selector: 'app-resourceedit',
  templateUrl: './resourceedit.component.html',
  styleUrls: ['./resourceedit.component.css']
})
export class ResourceeditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  Resource: cResource;
  httpResponse: any;
  id: string;
  stateOptions: any[];
  resourceNameValid: boolean = true;
  resourceConcurrentValid: boolean = true;
  resourceClasses: resourceClass[] = [];
  allclasses: any;
  allresources: resource[];
  allresourcegroupnames: resourcegroupname[];
  pageReady: boolean = true;
  stateOptionsRes: any;
  resourceLength: number;
  resourceLengthLocked: boolean = false;
  tags: any;
  activeIndex: number = 0;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) {
    this.stateOptions = [{ label: 'Off', value: false }, { label: 'On', value: true }];
    this.stateOptionsRes = [{ label: 'No', value: false }, { label: 'Yes', value: true }];

    this.Resource = {
      groupname: "",
      id: null,
      resourceId: null,
      name: "",
      useClasses: false,
      exclusive: false,
      eventid: null,
      concurrentUses: 0,
      maxConcurrent: 0,
      disabled: false,
      venueId: this.VenueID,
      colour: "",
      orderindex: 0,
      length: 0,
      classes: [],
      showonbooking: false,
      resources: [],
      isresourcecontainer: false,
      isconstructed: false,
      price: 0,
      fixedprice: true,
      resourcegroupname: null,
      tags: null,
      resourcegroup: null,
      selectionmode: "",
      selectableresources: null,
      startwhen: "",
      numofminutes: 0,
      optional: false,
      quantityselector: "",
      quantity: 0,
      mode: "",
      inline: false,
      ignore: false,
      admissions: null,
      inProgress: true,
      originatingid: '',
      wasoptional: false,
      wasselected: false
    }
  }

  loadTags(showdisabled: boolean) {
    this.http.get(bmxurl + "TagList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.tags = (response as tag_returndata).data;
      console.log(this.tags);
    }, error => {
    });
  }
  
  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");
    
    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("RESOURCES", user)){
          this.AccessAllowed = true;
          this.id = this.route.snapshot.paramMap.get('id');

          this.http.get(bmxurl + "classlist/" + this.VenueID).subscribe(response => {
            this.allclasses = (response as class_returndata);
            
            this.allclasses.data.forEach(thisclass => {
              if(!thisclass.disabled)
              {
                let newResourceClass: resourceClass = {
                  id: thisclass.id,
                  classID: thisclass.classID, 
                  name: thisclass.name,
                  disabled: thisclass.disabled,
                  venueid: thisclass.venueid,
                  capacity: 0
                }
        
                this.resourceClasses.push(newResourceClass);
              }
            });
      
            //get Resources and resource group names:
      
            this.http.get(bmxurl + "resourcelist/" + this.VenueID).subscribe(response => {
              this.allresources = (response as resource_returndata).data;
      
              this.http.get(bmxurl + "ResourceGroupNameList/" + this.VenueID).subscribe(response => {
                this.allresourcegroupnames = (response as resourcegroupname_returndata).data;
      
                this.loadTags(false);
        
              }, error => {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Resource Group Names'});
                    this.pageReady = false;
              });
      
              if(this.id != "0")
              {
                this.pageReady = false;
                this.http.get(bmxurl + "Resource/" + this.VenueID + "/" + this.id).subscribe(response => {
                  this.httpResponse = (response as resource_returndata)
                  if (this.httpResponse.succeeded)
                  {
                    this.Resource = {
                      groupname: "",
                      id: (this.httpResponse.data as cResource).id,
                      resourceId: (this.httpResponse.data as cResource).resourceId,
                      name: (this.httpResponse.data as cResource).name,
                      useClasses: (this.httpResponse.data as cResource).useClasses,
                      exclusive: (this.httpResponse.data as cResource).exclusive,
                      eventid: (this.httpResponse.data as cResource).eventid,
                      concurrentUses: (this.httpResponse.data as cResource).concurrentUses,
                      maxConcurrent: (this.httpResponse.data as cResource).maxConcurrent,
                      disabled: (this.httpResponse.data as cResource).disabled,
                      venueId: (this.httpResponse.data as cResource).venueId,
                      colour: (this.httpResponse.data as cResource).colour,
                      orderindex: (this.httpResponse.data as cResource).orderindex,
                      length: (this.httpResponse.data as cResource).length,
                      classes: (this.httpResponse.data as cResource).classes,
                      showonbooking: (this.httpResponse.data as cResource).showonbooking,
                      resources: (this.httpResponse.data as cResource).resources,
                      isresourcecontainer: (this.httpResponse.data as cResource).isresourcecontainer,
                      isconstructed: (this.httpResponse.data as cResource).isconstructed,
                      resourcegroupname: (this.httpResponse.data as cResource).resourcegroupname,
                      price: (this.httpResponse.data as cResource).price,
                      fixedprice: (this.httpResponse.data as cResource).fixedprice,
                      tags: (this.httpResponse.data as cResource).tags,
                      resourcegroup: (this.httpResponse.data as cResource).resourcegroup,
                      selectionmode: (this.httpResponse.data as cResource).selectionmode,
                      selectableresources: (this.httpResponse.data as cResource).selectableresources,
                      startwhen: (this.httpResponse.data as cResource).startwhen,
                      numofminutes: (this.httpResponse.data as cResource).numofminutes,
                      optional: (this.httpResponse.data as cResource).optional,
                      quantityselector: (this.httpResponse.data as cResource).quantityselector,
                      quantity: (this.httpResponse.data as cResource).quantity,
                      mode: (this.httpResponse.data as cResource).mode,
                      inline: (this.httpResponse.data as cResource).inline,
                      ignore: (this.httpResponse.data as cResource).ignore,
                      admissions: (this.httpResponse.data as cResource).admissions,
                      inProgress: (this.httpResponse.data as cResource).inProgress,
                      originatingid: (this.httpResponse.data as cResource).originatingid,
                      wasoptional: (this.httpResponse.data as cResource).wasoptional,
                      wasselected: (this.httpResponse.data as cResource).wasselected
                    };
      
                    this.resourceLength = this.Resource.length;
                    this.resourceLengthLocked = this.Resource.isresourcecontainer;
                    
          
                    if(this.Resource.classes.length == 0)
                    {
                      this.Resource.classes = this.resourceClasses;
                    }
          
                    this.pageReady = true;
                  }
                  else
                  {
                    this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Resource'});
                    this.pageReady = false;
                  }
                }, error => {
                  this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Resource, error:' + error.messages[0]});
                  this.pageReady = false;
                });
              }
              else
              {
                this.Resource.classes = this.resourceClasses;
              }
      
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Resources'});
                    this.pageReady = false;
            });
      
          }, error => {
            this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting classes'});
            this.pageReady = false;
          });
        }

        this.CompletedLoading = true;


      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });



  }

  updateData() {
    if (this.DoValidCheck()) {
      if (this.id == "0") {
        if(this.Resource.useClasses == false)
        {
          this.Resource.classes = [];
        }
        this.http.post<resource>(bmxurl + "/Resource", this.Resource).subscribe(response => {
          this.httpResponse = response
          if (this.httpResponse.succeeded == true) {
            this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Resource successfully created' });
            this.returnToList();
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Not Saved', detail: error.messages[0] });
        })
      }
      else {
        this.Resource.disabled = false;
        if(this.Resource.useClasses == false)
        {
          this.Resource.classes = [];
        }
        this.http.put<resource>(bmxurl + "/Resource", this.Resource).subscribe(response => {
          this.httpResponse = response
          if (this.httpResponse.succeeded == true) {
            this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Resource successfully updated' });
            this.returnToList();
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Not Saved', detail: error.messages });
        })
      }
    }
  }

  DoValidCheck() {
    let AllValid =true;

    this.Resource.classes.forEach(element => {
      if(element.capacity > this.Resource.concurrentUses)
      {
        element.capacity = this.Resource.concurrentUses;
      }
    });

    if (this.Resource.name == null || this.Resource.name == "") {
      AllValid = false;
      this.activeIndex = 0;
      this.resourceNameValid = false;
    }
    else {
      this.resourceNameValid = true;
    }

    if (this.Resource.concurrentUses == null || this.Resource.concurrentUses == 0) {
      AllValid = false;
      this.activeIndex = 0;
      this.resourceConcurrentValid = false;
    }
    else {
      this.resourceConcurrentValid = true;
    }

    return AllValid;
  }

  returnToList() {
    this.router.navigateByUrl("/resource");
  }

  AddToResources(resource){
    if(this.Resource.resources == null)
      this.Resource.resources = [];

    let newRes: cResource = {
      groupname: "",
      id: uuidv4(),
      resourceId:resource.resourceId,
      name:resource.name,
      useClasses:resource.useClasses,
      exclusive:resource.exclusive,
      eventid: resource.eventid,
      concurrentUses:resource.concurrentUses,
      maxConcurrent:resource.maxConcurrent,
      disabled:resource.disabled,
      venueId:resource.venueId,
      colour:resource.colour,
      orderindex:resource.orderindex,
      length:resource.length,
      classes:resource.classes,
      showonbooking:resource.showonbooking,
      resources:resource.resources,
      isresourcecontainer:resource.isresourcecontainer,
      isconstructed: resource.isconstructed,
      resourcegroupname:resource.resourcegroupname,
      price:resource.price,
      fixedprice: resource.fixedprice,
      tags: resource.tags,
      resourcegroup: resource.resourcegroup,
      selectionmode: resource.selectionmode,
      selectableresources: resource.selectableresources,
      startwhen: resource.startwhen,
      numofminutes: resource.numofminutes,
      optional: resource.optional,
      quantityselector: resource.quantityselector,
      quantity: resource.quantity,
      mode: resource.quantity,
      inline: resource.inline,
      ignore: resource.ignore,
      admissions: resource.admissions,
      inProgress: resource.inProgress,
      originatingid: resource.originatingid,
      wasoptional: resource.wasoptional,
      wasselected: resource.wasselected
    }

    this.Resource.resources.push(newRes);
    newRes = null;

    this.allresources = this.allresources.filter(x => x.resourceId != resource.resourceId);
    this.resourceLength += resource.length
  }

  removeFromResources(resource){
    this.Resource.resources = this.Resource.resources.filter(obj => obj.resourceId !== resource.resourceId);
    this.allresources.push(resource);
  }

  ContainerClick(eventIn: any){
    if(eventIn.option.label == "Yes")
    {
      this.resourceLengthLocked = true;
    }
    else
    {
      this.resourceLengthLocked = false;
    }
  }
}