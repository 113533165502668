import { Component, OnInit } from '@angular/core';
import { bmxurl } from 'src/globals';
import { HttpClient } from '@angular/common/http';
import { cPaymentRequest } from 'src/types/paymentRequest';
import { cPaymentResponse } from 'src/types/paymentResponse';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-payment-test',
  templateUrl: './payment-test.component.html',
  styleUrls: ['./payment-test.component.css']
})
export class PaymentTestComponent implements OnInit {

  constructor(private http: HttpClient,private sanitizer: DomSanitizer) { }
  httpResponse: any;
  ClientSecret: any;
  paymentHTML: any;
  dsHTML: any;

  ngOnInit(): void {

  }

  MakePayment()
  {
    //create payment request
    let newPayment:cPaymentRequest = new cPaymentRequest();
    let PaymentResponse: cPaymentResponse = new cPaymentResponse();

    newPayment.amount = 100;
    //newPayment.bookingid = "03943d6b-44ea-4300-a013-bc8b3df9438a";
    newPayment.bookingid = "1ae204a4-08cc-4590-9330-1927d21abf9b";
    newPayment.venueid = "aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee";

    //get secret
    this.http.post<cPaymentRequest>(bmxurl + "/MakePayment", newPayment).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      { 
        this.ClientSecret = (this.httpResponse.data as cPaymentResponse).clientSecret
      }
    }, error => {

    })
  }
}
