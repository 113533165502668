import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import { C } from '@fullcalendar/core/internal-common';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { bmxurl } from 'src/globals';
import { booking_returndata } from 'src/types/booking_returndata';
import { cCalendarEvent } from 'src/types/calendarEvent';
import { packagerule_returndata } from 'src/types/packagerule_returndata';
import { venue_returndata } from 'src/types/venue_returndata';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-bookingcalendar',
  templateUrl: './bookingcalendar.component.html',
  styleUrls: ['./bookingcalendar.component.css']
})
export class BookingcalendarComponent implements OnInit {

  ListBookings: any;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  Events: any = [];
  jsonEvents: any;
  Venue: any;
  venueStartTime: any;
  venueEndTime: any;
  BookingResources: any[] = [];
  ListResources: any[];


  calendarOptions: CalendarOptions = {
    initialView: 'listDay',
    plugins: [listPlugin],
    events: [this.Events]

  };

  constructor(private http: HttpClient, private globalService: GlobalService) { }

  ngOnInit(): void {
    
    this.globalService.setSmallScreenMenu(false);

//Get Times
      this.http.get(bmxurl + "venue/" + this.VenueID).subscribe(response => {
        this.Venue = (response as venue_returndata);
        this.venueStartTime = this.Venue.data.startTime;
        this.venueEndTime = this.Venue.data.endTime;
        //Get Recsources
        this.http.get(bmxurl + "ResourceList/" + this.VenueID + "/" + "/false").subscribe(response => {
          this.ListResources = (response as packagerule_returndata).data;

          this.http.get(bmxurl + "BookingList/" + this.VenueID + "/" + "/false").subscribe(response => {
            this.ListBookings = (response as booking_returndata).data;
            //Get Resources into the Array
            this.ListBookings.forEach(bookings => {
              bookings.resources.forEach(innerresource => {
                this.BookingResources.push(innerresource);
              });
              
              this.BookingResources.forEach(element => {

                let colour:string = "#FF9999";

                if(element.colour != null && element.colour !="")
                {
                  colour = element.colour;
                }

                let newEvent: cCalendarEvent;
                     newEvent = {
                       end: element.endTime,
                       start: element.startTime,
                       backgroundColor: colour,
                       title: 'Booking'
                    }
            
                    this.Events.push(newEvent);
              });

              this.calendarOptions = {
                initialView: 'listDay',
                plugins: [listPlugin],
                eventSources: [this.Events]
            
              };
          }, error => {
          }); 
        }, error => {
        });
      }, error => {
      });

    });
  }

}
