import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { bmxurl } from 'src/globals';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BusyService } from '../../_services/busy.service';
import { AuthService } from '@auth0/auth0-angular';
import { GlobalService } from '../../_services/global.service';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';
import { Subscription } from 'rxjs';
import { users_returndata } from 'src/types/user_returndata';


@Component({
  templateUrl: './paymentreport.component.html',
  styleUrls: ['./paymentreport.component.css']
})
export class PaymentreportComponent  implements OnInit {
  ListData: any[];
  ListDataGlobal: any[];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  ReportResponse: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  FromDate: Date = new Date();
  ToDate: Date = new Date();
  httpResponse: any;
  navSubscription: Subscription = null;

  AccessAllowed: boolean = true;
  CompletedLoading: boolean = false;
  CurrencySymbol: string = "#";

  TotalAmount: number = 0;

  cols: any[] = [];

  distinctMethods: any[] = [];

  divClass: string[] = [];
  divTotal: boolean[] = [];
  
  constructor(
    private http: HttpClient, 
    private router: Router, 
    private messageService: MessageService, 
    private busyService: BusyService,
    private auth: AuthService,
    private globalService: GlobalService) {
      
    let userid = localStorage.getItem("SmartUserID");

    }

  ngOnDestroy() {

  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
          this.httpResponse =  (response as venuesettings_returndata)
          if(this.httpResponse.succeeded)
          {
            //Set Venue Settings ID
            localStorage.setItem("VenueSettingsID", this.httpResponse.data.id);
    
            this.httpResponse.data.venueSettings.forEach(element => {
              localStorage.setItem(element.name, element.value);
            });

            this.loadgrid();

            this.CurrencySymbol = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;
          }
          else{
            this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
        });
      }
    });

     this.cols = [
      { field: 'bookingIdHR', header: 'Sales ID' },
      { field: 'method', header: 'Method' },
      { field: 'amount', header: 'Amount' },
      { field: 'paymentDate', header: 'Date' },
      { field: 'isRefund', header: 'Refund' },
      { field: 'isOnline', header: 'Online' },
      { field: 'isVoucher', header: 'Voucher' },
      { field: 'notes', header: 'Notes' },
    ];
  }

  editdata(ID){
    this.router.navigateByUrl("/editbooking/" + ID);
  }

  loadgrid()
  {
    this.busyService.busy();

    let FromDateYear = this.FromDate.getFullYear().toString();
    let FromDateMonth = ('00' + (this.FromDate.getMonth() + 1)).slice(-2);
    let FromDateDay = ('00' + this.FromDate.getDate()).slice(-2);

    let ToDateYear = this.ToDate.getFullYear().toString();
    let ToDateMonth = ('00' + (this.ToDate.getMonth() + 1)).slice(-2);
    let ToDateDay = ('00' + this.ToDate.getDate()).slice(-2);

    let fromDateChosen = FromDateDay + "-" +  FromDateMonth + "-" + FromDateYear;
    let toDateChosen = ToDateDay + "-" + ToDateMonth + "-" + ToDateYear;

    let DivID:number = 0;

    this.http.get(bmxurl + "/PaymentListDates/" + this.VenueID + "/" + fromDateChosen + "/" + toDateChosen).subscribe(response => {
      try{
        this.ReportResponse = (response as any);

        this.distinctMethods = this.ReportResponse.data.map((item) => item.method).filter((value, index, self) => self.indexOf(value) === index);
        this.distinctMethods.sort();

        let dataByMethod = [];
        this.distinctMethods.forEach(method => {
          dataByMethod.push({DID: DivID, method: method, data: this.ReportResponse.data.filter((item) => item.method == method), total: this.ReportResponse.data.filter((item) => item.method == method).reduce((a, b) => a + b.amount, 0)});
          DivID++;
        });

        this.ListData = dataByMethod;

        this.ListDataGlobal = this.ReportResponse.data;
        this.TotalAmount = 0;
        
        this.ListDataGlobal.forEach(element => {
          this.TotalAmount += element.amount;
        });

        //load divclass with chevron-right
        for (let index = 0; index < this.ListData.length; index++) {
          this.divClass.push("pi pi-chevron-down");
          this.divTotal.push(false);
        }


        this.busyService.idle();
      }
      catch (error){
        this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'Failed to get bookings in loadgrid, possible data mismatch!'});
        this.busyService.idle();
      }
      finally{
        this.busyService.idle();
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'An error occurred getting Bookings'});
      this.busyService.idle();
    });
  }

  doneDate(){
    this.loadgrid()
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  dateChange(num)
  {
    this.FromDate = this.FromDate;
    this.ToDate = this.addDays(new Date(this.ToDate), num);

    this.doneDate();
  }

  collapseDiv(collapsibleDiv, divID){

    var cDiv = document.getElementById(collapsibleDiv);

    if(cDiv.style.display == "none")
    {
      cDiv.style.display = "block";
      this.divClass[divID] = "pi pi-chevron-down";
      this.divTotal[divID] = false;
    }
    else
    {
      cDiv.style.display = "none";
      this.divClass[divID] = "pi pi-chevron-right";
      this.divTotal[divID] = true;
    }

  }

  exportData()
  {
  }

}