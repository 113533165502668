<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed" class="p-3 flex flex-column flex-auto">
    <h3>Cash up</h3>

    <div *ngIf="id == '0'" class="surface-card p-4 shadow-2 border-round" style="margin-top:5px;">
        <div style="margin-bottom:20px;">
            <div style="float: left; width:50px;">
                &nbsp;
            </div>
            <div style="width:20%;float: left;font-size:20px;font-weight:bold;">
                Payment Name
            </div>
            <div style="width:20%;float: left;font-size:20px;font-weight:bold;">
                Total Expected
            </div>
            <div style="width:20%;float: left;font-size:20px;font-weight:bold;">
                Total Actual
            </div>
            <div style="width:20%;float: left;font-size:20px;font-weight:bold;">
                Difference
            </div>
            <div style="clear:both;"></div>
        </div>
        <div *ngFor="let c of cashupdata" style="margin-bottom:20px;">
            <div style="float: left;">
                <button pButton pRipple type="button" style="margin-top: -8px !important;width: 40px !important;height: 40px;margin-right: 10px;" class="p-button-text p-element p-button-success w-auto mt-3" (click)="expand(c)">
                <span *ngIf="!paymentList[c.index]" style="margin-left:-9px;" class="pi pi-angle-down"></span>
                <span *ngIf="paymentList[c.index]" style="margin-left:-9px;" class="pi pi-angle-up"></span>
                </button>
            </div>
            <div style="width:20%;float: left;font-weight:bold;">
                {{c.name}}
            </div>
            <div style="width:20%;float: left;">
                {{c.paymenttotal | currency:CurrencySymbol}}
            </div>
            <div style="width:20%;float: left;">
                <p-inputNumber style="width: 130px!important; margin-top:-20px;" mode="currency" [(ngModel)]="actual[c.index]" currency="{{CurrencySymbol}}" locale="en-GB" [min]="-9999" [max]="99999" (onBlur)="CalcValue(c)"></p-inputNumber>
            </div>
            <div style="width:20%;float: left;color: red;font-weight:bold;" *ngIf="variance[c.index] < 0">
                {{variance[c.index] | currency:CurrencySymbol}}
            </div>
            <div style="width:20%;float: left;color: #18c718;font-weight:bold;" *ngIf="variance[c.index] > 0">
                {{variance[c.index] | currency:CurrencySymbol}}
            </div>
            <div style="width:20%;float: left;color: black;" *ngIf="variance[c.index] == 0">
                {{variance[c.index] | currency:CurrencySymbol}}
            </div>
            <div style="clear:both;"></div>
            <div *ngIf="paymentList[c.index]" class="surface-card p-4 shadow-2 border-round" >
                
            <p-table  #paymentsTable 
                [value]="c.payments" sortField="paymentDate" [paginator]="true" [rows]="10" 
                [showCurrentPageReport]="true" responsiveLayout="scroll"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                [rowsPerPageOptions]="[10,25,50]" 
                responsiveLayout="scroll"
                datakey="id"
                [selectionPageOnly]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>
                                Payment ID
                            </th>
                            <th pSortableColumn="paymentDate">
                                Payment Date
                            </th>
                            <th>Sales ID</th>
                            <th>Amount</th>
                            <th>Online</th>
                            <th>Refund</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data>
                        <tr [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                            <td>{{data.paymentId}}</td>
                            <td>{{data.paymentDate | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                            <td>{{data.bookingIdHR}}</td>
                            <td>{{data.amount | currency:CurrencySymbol}}</td>

                            <td *ngIf="data.isOnline"><span style="color:green" class="pi pi-check"></span></td>
                            <td *ngIf="!data.isOnline"><span style="color:red" class="pi pi-times"></span></td>

                            <td *ngIf="data.isRefund"><span style="color:green" class="pi pi-check"></span></td>
                            <td *ngIf="!data.isRefund"><span style="color:red" class="pi pi-times"></span></td>
                        </tr>
                    </ng-template>
            </p-table>

            </div>
        </div>
        <hr />
        <div style="margin-bottom:20px;">
            <div style="float: left; width:50px;">
                &nbsp;
            </div>
            <div style="width:20%;float: left;font-size:20px">
                &nbsp;
            </div>
            <div *ngIf="totalExpected < 0" style="width:20%;float: left;font-size:20px;color:red">
                {{totalExpected | currency:CurrencySymbol}}
            </div>
            <div *ngIf="totalExpected >= 0" style="width:20%;float: left;font-size:20px;color:black">
                {{totalExpected | currency:CurrencySymbol}}
            </div>

            <div *ngIf="totalActual < 0"  style="width:20%;float: left;font-size:20px;color:red">
                {{totalActual | currency:CurrencySymbol}}
            </div>
            <div *ngIf="totalActual >= 0"  style="width:20%;float: left;font-size:20px;color:black">
                {{totalActual | currency:CurrencySymbol}}
            </div>

            <div *ngIf="totalVariance < 0"  style="width:20%;float: left;font-size:20px;color:red">
                {{totalVariance | currency:CurrencySymbol}}
            </div>
            <div *ngIf="totalVariance >= 0"  style="width:20%;float: left;font-size:20px;color:black">
                {{totalVariance | currency:CurrencySymbol}}
            </div>

            <div style="clear:both;"></div>
        </div>
        <hr />
        <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
                <label for="notesName" class="font-medium">Notes</label>
                <textarea pInputTextarea [(ngModel)]="notes" id="notesName" type="text" ></textarea>
            </div>
        </div>

        <div style="clear:both;">
            <button pButton type="button" style="height: 50px; width: 187px;margin-top:20px;" class="p-button p-button p-button-success" (click)="saveReport()"> 
                <span class="pi pi-save" style="margin-right:8px;"></span>
                <span>Save and Clear</span>
            </button> &nbsp;
            <button pButton type="button" style="height: 50px; width: 120px;margin-top:20px;" class="p-button p-button p-button-danger" (click)="returnHome()">
                <span class="pi pi-times" style="margin-right:8px;"></span>
                <span>Cancel</span>
            </button> 
        </div>
    </div>

    <div *ngIf="id != '0'" class="surface-card p-4 shadow-2 border-round" style="margin-top:55px;">
        <div style="margin-bottom:20px;">
            <div style="width:20%;float: left;font-size:20px;font-weight:bold;">
                Payment Name
            </div>
            <div style="width:20%;float: left;font-size:20px;font-weight:bold;">
                Total Expected
            </div>
            <div style="width:20%;float: left;font-size:20px;font-weight:bold;">
                Total Actual
            </div>
            <div style="width:20%;float: left;font-size:20px;font-weight:bold;">
                Difference
            </div>
            <div style="clear:both;"></div>
        </div>
        <div *ngFor="let c of cashupdata.income" style="margin-bottom:20px;">
            <div style="width:20%;float: left;font-weight:bold;">
                {{c.method}}
            </div>
            <div style="width:20%;float: left;">
                {{c.expected | currency:CurrencySymbol}}
            </div>
            <div style="width:20%;float: left;">
                {{c.actual | currency:CurrencySymbol}}
            </div>
            <div style="width:20%;float: left;color: red;font-weight:bold;" *ngIf="c.variance < 0">
                {{c.variance | currency:CurrencySymbol}}
            </div>
            <div style="width:20%;float: left;color: #18c718;font-weight:bold;" *ngIf="c.variance > 0">
                {{c.variance  | currency:CurrencySymbol}}
            </div>
            <div style="width:20%;float: left;color: black;" *ngIf="c.variance == 0">
                {{c.variance  | currency:CurrencySymbol}}
            </div>
            <div style="clear:both;"></div>
        </div>
        <hr />
        <div style="margin-bottom:20px;">

            <div style="width:20%;float: left;font-size:20px">
                &nbsp;
            </div>
            <div *ngIf="totalExpected < 0" style="width:20%;float: left;font-size:20px;color:red">
                {{totalExpected | currency:CurrencySymbol}}
            </div>
            <div *ngIf="totalExpected >= 0" style="width:20%;float: left;font-size:20px;color:black">
                {{totalExpected | currency:CurrencySymbol}}
            </div>

            <div *ngIf="totalActual < 0"  style="width:20%;float: left;font-size:20px;color:red">
                {{totalActual | currency:CurrencySymbol}}
            </div>
            <div *ngIf="totalActual >= 0"  style="width:20%;float: left;font-size:20px;color:black">
                {{totalActual | currency:CurrencySymbol}}
            </div>

            <div *ngIf="totalVariance < 0"  style="width:20%;float: left;font-size:20px;color:red">
                {{totalVariance | currency:CurrencySymbol}}
            </div>
            <div *ngIf="totalVariance >= 0"  style="width:20%;float: left;font-size:20px;color:black">
                {{totalVariance | currency:CurrencySymbol}}
            </div>

            <div style="clear:both;"></div>
        </div>
        <hr />
        <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
                <label for="notesName" class="font-medium">Notes:</label>
                {{notes == null? "No notes" : notes}}
            </div>
        </div>

        <div style="clear:both;" style="margin-bottom: 20px;">
            <button pButton type="button" style="height: 50px; width: 180px;margin-top:20px;" class="p-button p-button p-button-danger" (click)="returnHome()">
                <span class="pi pi-chevron-left" style="margin-right:8px;"></span>
                <span>Back to list</span>
            </button> 
            &nbsp;
            <button *ngIf="!showData" pButton type="button" style="height: 50px; width: 390px;margin-top:20px;" class="p-button p-button p-button-success" (click)="toggle()">
                <span class="pi pi-print" style="margin-right:8px;"></span>
                <span>Show the data that makes up this report.</span>
            </button> 
            <button *ngIf="showData" pButton type="button" style="height: 50px; width: 390px;margin-top:20px;" class="p-button p-button p-button-success" (click)="toggle()">
                <span class="pi pi-print" style="margin-right:8px;"></span>
                <span>Hide the data that makes up this report.</span>
            </button> 
        </div>

        <div *ngIf="showData">
            <p-table  #allPaymentsTable 
            [columns]="cols"
            [value]="allPayments" sortField="paymentDate" [paginator]="true" [rows]="10" 
            [showCurrentPageReport]="true" responsiveLayout="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
            [rowsPerPageOptions]="[10,25,50]" 
            responsiveLayout="scroll"
            datakey="id"
            [selectionPageOnly]="true">
            <ng-template pTemplate="caption">
                <div class="flex">
                  <button
                      type="button"
                      pButton
                      pRipple
                      icon="pi pi-file-excel"
                      (click)="allPaymentsTable.exportCSV()"
                      class="mr-2"
                      pTooltip="Export to CSV"
                      tooltipPosition="bottom">
                  </button>
                </div>
            </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Payment ID</th>
                        <th>Payment Date</th>
                        <th>Sales ID</th>
                        <th>Payment Type</th>
                        <th>Amount</th>
                        <th>Online</th>
                        <th>Refund</th>
                        <th>Refund Reason</th>
                        <th>Notes</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                        <td>{{data.paymentId}}</td>
                        <td>{{data.paymentDate | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                        <td>{{data.bookingIdHR}}</td>
                        <td>{{data.method}}</td>
                        <td>{{data.amount | currency:CurrencySymbol}}</td>
                        <td>{{data.isOnline}}</td>
                        <td>{{data.isRefund}}</td>
                        <td>{{data.refundreason}}</td>
                        <td>{{data.notes}}</td>
                    </tr>
                </ng-template>
        </p-table>
        </div>
    </div>
</div>
