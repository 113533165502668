<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="screenwidth <= 1100">
    <div style="background-color: #dfe7ef; height:50px; width:100%; text-align: center;">
        <div>
            <div style="width:230px;">
                <button pButton type="button"  pTooltip="Previous day"  style="padding-left: 13px;margin-left:8px; height: 44px; margin-right: 5px;width: 44px; float:left;" (click)="dateChange(-1);" class="p-button decrementButton">
                    <span class="pi pi-angle-left" style="margin-right:8px;"></span>
                </button>
                <div style="float:left; width: 112px;">
                    <p-calendar style="height:44px; margin-top:2px;" [(ngModel)]="BookingDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" (onSelect)="doneDate()" (onChange)="doneDate()" style="height:44px;" class="lozenge" ></p-calendar>  
                </div> 
                <button pButton type="button" pTooltip="Next day" style="padding-left: 13px;height: 44px; margin-left: 5px;width: 44px; float:left;" (click)="dateChange(1);" class="p-button incrementButton">
                    <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                </button>
            </div>

            <div style="float:right;">
                <a  (click)="showInProgress()" style="margin-top:5px;background-color:white;text-decoration: none;color: orange;padding-bottom: 12px;height: 40px;display: inline-block;border: 1px solid orange;border-radius: 32px;margin-bottom: 6px;padding-top: 6px;padding-left: 12px;padding-right: 12px; margin-right: 8px" class="p-button-warning">
                    <span class="pi pi-clock" style="margin-right:8px; font-size: 16px;"></span>
                    <span style="font-size:17px;">{{bookingsInProgress}}</span>
                </a>

                <a (click)="unshowInProgress()" style="margin-top:5px;background-color:white;text-decoration: none;color: green;padding-bottom: 12px;height: 40px;display: inline-block;border: 1px solid green;border-radius: 32px;margin-bottom: 6px;padding-top: 6px;padding-left: 12px;padding-right: 12px; margin-right: 8px" class="p-button-warning">
                    <span class="pi pi-check-circle" style="margin-right:8px; font-size: 16px;"></span>
                    <span style="font-size:17px;">{{totalBookingsToday}}</span>
                </a>
            </div>
            <div style="clear:both;"></div>
            <div>
                <p-accordion>
                    <p-accordionTab *ngFor="let booking of ListBookings">
                        <p-header style="width: 100%;">
                            <div style="float:left;">
                                {{booking.bookingIdHR.substring(booking.bookingIdHR.length - 6)}} | {{booking.startTime | date:'HH:mm' : 'UTC'}} | {{booking.name}}
                            </div>
                            <div style="float: right; text-align:right;">
                                <span *ngIf="booking.extraslist != null && booking.extraslist != ''" class="pi pi-exclamation-triangle" style="margin-right:8px; color: #ef4444"></span>
                                <span *ngIf="booking.allergy != null && booking.allergy != ''" class="pi pi-star" style="margin-right:8px; color: orange"></span>
                                <span *ngIf="booking.disabled" class="pi pi-trash" style="margin-right:8px; color: red"></span>
                            </div>
                        </p-header>

                        <div>
                            <div style="display: flex; text-align: left;width:100%">
                                <div style="width:100%">
                                    <div style="width:100%;margin-bottom: 10px">
                                        <span style="font-weight: bold;">Package:</span> {{booking.packageName}}
                                    </div>
                                    <div style="width:100%;margin-bottom: 10px">
                                        <span style="font-weight: bold;">Guests:</span> {{booking.guests}}
                                    </div>
                                    <div style="width:100%;margin-bottom: 10px">
                                        <span style="font-weight: bold;">Total:</span> {{booking.total | currency: CurrencySymbol}}
                                    </div>
                                    <div style="width:100%;margin-bottom: 10px">
                                        <span style="font-weight: bold;">O/S:</span> {{booking.outstanding | currency: CurrencySymbol}}
                                    </div>
                                    <div style="width:100%;margin-bottom: 10px">
                                        <span style="font-weight: bold;">Extras:</span> {{booking.extraslist}}
                                    </div>
                                    <div style="width:100%;margin-bottom: 10px">
                                        <span style="font-weight: bold;">Allergy:</span> {{booking.allergy}}
                                    </div>
                                    <div style="width:100%;margin-bottom: 10px">
                                        <button style="width:100%" pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" (click)="editdatanew(booking.bookingId)">
                                            <span class="pi pi-envelope" style="margin-right:8px"></span>
                                            Edit Booking
                                        </button>
                                    </div>
                                    <div style="width:100%;margin-bottom: 10px">
                                        <button style="width:100%" pButton type="button" class="p-button-sm p-element p-ripple p-button-warning p-button p-component" (click)="emailConf(booking.bookingId)">
                                            <span class="pi pi-envelope" style="margin-right:8px"></span>
                                            Send Confirmation
                                        </button>
                                    </div>
                                    <div style="width:100%">
                                        <button style="width:100%" pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="disableData(booking.bookingId)">
                                            <span class="pi pi-trash" style="margin-right:8px"></span>
                                            Disable Booking
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </div>

        </div>
    </div>
</div>

<div *ngIf="screenwidth > 1100" >
    <div *ngIf="ListBookings && AccessAllowed" class="p-3 flex flex-column flex-auto">    
        <h3 style="margin-bottom: -38px" i18n>Bookings Created: </h3>
        <div style="width: calc(100vw - 607px); float: left; margin-left: 252px; margin-bottom:20px;">
            <button pButton type="button"  pTooltip="Previous day"  style="height: 50px; margin-right: 5px;width: 53px; float:left;" (click)="dateChange(-1);" class="p-button-sm p-element p-ripple p-button p-component">
                <span class="pi pi-angle-left" style="margin-right:8px;"></span>
            </button>
            <div style="float:left; width: 200px;">
                <p-calendar [(ngModel)]="BookingDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" (onSelect)="doneDate()" (onChange)="doneDate()"></p-calendar>  
            </div> 
            <button pButton type="button" pTooltip="Next day" style="height: 50px; margin-left: 5px;width: 53px; float:left;" (click)="dateChange(1);" class="p-button-sm p-element p-ripple p-button p-component">
                <span class="pi pi-angle-right" style="margin-right:8px;"></span>
            </button>
            &nbsp;
            <p-toggleButton pTooltip="Show disabled bookings" *ngIf="!inProgress" class="p-button-sm p-button-danger p-component" [(ngModel)]="isDisabled" offIcon="pi pi-eye-slash" onIcon="pi pi-eye" [style]="{'margin-right': '10px', 'color' : 'white', 'background-color': '#EF4444', 'font-weight': 'normal', 'height': '50px'}" (click)="toggleDisabled()">
            </p-toggleButton>

            <div style="float:right; width: 300px;text-align: right;">
                <button pTooltip="Bookings in progress" pButton type="button" style="height: 50px; margin-right:10px;" class="p-button-sm p-element p-ripple p-button-warning p-button p-component" (click)="showInProgress()">
                    <span class="pi pi-clock" style="margin-right:8px; font-size: 16px;"></span>
                    <span style="font-size:17px;">{{bookingsInProgress}}</span>
                </button>
                
                <button pTooltip="Bookings made today" pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-success p-button p-component" (click)="unshowInProgress()">
                    <span class="pi pi-check-circle" style="margin-right:8px; font-size: 16px;"></span>
                    <span style="font-size:17px;">{{totalBookingsToday}}</span>
                </button>
            </div>
        </div>

        <div class="surface-card p-4 shadow-2 border-round">
        <p-table  #resourceTable (onPage)="paginate($event)"
        [columns]="cols"
        [value]="ListBookings" sortField="startTime" [paginator]="true" [rows]="10" 
        [showCurrentPageReport]="true" responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
        [rowsPerPageOptions]="[10,25,50]" 
        [globalFilterFields]="['name', 'bookingId', 'bookingIdHR', 'packageName']"
        [(selection)]="selectedItems"
        responsiveLayout="scroll"
        [selectionPageOnly]="true">
        <ng-template pTemplate="caption">
            <div class="flex">
            <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-excel"
                (click)="resourceTable.exportCSV()"
                class="mr-2"
                pTooltip="Export to CSV"
                tooltipPosition="bottom">
            </button>
            </div>
        </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th width="1%">
                        &nbsp;
                    </th>
                    <th width="1%">
                        &nbsp;
                    </th>
                    <th pSortableColumn="bookingId" >Sales ID
                        <p-sortIcon [field]="bookingIdHR"></p-sortIcon>
                    </th>
                    <th pSortableColumn="startTime" >Start Date
                        <p-sortIcon [field]="startTime"></p-sortIcon>
                    </th>
                    <th pSortableColumn="startTime">Start
                        <p-sortIcon [field]="startTime"></p-sortIcon>
                    </th>
                    <th pSortableColumn="name" >Name
                        <p-sortIcon [field]="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="packageName">Package
                        <p-sortIcon [field]="packageName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="guests">Guests
                        <p-sortIcon [field]="guests"></p-sortIcon>
                    </th>
                    <th pSortableColumn="total">Total
                        <p-sortIcon [field]="total"></p-sortIcon>
                    </th>
                    <th pSortableColumn="outstanding">O/S
                        <p-sortIcon [field]="outstanding"></p-sortIcon>
                    </th>
                    <th *ngIf="inProgress" pSortableColumn="paymentlinksentdate">Link Sent
                        <p-sortIcon [field]="paymentlinksentdate"></p-sortIcon>
                    </th>
                    <th *ngIf="inProgress" pSortableColumn="paymentlinksentdate">Time left to Expiry
                        <p-sortIcon [field]="paymentlinksentdate"></p-sortIcon>
                    </th>
                    <th>
                        Extras
                    </th>
                    <th>
                        Allergy
                    </th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}"  *ngIf="!data.inProgress && !inProgress" onmouseover="this.originalstyle=this.style.backgroundColor;this.style.backgroundColor='#dddddd'" onmouseout="this.style.backgroundColor=this.originalstyle;" [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                    <td (click)="editdatanew(data.bookingId)" >
                        <span *ngIf="data.publicBooking" class="pi pi-user"></span> 
                    </td>
                    <td (click)="editdatanew(data.bookingId)">
                        <span style="color:red" *ngIf="data.notes != '' && data.notes != null" class="pi pi-exclamation-circle" pTooltip="{{data.notes}}"></span> 
                    </td>
                    <td (click)="editdatanew(data.bookingId)">{{data.bookingIdHR}}</td>
                    <td (click)="editdatanew(data.bookingId)">{{data.startTime | date:'dd/MM/yy'}}</td>
                    <td (click)="editdatanew(data.bookingId)">{{data.startTime | date:'HH:mm' : 'UTC'}}</td>
                    <td (click)="editdatanew(data.bookingId)">{{data.name}}</td>
                    <td (click)="editdatanew(data.bookingId)">{{data.packageName}}</td>
                    <td (click)="editdatanew(data.bookingId)">{{data.guests}}</td>
                    <td (click)="editdatanew(data.bookingId)">{{data.total | currency: CurrencySymbol}}</td>
                    <td (click)="editdatanew(data.bookingId)" *ngIf="data.outstanding <=0">{{data.outstanding | currency: CurrencySymbol}}</td>
                    <td (click)="editdatanew(data.bookingId)" *ngIf="data.outstanding >0"><span style="color: Red; font-weight: bold;">{{data.outstanding | currency: CurrencySymbol}}</span></td>
                    <td (click)="editdatanew(data.bookingId)">{{data.extraslist}}</td>
                    <td (click)="editdatanew(data.bookingId)">{{data.allergy}}</td>

                    <td *ngIf="!data.disabled"><button  pTooltip="Send Booking Confirmation" pButton type="button" class="p-button-sm p-element p-ripple p-button-warning p-button p-component" (click)="emailConf(data.bookingId)" i18>
                        <span class="pi pi-envelope"></span>
                    </button></td>

                    <td *ngIf="!data.disabled"><button pTooltip="Disable Booking" pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="disableData(data.bookingId)" i18>
                        <span class="pi pi-trash"></span>
                    </button></td>

                    <td *ngIf="data.disabled"><button pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" (click)="undisableData(data.bookingId)" i18>
                        <span class="pi pi-check-circle"></span>
                    </button></td>
                </tr>

                <tr *ngIf="data.inProgress && inProgress" style="background-color: #FCE1B5;">
                    <td>
                        <span *ngIf="data.publicBooking" class="pi pi-user"></span> 
                    </td>
                    <td>
                        <span style="color:red" *ngIf="data.notes != '' && data.notes != null" class="pi pi-exclamation-circle" pTooltip="{{data.notes}}"></span> 
                    </td>
                    <td style="font-style: italic;">{{data.bookingIdHR}}</td>
                    <td style="font-style: italic;">{{data.startTime | date:'dd/MM/yy'}}</td>
                    <td style="font-style: italic;">{{data.startTime | date:'HH:mm' : 'UTC'}}</td>
                    <td style="font-style: italic;">{{data.name}}</td>
                    <td style="font-style: italic;">{{data.packageName}}</td>
                    <td style="font-style: italic;">{{data.guests}}</td>
                    <td style="font-style: italic;">{{data.total | currency: CurrencySymbol}}</td>
                    <td style="font-style: italic;">{{data.outstanding | currency: CurrencySymbol}}</td>
                    
                    <td *ngIf="(data.paymentlinksentdate | date:'dd/MM/yy HH:mm') != '01/01/01 00:00'" style="font-style: italic; text-align: centre;">{{data.paymentlinksentdate | date:'dd/MM/yy HH:mm'}}</td>
                    <td *ngIf="(data.paymentlinksentdate | date:'dd/MM/yy HH:mm') == '01/01/01 00:00'" style="font-style: italic; text-align: centre;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--</td>

                    <td style="font-style: italic;">{{data.expiry | date:'HH:mm:ss'}}</td>

                    <td>
                        <td *ngIf="!data.publicBooking"><button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="abandonBooking(data.bookingId)" i18>
                            <span class="pi pi-times"></span>
                        </button>
                    </td>
                    <td *ngIf="data.publicBooking">
                    </td>
                </tr>
            </ng-template>
        </p-table>

            <!-- <button pButton type="button" class="p-button-sm p-element p-ripple p-button p-component" (click)="exportData()" i18>
                <span class="pi pi-plus"></span> Export this data
            </button> -->
        </div>
    </div>
</div>