import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { packagerule_returndata } from 'src/types/packagerule_returndata';
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { packagerule } from 'src/types/packagerule';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { booking_returndata } from 'src/types/booking_returndata';
import { BusyService } from '../_services/busy.service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AuthService } from '@auth0/auth0-angular';
import { GlobalService } from '../_services/global.service';
import { MenuItem } from 'primeng/api/menuitem';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';
import { cBookingfee } from 'src/types/bookingfee';
import { bookingfee_returndata } from 'src/types/bookingfee_returndata';
import { environment } from 'src/environments/environment';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  ListBookings: any[];
  ListBookingsGlobal: any[];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateUpdateResponse: any;
  DisabledListResource: any;
  checked: boolean = true;
  showDisabled: boolean = false;
  selectedItems: any;
  BookingDate: Date;
  httpResponse: any;
  searchCritera: string = "";

  CompletedLoading: boolean = false;
  AccessAllowed: boolean = false;

  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

  constructor(
    private http: HttpClient, 
    private appComp: AppComponent, 
    private router: Router, 
    private messageService: MessageService, 
    private busyService: BusyService,
    private auth: AuthService,
    private globalService: GlobalService,
    private route: ActivatedRoute) { 
    }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let qssearchparam = "";
    this.route.queryParams
      .subscribe(params => {
        this.searchCritera = params.crit;
        this.showSearchResults(this.showDisabled);
        this.showDisabled = true;
      }
    );
  }

  toggleDisabled(){
    localStorage.removeItem("SearchResults");
    this.showSearchResults(!this.showDisabled);
  }

  editdatanew(ID){
    this.router.navigate(
      ['/editbooking/' + ID],
      { queryParams: { fs: 'true' } }
    );
  }

  showSearchResults(showdis)
  {
    if(localStorage.getItem("SearchResults") == null)
    {
      this.busyService.busy();

      const bookingRefPattern = /^[a-zA-Z]{3}-\d{6}-[a-zA-Z0-9]{6}$/;

      if(this.searchCritera && bookingRefPattern.test(this.searchCritera))
      { 
        showdis = true;
      }

      this.http.get(bmxurl + "Booking/Search/" + this.VenueID + "/" + this.searchCritera + "/" + showdis).subscribe(response => {
        try{
          this.CreateUpdateResponse = (response as booking_returndata);
          this.ListBookings = this.CreateUpdateResponse.data;

          localStorage.setItem("SearchResults",  JSON.stringify(this.ListBookings))
        }
        catch{
          this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'Failed to get bookings'});
        }
        finally{
          this.busyService.idle();
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'An error occurred getting Bookings'});
        this.busyService.idle();
      });
    }
    else{
      this.ListBookings = (JSON.parse(localStorage.getItem("SearchResults")) as any[]);
    }
  }

  disableData(BookingID)
  {
    if (confirm("Are you sure you want to disable this booking?"))
     {
        this.http.delete(bmxurl + "Booking/" + this.VenueID + "/" + BookingID).subscribe(response => {
          this.CreateUpdateResponse = response
          if(this.CreateUpdateResponse.succeeded = true)
          {
            this.messageService.add({severity:'success', summary:'Disabled', detail:'Booking successfully disabled'});
            this.showSearchResults(false);
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Booking'});
        })
      }
  }


}
