import { Component, OnInit } from '@angular/core';
import { bmxurl, getAccess } from 'src/globals';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AvailableTimes, Package, AvailabilityReq, Admission, SelectedAdmission, BookingReq, Package_returndata, Payment, BookingReq_returndata, Customer, Resource } from '../../PackageClasses';
import { customer } from 'src/types/customer';
import { booking, cBooking, cBookingExtras } from 'src/types/booking';
import { booking_returndata } from 'src/types/booking_returndata';
import { MessageService } from 'primeng/api';
import { cExtra } from 'src/types/extra';
import { cPackageExtra } from 'src/types/packageExtra';

import { cDepositpolicy } from 'src/types/depositpolicies';
import { formatDate } from '@angular/common';
import { cResource, resource } from 'src/types/resource';
import { v4 as uuidv4  } from 'uuid';
import { BusyService } from '../_services/busy.service';
import { admission, counters } from 'src/types/admission';
import { cBookingfee } from 'src/types/bookingfee';
import { bookingfee_returndata } from 'src/types/bookingfee_returndata';
import { paymenttypes_returndata } from 'src/types/paymenttypes_returndata';
import { bookingsummary, cBookingsummary } from 'src/types/bookingsummary';
import { DomSanitizer } from '@angular/platform-browser';
import { packagegroups_returndata } from 'src/types/packagegroup_returndata';
import { cPackagegroups } from 'src/types/packagegroups';
import { cPackage } from 'src/types/package';
import { cDiscountvalidate, cOpenDiscountvalidate } from 'src/types/discountvailidate';
import { conf_returndata } from 'src/types/conf_returndata';
import { payment_returndata } from 'src/types/payment_returndata';
import { users_returndata } from 'src/types/user_returndata';
import { allergyanswer, birthdayanswers, questionanswers } from 'src/types/questionanswers';
import { bookingquestionitem } from 'src/types/bookingquestion';
import { iif } from 'rxjs';
import { captureRejectionSymbol } from 'events';
import { cVouchervalidate } from 'src/types/vouchervalidate';
import { venueclosings_returndata } from 'src/types/venueclosings_returndata';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';
import { GlobalService } from '../_services/global.service';


@Component({
  selector: 'app-makeabooking',
  templateUrl: './makeabooking.component.html',
  styleUrls: ['./makeabooking.component.css']
})
export class MakeabookingComponent implements OnInit {

  //#region  Variables
  id: string;
  BookingComplete: boolean = false;
  packages: any[] = [];
  packages1: any[] = [];
  SelectedPackage: any;
  SelectedResources: any[] = []
  SelectedDate: Date;
  startTimes: any;
  startTimesFiltered: any;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateCustomer: any;
  PaymentTypes: any = [];
  selectedPayment: any;
  packageExtras: cPackageExtra[];
  selectedPackageExtras: cExtra[];

  BookingDateAndTime: string = "";
  BookingPackage: string = "";
  BookingAdmissions: string = "";
  BookingAddress: string = "";
  BookingEmail: string = "";
  BookingMobile: string = "";
  BookingValue: string = "";
  Itinerary: string = "";
  bookingExtras: string = "";

  paymentValueValid: boolean = true;
  paymentTypeValid: boolean = true;
  paymentValueExceededValid: boolean = true;
  paymentDepositValid: boolean = true;
  validPaymentAmount: number = 0;

  AllValid: boolean =true;
  CustomerAllValid: boolean =true;

  PackageAdmissions: admission[];
  SelectedAdmissions: admission[];

  PackageHeader: string = "Select a Package";
  CalendarHeader: string = "Select a Date";
  AdmissionHeader: string = "Select your Admissions";
  TimeHeader: string = "Select a Time";
  CustomerHeader: string = "Create a Customer";
  PaymentHeader: string = "Add a Payment";
  ExtrasHeader: string = "Select Extras";
  SelResourceHeader: string = "Please Select";

  packageSelected: Boolean;
  admissionsSelected: Boolean;
  dateSelected: Boolean;
  timeSelected: Boolean;
  customerSelected:Boolean;
  paymentSelected:Boolean;
  extraSelected: Boolean;
  notesSelected: Boolean;
  partyroomsSelected: Boolean;
  bookingQuestionsSelected: Boolean;

  packageActive: Boolean;
  admissionsActive: Boolean;
  dateActive: Boolean;
  timeActive: Boolean;
  customerActive:Boolean;
  paymentActive:Boolean;
  extraActive: Boolean;
  notesActive: Boolean;
  partyroomsActive: Boolean;
  partyroomsVisible: Boolean;
  bookingQuestionsActive: Boolean;

  //Customer
  firstName: string = "";
  lastName: string = "";
  address1: string = "";
  address2: string = "";
  town: string = "";
  postCode: string = "";
  email: string = "";
  mobile: string = "";
  dob: Date = new Date(1900, 1, 1);

  firstNameValid: boolean = true;
  lastNameValid: boolean = true;
  Address1Valid: boolean = true;
  TownValid: boolean = true;
  PostCodeValid: boolean = true;
  EmailValid: boolean = true;
  MobileValid: boolean = true;
  NewBooking = {} as booking;

  EmailFormatValid: boolean = true;
  MobileFormatValid: boolean = true;

  stateOptions: any[];
  stateOptionsMin: any[];
  statePayOptions: any[];

  ProvideMinimalPersonalData: string = "LIMITED";

  BookingResponse: any;

  //payment
  paymentAmount: number;
  maxPayment: number;

  searchCriteria: string;
  customersResult: any;

  disgnosticsMode: boolean = true;
  ShowCustomerDetails: boolean = false;

  makeABookingHeader: string = "Make a Booking"
  notes: string;

  SelectedSeletableResource: any[] = [];
  SelectedSeletableResourceBool: boolean[] = [];

  customerSelectedResources: any[] = [];
  includesComplexResources: boolean = false;
  includesOptionalResources: boolean = false;

  systemChoosesAllResources: boolean = true;

  validselection: boolean[] = [];

  totalAdmissions: number;

  totalAdmissionInRange: boolean;
  PackageHasExtras: boolean;

  MinimumValidPayment:number = 0;
  MustIncludePass: boolean = true;

  httpResponse: any;
  BookingFee: cBookingfee;
  BookingSummary: bookingsummary;
  packageGroups: any[];

  checked: any[] = [];
  popular: any;

  showDiscount: boolean = false;
  discountCode: string = "";

  BookingFeeAdddedOn: number = 0;
  DiscountTakenOff: number = 0;

  showPrintConfirmations: boolean = false;
  confHTML: string = "";

  TotalExtras: number = 0;
  minimumDiscount: number = 0;

  BookingPayments: Payment[] = [];
  totalPayments: number;

  showDeferReason: boolean = false;
  deferNotes: string = "";
  AvailabilityReq = {} as AvailabilityReq;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  stateOptionAllergy: any[];
  birthdayitems: birthdayanswers[] = [];

  mustanswerallergies: boolean[] = [];
  mustanswerbirthdays: boolean[] = [];
  mustanswernumber: boolean[] = [];
  mustanswerfreetext: boolean[] = [];
  mustanswermultichoicesingleanswer: boolean[] = [];
  mustanswermultichoicemultianswer: boolean[] = [];
  mustanswerdate: boolean[] = [];

  questionAnswers: questionanswers[] = [];
  questionAnswer: questionanswers;

  bookingFreeTextAnswer: string = "";
  bookingallergyanswer: string = "";
  bookingnumberanswer: number = 0;
  bookingdateanswer: Date = null;
  allergyAnswer:boolean[] = [];
  bookingmultichoicesingleanswer: bookingquestionitem = null;
  bookingmultichoicemultianswer: bookingquestionitem[] = [];
  showVoucher: boolean = false;
  voucherCode: string = "";
  voucherpin: string = "";

  timetobetried: string = "";

  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

  allergyOptions: any[] = [{ name: 'Yes', value: true }, { name: 'No', value: false}];

  CurrentDate = new Date();//new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0,0,0,0);

  VoucherComplete: boolean = false;

  SummaryItinerary: boolean = false;

  allQuestionsValid: boolean = true;
  linkedAdmissionMessageShown: boolean = false;
  showWarning: boolean = false;
  WarningMessage: string = "";
  totalDiscount: number = 0;

  paymentButtonLocked: boolean = false;

  stateOptionOpenDiscount: any[];
  stateGenderOptions: any[];

  openDiscount: boolean = false;

  chosenDiscountAmount: number = 0;

  showVCWarning: boolean = false;
  VCWarningMessage: string = "";

  venueClosings: any[] = [];

  caldays: any[] = [];
  calmonths: any[] = [];
  calyears: any[] = [];

  DOBDay: any[] = [];
  DOBMonth: any[] = [];
  DOBYear: any[] = [];

  DOBIsValid: boolean = true;
  dobage: any[] = [];

  ShowAge: boolean = false;
  ShowDOB: boolean = false;
  ShowGender: boolean = false;
  ShowInterests: boolean = false;

  oldDepositValue: number = 0;

  UseIntervalAvailability: boolean = false;

  //#endregion

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private busyService: BusyService, private globalService: GlobalService) { 
      this.stateOptions = [{ label: 'Use full personal details', value: "FULL" }, { label: 'Use limited personal details', value: "LIMITED"}, { label: 'Walk In', value: "WALKIN" }]; 
      this.stateOptionsMin = [{ label: 'Use full personal details', value: "FULL" }, { label: 'Use limited personal details', value: "LIMITED"}];    
      this.stateOptionAllergy = [{ label: 'Yes', value: true }, { label: 'No', value: false}];  
      this.stateOptionOpenDiscount = [{ label: 'Enter Discount Value', value: true }, { label: 'Enter Discount Code', value: false}]; 
      this.stateGenderOptions =  [{ label: 'Rather not say', value: 'NOT STATED' }, { label: 'Male', value: 'MALE'}, {label: 'Female', value: 'FEMALE'}]; 
      
      this.dobage = [
        {name:'0 to 3 months', value:'0 to 3 months'},
        {name:'4 to 6 months', value:'4 to 6 months'},
        {name:'6 to 12 months', value:'6 to 12 months'},
        {name:'1', value:'1'},
        {name:'2', value:'2'},
        {name:'3', value:'3'},
        {name:'4', value:'4'},
        {name:'5', value:'5'},
        {name:'6', value:'6'},
        {name:'7', value:'7'},
        {name:'8', value:'8'},
        {name:'9', value:'9'},
        {name:'10', value:'10'},
        {name:'11', value:'11'},
        {name:'12', value:'12'},
        {name:'13', value:'13'},
        {name:'14', value:'14'},
        {name:'15', value:'15'},
        {name:'16', value:'16'},
        {name:'17', value:'17'},
        {name:'18', value:'18'},
        {name:'Over 18', value:'Over 18'}
      ];

      this.caldays =  [
        {name: '01', value:'01'},
        {name: '02', value:'02'},
        {name: '03', value:'03'},
        {name: '04', value:'04'},
        {name: '05', value:'05'},
        {name: '06', value:'06'},
        {name: '07', value:'07'},
        {name: '08', value:'08'},
        {name: '09', value:'09'},
        {name: '10', value:'10'},
        {name: '11', value:'11'},
        {name: '12', value:'12'},
        {name: '13', value:'13'},
        {name: '14', value:'14'},
        {name: '15', value:'15'},
        {name: '16', value:'16'},
        {name: '17', value:'17'},
        {name: '18', value:'18'},
        {name: '19', value:'19'},
        {name: '20', value:'20'},
        {name: '21', value:'21'},
        {name: '22', value:'22'},
        {name: '23', value:'23'},
        {name: '24', value:'24'},
        {name: '25', value:'25'},
        {name: '26', value:'26'},
        {name: '27', value:'27'},
        {name: '28', value:'28'},
        {name: '29', value:'29'},
        {name: '30', value:'30'},
        {name: '31', value:'31'}
      ];
          
      this.calmonths = [
        {name: '01', value:'01'},
        {name: '02', value:'02'},
        {name: '03', value:'03'},
        {name: '04', value:'04'},
        {name: '05', value:'05'},
        {name: '06', value:'06'},
        {name: '07', value:'07'},
        {name: '08', value:'08'},
        {name: '09', value:'09'},
        {name: '10', value:'10'},
        {name: '11', value:'11'},
        {name: '12', value:'12'}
      ];
      
      for(let i = 2050; i > 1920; i--)
        {
          let thisYear = new Date().getFullYear();
          if(i <= thisYear)
          {
            this.calyears.push({name: i.toString(), value: i.toString()});
          }
        }

      this.SummaryItinerary = localStorage.getItem("SummaryItinerary") == "true" ? true : false;
  }

  WarningContinue(){

    this.showWarning = false;
  }

  admitall(){
    this.http.get(bmxurl + "BookingCheckin/" + this.VenueID + "/" + this.NewBooking.bookingId).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded = true)
      {
        this.messageService.add({severity:'success', summary:'Admitted', detail:'All customers admitted for this booking.'});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Admitted', detail: 'An error occurred admitting all customers for this booking'});
    })
  }

  addBirthdayRow(){

    let INDEX = this.birthdayitems.length;
   
      this.birthdayitems.push({
        index: INDEX, 
        id: uuidv4(), name: "", 
        age: "", 
        birthdate: "",
        gender: "NOT STATED",
        interests: "",
        showage: this.ShowAge,
        showdob: this.ShowDOB,
        showgender: this.ShowGender,
        showinterests: this.ShowInterests,
        dobday: "",   
        dobmonth: "",
        dobyear: ""
      });

  }

  deleteBirthdayRow(inId){
    let index = 0;  
    this.birthdayitems.forEach(element => {
      if(element.id == inId)
      {
        this.birthdayitems.splice(index, 1);
      }
      index++;
    });
  }

  sendPaymentLink(){

    this.NewBooking.payments = null;
    this.NewBooking.inProgress = false;
    this.NewBooking.awaitingpayment = true;

    this.http.post<any>(bmxurl + "/Payment", this.NewBooking).subscribe(response => {
     this.BookingResponse = response
     this.BookingDateAndTime = new Date(this.BookingResponse.data.startTime).getUTCDate().toString().padStart(2, '0') + "/" + new Date(this.BookingResponse.data.startTime).getUTCMonth().toString().padStart(2, '0') + "/" + new Date(this.BookingResponse.data.startTime).getUTCFullYear() + " " + new Date(this.BookingResponse.data.startTime).getUTCHours().toString().padStart(2, '0') + ":" + new Date(this.BookingResponse.data.startTime).getUTCMinutes().toString().padStart(2, '0');

     this.BookingPackage = this.BookingResponse.data.packageName;
    
     this.http.get<any>(bmxurl + "sendpaymentlink/" + this.VenueID + "/" + this.NewBooking.bookingId).subscribe(response => {
      this.httpResponse = response;

      if(!this.httpResponse.succeeded)
      {
        this.messageService.add({severity:'error', summary:'Failure', detail: this.httpResponse.messages[0]});
      }
      else
      {
        if(this.BookingResponse.data.payments.length > 0)
        {
          this.messageService.add({severity:'success', summary:'Success', detail: 'Payment link sent'});
        }
        else
        {
          this.messageService.add({severity:'success', summary:'Success', detail: 'Payment link sent, the customer will have 4 hours to respond to this link otherwise the booking will be cancelled. Cancelled bookings cannot be paid.'});
        }
        this.DoSummary(this.BookingResponse.data);
      }

    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail: 'An error occurred whilst trying to send the Email'});
    })

    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail: 'An error occurred whilst trying to defer payment'});
    })

  }

  doneBookingQuestions(){
    let AllValid = true;

    this.SelectedPackage.questionnaire.bookingquestions.forEach(question => {
      if(question.questiontype == "ALLERGY")
      {
        if(question.answers.allergyanswer.allergypresent == true)
        {
          if(question.answers.allergyanswer.allergytext == "" && question.mandatory)
          {
            AllValid = false;
            this.mustanswerallergies[question.listorder] = false;
          }
          else
          {
            this.mustanswerallergies[question.listorder] = true;
          }
        }
        else
        {
          this.mustanswerallergies[question.listorder] = true;
        }
      }
      else if(question.questiontype == "BIRTHDAY")
      {
        this.mustanswerbirthdays[question.listorder] = true;

        this.birthdayitems.forEach(birthday => {

        if(birthday.showdob) 
        {
          let Year = birthday.dobyear;
          let Month = birthday.dobmonth;
          let Day = birthday.dobday;

          birthday.birthdate = birthday.dobday + "/" + birthday.dobmonth + "/" + birthday.dobyear;
        }
        else
        {
          birthday.birthdate = "";
        }

        if(question.mandatory)
        {
          if(birthday.name == "" || ((birthday.interests == "" || birthday.interests == null)  && birthday.showinterests) || ((birthday.age == "" || birthday.age == null)  && birthday.showage) || ((birthday.birthdate == "" || birthday.birthdate == null) && birthday.showdob))
          {
            AllValid = false;
            this.mustanswerbirthdays[question.listorder] = false;
          }
        }
        });
      }
      else if(question.questiontype == "NUMBER")
      {
        if(question.answers.numberanswer == null && question.mandatory)
        {
          AllValid = false;
          this.mustanswernumber[question.listorder] = false
        }
        else
        {
          this.mustanswernumber[question.listorder] = true
        }
      }
      else if(question.questiontype == "FREETEXT")
      {
        if((question.answers.freetextanswer == null || question.answers.freetextanswer == "") && question.mandatory)
        {
          AllValid = false;
          this.mustanswerfreetext[question.listorder] = false;
        }
        else
        {
          this.mustanswerfreetext[question.listorder] = true;
        }
      }
      else if(question.questiontype == "DATE")
      {
        if(question.answers.dateanswer == null && question.mandatory)
        {
          AllValid = false;
          this.mustanswerdate[question.listorder] = false;
        }
        else
        {
          this.mustanswerdate[question.listorder] = true;
        }
      }
      else if(question.questiontype == "MULTICHOICESINGLE")
      {
        if(question.answers.multichoicesingleanswer.id == null && question.mandatory)
        {
          AllValid = false;
          this.mustanswermultichoicesingleanswer[question.listorder] = false;
        }
        else
        {
          this.mustanswermultichoicesingleanswer[question.listorder] = true;
        }

      }
      else if(question.questiontype == "MULTICHOICEMULTIPLE")
      {
        if(question.answers.multichoicemultianswer.length == 0 && question.mandatory)
        {
          AllValid = false;
          this.mustanswermultichoicemultianswer[question.listorder] = false;
        }
        else
        {
          this.mustanswermultichoicemultianswer[question.listorder] = true;
        }

      }
    });
   
    if(AllValid)  
    {
      this.SelectedPackage.questionnaire.bookingid = this.NewBooking.bookingId;
      
      this.SelectedPackage.questionnaire.bookingquestions.forEach(question => {
        if(question.questiontype != "ALLERGY")
        {
          question.answers.allergypresent = false;
          question.answers.allergytext = "";
          question.answers.allergyanswer.allergypresent = false;
        }
        
        if(question.questiontype == "BIRTHDAY")
          question.answers.birthdayanswers = this.birthdayitems;
      });

      this.http.post<booking_returndata>(bmxurl + "/questionanswers", this.SelectedPackage.questionnaire).subscribe(response => {
        this.httpResponse = response
        if (this.httpResponse.succeeded == true) {
          //this.setTab(Sections.Notes);

          if(this.packageExtras.length == 0)
            {
              this.setTab(Sections.Customer);
            }
            else
            {
              this.setTab(Sections.Extras);
            }
        }
        else
        {
          this.messageService.add({ severity: 'error', summary: 'Issue with birthday information:', detail:(this.httpResponse.messages[0])});
        }
      }, error => {
        this.messageService.add({ severity: 'error', summary: 'Not Added', detail: error.messages[0] });
      })
    }
  }

  isValidDate(dateString: string): boolean {
     //check if date is valid
      let date = new Date(dateString);
      return date instanceof Date && !isNaN(date.getTime());
  }


  IsToday()
  {
    var today = new Date();
    var selected = new Date(this.SelectedDate);
    return today.getDate() == selected.getDate() && today.getMonth() == selected.getMonth() && today.getFullYear() == selected.getFullYear();
  }

  IsDiscountValid(p)
  {
    if(p.earlybirddiscount)
    {
      if(p.discountrangetype == 0)
      {
        let dstartdate: Date = new Date(p.discountstart);
        let dstartend: Date = new Date(p.discountend);

        //if(dstartdate.getTime() <= this.CurrentDate.getTime() && dstartend.getTime() <= this.CurrentDate.getTime())
        if(this.CurrentDate.getTime() >= dstartdate.getTime() && this.CurrentDate.getTime() < dstartend.getTime())
        {
          return true;
        }
        else
        {
          return false;
        }
      }
      else
      {
        let today = new Date();
        today = new Date(today.toDateString());
        let bookingdate = new Date(this.SelectedDate);
        let diff = bookingdate.getTime() - today.getTime();
        let days = diff / (1000 * 3600 * 24);
        if(days >= p.discountdaysbefore)
        {
          return true;
        }
        else
        {
          return false;
        }
      }

    }
    else
    {
      return false;
    }

  }

  doReferralNote(){
    this.showDeferReason = false;

    this.NewBooking.inProgress = false;

    this.NewBooking.payments = null;
    this.NewBooking.deferredpayment = true;

    this.http.post<customer>(bmxurl + "/deferpayment", this.NewBooking).subscribe(response => {
      this.BookingResponse = response
      this.BookingDateAndTime = new Date(this.BookingResponse.data.startTime).getUTCDate().toString().padStart(2, '0') + "/" + new Date(this.BookingResponse.data.startTime).getUTCMonth().toString().padStart(2, '0') + "/" + new Date(this.BookingResponse.data.startTime).getUTCFullYear() + " " + new Date(this.BookingResponse.data.startTime).getUTCHours().toString().padStart(2, '0') + ":" + new Date(this.BookingResponse.data.startTime).getUTCMinutes().toString().padStart(2, '0');

      this.BookingPackage = this.BookingResponse.data.packageName;
    
      this.DoSummary(this.BookingResponse.data);

    }, error => {
    })

    // let HistoryEntry: any = {
    //   bookingid: this.NewBooking.bookingId,
    //   historyitem: this.deferNotes
    // }

    // this.http.post<any>(bmxurl + "/History", HistoryEntry).subscribe(response => {

    //   if(response.succeeded)
    //   {
    //     this.messageService.add({ severity: 'success', summary: 'Message added', detail: 'Your deferred payment message has been added' });
    //     this.deferNotes = "";
    //   }
      
    // }, error => {
    //   this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Failed to add Deferral Message' });
    // })
  }

  genRandomNum()
  {
    return Math.floor((Math.random()*10000)+1).toString();
  }

  changedPGSelection(selectedButton){
    this.packages = [];

    this.packageGroups.forEach(pg => {
      if(this.checked[pg.packagegroupid] != null)
      {
        if(this.checked[pg.packagegroupid] == true)
        {
          pg.packages.forEach(packagesInPG => {

            let found = false;  
            this.packages.forEach(pkg => {
              if(pkg.packageId == packagesInPG.packageId)
                found = true;
            });

            if(!found)
              this.packages.push(packagesInPG)

            // if(!this.packages.includes(packagesInPG))
            //   this.packages.push(packagesInPG)
          });
        }
      }
    });
  }

  doneDiscount(){
    //send discount to server
    let dv: cDiscountvalidate = {
      booking: this.NewBooking,
      discountcode: this.discountCode.trim()
    }

    this.http.post<booking_returndata>(bmxurl + "/DiscountValidate", dv).subscribe(response => {
      this.httpResponse = response
      if (this.httpResponse.succeeded == true) {
        this.messageService.add({ severity: 'success', summary: 'Discount Added', detail: 'Your discount has been added' });

        this.showDiscount = false;
        this.discountCode = "";

        this.NewBooking = (response as booking_returndata).data;

        if(this.NewBooking.PublicBooking)
        {
          this.validPaymentAmount = this.NewBooking.totalpublicdeposit;
        }
        else{
          this.validPaymentAmount = this.NewBooking.totalvenuedeposit;
        }

      }
      else
      {
        this.messageService.add({ severity: 'error', summary: 'Not Added', detail:(this.httpResponse.messages[0])});
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Not Added', detail: error.messages[0] });
    })
  }

  doneOpenDiscount(){
        //send discount to server
        let dv: cOpenDiscountvalidate = {
          booking: this.NewBooking,
          value : this.chosenDiscountAmount
        }
    
        this.http.post<booking_returndata>(bmxurl + "/opendiscount", dv).subscribe(response => {
          this.httpResponse = response
          if (this.httpResponse.succeeded == true) {
            this.messageService.add({ severity: 'success', summary: 'Discount Added', detail: 'Your discount has been added' });
    
            this.showDiscount = false;
            this.discountCode = "";
    
            this.NewBooking = (response as booking_returndata).data;
    
            if(this.NewBooking.PublicBooking)
            {
              this.validPaymentAmount = this.NewBooking.totalpublicdeposit;
            }
            else{
              this.validPaymentAmount = this.NewBooking.totalvenuedeposit;
            }
    
          }
          else
          {
            this.messageService.add({ severity: 'error', summary: 'Not Added', detail:(this.httpResponse.messages[0])});
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Not Added', detail: error.messages[0] });
        })
  }

  doneVoucher()
  {  
    this.busyService.busy();

    let newCustomer: any;

    newCustomer = this.NewBooking.customer;
    
    let dv: cVouchervalidate = {
      booking: this.NewBooking,
      vouchercode: this.voucherCode.trim(),
      voucherpin : this.voucherpin 
    }

    this.http.post<booking_returndata>(bmxurl + "/VoucherValidate", dv).subscribe(response => {
      this.httpResponse = response
      if (this.httpResponse.succeeded == true) {
        this.messageService.add({ severity: 'success', summary: 'Voucher Added', detail: 'Your voucher has been added' });

        this.showDiscount = false;
        this.discountCode = "";

        this.showVoucher = false;
        this.voucherCode = "";

        this.NewBooking = (response as booking_returndata).data;

        this.BookingPayments = this.NewBooking.payments;

        this.NewBooking.customer = newCustomer;

        if(this.NewBooking.PublicBooking)
        {
          this.validPaymentAmount = this.NewBooking.totalpublicdeposit;
        }
        else{
          this.validPaymentAmount = this.NewBooking.totalvenuedeposit;
        }

        if(this.NewBooking.totalpayments >= this.NewBooking.totalpublicdeposit)
        {
          this.DoSummary(this.NewBooking);
          this.BookingComplete = true;
        }
        this.busyService.idle();
      }
      else
      {
        this.busyService.idle();
        this.messageService.add({ severity: 'error', summary: 'Not Added', detail:(this.httpResponse.messages[0])});
      }
    }, error => {
      this.busyService.idle();
      this.messageService.add({ severity: 'error', summary: 'Not Added', detail: error.error.messages[0] });
    })
  }

  displayDiscount(){
    this.showDiscount = !this.showDiscount;
  }

  displayVoucher(){
    this.showVoucher = !this.showVoucher;
  }
  
  donePartyRooms()
  {
    this.SelResourceHeader = "You selected: ";
    this.customerSelectedResources = [];
    
    let index = 0;
    this.SelectedResources.forEach(packRes => {
      if(packRes.optional == true)
      {
        if(this.SelectedSeletableResourceBool[index] == true)
        {
          packRes.ignore = false;
          if(packRes.resources.length > 0)
          {
              if(packRes.isconstructed && packRes.isresourcecontainer)
              {
                let resourceToSave: resource;
                packRes.resources.forEach(selectedRes => {
                  if(selectedRes != null)
                  {
                    if (selectedRes.id == this.SelectedSeletableResource[index])
                    {
                      resourceToSave = selectedRes;
                    }
                  }
                });
                
                packRes.resources = [];
                packRes.resources.push(resourceToSave);
              }
              this.customerSelectedResources.push(packRes);
              this.validselection[index] = true;
          }
          else
          {
            packRes.ignore = false;
            this.customerSelectedResources.push(packRes);
            this.SelResourceHeader += packRes.name + ", ";
          }
        }
        else
        {
          packRes.ignore = true;
          this.customerSelectedResources.push(packRes);
        }
      }
      else
      {
        if (this.SelectedSeletableResource[index] != null)
        {
          //if(packRes.selectionmode != "ANY" && packRes.selectionmode != "SYSTEMANY")
          //{
            let resourceToSave: resource;
            packRes.resources.forEach(selectedRes => {
              if (selectedRes.id == this.SelectedSeletableResource[index])
              {
                resourceToSave = selectedRes;
              }
            });
            
            packRes.resources = [];
            packRes.resources.push(resourceToSave);

            this.customerSelectedResources.push(packRes);
            this.validselection[index] = true;
        }
        else
        {
          this.customerSelectedResources.push(packRes);
          if(packRes.mode != "SIMPLE")
            this.validselection[index] = true;
        }
      }
      index++;
    });

    let validationPassed = true;
    
    //Check validation:
    index = 0;
    this.customerSelectedResources.forEach(innerPackageRes => {
      if(innerPackageRes != null)
      {
        if(innerPackageRes.optional == true)
        {
          if (this.SelectedSeletableResourceBool[index] == true)
          {
            if(innerPackageRes.resources.length > 0)
            {
              if(innerPackageRes.resources[0] == null)
                this.validselection[index] = false;
              else
                this.validselection[index] = true;
            }
          }
          else
          {
            this.validselection[index] = true;
          }
        }
      }
      index++;
    });

    this.validselection.forEach(element => {
      if (element == false)
        validationPassed = false;
    });
    
    if (!validationPassed)
      return;


    this.SelectedPackage.resources = []

    this.customerSelectedResources.forEach(element => {
      if(!element.ignore)
      {
        this.SelectedPackage.resources.push(element);
        if(element.resources.length == 0)
        {
          if(element.mode != "SIMPLE")
            this.SelResourceHeader += element.name + ", ";
        }
        else
        {
          if(element.isresourcecontainer && !element.isconstructed)
          {
            if(element.mode != "SIMPLE")
              this.SelResourceHeader += element.name + ", ";
          }
          else
          {
            if(element.mode != "SIMPLE")
            {
              if(element.resources[0] != null)
                this.SelResourceHeader += element.resources[0].name + ", ";
            }
          }
        }
      }
    });
    

    if(this.SelResourceHeader.length > 2)
      this.SelResourceHeader = this.SelResourceHeader.substring(0, this.SelResourceHeader.length - 2)

      this.setTab(4);
      this.getAvailability();
  }

  addselectedCustomer(passCustomer){
    this.searchCriteria = "";

    this.CustomerHeader = "Customer: " +  passCustomer.firstName + "  " + passCustomer.lastName;

    let newCustomer: Customer = {
      id: null,
      customerid: null,
      firstName: passCustomer.firstName,
      lastName: passCustomer.lastName,
      fullName: passCustomer.firstName + " " + passCustomer.lastName,
      address1: passCustomer.address1,
      address2: passCustomer.address2,
      townCity: passCustomer.townCity,
      postCode: passCustomer.postCode,
      email: passCustomer.email,
      mobile: passCustomer.mobile,
      dob: this.dob,
      venueId: this.VenueID,
      disabled: false,
      bookingid: null
    }

    this.NewBooking.customer = newCustomer;

    this.setTab(Sections.Notes);
    
    this.customersResult = null;
    this.searchCriteria = "";
  }

  addCustomer(){
    //Check Search field for postcode

    if(this.valid_postcode(this.searchCriteria))
    {
      this.postCode = this.searchCriteria
    }
    else if(this.valid_mobile(this.searchCriteria))
    {
      //Check Telephone number
      this.mobile = this.searchCriteria
    }
    else if(this.searchCriteria.includes("@"))
    {
      this.email = this.searchCriteria
    }
    else
    {
      this.lastName = this.searchCriteria
    }


    this.ShowCustomerDetails = true;
    //this.ProvideMinimalPersonalData = "FULL";
  }

  doWalkin(){
    this.ProvideMinimalPersonalData = "WALKIN";
    this.lastName = this.searchCriteria;
    this.ShowCustomerDetails = true;
  }

  valid_postcode(postcode) {
    postcode = postcode.replace(/\s/g, "");
    var regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
    return regex.test(postcode);
  }

  valid_mobile(postcode) {
    postcode = postcode.replace(/\s/g, "");
    var regex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    return regex.test(postcode);
  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");

    let userid = localStorage.getItem("SmartUserID");
    
    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("MAKEABOOKING", user)){
          this.AccessAllowed = true;

          let inputtedDate = "";
          this.route.queryParams
            .subscribe(params => {
              inputtedDate = params.bookingDate + "UTC";
            }
          );
      
          this.BookingPayments = [];
          this.totalPayments = 0;
      
          this.partyroomsVisible = true;
          this.id = this.route.snapshot.paramMap.get('id');
          this.packages = [];
          this.customerSelectedResources = [];
          this.SelectedResources = [];
      
          this.checked = [];

          this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
            this.httpResponse = (response as venuesettings_returndata)
            if(this.httpResponse.succeeded)
            {
              this.httpResponse.data.venueSettings.forEach(element => {
                if (element.name == "UseIntervalAvailability") {
                  this.UseIntervalAvailability = element.value;
                }
              });
            }
            else
            {
              this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Venue Settings.'});
            }
          }, error => {
            this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Venue Settings.'});
          })
      
          //Get Payment Types
      
          this.http.get(bmxurl + "paymenttypes").subscribe(response => {
            if((response as paymenttypes_returndata).succeeded)
            {
              this.PaymentTypes = (response as paymenttypes_returndata).data;

              this.PaymentTypes = this.PaymentTypes.filter(pt => pt.system == false);
            }
            else
            {
              this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Payment Types.'});
            }
          }, error => {
            this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Payment Types.'});
          })
      
          if(this.id == "0")
          {
            if(inputtedDate != undefined && inputtedDate != "" && inputtedDate != "undefined" && inputtedDate != "undefinedUTC")
            {
              this.SelectedDate = this.DatifyString(inputtedDate);
              this.timetobetried = inputtedDate.substring(11, 16);

              inputtedDate = "";
              this.dateSelected = true;
              this.packageSelected = false;
              this.admissionsSelected = false;
              this.doneDate();
            }
            else
            {
              this.dateSelected = true;
              this.packageSelected = false;
              this.admissionsSelected = false;
              this.setTab(Sections.Date);
            }
          }
          else
          {
            this.http.get(bmxurl + "booking/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.NewBooking = (response as booking_returndata).data;
              if((response as booking_returndata).succeeded)
              {
                this.SelectedDate  = new Date(this.NewBooking.startTime);
              }
              else{
                this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Booking.'});
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Booking.'});
            })
      
            this.dateSelected = true;
            this.setTab(Sections.Date);
      
            this.dateActive = false;
            this.packageActive = false;
            this.admissionsActive = false;
            this.timeActive = false;
            this.customerActive = false;
            this.paymentActive = false;
            this.extraActive = false;
            this.notesActive = false;
          }
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  DatifyString(date: string): Date
  {
    let returnDate = new Date();

    returnDate.setUTCFullYear(Number(date.substring(0, 4)));
    returnDate.setUTCMonth(Number(date.substring(5, 7)) - 1);
    returnDate.setUTCDate(Number(date.substring(8, 10)));
    returnDate.setUTCHours(0);
    returnDate.setUTCMinutes(0);
    returnDate.setUTCSeconds(0);

    return returnDate;
  }

  Selectpopular(){
    //Get Popular Group
    let PopularGroup: cPackagegroups;
    this.packageGroups.forEach(pkgrp => {
      if(pkgrp.packagegroupname == 'Popular')
        PopularGroup = pkgrp;
    });

    this.packages1.forEach(pkg => {
      PopularGroup.packages.forEach(PGPgs => {
        if(PGPgs.packageId == pkg.packageId)
          this.packages.push(pkg)
      });
    });
    
    this.checked[PopularGroup.packagegroupid] = true;
  }

  sumAdmissions(){

    this.doLinkedDiscounts();

    this.doLinkedAdmissions();

    this.totalAdmissions = this.SelectedAdmissions.map(a => a.noofpeople).reduce(function(a, b)
    {
      return Number(a) + Number(b);
    });

    if((this.totalAdmissions > this.SelectedPackage.max && this.SelectedPackage.max > 0) || (this.totalAdmissions < this.SelectedPackage.min && this.SelectedPackage.min > 0))
    {
      this.totalAdmissionInRange = false;
    }
    else
    {
      this.totalAdmissionInRange = true;
    }
  }

  doLinkedDiscounts()
  {
    this.totalDiscount = 0;

    let totalNumberOfLinkedDiscounts = 0;

    this.SelectedAdmissions.forEach(adm => {

      if(adm.linkeddiscounts != null)
      {
        adm.linkeddiscounts.forEach(ladm => {
          let linkedDis = this.SelectedAdmissions.find(a => a.admissionid == ladm.admissionid);

          if(linkedDis != null)
          {
            if(linkedDis.noofpeople >= ladm.threshold)
            {
              let numberOfPeopleForDiscount = Math.floor(linkedDis.noofpeople / ladm.threshold);

              if(adm.noofpeople < numberOfPeopleForDiscount)
              {
                numberOfPeopleForDiscount = adm.noofpeople;
              }

              this.totalDiscount += Number(adm.price * numberOfPeopleForDiscount) * Number(ladm.percentagediscount / 100);
            }
          }
        });
      }
    });
  }

  doLinkedAdmissions()
  {
    let totalNumberOfLinked = 0;

    this.SelectedAdmissions.forEach(adm => {

      let OldNoOfPeople = adm.min;

      if(adm.linkedadmissions != null)
      {
        adm.linkedadmissions.forEach(ladm => {
          let linkedAdm = this.SelectedAdmissions.find(a => a.admissionid == ladm.admissionid);

          if(linkedAdm != null)
          {
            totalNumberOfLinked += Number(linkedAdm.noofpeople);
          }
        });

        if(adm.onlysetmax)
        {
          adm.max = Number(totalNumberOfLinked);
          if (adm.max < adm.min)
          {
            adm.max = adm.min;
          }
          if(Number(adm.noofpeople) > Number(totalNumberOfLinked))
          {
            if(Number(OldNoOfPeople) > Number(totalNumberOfLinked))
            {
              adm.noofpeople = Number(OldNoOfPeople);
            }
            else
            {
              adm.noofpeople = Number(totalNumberOfLinked);
            }
          }
          adm.fixed = false;
        }
        else
        {
          adm.noofpeople = Number(totalNumberOfLinked);
          adm.fixed = true;
        }
      }
    });

  }

  applyCustomer(passCustomer)
  {
    this.ShowCustomerDetails = true;
    this.firstName = passCustomer.firstName;
    this.lastName = passCustomer.lastName;
    this.address1 = passCustomer.address1;
    this.address2 = passCustomer.address2;
    this.town = passCustomer.townCity;
    this.postCode = passCustomer.postCode;
    this.email = passCustomer.email;
    this. mobile = passCustomer.mobile;

    this.customersResult = null;
    this.searchCriteria = "";

  }

  searchCustomer(){
    this.http.get(bmxurl + "customer/search/" + this.VenueID + "/" + this.searchCriteria).subscribe(response => {
      this.customersResult = (response as customer[])
    }, error => {
      console.log(error);
    })

  }

  VCContinue()
  {
    this.showVCWarning = false;
  }

  doneDate()
  {
    this.CalendarHeader = "You selected: " + this.SelectedDate.toDateString();
    this.packages = [];

    this.http.get(bmxurl + "PackageGroupList/" + this.VenueID + "/false/" + 
    ('00' + this.SelectedDate.getDate()).slice(-2)+
    ('00' + (Number(this.SelectedDate.getMonth() + 1).toString())).slice(-2)
    +this.SelectedDate.getFullYear().toString().substr(-2)
    ).subscribe(response => {
      this.packageGroups = (response as packagegroups_returndata).data;
      
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Package Groups.'});
    })

    //Does a current booking exist for this date?
    if(this.NewBooking.bookingId != null)
    {
      let abandonBooking: any = {
        bookingid: this.NewBooking.bookingId,
        jwt: null
      }
      //Booking Exists, abandon it
      this.http.post(bmxurl + "abandonbooking/", abandonBooking).subscribe(response => {
        this.NewBooking = (response as booking_returndata).data;

      }, error => {
        this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to abandon booking.'});
      });
    }

    let BookingDateYear = this.SelectedDate.getFullYear().toString().substr(-2);
    let BookingDateMonth = ('00' + (this.SelectedDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.SelectedDate.getDate()).slice(-2);

    let dateChosen = BookingDateDay + BookingDateMonth + BookingDateYear;

    this.http.get<venueclosings_returndata>(bmxurl + "/VenueClosingList/" + this.VenueID + "/false/" + dateChosen).subscribe(response => {
      this.venueClosings = (response as venueclosings_returndata).data;

      if(this.venueClosings != null)
      {
        this.venueClosings.forEach(element => {
          
            this.VCWarningMessage += element.publicMessage + "<BR>";
            this.showVCWarning = true;
        });
      }
      else
      {
        this.showVCWarning = false;
      }
    });

    this.AbandonBooking();
    this.checked = [];
    
    this.setTab(Sections.Package);
  }

  toggleMore(inDiv)
  {
    let collapsedDiv = document.getElementById(inDiv + "collapse") as HTMLElement;
    let expandeddDiv = document.getElementById(inDiv + "expanded") as HTMLElement;
    let toggleButton = document.getElementById(inDiv + "toggleButton") as HTMLButtonElement;

    if(collapsedDiv.hidden == true)
    {
      collapsedDiv.hidden = false
      collapsedDiv.style.display = "false";
    }
    else
    {
      collapsedDiv.hidden = true
      collapsedDiv.style.display = "true";
    }

    if(expandeddDiv.hidden == true)
    {
      expandeddDiv.hidden = false
      expandeddDiv.style.display = "false";
    }
    else
    {
      expandeddDiv.hidden = true
      expandeddDiv.style.display = "true";
    }

    if(toggleButton.value == "Show More...")
    {
      toggleButton.value = "Show Less..."
      toggleButton.textContent = "Show Less..."
    }
    else
    {
      toggleButton.value = "Show More..."
      toggleButton.textContent = "Show More..."
    }
  }

  donePackage(inPackage)
  {
    this.SelectedPackage = inPackage;

    if(this.SelectedPackage.questionnaire != null)
    {
      for(var i = 0; i < this.SelectedPackage.questionnaire.bookingquestions.length; i++)
      {
        this.mustanswerallergies[i] = true;
        this.mustanswerbirthdays[i] = true;
        this.mustanswernumber[i] = true;  
        this.mustanswerfreetext[i] = true;
        this.mustanswermultichoicesingleanswer[i] = true;
        this.mustanswermultichoicemultianswer[i] = true;
        this.mustanswerdate[i] = true;
        this.allergyAnswer[i] = true;  
      }
    }

    this.customerSelectedResources = [];
    this.SelectedResources = [];
    
    this.PackageHeader = "You selected: " + this.SelectedPackage.name;

    this.SelectedAdmissions = [];

    this.SelectedPackage.admissions.forEach(adm => {
      var newAdm: admission = {
        id: adm.id,
        admissionid: adm.admissionid,
        classid: adm.classid,
        noofpeople: adm.min,
        price: adm.price,
        name: adm.name,
        index: adm.index,
        max: adm.max,
        min: adm.min,
        venueid: adm.venueid,
        disabled: adm.disabled,
        admissionClass: adm.admissionClass,
        bookingwizardtext: adm.bookingwizardtext,
        fixedPrice: adm.fixedPrice,
        participant: adm.participant,
        mustinclude: adm.mustinclude,
        eventtickettype: adm.eventtickettype,
        counter: [],
        uselinkeddiscounts: adm.uselinkeddiscounts,
        linkedadmissions: adm.linkedadmissions,
        linkeddiscounts: adm.linkeddiscounts,
        uselinkedadmissions: adm.uselinkedadmissions,
        maxlinkeddiscounts: adm.maxlinkeddiscounts,
        maxlinkedadmissions:adm.maxlinkedadmissions,
        fixed: adm.fixed,
        onlysetmax: adm.onlysetmax
    }

    this.doLinkedAdmissions();


    this.SelectedAdmissions.push(newAdm);
    });

    this.SelectedPackage.resources.forEach(res => {

      let selectableinnerresources: any[] = [];
      if(res.selectableresources != null && res.selectableresources.length > 0)
      {
        res.selectableresources.forEach(innerRes => {
          selectableinnerresources.push(innerRes);
        });
      }

      var newRes: cResource = {
        groupname: uuidv4(),
        id: res.id,
        resourceId: res.resourceId,   
        name: res.name,
        useClasses: res.useClasses,
        exclusive: res.exclusive,
        eventid: res.eventid,
        concurrentUses: res.concurrentUses,
        maxConcurrent: res.maxConcurrent,
        disabled: res.disabled,
        venueId: res.venueId,
        colour: res.colour,
        orderindex: res.orderindex,
        length: res.length,
        classes:  res.classes,
        showonbooking:res.showonbooking,
        resources: selectableinnerresources,
        isresourcecontainer: res.isresourcecontainer,
        isconstructed: res.isconstructed,
        resourcegroupname: res.resourcegroupname,
        price: res.price,
        fixedprice: res.fixedprice,
        tags: res.tags,
        resourcegroup: res.resourcegroup,
        selectionmode: res.selectionmode,
        selectableresources: res.selectableresources,
        startwhen: res.startwhen,
        numofminutes: res.numofminutes,
        optional: res.optional,
        quantityselector: res.quantityselector,
        quantity: res.quantity,
        mode: res.mode,
        inline: res.inline,
        ignore: res.ignore,
        admissions: res.admissions,
        inProgress: res.inProgress,
        originatingid: res.originatingid,
        wasoptional: res.wasoptional,
        wasselected: res.wasselected
    }

    this.SelectedResources.push(newRes);
    });

    //Get day for selected date.
    let day = this.SelectedDate.getDay();

    this.packageExtras = [];

    if(this.SelectedPackage.extras != null)
    {
      switch (day)
      {
        case 0:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.sunday == true);
          break;
        case 1:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.monday == true);
          break;
        case 2:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.tuesday == true);
          break;
        case 3:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.wednesday == true);
          break;
        case 4:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.thursday == true);
          break;
        case 5:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.friday == true);
          break;
        case 6:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.saturday == true);
          break;
        default:
          this.packageExtras = this.SelectedPackage.extras.filter(obj => obj.sunday == true);
          break;
      }
    }

    this.packageExtras.forEach(ext => {
      ext.qty = 0;
     });

     if(this.packageExtras.length > 0)
        this.PackageHasExtras = true;

    this.SelectedResources.forEach(element => {
      if(element.mode == "COMPLEX")
      {
        if(element.selectionmode == "SYSTEMANY" || element.selectionmode == "SYSTEMLIST" || element.selectionmode == "") 
        {
          this.includesComplexResources = false;
        }
        else{
          this.includesComplexResources = true;
        }
        
      }
      else{
        this.includesComplexResources = false;
      }
      if(element.selectionmode != "SYSTEMANY" && element.selectionmode != "SYSTEMLIST")
      { 
        this.systemChoosesAllResources = false;
      }
      else
      {
        this.systemChoosesAllResources = true;
      }

      if(element.optional)
      {
        this.includesOptionalResources = true;
      }
    });

    if(!this.includesComplexResources || this.systemChoosesAllResources)
    {
      this.customerSelectedResources = this.SelectedPackage.resources;
    }

    if(this.includesComplexResources)
    {
      this.partyroomsVisible = true;
    }
    else
    {
      this.partyroomsVisible = false;
    }

    this.sumAdmissions();
    this.AbandonBooking();
    this.setTab(Sections.Admissions);

    if(this.SelectedPackage.warning != null)
      {
        if(this.SelectedPackage.warning.venuemessage != null && this.SelectedPackage.warning.venuemessage != "")
        {
          this.showWarning = true;
          this.WarningMessage = this.SelectedPackage.warning.venuemessage;
        }
      }
  }

  doneAdmissions()
  {
   let dropOut = false;
   let HasMustIncludes = false;
   let HasSelectedMustIncludes = false;

   this.SelectedAdmissions.forEach(element => {
       if(element.mustinclude)
       {
        HasMustIncludes = true;
       }
       if(element.mustinclude && element.noofpeople > 0)
       {
        HasSelectedMustIncludes = true;
       }
   });

   if(HasMustIncludes && !HasSelectedMustIncludes)
    {
      this.MustIncludePass = false;
      this.messageService.add({severity:'warn', summary:'Must include', detail:'You must include at least 1 of the admissions with an asterix'});
    }
    else
    {
      this.MustIncludePass = true;
    }

    if(this.totalAdmissionInRange)
    {
      if(this.MustIncludePass)
      {
        let AdmissionsList: string = "";

        this.SelectedAdmissions.forEach(adm => {
          if(adm.noofpeople > 0)
            AdmissionsList += adm.noofpeople + " x " +  adm.name + ", ";
        });

        if(AdmissionsList.length > 2)
        AdmissionsList = AdmissionsList.substring(0, AdmissionsList.length - 2);

        this.AdmissionHeader = "You selected: " + AdmissionsList;

        this.AbandonBooking();

        
        if(this.SelectedPackage.questionnaire != null)
          {
            this.SelectedPackage.questionnaire.bookingquestions.forEach(question => {
      
              let bQuestions = [];
              if(question.questiontype == "BIRTHDAY" && !question.linkedtoparticipants)
              {
                this.ShowAge = question.showage;
                this.ShowDOB = question.showdob;
                this.ShowGender = question.showgender;
                this.ShowInterests = question.showinterests;

                this.birthdayitems = [];
                bQuestions.push({
                  index: 0, 
                  id: uuidv4(), 
                  name: "", 
                  age: 0, 
                  birthdate: "",
                  gender: "NOT STATED",
                  interests: "",
                  showage: question.showage,
                  showdob: question.showdob,
                  showgender: question.showgender,
                  showinterests: question.showinterests,
                  dobday: question.dobday,
                  dobmonth: question.dobmonth,
                  dobyear: question.dobyear
                });
              }
              else if(question.questiontype == "BIRTHDAY" && question.linkedtoparticipants)
              {
                this.birthdayitems = [];
                let totalAllowed = 0;
  
                this.SelectedAdmissions.forEach(admission => {
                  if (admission.participant)
                  {
                    totalAllowed += admission.noofpeople;
                  }
                });
  
                let index = 0;
  
                for(var i = 0; i < totalAllowed; i++){
                  bQuestions.push({
                    index: index, 
                    id: uuidv4(), 
                    name: "", 
                    age: 0, 
                    birthdate: new Date(),
                    gender: "NOT STATED",
                    interests: "",
                    showage: question.showage,
                    showdob: question.showdob,
                    showgender: question.showgender,
                    showinterests: question.showinterests,
                    dobday: question.dobday,
                    dobmonth: question.dobmonth,
                    dobyear: question.dobyear
                  });
  
                  index++;
                }
              }

              bQuestions.forEach(bQuestion => {
                this.birthdayitems.push(bQuestion);
              });

              question.answers = {
                bookingid: "",
                bookingquestionnaireid:  null,
                bookingquestionid: question.bookingquestionid,
                birthdayanswers: bQuestions,
                allergyanswer: {
                  id: null,
                  allergypresent: null,
                  allergytext: ""
                },
                freetextanswer: "",
                numberanswer: null,
                dateanswer:  null,
                multichoicesingleanswer: {
                  id: null,
                  listitemtext: ""
                },
                multichoicemultianswer: []
              }
            });
        }
  

        if((this.includesComplexResources && !this.systemChoosesAllResources) || this.includesOptionalResources)
        {
          this.setTab(Sections.Partyroom);
        }
        else{
          this.setTab(Sections.Time);
          this.getAvailability();
        }
      }
    }
    else
    {
      if (this.totalAdmissions > this.SelectedPackage.max)
        this.messageService.add({severity:'warn', summary:'Admissions', detail:'There are too many admissions, please reduce the admissions to ' + this.SelectedPackage.max + ' or less.'});
      else
        this.messageService.add({severity:'warn', summary:'Admissions', detail:'There are too few admissions, please increase the admissions to ' + this.SelectedPackage.min + ' or more.'});
    }

    this.linkedAdmissionMessageShown = false;
  }

  Checkfortoday()
  {
    var today = new Date();
    var selected = new Date(this.SelectedDate);
    return today.getDate() == selected.getDate() && today.getMonth() == selected.getMonth() && today.getFullYear() == selected.getFullYear();
  }

  bookSlot(timeslot: AvailableTimes)
  {
    this.busyService.busy();
    this.TimeHeader = "You selected: " + new Date(timeslot.time).getUTCHours().toString().padStart(2, '0') + ":" + new Date(timeslot.time).getUTCMinutes().toString().padStart(2, '0');

    var instanceID: any = "BMP1"
    var id:any = this.VenueID;
    var todaysDate: Date = new Date(timeslot.time);
    // var thisYear: any = todaysDate.getFullYear();
    // var thisMonth: any = ('00' + (todaysDate.getMonth() + 1)).slice(-2);
    // var thisDay: any = ('00' + todaysDate.getDate()).slice(-2);
    // var fullDate: any = thisYear + thisMonth + thisDay;
  
    //var venueDate: any =  id + "_" + fullDate;
    var venueDate: any =  id + "_" + timeslot.time.toString().substring(0, 10).replace(/-/g, "");

    this.SelectedPackage.resources.forEach(adm => {
      adm.admissions = this.SelectedAdmissions;
   });

    let totalNoOfPeople: number = 0;

    this.NewBooking.venuedate = venueDate
    this.NewBooking.packageId = this.SelectedPackage.packageId;
    this.NewBooking.guests =  totalNoOfPeople;
    this.NewBooking.startTime = timeslot.time;
    this.NewBooking.PackageName = this.SelectedPackage.name;
    this.NewBooking.venueId = this.VenueID;
    this.NewBooking.payments = null;
    this.NewBooking.customer = null;
    this.NewBooking.resources = timeslot.resources; //this.SelectedPackage.resources;
    this.NewBooking.Disabled = false;
    this.NewBooking.outstanding = 0;
    this.NewBooking.name = "";
    this.NewBooking.inProgress = true;
    this.NewBooking.availabilityreq = this.AvailabilityReq;
    this.NewBooking.initialbooking = true;
    this.NewBooking.salestype = 0;

    if(this.SelectedPackage.bookingtimeout == 0)
    {
      this.NewBooking.bookingtimeout = 300;
    }
    else
    {
      this.NewBooking.bookingtimeout = this.SelectedPackage.bookingtimeout;
    }


    this.NewBooking.resources.forEach(element => {
      element.inProgress = true;
    });

    this.http.post<booking_returndata>(bmxurl + "/savebooking", this.NewBooking).subscribe(response => {
      this.httpResponse = (response as booking_returndata);      

      if(this.httpResponse.succeeded)
      {
        this.BookingResponse = this.httpResponse.data
        
        this.NewBooking = this.BookingResponse.bookingObj;

        let NumPeople: number = 0;
        this.NewBooking.resources[0].admissions.forEach(adm => {
          NumPeople += adm.noofpeople;
        });
  
        this.paymentAmount = this.BookingResponse.bookingObj.total;

        if(this.NewBooking.PublicBooking)
        {
          this.validPaymentAmount = this.NewBooking.totalpublicdeposit - this.NewBooking.totalpayments;
        }
        else{
          this.validPaymentAmount = this.NewBooking.totalvenuedeposit - this.NewBooking.totalpayments;
        }
  
        const formatter = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: this.CurrencySymbol});
  
        this.makeABookingHeader = "Make a Booking - Sales Value: " + formatter.format(this.paymentAmount);
        //this.startTimes = null;
        //this.startTimesFiltered = null;
  
        //this.doneAdmissions();
        if(this.packageExtras.length == 0 && this.SelectedPackage.questionnaire == null)
        {
          this.setTab(Sections.Customer);
        }
        else if(this.SelectedPackage.questionnaire != null)
        {
          this.setTab(Sections.BookingQuestions);
        }
        else
        {
          this.setTab(Sections.Extras);
        }
        this.busyService.idle();
      }
      else
      {
        this.getAvailability();
        
        this.messageService.add({severity:'error', summary:'Error', detail: this.httpResponse.messages});
        this.busyService.idle();
      }
    }, error => {
      console.log(error);
      this.busyService.idle();
      this.messageService.add({severity:'error', summary:'Error', detail: error.message});
    })
  }

  removeDiscount()
  {
    this.busyService.busy();

    this.NewBooking.discounts = null;
    this.NewBooking.salestype = 0;

    this.http.put<booking_returndata>(bmxurl + "/savebooking", this.NewBooking).subscribe(response => {
      this.httpResponse = (response as booking_returndata);     

      this.BookingResponse = this.httpResponse.data

        this.NewBooking = this.BookingResponse.bookingObj;

        this.validPaymentAmount = this.NewBooking.outstanding;
  
        this.paymentAmount = this.BookingResponse.bookingObj.total;
        let NumPeople: number = 0;
        this.NewBooking.resources[0].admissions.forEach(adm => {
          NumPeople += adm.noofpeople;
        });
  
        this.paymentAmount = this.BookingResponse.bookingObj.total;
        //this.OutstandingBalance = this.BookingResponse.bookingObj.outstanding;
        const formatter = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: this.CurrencySymbol});
  
        this.startTimes = null;
        this.startTimesFiltered = null;
  
        this.messageService.add({ severity: 'success', summary: 'Discount Removed', detail: 'Your discount has been removed' });

        this.busyService.idle();

    }, error => {
      console.log(error);
      this.busyService.idle();
    })
  }

  doneExtras()
  {
    this.allQuestionsValid = true;

    this.packageExtras.forEach(element => {
      if(element.question != null)
      {
        if((element.question != "" && element.qty > 0) && (element.answer == null || element.answer == "")) 
        {
          this.allQuestionsValid = false;
        }
      }
      if (element.qty == 0)
      {
        element.answer = "";
      }
    });

    if(this.allQuestionsValid)
    {
      this.ExtrasHeader = "You selected: ";

      let cost: number = 0;

      this.packageExtras.forEach(element => {
        if (element.qty == null)
          element.qty = 0;
        
        if(element.qty > 0)
        {
          this.ExtrasHeader += element.name + " x " + element.qty + ", ";
          this.bookingExtras += element.name + " x " + element.qty + ", ";
          cost += element.qty * element.price;
        }
      });

      if(this.ExtrasHeader.length > 14)
      {
        this.ExtrasHeader = this.ExtrasHeader.substring(0, this.ExtrasHeader.length - 2);
      }
      else
      {
        this.ExtrasHeader = "You selected: No Extras";
      }
      this.NewBooking.extras = this.packageExtras;
      this.NewBooking.salestype = 0;
      
      //------------

      this.http.post<booking_returndata>(bmxurl + "/BookingExtra", this.NewBooking).subscribe(response => {
        this.httpResponse = (response as booking_returndata);      

        if(this.httpResponse.succeeded)
        {
          this.BookingResponse = this.httpResponse.data
          
          this.NewBooking = this.BookingResponse;

          const formatter = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: this.CurrencySymbol});

          this.paymentAmount = this.BookingResponse.total;
          this.makeABookingHeader = "Make a Booking - Sales Value: " + formatter.format(this.paymentAmount);
          this.bookingExtras = this.bookingExtras.substring(0, this.bookingExtras.length - 2);

          this.setTab(Sections.Customer);
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail: this.httpResponse.messages});
          this.busyService.idle();
          
        }
      }, error => {
        console.log(error);
        this.messageService.add({severity:'error', summary:'Error', detail: error.message});
      })
    }
  }

  doneCustomer()
  {
    this.CustomerAllValid = true;

    if(this.ProvideMinimalPersonalData == "WALKIN")
    {
      if (this.lastName == "") {
        this.CustomerAllValid = false;
        this.lastNameValid = false;
      }
      else {
        this.lastNameValid = true;
      }
    }
    else if(this.ProvideMinimalPersonalData)
    {
      if (this.firstName == "") {
        this.CustomerAllValid = false;
        this.firstNameValid = false;
      }
      else {
        this.firstNameValid = true;
      }

      if (this.lastName == "") {
        this.CustomerAllValid = false;
        this.lastNameValid = false;
      }
      else {
        this.lastNameValid = true;
      }

      if (this.email == "") {
        if(this.ProvideMinimalPersonalData == "FULL" || this.ProvideMinimalPersonalData == "LIMITED")
        {
          this.CustomerAllValid = false;
          this.EmailValid = false;
        }
        else
        {
          this.EmailValid = true;
        }
      }
      else {
        this.EmailValid = true;
      }

      if (this.mobile == "") {
        if(this.ProvideMinimalPersonalData == "FULL" || this.ProvideMinimalPersonalData == "LIMITED")
        {
          this.CustomerAllValid = false;
          this.MobileValid = false;
        }
        else
        {
          this.MobileValid = true;
        }
      }
      else {
        this.MobileValid = true;
      }
    }
    else
    {
      if (this.firstName == "") {
        this.CustomerAllValid = false;
        this.firstNameValid = false;
      }
      else {
        this.firstNameValid = true;
      }

      if (this.lastName == "") {
        this.CustomerAllValid = false;
        this.lastNameValid = false;
      }
      else {
        this.lastNameValid = true;
      }

      if (this.address1 == "") {
        this.CustomerAllValid = false;
        this.Address1Valid = false;
      }
      else {
        this.Address1Valid = true;
      }

      if (this.town == "") {
        this.CustomerAllValid = false;
        this.TownValid = false;
      }
      else {
        this.TownValid = true;
      }

      if (this.postCode == "") {
        this.CustomerAllValid = false;
        this.PostCodeValid = false;
      }
      else {
        this.PostCodeValid = true;
      }

      if (this.email == "") {
        this.CustomerAllValid = false;
        this.EmailValid = false;
      }
      else {
        this.EmailValid = true;
      }

      if (this.mobile == "") {
        this.CustomerAllValid = false;
        this.MobileValid = false;
      }
      else {
        this.MobileValid = true;
      }
    }


    //Check for valid email and mobile

    if (this.email != "") {
      if (!this.validateEmail(this.email)) {
        this.CustomerAllValid = false;
        this.EmailFormatValid = false;
      }
      else {
        this.EmailFormatValid = true;
      }
    }

    if(this.mobile != ""){
      if (!this.validateMobile(this.mobile)) {
        this.CustomerAllValid = false;
        this.MobileFormatValid = false;
      }
      else {
        this.MobileFormatValid = true;
      }
    }

    if(this.CustomerAllValid == true)
    {
      this.CustomerHeader = "Customer: " + this.firstName + "  " + this.lastName;

      let newCustomer: Customer = {
        id: null,
        customerid: null,
        firstName: this.firstName,
        lastName: this.lastName,
        fullName: this.firstName + " " + this.lastName,
        address1: this.address1,
        address2: this.address2,
        townCity: this.town,
        postCode: this.postCode,
        email: this.email,
        mobile: this.mobile,
        dob: this.dob,
        venueId: this.VenueID,
        disabled: false,
        bookingid: null
      }

      this.NewBooking.customer = newCustomer;
      
      this.setTab(Sections.Notes);
    }
  }

  doneNotes(){
    // Do deposit.
    //this.CalcDeposit(this.BookingResponse.bookingObj.totaladmission - this.DiscountTakenOff);

    //this.NewBooking.totalvenuedeposit = this.BookingResponse.bookingObj.totalvenuedeposit;
    //this.NewBooking.totalpublicdeposit  = this.BookingResponse.bookingObj.totalpublicdeposit ;

    if(this.NewBooking.PublicBooking)
    {
      this.validPaymentAmount = this.NewBooking.totalpublicdeposit - this.NewBooking.totalpayments;
    }
    else{
      this.validPaymentAmount = this.NewBooking.totalvenuedeposit - this.NewBooking.totalpayments;
    }

    this.setTab(Sections.Payment);
  }

  // CalcDeposit(passedtotal: number){
  
  //   this.paymentAmount = passedtotal;
  //   let NumPeople: number = 0;
  //   this.NewBooking.resources[0].admissions.forEach(adm => {
  //     NumPeople += adm.noofpeople;
  //   });

  //   if(this.SelectedPackage.venueDepositPolicy == null)
  //   {
  //       this.paymentAmount = passedtotal;
  //   }
  //   else
  //   {
  //     let dp: cDepositpolicy = this.SelectedPackage.venueDepositPolicy;

  //     switch (dp.type)
  //     {
  //       case "Percentage":

  //         //Calculate date difference

  //         const oneDay = 24 * 60 * 60 * 1000;
  //         const creationDate: any = new Date();
  //         const bookingDate: any = new Date(this.NewBooking.startTime);

  //         const diffDays = Math.round(Math.abs((bookingDate - creationDate) / oneDay));

  //         if(diffDays < dp.alternativeamountthreshold)
  //         {
  //           this.validPaymentAmount = parseFloat(((this.paymentAmount * dp.alternativeamount) / 100).toFixed(2));
  //         }
  //         else
  //         {
  //           this.validPaymentAmount = parseFloat(((this.paymentAmount * dp.amount) / 100).toFixed(2));
  //         }
  //         break;
  //       case "PerPerson":
  //         this.validPaymentAmount = NumPeople * dp.amount;
  //         break;
  //       case "Fixed":
  //         this.validPaymentAmount = dp.amount;
  //         break;
  //       case "NoDeposit":
  //         this.validPaymentAmount = 0;
  //         break;
  //       default:
  //         this.validPaymentAmount = this.paymentAmount;
  //     }

  //     if(this.validPaymentAmount > this.paymentAmount)
  //     {
  //       this.validPaymentAmount = this.paymentAmount;
  //     }

  //     this.minimumDiscount = this.validPaymentAmount;
  //     this.maxPayment =  passedtotal;
  //   }

  //   //Add Booking Fee
  //   let BookingFeeValue = 0;
    
  //   if(localStorage.getItem("VenueBookingFee") != null)
  //   {
  //     let venueBookingFee: cBookingfee = (JSON.parse(localStorage.getItem("VenueBookingFee")) as cBookingfee)
     
  //     if (venueBookingFee.bfadminapply)
  //     {
  //       switch (venueBookingFee.bfadmintype)
  //       {
  //         case "PERPERSON":
  //           BookingFeeValue = venueBookingFee.bfadminfee * NumPeople;
  //           break;
  //         case "FIXED":
  //           BookingFeeValue =venueBookingFee.bfadminfee;
  //           break;
  //         case "PERCENTAGE":
  //           BookingFeeValue = parseFloat(((this.BookingResponse.bookingObj.total * venueBookingFee.bfadminfee) / 100).toFixed(2));
  //           break;
  //         default:
  //           BookingFeeValue = 0;
  //       }

  //       if (BookingFeeValue > venueBookingFee.bfadminmax && venueBookingFee.bfadminmax > 0)
  //         BookingFeeValue = venueBookingFee.bfadminmax

  //       if (BookingFeeValue < venueBookingFee.bfadminmin)
  //         BookingFeeValue = venueBookingFee.bfadminmin
  //     }
  //   }

  //   this.BookingFeeAdddedOn = BookingFeeValue;

  //   this.validPaymentAmount += BookingFeeValue

  //   this.MinimumValidPayment = this.validPaymentAmount;
  // }

  validateEmail(email){ 
    var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; 
    return re.test(email); 
  }

  validateMobile(mobile){ 
    var re = /^(\+44\s?|0)7\d{9}$/; 
    return re.test(mobile); 
  }

  deferPayment(){
    this.showDeferReason = true;
  }

  DoSummary(BookingSummary)
  {
    let admissionsText: string = "";
    let extrasText: string = "";

    BookingSummary.resources[0].admissions.forEach(adm => {
      if(adm.noofpeople > 0)
        admissionsText += adm.noofpeople + " x " +  adm.name + ", ";
    });

    if(admissionsText.length > 2)
    admissionsText = admissionsText.substring(0, admissionsText.length - 2);

    if(BookingSummary.extras != null)
    {
      if(BookingSummary.extras.length > 0)
      {
        BookingSummary.extras.forEach(element => {
          if(element.qty > 0)
          {
            if(element.answer != null && element.answer != "")
            {
              extrasText += element.name + " x " + element.qty + " (" + element.question + ": " + element.answer + "), ";
            }
            else
            {
              extrasText += element.name + " x " + element.qty + ", ";
            }
          }
        });
      }
    }

    if(extrasText.length > 2)
    {
      extrasText = extrasText.substring(0, extrasText.length - 2);
    }

    let CustomerName = "";
    let CustomerAddress1 = "";
    let CustomerAddress2 = "";
    let CustomerTownCity = "";
    let CustomerPostCode = "";
    let FullCustomer = "";

    CustomerName = BookingSummary.customer.fullName.trim();

    if (BookingSummary.customer.address1 != null && BookingSummary.customer.address1 != "") 
    {
      CustomerAddress1 = BookingSummary.customer.address1 = ", "
    }
    
    if (BookingSummary.customer.address2 != null && BookingSummary.customer.address2 != "") 
    {
      CustomerAddress2 = BookingSummary.customer.address2 = ", "
    }

    if (BookingSummary.customer.townCity != null && BookingSummary.customer.townCity != "") 
    {
      CustomerTownCity = BookingSummary.customer.townCity = ", "
    }

    if (BookingSummary.customer.postCode != null && BookingSummary.customer.postCode != "") 
    {
      CustomerPostCode = BookingSummary.customer.postCode = ", "
    }

    FullCustomer = CustomerName + CustomerAddress1 + CustomerAddress2 + CustomerTownCity + CustomerPostCode;

    let newBookingSummary:cBookingsummary = {
      Disabled: BookingSummary.disabled,
      bookingId: BookingSummary.bookingId,
      bookingIdHR: BookingSummary.bookingIdHR,
      admissions: admissionsText,
      startTime: BookingSummary.startTime,
      endTime: BookingSummary.endTime,
      name: BookingSummary.name,
      arrival: BookingSummary.arrival,
      guests: BookingSummary.guests,
      total: BookingSummary.total,
      outstanding: BookingSummary.outstanding,
      paid: Number(BookingSummary.total) - Number(BookingSummary.outstanding),
      totaldiscounts: Number(BookingSummary.totaldiscounts),
      bookingfee: Number(BookingSummary.bookingfee),
      customerName: BookingSummary.customer.fullName,
      customerAddress: FullCustomer,
      customerMobile: BookingSummary.customer.mobile,
      customerEmail:  BookingSummary.customer.email,
      packageName:  BookingSummary.packageName,
      publicBooking: BookingSummary.publicBooking,
      extras: extrasText,
      itinerary: BookingSummary.itinerary,
      notes: BookingSummary.notes

    }

    this.BookingSummary = newBookingSummary;
    
    this.BookingComplete = true;
  }

  sumExtras(){
    this.TotalExtras = 0;

    this.packageExtras.forEach(element => {
      this.TotalExtras += Number(element.price) * Number(element.qty);
    });
  }

  donePayment(paymentid: string, paymentname : string)
  {

    this.paymentButtonLocked = true;

    //Get number of people
    let NumPeople: number = 0;
    this.NewBooking.resources[0].admissions.forEach(adm => {
      NumPeople += adm.noofpeople;
    });

    this.AllValid = true;
    let AmountDue:number =this.validPaymentAmount;

    if (this.paymentAmount == null || (this.paymentAmount < 0)) {
      this.AllValid = false;
      this.paymentValueValid = false;
    }
    else {
      this.paymentValueValid = true;
      this.AllValid = true;
    }

    if(this.AllValid)
    {
      let newPayment: Payment = {
        id: null,
        PaymentId: null,
        BookingId: this.BookingResponse.bookingId,
        bookingIdHR: this.BookingResponse.bookingIdHR,
        method: paymentname,
        paymenttypeid: paymentid,
        amount: this.validPaymentAmount,
        paymentDate: new Date,
        disabled: false,
        venueid: this.VenueID,
        refunds: null,
        balance: 0
      }

      this.NewBooking.payments = [];
      this.NewBooking.payments.push(newPayment);
      this.NewBooking.inProgress = false;

      if(this.oldDepositValue == 0)
      { 
        if(this.NewBooking.PublicBooking)
        {
          this.oldDepositValue = this.NewBooking.totalpublicdeposit;
        }
        else{
          this.oldDepositValue = this.NewBooking.totalvenuedeposit;
        }
      }

      this.http.post<booking_returndata>(bmxurl + "/Payment", this.NewBooking).subscribe(response => {
        this.BookingResponse = (response as booking_returndata).data
        
        if(response.succeeded == true)
        {
          setTimeout(() => {
            this.paymentButtonLocked = false;
          }, 1000);

          this.NewBooking = this.BookingResponse;
          this.BookingPayments = this.BookingResponse.payments;
          let despostvalue = 0;
          if(this.NewBooking.PublicBooking)
          {
            this.validPaymentAmount = this.NewBooking.totalpublicdeposit;// - this.NewBooking.totalpayments;
            despostvalue = this.NewBooking.totalpublicdeposit;
          }
          else{
            this.validPaymentAmount = this.NewBooking.totalvenuedeposit;// - this.NewBooking.totalpayments;
            despostvalue = this.NewBooking.totalvenuedeposit;
          }

          this.totalPayments = 0;
          this.BookingPayments.forEach(element => {
            this.totalPayments += element.amount;
          });

          if(this.NewBooking.totalpayments >= this.oldDepositValue)
          {
            this.DoSummary(this.BookingResponse);
          }
          else
          {
            this.NewBooking.totalvenuedeposit = this.BookingResponse.bookingObj.totalvenuedeposit;
            this.NewBooking.totalpublicdeposit  = this.BookingResponse.bookingObj.totalpublicdeposit ;
    
            if(this.NewBooking.PublicBooking)
            {
              this.validPaymentAmount = this.NewBooking.totalpublicdeposit;// - this.NewBooking.totalpayments;
            }
            else{
              this.validPaymentAmount = this.NewBooking.totalvenuedeposit;// - this.NewBooking.totalpayments;
            }
          }
        
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail: this.httpResponse.messages});
          this.paymentButtonLocked = false;
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail: error.message});
        this.paymentButtonLocked = false;
      })
    }
  }  
  
  sendSMSConfirmation(){
     this.http.get<customer>(bmxurl + "confirmation/" + this.VenueID + "/" + this.NewBooking.bookingId).subscribe(response => {
       this.messageService.add({severity:'success', summary:'Success', detail:'SMS Message sent.'});
     }, error => {
       this.messageService.add({severity:'error', summary:'Failure', detail: 'An error occurred whilst trying to send the SMS'});
     })
  }

  sendEmailConfirmation(){
     this.http.get<customer>(bmxurl + "confirmationemail/" + this.VenueID + "/" + this.NewBooking.bookingId).subscribe(response => {
       this.messageService.add({severity:'success', summary:'Success', detail:'Email Message sent.'})
     }, error => {
       this.messageService.add({severity:'error', summary:'Failure', detail: 'An error occurred whilst trying to send the Email'});
     })
  }

  setTab(tabNumber: number)
  {
    if(tabNumber == Sections.Date)
    {
      this.dateSelected = true;
      this.packageSelected = false;
      this.admissionsSelected = false;
      this.partyroomsSelected = false;
      this.timeSelected = false;
      this.customerSelected = false;
      this.paymentSelected = false;
      this.extraSelected = false;
      this.notesSelected = false;
      this.bookingQuestionsSelected = true;

      this.dateActive = false;
      this.packageActive = true;
      this.admissionsActive = true;
      this.partyroomsActive = true;
      this.timeActive = true;
      this.customerActive = true;
      this.paymentActive = true;
      this.extraActive = true;
      this.notesActive = true;
      this.bookingQuestionsActive = true;
    }
    if(tabNumber == Sections.Package) 
    {
      this.dateSelected = false;
      this.packageSelected = true;
      this.admissionsSelected = false;
      this.partyroomsSelected = false;
      this.timeSelected = false;
      this.customerSelected = false;
      this.paymentSelected = false;
      this.extraSelected = false;
      this.notesSelected = false;
      this.bookingQuestionsSelected = false;

      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = true;
      this.partyroomsActive = true;
      this.timeActive = true;
      this.customerActive = true;
      this.paymentActive = true;
      this.extraActive = true;
      this.notesActive = true;
      this.bookingQuestionsActive = true;
    }
    if(tabNumber == Sections.Admissions)
    {
      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = true;
      this.partyroomsSelected = false;
      this.timeSelected = false;
      this.customerSelected = false;
      this.paymentSelected = false;
      this.extraSelected = false;
      this.notesSelected = false;
      this.bookingQuestionsSelected = false;

      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = true;
      this.timeActive = true;
      this.customerActive = true;
      this.paymentActive = true;
      this.extraActive = true;
      this.notesActive = true;
      this.bookingQuestionsActive = true;
    }
    if(tabNumber == Sections.Partyroom)
    {
      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = false;
      this.partyroomsSelected = true;
      this.timeSelected = false;
      this.customerSelected = false;
      this.paymentSelected = false;
      this.extraSelected = false;
      this.notesSelected = false;
      this.bookingQuestionsSelected = false;


      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = false;
      this.timeActive = true;
      this.customerActive = true;
      this.paymentActive = true;
      this.extraActive = true;
      this.notesActive = true;
      this.bookingQuestionsActive = true;
    }
    if(tabNumber == Sections.Time) 
    {
      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = false;
      this.partyroomsSelected = false;
      this.timeSelected = true;
      this.customerSelected = false;
      this.paymentSelected = false;
      this.extraSelected = false;
      this.notesSelected = false;
      this.bookingQuestionsSelected = false;


      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = false;
      this.timeActive = false;
      this.customerActive = true;
      this.paymentActive = true;
      this.extraActive = true;
      this.notesActive = true;
      this.bookingQuestionsActive = true;
    }
    if(tabNumber == Sections.BookingQuestions) 
    {
      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = false;
      this.partyroomsSelected = false;
      this.timeSelected = false;
      this.customerSelected = false;
      this.paymentSelected = false;
      this.extraSelected = false;
      this.notesSelected = false;
      this.bookingQuestionsSelected = true;


      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = false;
      this.timeActive = false;
      this.customerActive = true;
      this.paymentActive = true;
      this.extraActive = true;
      this.notesActive = true;
      this.bookingQuestionsActive = false;
    }
    if(tabNumber == Sections.Customer)
    {
      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = false;
      this.partyroomsSelected = false;
      this.timeSelected = false;
      this.customerSelected = true;
      this.paymentSelected = false;
      this.extraSelected = false;
      this.notesSelected = false;
      this.bookingQuestionsSelected = false;


      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = false;
      this.timeActive = false;
      this.customerActive = false;
      this.paymentActive = true;
      this.extraActive = false;
      this.notesActive = true;
      this.bookingQuestionsActive = false;
    }
    if(tabNumber == Sections.Extras)
    {
      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = false;
      this.partyroomsSelected = false;
      this.timeSelected = false;
      this.customerSelected = false;
      this.paymentSelected = false;
      this.extraSelected = true;
      this.notesSelected = false;
      this.bookingQuestionsSelected = false;


      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = false;
      this.timeActive = false;
      this.customerActive = true;
      this.extraActive = false;
      this.notesActive = true;
      this.paymentActive = true;
      this.bookingQuestionsActive = false;
    }
    if(tabNumber == Sections.Notes)
    {
      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = false;
      this.partyroomsSelected = false;
      this.timeSelected = false;
      this.customerSelected = false;
      this.extraSelected = false;
      this.notesSelected = true;
      this.paymentSelected = false;
      this.bookingQuestionsSelected = false;


      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = false;
      this.timeActive = false;
      this.customerActive = false;
      this.extraActive = false;
      this.notesActive = false;
      this.paymentActive = true;
      this.bookingQuestionsActive = false;
    }
    if(tabNumber == Sections.Payment)
    {
      if(this.NewBooking.customer == null)
      {
        this.messageService.add({ severity: 'Customer', summary: 'Please enter a customer' });
        this.setTab(Sections.Customer);
        return;
      }

      this.dateSelected = false;
      this.packageSelected = false;
      this.admissionsSelected = false;
      this.partyroomsSelected = false;
      this.timeSelected = false;
      this.customerSelected = false;
      this.extraSelected = false;
      this.notesSelected = false;
      this.paymentSelected = true;
      this.bookingQuestionsSelected = false;


      this.dateActive = false;
      this.packageActive = false;
      this.admissionsActive = false;
      this.partyroomsActive = false;
      this.timeActive = false;
      this.customerActive = false;
      this.extraActive = false;
      this.notesActive = false;
      this.paymentActive = false;
      this.bookingQuestionsActive = true;
    }
  }

  returnHome(){
    this.AbandonBooking();

    this.setTab(Sections.Date);
    this.BookingPayments = [];
    this.totalPayments = 0;

    this.checked = [];

    this.BookingComplete = false;

    this.PackageHeader = "Select a Package";
    this.CalendarHeader = "Select a Date";
    this.AdmissionHeader = "Select your Admissions";
    this.TimeHeader = "Select a Time";
    this.CustomerHeader = "Create a Customer";
    this.PaymentHeader = "Add a Payment";
    this.ExtrasHeader = "Select Extras";

    this.startTimes = null;
    this.startTimesFiltered = null;

    this.SelectedPackage = null;
    this.SelectedDate = null;

    this.firstName = "";
    this.lastName = "";
    this.address1 = "";
    this.address2 = "";
    this.town = "";
    this.postCode = "";
    this.email = "";
    this.mobile = "";
    this.searchCriteria = "";
    this.customersResult = null;
    this.ShowCustomerDetails = false;

    this.selectedPayment = null;
    this.packageExtras = null;
    this.paymentAmount = 0;
    this.makeABookingHeader = "Make a Booking";
    this.NewBooking = {} as booking;
    this.customerSelectedResources = [];
    this.SelectedResources = [];

    this.MinimumValidPayment = 0;
  }
  
  AbandonBooking() {
    //Does a current booking exist for this date?
    if (this.NewBooking.bookingId != null) {
      let abandonBooking: any = {
        bookingid: this.NewBooking.bookingId,
        jwt: null
      };
      //Booking Exists, abandon it
      this.http.post(bmxurl + "abandonbooking/" + this.VenueID, abandonBooking).subscribe(response => {
      }, error => {
        this.messageService.add({ severity: 'error', summary: 'Failure', detail: 'Failed to abandon booking.' });
      });
    }
  }

  getAvailability(){
    this.busyService.busy();
    let totalNoOfPeople: number = 0;

    this.AvailabilityReq.Admissions = [] as admission[];

     this.SelectedAdmissions.forEach(adm => {
    
       this.AvailabilityReq.Admissions.push(adm);
      //totalNoOfPeople += adm.noofpeople;
      
    });

    this.AvailabilityReq.packageId = this.SelectedPackage.packageId;
    //AvailabilityReq.NoOfPeople =  totalNoOfPeople;
    this.AvailabilityReq.venueid = this.VenueID;
    this.AvailabilityReq.StartTime =  this.SelectedDate.getFullYear() + "-" +  (this.SelectedDate.getMonth() + 1) + "-" +  this.SelectedDate.getDate() + "T00:00:00.000Z";
    this.AvailabilityReq.DiagnosticMode = true;
    this.AvailabilityReq.Resources = this.customerSelectedResources;
    this.AvailabilityReq.useintervalavailability = this.UseIntervalAvailability;

    this.http.post<AvailableTimes>(bmxurl + "/Availability", this.AvailabilityReq).subscribe(response => {
      this.startTimes =(response as AvailableTimes)
      let found: Boolean = false;

    this.startTimesFiltered = [];
      this.startTimes.forEach(element => {
        if(element.timeAvailable)
        {
          this.startTimesFiltered.forEach(innerelement => {
            if(innerelement.time == element.time)
              found = true;
          });

          if(!found)
          {
            this.startTimesFiltered.push(element);
          }
          found = false;
        }
      });

      if(this.timetobetried != "")
      {
        let timefound = false;

        this.startTimes.forEach(element => {
          //let timetocheck = new Date(element.time);
          //let sTimetocheck = timetocheck.getHours().toString().padStart(2, '0') + ":" + timetocheck.getMinutes().toString().padStart(2, '0');
          
          if(element.timeString == this.timetobetried && element.timeAvailable)
          {
            this.busyService.busy();
            timefound = true;
            this.timetobetried = "";
            this.bookSlot(element);
          }
        });

        if(!timefound){
          this.messageService.add({severity:'error', summary:'Error', detail: "The time you selected in 'Book this time' is no longer available. Please select another time."});
        }
      }

      this.busyService.idle();

    }, error => {
      this.busyService.idle();
      console.log(error);
  })
  this.busyService.idle();

  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  addMs = (date: Date, ms: number): Date => {
    const result = new Date(date);
    result.setMilliseconds(result.getMilliseconds() + ms);
    return result;
  };

  printConfirmation()
  {
    //this.confHTML = "https://email.wannabook.online/print/" + this.VenueID + "/" + this.NewBooking.bookingId;
    this.showPrintConfirmations = true;
    this.http.get(bmxurl + "print/" + this.VenueID + "/" + this.NewBooking.bookingId).subscribe(response => {
      this.confHTML = (response as conf_returndata).data.toString();
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Payment Types.'});
    })

  }

  switchToToday(){
    this.SelectedDate = new Date();
    this.doneDate();
  }

  switchTomorrow(){
    let tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);

    this.SelectedDate = tomorrow;
    this.doneDate();
  }

  switchToSaturday()
  {
    let saturday = new Date();
    let overflow = 0;

    while(saturday.getDay() != 6 && overflow <= 7)
    {
      saturday.setDate(saturday.getDate() + 1);
      overflow ++;
    }

    this.SelectedDate = saturday;
    this.doneDate();
  }

  switchToSunday()
  {
    let sunday = new Date();
    let overflow = 0;

    while(sunday.getDay() != 0 && overflow <= 7)
    {
      sunday.setDate(sunday.getDate() + 1);
      overflow ++;
    }

    this.SelectedDate = sunday;
    this.doneDate();
  }
}

enum Sections {
  Date = 0,
  Package = 1,
  Admissions = 2,
  Partyroom = 3,
  Time = 4,
  Customer = 5,
  Extras = 6,
  Notes = 7,
  Payment = 8,
  BookingQuestions = 9
}


