<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed" class="p-3 flex flex-column flex-auto">
    <h3 style="margin-top: 18px;" i18n>Booking List for period: </h3>
    <div style="width: calc(100vw - 607px); float: left; margin-left: 304px; margin-bottom:20px;margin-top:-71px;">
        
        <div style="float:left; width: 200px;margin-right: 20px;">
            <span>From Date:</span>
            <p-calendar [selectOtherMonths]="true" [(ngModel)]="FromDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>  
        </div> 
       &nbsp;
        <div style="float:left; width: 200px;">
            <span>To Date:</span>
            <p-calendar [selectOtherMonths]="true" [(ngModel)]="ToDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>  
        </div> 
        &nbsp;
        <div style="float:left; width: 400px;margin-left:24px;">
            <span>Tags:</span><br>
            <p-multiSelect [options]="tags" optionLabel="name" [(ngModel)]="chosentags" display="chip" style="float:left;">
                <ng-template let-value pTemplate="selectedItems">
                  <span *ngFor="let tag of chosentags">
                    <p-chip label="{{tag.name}}" styleClass="mr-2" [style]="{'background': tag.colour, 'color': 'white', 'border-radius': '5px' }">
                    </p-chip>
                  </span>
                  <div *ngIf="!chosentags || chosentags.length === 0" class="country-placeholder"> Select Tag(s) </div>
                </ng-template>
              </p-multiSelect>

              <div style="width:180px; float:left;margin-top:-16px;">

                <div style="float:left; width: 90px;">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" style="background-color: #22C55E;border-color: #22C55E;margin-left: 12px;" (click)="doneDate()">
                        Go
                      </button>
                </div> 
                <div style="float:left; width: 90px;margin-top:16px;">
                    <button *ngIf="ListBookings"
                        type="button"
                        pButton
                        pRipple
                        icon="pi pi-file-excel"
                        (click)="ListBookingsTable.exportCSV()"
                        class="mr-2"
                        pTooltip="Export to CSV"
                        tooltipPosition="bottom">
                    </button>
                </div>
              </div>
        </div>
    </div>

    <div class="surface-card p-4 shadow-2 border-round">
        <p-table  #ListBookingsTable 
        [columns]="cols"
        [value]="ListBookings" 
        responsiveLayout="scroll">

        <ng-template pTemplate="header" let-columns>
            <th width="8%">Sales ID
              
            </th>
            <th  width="10%">Start Date
               
            </th>
            <th width="8%">Start
              
            </th>
            <th width="8%">Name
  
            </th>
            <th  width="15%">Package
          
            </th>
            <th style="text-align: left;" width="20%">Admissions
   
            </th>
            <th style="text-align: center;" width="6%">Guests
   
            </th>
            <th style="text-align: center;"  width="5%">Total
              
            </th>
            <th style="text-align: center;"  width="5%">O/S
                
            </th>
            <th width="20%">
                Extras
            </th>
            <th width="5%">
                Allergy
            </th>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td>{{rowData.bookingIdHR}}</td>
                <td>{{rowData.startTime | date:'dd/MM/yy'}}</td>
                <td>{{rowData.startTime | date:'HH:mm' : 'UTC'}}</td>
                <td>{{rowData.name}}</td>
                <td>{{rowData.packageName}}</td>
                <td>{{rowData.admissions}}</td>
                <td style="text-align: right;">{{rowData.guests}}</td>
                <td style="text-align: right;">{{rowData.total | currency: CurrencySymbol}}</td>
                <td style="text-align: right;" *ngIf="rowData.outstanding <=0">{{rowData.outstanding | currency: CurrencySymbol}}</td>
                <td style="text-align: right;" *ngIf="rowData.outstanding >0"><span style="color: Red; font-weight: bold;">{{rowData.outstanding | currency: CurrencySymbol}}</span></td>
                <td>{{rowData.extraslist}}</td>
                <td>{{rowData.allergy}}</td>
            </tr>
        </ng-template>

        <ng-template  pTemplate="footer">
            <tr *ngIf="ListBookings.length > 0 && ListBookings != null">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style="text-align: right;">
                    <b>{{guests}}</b>
                <td style="text-align: right;">
                    <b>{{total | currency: CurrencySymbol}}</b>
                </td>
                <td style="text-align: right;">
                    <b>{{outstanding | currency: CurrencySymbol}}</b>
                </td>
                <td></td>
                <td></td>
            </tr>
            <tr *ngIf="ListBookings.length == 0 || ListBookings == null">
                <td style="text-align: center;" colspan="8">
                    There is no data for the selected date range.
                </td>
            </tr> 
        </ng-template>
    </p-table>
    </div>
</div>
