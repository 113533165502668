import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { class_returndata } from 'src/types/class_returndata'; 
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { classes } from 'src/types/class';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { users_returndata } from 'src/types/user_returndata';
import { link_returndata } from 'src/types/link_returndata';
import { ClipboardService } from 'ngx-clipboard';
import { host_returndata } from 'src/types/host_returndata';
import { GlobalService } from '../_services/global.service';


@Component({
  selector: 'app-linkmanager',
  templateUrl: './linkmanager.component.html',
  styleUrls: ['./linkmanager.component.css']
})

export class LinkmanagerComponent  implements OnInit {
  ListLinks: any;
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  DisabledLink: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;


  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService, private clipboardService: ClipboardService, private globalService: GlobalService) { 

    }

  copyData(LinkID)
  {
    let url = "";

    this.http.get(bmxurl + "/venuehost/" + this.VenueID).subscribe(response => {
      this.httpResponse =  (response as host_returndata);
          if((response as host_returndata).succeeded)
          {
            url = "https://wannabook.online/booking/" + (response as host_returndata).data.hostname + "?LinkId=" + LinkID;

            this.clipboardService.copyFromContent(url);
            this.messageService.add({severity:'success', summary:'Copied', detail:'Link successfully copied to clipboard'});
          }
          else
          {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:'No Host has been set for this venue'});
          }
    });
  
  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("CLASSES", user)){
          this.AccessAllowed = true;
          this.loadgrid(false);
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(LinkID)
  {
    this.http.delete(bmxurl + "Link/" + this.VenueID + "/" + LinkID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded == true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Link successfully disabled'});
        this.router.navigateByUrl("/linkmanager");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Link'});
    })
  }
  
  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "LinkList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.ListLinks = (response as link_returndata);

        if ((response as link_returndata).messages.length > 0)
        {
          (response as link_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error getting Classes, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Classes, error: ' + error.messages});
    });
  }

  editData(EditData)
  {
    let linkID = EditData;
    this.router.navigateByUrl("/linkeditor/" + linkID);
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "Link/" + this.VenueID + "/" + element.linkid).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.loadgrid(false);
      } 
      else{
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error disabling Classes, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error disabling Classes, error: ' + error.messages});
    })
    });
    
  }

  
  }
