import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';
import { datasets } from 'src/types/event';


@Component({
  selector: 'app-marketingemails',
  templateUrl: './marketingemails.component.html',
  styleUrls: ['./marketingemails.component.css']
})
export class MarketingemailsComponent implements OnInit{
  basicOptions: any;
  chartData: any;
  pTableData: any[] = [];

  constructor(private globalService: GlobalService) { }

  createData()
  {

 let dataRow = {
  "datetimesent": new Date("01/05/2024 05:23"),
  "emailaddress": "mirod@comcast.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 05:23"),
  "emailaddress": "luebke@aol.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 05:23"),
  "emailaddress": "koudas@me.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("02/05/2024 05:23"),
  "emailaddress": "pfitza@comcast.net",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 05:23"),
  "emailaddress": "majordick@mac.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("04/05/2024 05:23"),
  "emailaddress": "mcraigw@me.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("05/05/2024 05:23"),
  "emailaddress": "weidai@verizon.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("06/05/2024 05:23"),
  "emailaddress": "jpflip@live.com",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("07/05/2024 05:23"),
  "emailaddress": "jshearer@att.net",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("08/05/2024 05:23"),
  "emailaddress": "bmidd@msn.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 05:23"),
  "emailaddress": "tskirvin@live.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 05:23"),
  "emailaddress": "frode@att.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 05:23"),
  "emailaddress": " bescoto@mac.com",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("02/05/2024 05:23"),
  "emailaddress": "kaiser@live.com",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 05:23"),
  "emailaddress": "rwelty@outlook.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("04/05/2024 05:23"),
  "emailaddress": "howler@comcast.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("05/05/2024 05:23"),
  "emailaddress": "steve@outlook.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("06/05/2024 05:23"),
  "emailaddress": "murty@gmail.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("07/05/2024 05:23"),
  "emailaddress": "gward@me.com",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("08/05/2024 05:23"),
  "emailaddress": "lcheng@att.net",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("09/05/2024 05:23"),
  "emailaddress": "dleconte@sbcglobal.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("10/05/2024 05:23"),
  "emailaddress": "quinn@hotmail.com",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("11/05/2024 05:23"),
  "emailaddress": "jpflip@sbcglobal.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("12/05/2024 05:23"),
  "emailaddress": "esasaki@yahoo.ca",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("13/05/2024 05:23"),
  "emailaddress": "durist@comcast.net",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("14/05/2024 05:23"),
  "emailaddress": "carcus@yahoo.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 21:43"),
  "emailaddress": "muzzy@comcast.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 21:43"),
  "emailaddress": "cumarana@mac.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 21:43"),
  "emailaddress": "ghaviv@aol.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 21:43"),
  "emailaddress": "aukjan@yahoo.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 21:43"),
  "emailaddress": "kalpol@hotmail.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 21:43"),
  "emailaddress": "noneme@att.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 21:43"),
  "emailaddress": "bahwi@att.net",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 21:43"),
  "emailaddress": "webinc@yahoo.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 21:43"),
  "emailaddress": "adillon@live.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 21:43"),
  "emailaddress": "tangsh@yahoo.ca",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 21:43"),
  "emailaddress": "tskirvin@icloud.com",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 21:43"),
  "emailaddress": "drezet@gmail.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("01/05/2024 21:43"),
  "emailaddress": "malattia@live.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("02/05/2024 05:53"),
  "emailaddress": "benits@verizon.net",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("02/05/2024 05:53"),
  "emailaddress": "scato@yahoo.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("02/05/2024 05:53"),
  "emailaddress": "drjlaw@msn.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("02/05/2024 05:53"),
  "emailaddress": "scottlee@verizon.net",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 05:53"),
  "emailaddress": "skippy@icloud.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("04/05/2024 05:53"),
  "emailaddress": "chinthaka@verizon.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("05/05/2024 05:53"),
  "emailaddress": "wildfire@icloud.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("06/05/2024 05:53"),
  "emailaddress": "sriha@live.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("07/05/2024 05:53"),
  "emailaddress": "kannan@msn.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("08/05/2024 05:53"),
  "emailaddress": "cvrcek@att.net",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("09/05/2024 05:53"),
  "emailaddress": "falcao@live.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("10/05/2024 05:53"),
  "emailaddress": "chance@mac.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("11/05/2024 05:53"),
  "emailaddress": "jshearer@hotmail.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("12/05/2024 05:53"),
  "emailaddress": "cgcra@sbcglobal.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("02/05/2024 14:03"),
  "emailaddress": "kronvold@yahoo.ca",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("02/05/2024 14:03"),
  "emailaddress": "baveja@att.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("02/05/2024 14:03"),
  "emailaddress": "jkegl@yahoo.ca",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("02/05/2024 14:03"),
  "emailaddress": "bachmann@gmail.com",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("02/05/2024 14:03"),
  "emailaddress": "cmdrgravy@outlook.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 14:03"),
  "emailaddress": "clkao@icloud.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("04/05/2024 14:03"),
  "emailaddress": "fhirsch@sbcglobal.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("02/05/2024 14:03"),
  "emailaddress": "stinson@yahoo.ca",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("02/05/2024 14:03"),
  "emailaddress": "chaikin@comcast.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("02/05/2024 14:03"),
  "emailaddress": "tlinden@yahoo.ca",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 14:03"),
  "emailaddress": "cparis@msn.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("04/05/2024 14:03"),
  "emailaddress": "parrt@optonline.net",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("05/05/2024 14:03"),
  "emailaddress": "bcevc@outlook.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("06/05/2024 14:03"),
  "emailaddress": "pgolle@icloud.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("07/05/2024 14:03"),
  "emailaddress": "bebing@sbcglobal.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("08/05/2024 14:03"),
  "emailaddress": "payned@hotmail.com",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("09/05/2024 14:03"),
  "emailaddress": "enintend@yahoo.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("10/05/2024 14:03"),
  "emailaddress": "scarolan@icloud.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("11/05/2024 14:03"),
  "emailaddress": "gknauss@me.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("12/05/2024 14:03"),
  "emailaddress": "marnanel@verizon.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("13/05/2024 14:03"),
  "emailaddress": "gommix@live.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("14/05/2024 14:03"),
  "emailaddress": "josephw@sbcglobal.net",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("15/05/2024 14:03"),
  "emailaddress": "jwarren@optonline.net",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 06:23"),
  "emailaddress": "wbarker@verizon.net",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 06:23"),
  "emailaddress": "gmcgath@yahoo.ca",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 06:23"),
  "emailaddress": "jimmichie@outlook.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 06:23"),
  "emailaddress": "jwarren@icloud.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 06:23"),
  "emailaddress": "eabrown@icloud.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 06:23"),
  "emailaddress": "flavell@sbcglobal.net",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 06:23"),
  "emailaddress": "jorgb@att.net",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 06:23"),
  "emailaddress": "jfreedma@aol.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 06:23"),
  "emailaddress": "daveed@yahoo.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 06:23"),
  "emailaddress": "bryanw@sbcglobal.net",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 06:23"),
  "emailaddress": "aschmitz@yahoo.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 06:23"),
  "emailaddress": "miyop@mac.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("03/05/2024 14:33"),
  "emailaddress": "richard@optonline.net",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("04/05/2024 14:33"),
  "emailaddress": "msloan@me.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("05/05/2024 14:33"),
  "emailaddress": "frikazoyd@optonline.net",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("06/05/2024 14:33"),
  "emailaddress": "novanet@mac.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("07/05/2024 14:33"),
  "emailaddress": "attwood@icloud.com",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("08/05/2024 14:33"),
  "emailaddress": "geoffr@yahoo.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("09/05/2024 14:33"),
  "emailaddress": "janusfury@me.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("10/05/2024 14:33"),
  "emailaddress": "mallanmba@msn.com",
  "status": "Read"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("11/05/2024 14:33"),
  "emailaddress": "petersen@optonline.net",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("12/05/2024 14:33"),
  "emailaddress": "ganter@optonline.net",
  "status": "Link Clicked"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("13/05/2024 14:33"),
  "emailaddress": "gslondon@outlook.com",
  "status": "Delivered"
}
this.pTableData.push(dataRow);
dataRow = {
  "datetimesent": new Date("14/05/2024 14:33"),
  "emailaddress": "epeeist@icloud.com",
  "status": "Read"
}

  }


  ngOnInit() {

    this.globalService.setSmallScreenMenu(false);

    this.createData();

    this.basicOptions = {

      plugins: {
          // legend: {
          //     display: false
          // }
      },
      responsive: true,
      // scales: {
      //     y: {
      //         ticks: {
      //             display: false,
      //         },
      //         grid: {
      //             display: false
      //         }
      //     },
      //     x: {
      //         ticks: {
      //             display: false,
      //         },
      //         grid: {
      //             display: false
      //         }
      //     }
      // }
    };

    let newLabels: string[] = [];
    newLabels.push("1st");
    newLabels.push("2nd");
    newLabels.push("3rd");
    newLabels.push("4th");
    newLabels.push("5th");
    newLabels.push("6th");
    newLabels.push("7th");
    newLabels.push("8th");
    newLabels.push("9th");
    newLabels.push("10th");
    newLabels.push("11th");
    newLabels.push("12th");
    newLabels.push("13th");
    newLabels.push("14th");
    newLabels.push("15th");
    newLabels.push("16th");
    newLabels.push("17th");
    newLabels.push("18th");
    newLabels.push("19th");
    newLabels.push("20th");
    newLabels.push("21st");
    newLabels.push("22nd");
    newLabels.push("23rd");
    newLabels.push("24th");
    newLabels.push("25th");
    newLabels.push("26th");
    newLabels.push("27th");
    newLabels.push("28th");
    newLabels.push("29th");
    newLabels.push("30th");
    newLabels.push("31st");

    let newData: number[] = [];
    newData.push(46);
    newData.push(63);
    newData.push(53);
    newData.push(89);
    newData.push(92);
    newData.push(24);
    newData.push(42);
    newData.push(56);
    newData.push(44);
    newData.push(62);
    newData.push(77);
    newData.push(102);
    newData.push(35);
    newData.push(37);
    newData.push(44);
    newData.push(25);
    newData.push(49);
    newData.push(88);
    newData.push(75);
    newData.push(32);
    newData.push(52);
    newData.push(32);
    newData.push(73);
    newData.push(52);
    newData.push(106);
    newData.push(133);
    newData.push(33);
    newData.push(72);
    newData.push(54);
    newData.push(63);
    newData.push(84);

    let newData1: number[] = [];
    newData1.push(35);
    newData1.push(55);
    newData1.push(34);
    newData1.push(67);
    newData1.push(63);
    newData1.push(12);
    newData1.push(23);
    newData1.push(30);
    newData1.push(32);
    newData1.push(36);
    newData1.push(55);
    newData1.push(85);
    newData1.push(12);
    newData1.push(34);
    newData1.push(34);
    newData1.push(18);
    newData1.push(43);
    newData1.push(66);
    newData1.push(59);
    newData1.push(33);
    newData1.push(45);
    newData1.push(28);
    newData1.push(51);
    newData1.push(33);
    newData1.push(78);
    newData1.push(99);
    newData1.push(25);
    newData1.push(52);
    newData1.push(21);
    newData1.push(35);
    newData1.push(62);

    let newData2: number[] = [];
    newData2.push(12);
    newData2.push(10);
    newData2.push(6);
    newData2.push(7);
    newData2.push(3);
    newData2.push(1);
    newData2.push(9);
    newData2.push(1);
    newData2.push(4);
    newData2.push(15);
    newData2.push(23);
    newData2.push(22);
    newData2.push(1);
    newData2.push(12);
    newData2.push(14);
    newData2.push(0);
    newData2.push(10);
    newData2.push(7);
    newData2.push(3);
    newData2.push(1);
    newData2.push(2);
    newData2.push(6);
    newData2.push(8);
    newData2.push(9);
    newData2.push(12);
    newData2.push(4);
    newData2.push(11);
    newData2.push(17);
    newData2.push(2);
    newData2.push(11);
    newData2.push(21);

    let newDataSet: datasets = {
      label: "Emailed",
      data: newData,
      backgroundColor: '#64748b',
      borderColor: '#64748b',
    }

    let newDataSet1: datasets = {
      label: "Read",
      data: newData1,
      backgroundColor: '#f59e0b',
      borderColor: '#f59e0b',
    }

    let newDataSet2: datasets = {
      label: "Link Clicked",
      data: newData2,
      backgroundColor: '#22c55e',
      borderColor: '#22c55e',
    }

    this.chartData = {
      labels: newLabels,
      datasets: [
        newDataSet,newDataSet1, newDataSet2
      ]
    }
  }
}

