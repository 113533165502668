import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-printconfirmation',
  templateUrl: './printconfirmation.component.html',
  styleUrls: ['./printconfirmation.component.css']
})
export class PrintconfirmationComponent implements OnInit {
  @Input() confHTML: string;
  
  constructor(private globalService: GlobalService) { }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);
    
  }

}
