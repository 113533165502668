import { Component, OnInit } from '@angular/core';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable'
import { reportdata, reportdefinition } from 'src/types/reportdefinition';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  constructor(private globalService: GlobalService) { }

  cols: any[];
  reportData: any[];
  exportColumns: any[];
  _selectedColumns;

  ReportName: string = '';

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);
    
  // this.cols = [
  //     { field: 'code', header: 'Code' },
  //     { field: 'name', header: 'Name' },
  //     { field: 'category', header: 'Category' },
  //     { field: 'quantity', header: 'Quantity' }
  // ];

  //   this.reportData = [
  //     { 'code' : '1234', 'name' : 'Apple', 'category' : 'Fruit', 'quantity' : '10' },
  //     { 'code' : '1235', 'name' : 'Orange', 'category' : 'Fruit', 'quantity' : '48' },
  //     { 'code' : '1236', 'name' : 'Pear', 'category' : 'Fruit', 'quantity' : '15' },
  //     { 'code' : '1237', 'name' : 'Peach', 'category' : 'Fruit', 'quantity' : '31' }
  //   ];

  //   this._selectedColumns = this.cols;
  //   this.exportColumns = this.cols.map(col => ({
  //     title: col.header,
  //     dataKey: col.field
  //   }));
  
  let report: reportdefinition = {
    id: 'b22257e1-afa2-47f5-9ca9-fedbc4092436',
    reportid: 'afe91457-f366-4b3d-ad63-ded274ad59e7',
    reportname: 'Test Report',
    Criteria: [
      { criterion: 'startdate', displayname: 'Start Date' },
      { criterion: 'enddate', displayname: 'End Date' }
    ],
    Columns: [
      { header: 'Booking ID', fieldname: 'id', orderby: null },
      { header: 'Booking Name', fieldname: 'name', orderby: '1' },
      { header: 'Start Date', fieldname: 'startdate', orderby: null },
      { header: 'Start Time', fieldname: 'endate', orderby: null },
      { header: 'Booking Value', fieldname: 'value', orderby: null }
    ]
  }

  let data: reportdata = {
    datarow: [
      {
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109718' },
          { datafieldname: 'name', datafieldvalue: 'Claire Smith' },
          { datafieldname: 'startdate', datafieldvalue: '21/05/2024' },
          { datafieldname: 'starttime', datafieldvalue: '16:00' },
          { datafieldname: 'value', datafieldvalue: '£231.99' }
        ]
      },
      {
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109702' },
          { datafieldname: 'name', datafieldvalue: 'Sarah Luker' },
          { datafieldname: 'startdate', datafieldvalue: '02/01/2021' },
          { datafieldname: 'enddate', datafieldvalue: '16:00' },
          { datafieldname: 'value', datafieldvalue: '£145.73' }
        ]
      },
      {
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109701' },
          { datafieldname: 'name', datafieldvalue: 'Rose Riley' },
          { datafieldname: 'startdate', datafieldvalue: '21/05/2024' },
          { datafieldname: 'starttime', datafieldvalue: '16:00' },
          { datafieldname: 'value', datafieldvalue: '£12.25' }
        ]
      },
      {
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109677' },
          { datafieldname: 'name', datafieldvalue: 'Hayley Jobling' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '16:00' },
          { datafieldname: 'value', datafieldvalue: '£11.50' }
        ]
      },
      {
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109674' },
          { datafieldname: 'name', datafieldvalue: 'Christine Hunt' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '16:00' },
          { datafieldname: 'value', datafieldvalue: '£7.00' }
        ]
      },
      {
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109671' },
          { datafieldname: 'name', datafieldvalue: 'Zak Wood' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '16:00' },
          { datafieldname: 'value', datafieldvalue: '£23.35' }
        ]
      },
      {
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109670' },
          { datafieldname: 'name', datafieldvalue: 'Jamielee  Tolcher ' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '16:00' },
          { datafieldname: 'value', datafieldvalue: '£22.00' }
        ]
      },
      {
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109669' },
          { datafieldname: 'name', datafieldvalue: 'Claire Hobbs' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '16:00' },
          { datafieldname: 'value', datafieldvalue: '£180.00' }
        ]
      },
      {
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109667' },
          { datafieldname: 'name', datafieldvalue: 'Luke Admans' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '16:00' },
          { datafieldname: 'value', datafieldvalue: '£259.00' }
        ]
      },
      {	
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109666' },
          { datafieldname: 'name', datafieldvalue: 'Kavita Maganti' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'enddate', datafieldvalue: '16:00' },
          { datafieldname: 'value', datafieldvalue: '£259.00' }
        ]
      },
      {	 	
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109665' },
          { datafieldname: 'name', datafieldvalue: 'Sharney Lawrence' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '11:00' },
          { datafieldname: 'value', datafieldvalue: '£6.00' }
        ]
      },
      {
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109664' },
          { datafieldname: 'name', datafieldvalue: 'Hayley Jobling' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '16:00' },
          { datafieldname: 'value', datafieldvalue: '£7.90' }
        ]
      },
      {
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109663' },
          { datafieldname: 'name', datafieldvalue: 'Theo Tsiolas' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '16:00' },
          { datafieldname: 'value', datafieldvalue: '£11.50' }
        ]
      },
      {
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3409633' },
          { datafieldname: 'name', datafieldvalue: 'Erin Thomson' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '09:30' },
          { datafieldname: 'value', datafieldvalue: '£8.45' }
        ]
      },
      {
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109661' },
          { datafieldname: 'name', datafieldvalue: 'Dexter Reid' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '11:00' },
          { datafieldname: 'value', datafieldvalue: '£10.45' }
        ]
      },
      {	
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109660' },
          { datafieldname: 'name', datafieldvalue: 'Angela Patterson' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '10:00' },
          { datafieldname: 'value', datafieldvalue: '£16.80' }
        ]
      },
      {	 
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109659' },
          { datafieldname: 'name', datafieldvalue: 'Zoe Hemmings' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '16:00' },
          { datafieldname: 'value', datafieldvalue: '£13.50' }
        ]
      },
      {	 
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109658' },
          { datafieldname: 'name', datafieldvalue: 'Mike Westall' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '16:00' },
          { datafieldname: 'value', datafieldvalue: '£10.45' }
        ]
      },
      {	 
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109657' },
          { datafieldname: 'name', datafieldvalue: 'Theresa Odame' },
          { datafieldname: 'startdate', datafieldvalue: '16/02/2024' },
          { datafieldname: 'starttime', datafieldvalue: '13:00' },
          { datafieldname: 'value', datafieldvalue: '£28.00' }
        ]
      },
      {
        datacolumn: [
          { datafieldname: 'id', datafieldvalue: '3109656' },
          { datafieldname: 'name', datafieldvalue: 'Abby Thomas' },
          { datafieldname: 'startdate', datafieldvalue: '29/01/2024' },
          { datafieldname: 'enddate', datafieldvalue: '14:30:00' },
          { datafieldname: 'value', datafieldvalue: '£23.00' }
        ]
      }
    ]

  }

  this.reportData = data.datarow.map(row => {
    let obj = {};
    row.datacolumn.forEach(col => {
      obj[col.datafieldname] = col.datafieldvalue;
    });
    return obj;
  });

  this.ReportName = report.reportname;

  this.cols = report.Columns.map(col => ({
    field: col.fieldname,
    header: col.header
  }));

}

  exportPdf() { 
    const doc = new jsPDF('p','pt');
    doc['autoTable'](this.exportColumns, this.reportData);
    doc.save("report.pdf");
  }
}