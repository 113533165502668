import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { timesets_returndata } from 'src/types/timesets_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cTimesets, timesets } from 'src/types/timesets';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';
import { holidayopenings } from 'src/types/holidayopenings';
import { ThisReceiver } from '@angular/compiler';
import { v4 } from 'uuid';

import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-timesetedit',
  templateUrl: './timesetedit.component.html',
  styleUrls: ['./timesetedit.component.css']
})
export class TimeseteditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  TimeSet: cTimesets;
  httpResponse: any;
  id: string;
  stateOptions: any;
  holidayOpeningStartDate: Date;
  holidayOpeningEndDate: Date;
  isdefault: any;
  pageReady: boolean = false;

  timesetNameValid: boolean = true;
  timesetDatesValid: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 
    this.stateOptions = [{label: 'No', value: false}, {label: 'Yes', value: true}];

    this.TimeSet = {
      id: null,
      venueid: this.VenueID,
      isdefault: false,
      name: "",
      disabled: false,
      holidayopenings: [],
      isactive: false
    }


  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("PACKAGE", user)){
          this.AccessAllowed = true;
          this.id = this.route.snapshot.paramMap.get('id');
          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "timesets/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as timesets_returndata)
              if (this.httpResponse.succeeded)
              {
                this.TimeSet = {
                  id: (this.httpResponse.data as cTimesets).id,
                  venueid: (this.httpResponse.data as cTimesets).venueid,
                  isdefault: (this.httpResponse.data as cTimesets).isdefault,
                  name:(this.httpResponse.data as cTimesets).name,
                  disabled:(this.httpResponse.data as cTimesets).disabled,
                  holidayopenings: (this.httpResponse.data as cTimesets).holidayopenings,
                  isactive: (this.httpResponse.data as cTimesets).isactive
                };
      
                this.pageReady = true;
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Time Sets'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Time Sets, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
          else{
            this.pageReady = true;
          }
        }

        this.CompletedLoading = true;
            

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }
  
  insertDate()
  {
    if(this.TimeSet.isdefault)
    {
      this.messageService.add({severity:'warn', summary:'Invalid Action', detail:'You cannot add dates to a Default Time Set'});
    }
    else
    {
      if(this.holidayOpeningStartDate <= this.holidayOpeningEndDate)
      {
        this.timesetDatesValid = true;
        let newUUID = v4();
        let newHO: holidayopenings = {
          id: newUUID,
          dateFrom: this.castToJsonDate(this.holidayOpeningStartDate),
          dateTo: this.castToJsonDate(this.holidayOpeningEndDate)
        }
        this.TimeSet.holidayopenings.push(newHO);

        this.holidayOpeningStartDate = null;
        this.holidayOpeningEndDate = null;

        this.messageService.add({severity:'success', summary:'Saved', detail:'Date successfully added - Please click "Save Changes" to save your changes'});
      }
      else
      {
        this.timesetDatesValid = false;
        this.messageService.add({severity:'error', summary:'Not Added', detail:'Your Start Date must be earlier than your End Date'});
      }
    }
  }

  castToJsonDate(inDate)
  {
    let day = inDate.getDate();
    let month = inDate.getMonth() + 1;
    let year = inDate.getFullYear();

    let returnDate = year + "-" + month.toString().padStart(2, '0') + "-" + day.toString().padStart(2, '0') + "T00:00:00.000Z";

    return returnDate;
  }

  updateData()
  {
    if (this.DoValidCheck())
    {
      if(this.id==null)
      {
        this.http.post<timesets>(bmxurl + "/timesets", this.TimeSet).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Time Set successfully created'});
            this.router.navigateByUrl("/timesets");
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages[0]});
        })
      }
      else
      {
        this.TimeSet.disabled = false;
        
        this.http.put<timesets>(bmxurl + "/timesets", this.TimeSet).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Time Set successfully updated'});
            this.router.navigateByUrl("/timesets");
          }
          else
          {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:this.httpResponse.messages[0]});
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }
  }

  deleteData(id){
    this.TimeSet.holidayopenings = this.TimeSet.holidayopenings.filter(obj => obj.id !== id);
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.TimeSet.name == null || this.TimeSet.name == "")
    {
      AllValid = false;
      this.timesetNameValid = false;
    }
    else
    {
      this.timesetNameValid = true;
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/timesets");
  }
}



//dateFrom: new Date(new Date(this.holidayOpeningStartDate).getUTCFullYear(), //
//new Date(this.holidayOpeningStartDate).getUTCMonth(), 
//new Date(this.holidayOpeningStartDate).getUTCDate()),

//dateTo: this.holidayOpeningEndDate()