import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { tasktype_returndata } from 'src/types/tasktype_returndata'; 
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { classes } from 'src/types/class';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';


@Component({
  selector: 'app-tasktypelist',
  templateUrl: './tasktypelist.component.html',
  styleUrls: ['./tasktypelist.component.css']
})
export class TasktypelistComponent {

  ListTasks: any;
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  DisabledClass: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 
    }

    ngOnInit(): void {

      this.globalService.setSmallScreenMenu(false);
      
      localStorage.removeItem("screenMemoryBookingss");
      localStorage.removeItem("screenMemoryEvents");
      
      let userid = localStorage.getItem("SmartUserID");
  
      this.http.get(bmxurl + "User/" + userid).subscribe(response => {
        this.httpResponse =  (response as users_returndata)
        if(this.httpResponse.succeeded)
        {
          let user = this.httpResponse.data;
  
          if(getAccess("TASKS", user)){
            this.AccessAllowed = true;
            this.loadgrid(false);
          }
  
          this.CompletedLoading = true;
  
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
      });
  
    }
  
    toggleDisabled(){
      this.loadgrid(!this.isDisabled);
    }
  
    disableData(TaskID)
    {
      if(confirm("Please note this will disable this task.  The task will no longer be added to new bookings.  However any bookings which already have this task will still have the task.  You will need to remove the task from the booking, using the task status page.  Are you sure you want to disable this task?"))
      {
        this.http.delete(bmxurl + "Task/" + this.VenueID + "/" + TaskID).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded == true)
          {
            this.messageService.add({severity:'success', summary:'Disabled', detail:'Task Type successfully disabled'});
            this.router.navigateByUrl("/tasklist");
    
            this.loadgrid(false);
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Class'});
        })
      }
    }
    
    loadgrid(showdisabled: boolean)
    {
      this.http.get(bmxurl + "TasktypeList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.httpResponse = response
        if(this.httpResponse.succeeded)
        {
          this.ListTasks = (response as tasktype_returndata);
  
          if ((response as tasktype_returndata).messages.length > 0)
          {
            (response as tasktype_returndata).messages.forEach(error => {
              this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
            });
          }
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error getting Tasks, error: ' + this.httpResponse.messages[0]});
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Tasks, error: ' + error.messages});
      });
    }
  
    editData(EditData)
    {
      let TaskID = EditData;
      this.router.navigateByUrl("/tasktypeedit/" + TaskID);
    }
  
    disableMulti()
    {
      if(confirm("Please note this will disable this task.  The task will no longer be added to new bookings.  However any bookings which already have this task will still have the task.  You will need to remove the task from the booking, using the task status page.  Are you sure you want to disable this task?"))
      {
        this.selectedItems.forEach(element => {
    
        this.http.delete(bmxurl + "Task/" + this.VenueID + "/" + element.tasktypeid).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.loadgrid(false);
          } 
          else{
            this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error disabling Tasks, error: ' + this.httpResponse.messages[0]});
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error disabling Tasks, error: ' + error.messages});
        })
        });
      }
    }
  

}
