import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { class_returndata } from 'src/types/class_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';

import { cTillproduct } from 'src/types/tillproduct';
import { tillproduct } from 'src/types/tillproduct';
import { tillproducts_returndata } from 'src/types/tillproduct_returndata';
import { departments_returndata } from 'src/types/departments_returndata';
import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';


@Component({
  selector: 'app-tillproductsedit',
  templateUrl: './tillproductsedit.component.html',
  styleUrls: ['./tillproductsedit.component.css']
})
export class TillproductseditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  Product: cTillproduct;
  httpResponse: any;
  id: string;
  pageReady: boolean = true;
  ListDepartments: any;

  productNameValid: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 
    this.Product = {
      id: null,
      name: "",
      venueid: this.VenueID,
      disabled: false,
      departmentId: "",
      tilldepartment: null,
      colour: "",
      price: 0,
      options: null
    }


  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("TILLDEPARTMENTS", user)){
          this.AccessAllowed = true;
          this.id = this.route.snapshot.paramMap.get('id');
    
    
          this.http.get(bmxurl + "TillDepartmentsList/" + this.VenueID + "/false").subscribe(response => {
            this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              this.ListDepartments = (response as departments_returndata).data;
      
              if(this.id != "0")
              {
                this.pageReady = false;
                this.http.get(bmxurl + "TillProduct/" + this.VenueID + "/" + this.id).subscribe(response => {
                  this.httpResponse = (response as tillproducts_returndata)
                  if (this.httpResponse.succeeded)
                  {
                    this.Product = {
                      id: (this.httpResponse.data as cTillproduct).id,
                      name: (this.httpResponse.data as cTillproduct).name,
                      venueid: (this.httpResponse.data as cTillproduct).venueid,
                      disabled: (this.httpResponse.data as cTillproduct).disabled,
                      departmentId: (this.httpResponse.data as cTillproduct).departmentId,
                      tilldepartment: (this.httpResponse.data as cTillproduct).tilldepartment,
                      colour: (this.httpResponse.data as cTillproduct).colour,
                      price: (this.httpResponse.data as cTillproduct).price,
                      options: null
                    };
          
                    this.pageReady = true;
                  }
                  else
                  {
                    this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting classes'});
                    this.pageReady = false;
                  }
                }, error => {
                  this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting classes, error:' + error.messages[0]});
                  this.pageReady = false;
                });
              }
      
            }
            else
            {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error getting Departments, error: ' + this.httpResponse.messages[0]});
            }
          }, error => {
            this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Departmnemts, error: ' + error.messages});
          });
        }

        this.CompletedLoading = true;
            

    
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


    

  }

  updateData()
  {
    if (this.DoValidCheck())
    {
       this.Product.departmentId = this.Product.tilldepartment.id;
      if(this.id == "0")
      {
        this.http.post<cTillproduct>(bmxurl + "/TillProduct", this.Product).subscribe(response => {
          this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              this.messageService.add({severity:'success', summary:'Saved', detail:'Class successfully updated'});
              this.router.navigateByUrl("/tillproducts/" + this.Product.departmentId);

              
            }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
          })
      }
      else
      {
        this.Product.disabled = false;
        
        this.http.put<cTillproduct>(bmxurl + "/TillProduct", this.Product).subscribe(response => {
        this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Class successfully updated'});
            this.router.navigateByUrl("/tillproducts/" + this.Product.departmentId);
          }
          }, error => {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.Product.name == null || this.Product.name == "")
    {
      AllValid = false;
      this.productNameValid = false;
    }
    else
    {
      this.productNameValid = true;
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/tillproducts");
  }
}
