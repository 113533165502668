<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed" class="p-3 flex flex-column flex-auto">
    <h3 style="margin-bottom: -38px" i18n>Bookings for: </h3>
    <div style="width: calc(100vw - 607px); float: left; margin-left: 178px; margin-bottom:20px;">
        <!-- <button pButton type="button"  pTooltip="Previous day"  style="height: 50px; margin-right: 5px;width: 53px; float:left;" (click)="dateChange(-1);" class="p-button-sm p-element p-ripple p-button p-component">
            <span class="pi pi-angle-left" style="margin-right:8px;"></span>
        </button> -->
        <div style="float:left; width: 200px;">
            <p-calendar [selectOtherMonths]="true" [(ngModel)]="BookingDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>  
        </div> 
        <!-- <button pButton type="button" pTooltip="Next day" style="height: 50px; margin-left: 5px;width: 53px; float:left;" (click)="dateChange(1);" class="p-button-sm p-element p-ripple p-button p-component">
            <span class="pi pi-angle-right" style="margin-right:8px;"></span>
        </button> -->
        &nbsp;
        <div style="float:left; width: 90px; margin-top: -24px;">
            <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" style="background-color: #22C55E;border-color: #22C55E;margin-top: 24px !important;margin-left: 12px;" (click)="doneDate()">
                Go
              </button>
        </div> 
        <div style="float:left; width: 90px;">
        <button *ngIf="ListBookingsGlobal"
            type="button"
            pButton
            pRipple
            icon="pi pi-file-excel"
            (click)="paymnentsTable.exportCSV()"
            class="mr-2"
            pTooltip="Export to CSV"
            tooltipPosition="bottom">
        </button>
        </div>
    </div>

    <div class="surface-card p-4 shadow-2 border-round">
        <p-table  #resourceTable 
        [columns]="cols"
        [value]="ListBookingsGlobal"
        responsiveLayout="scroll"
        [selectionPageOnly]="true">

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    <span *ngIf="col.header != 'Total' && col.header != 'Guests' && col.header != 'Outstanding'">{{col.header}}</span>
                    <span style="text-align: right; width:100%" *ngIf="col.header == 'Total'">{{col.header}}</span>
                    <span style="text-align: right; width:100%" *ngIf="col.header == 'Guests'">{{col.header}}</span>
                    <span style="text-align: right; width:100%" *ngIf="col.header == 'Outstanding'">{{col.header}}</span>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns">
                    <span *ngIf="col.header != 'Total' && col.header != 'Guests' && col.header != 'Outstanding'">{{ rowData[col.field] }}</span>
                    <span style="text-align: right; width:100%" *ngIf="col.header == 'Total'">{{ rowData[col.field]  | currency: CurrencySymbol }}</span>
                    <span style="text-align: right; width:100%" *ngIf="col.header == 'Guests'">{{ rowData[col.field]}}</span>
                    <span style="text-align: right; width:100%" *ngIf="col.header == 'Outstanding'">{{ rowData[col.field]  | currency: CurrencySymbol }}</span>
                </td>

            </tr>

        </ng-template>

        <ng-template  pTemplate="footer">
            <tr *ngIf="ListBookingsGlobal.length == 0 || ListBookingsGlobal == null">
                <td style="text-align: center;" colspan="8">
                    There is no data for the selected date range.
                </td>
            </tr>
            <tr *ngIf="ListBookingsGlobal.length > 0 && ListBookingsGlobal != null">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style="text-align: right;">
                    <b>{{guests}}</b>
                <td style="text-align: right;">
                    <b>{{total | currency: CurrencySymbol}}</b>
                </td>
                <td></td>
                <td></td>
            </tr>
        </ng-template>

    </p-table>
    </div>
</div>
