import { Component, OnInit } from '@angular/core';
import { cTerms } from 'src/types/terms';
import { HttpClient } from '@angular/common/http';
import { bmxurl } from 'src/globals';
import { cBookingfee } from 'src/types/bookingfee';
import { MessageService } from 'primeng/api';
import { Router, RouterLink } from '@angular/router';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-publictermsandconditions',
  templateUrl: './publictermsandconditions.component.html',
  styleUrls: ['./publictermsandconditions.component.css']
})
export class PublictermsandconditionsComponent implements OnInit {

  httpResponse: any;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  tandc: cTerms;
  id: string;
  pageReady: boolean = true;
  constructor(private http: HttpClient, private messageService: MessageService, private router: Router, private globalService: GlobalService) { }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    this.http.post<cBookingfee>(bmxurl + "/terms/" + this.VenueID, this.tandc).subscribe(response => {
      this.httpResponse = response
        if(this.httpResponse.succeeded)
        {
          this.messageService.add({severity:'success', summary:'Saved', detail:'Terms and Conditions successfully updated'});
          this.router.navigateByUrl("/termsandconditions");
        }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
      })


  }

}
