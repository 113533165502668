import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { class_returndata } from 'src/types/class_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cClass, classes } from 'src/types/class';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';

import { users_returndata } from 'src/types/user_returndata';
import { cTasktype } from 'src/types/tasktype';
import { emailtemplate_returndata } from 'src/types/emailtemplate_returndata';
import { PackagestarttimesComponent } from '../packagestarttimes/packagestarttimes.component';
import { cPackage } from 'src/types/package';
import { v4 } from 'uuid';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-tasktypeedit',
  templateUrl: './tasktypeedit.component.html',
  styleUrls: ['./tasktypeedit.component.css']
})
export class TasktypeeditComponent {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  TaskType: cTasktype;
  httpResponse: any;
  id: string;
  pageReady: boolean = true;
  taskNameValid: boolean = true;
  taskDescValid: boolean = true;
  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  taskPurposes: any[];
  taskOptions: any[];
  taskTypevalue: any = { label: 'Remind', value: "REMIND", icon: "pi pi-bell" }
  EmailTemplates: any[] = [];
  selectedEmailTemplate: any;
  taskfrequency: any[];
  taskEmailTemplateValid: boolean = true;
  stateOptionsDiscount: any[];
  stateOptionsPackages: any[];

  packages: any[] = [];
  selectedPackages: any[] = [];
  allPackages: boolean = true;
  needSelectedPackages: boolean = false;
  VoucherExpiry: number = 1;


  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 
      this.TaskType = {
        id: null,
        tasktypeid: null,
        name: "",
        venueid: this.VenueID,
        disabled: false,
        desc: "",
        tasktype: "REMIND",
        noofdays: 1,
        emailtemplate: null,
        frequency: "NOREPEAT",
        packagelist: [],
        discounttype: "PERCENTAGE",
        discountamount: 0,
        voucherexpiry: 1
      }

      this.taskfrequency = [{ label: 'No Repeat', value: "NOREPEAT" }, { label: 'Every Day', value: "DAILY" }, { label: 'Every other day', value: "EVERYOTHERDAY" }, { label: 'Weekly', value: "WEEKLY" }, { label: 'Every month', value: "MONTHLY"}, { label: 'Every 3 months', value: "EVERY3MONTHS"}];
      this.taskOptions = [{ label: 'Remind', value: "REMIND", icon: "pi pi-bell" }, { label: 'Collect', value: "COLLECT", icon: "pi pi-pound"  }, { label: 'Thank', value: "THANK", icon: "pi pi-heart"  }, { label: 'Feedback', value: "FEEDBACK", icon: "pi pi-thumbs-up"  }];
      this.stateOptionsDiscount = [{ label: 'Percentage', value: "PERCENTAGE" }, { label: 'Fixed Amount', value: "FIXED" }];
      this.stateOptionsPackages = [{ label: 'All Packages', value: true }, { label: 'Select Packages', value: false }];
    }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);
    
    this.pageReady = false;
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("TASKS", user)){
          this.AccessAllowed = true;        

          this.id = this.route.snapshot.paramMap.get('id');
          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "tasktype/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as class_returndata)
              if (this.httpResponse.succeeded)
              {
                this.TaskType = {
                  id: (this.httpResponse.data as cTasktype).id,
                  tasktypeid: (this.httpResponse.data as cTasktype).tasktypeid,
                  name: (this.httpResponse.data as cTasktype).name,
                  venueid: (this.httpResponse.data as cTasktype).venueid,
                  disabled: (this.httpResponse.data as cTasktype).disabled,
                  desc: (this.httpResponse.data as cTasktype).desc,
                  tasktype: (this.httpResponse.data as cTasktype).tasktype,
                  noofdays: (this.httpResponse.data as cTasktype).noofdays,
                  emailtemplate: (this.httpResponse.data as cTasktype).emailtemplate,
                  frequency: (this.httpResponse.data as cTasktype).frequency,
                  packagelist: (this.httpResponse.data as cTasktype).packagelist,
                  discounttype: (this.httpResponse.data as cTasktype).discounttype,
                  discountamount: (this.httpResponse.data as cTasktype).discountamount,
                  voucherexpiry: (this.httpResponse.data as cTasktype).voucherexpiry
                };

                this.http.get(bmxurl + "/packagelist/" + this.VenueID + "/false").subscribe(response => {
                  this.httpResponse = response
                  if(this.httpResponse.succeeded)
                  {
                    this.packages = (response as class_returndata).data;

                    this.TaskType.packagelist.forEach(element => {
                      let newpack = this.packages.filter(obj => obj.packageId == element);
                      if(newpack != null && newpack.length > 0)
                      {
                        let existingpack = this.selectedPackages.filter(obj => obj.packageId == element);

                        if(existingpack.length == 0)
                        {
                          this.selectedPackages.push(newpack[0]);
                        }
                      }
                    });

                    this.selectedPackages.forEach(element => {
                      this.packages = this.packages.filter(obj => obj.id !== element.id);
                    });

                    if(this.selectedPackages.length == 0)
                    {
                      this.allPackages = true;
                    }
                    else
                    {
                      this.allPackages = false;
                    }
          
                    this.pageReady = true;   
                  }
                  else
                  {
                    this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Packages'});
                    this.pageReady = false;
                  }
                }, error => {
                  this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Packages, error:' + error.messages[0]});
                  this.pageReady = false;
                });
           
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Task Types'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Task Types, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
          else
          {
            this.http.get(bmxurl + "/packagelist/" + this.VenueID + "/false").subscribe(response => {
              this.httpResponse = response
              if(this.httpResponse.succeeded)
              {
                this.packages = (response as class_returndata).data;
      
                this.pageReady = true;   
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Packages'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Packages, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
        }

        this.CompletedLoading = true;
        this.pageReady = true;
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

    this.http.get(bmxurl + "/EmailTemplateList/" + this.VenueID + "/false").subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.EmailTemplates = (response as emailtemplate_returndata).data;
      }
      else
      {
        this.messageService.add({severity:'warn', summary:'Response Message', detail: this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Email Templates, error: ' + error.messages});
    });

  }
  
  AddToList(product){
    if(this.packages == null)
    {
      let newId = v4();
      let newpeId = v4();

      this.selectedPackages = [];
      let newPackage: cPackage = {
        id: product.id,
        packageId: product.packageId,
        name: product.name,
        eventid: product.eventid,
        description: product.description,
        imageurl: product.imageurl,
        message: product.message,
        disabled: product.disabled,
        venueID: this.VenueID,
        resources: product.resources,
        startTimes: product.startTimes,
        admissions: product.admissions,
        packageLength: product.packageLength,
        questionnaire: product.questionnaire,
        visibility: product.visibility,
        min:product.min,
        max:product.max,
        tags: product.tags,
        startDate: product.startDate,
        endDate: product.endDate,
        colour: product.colour,
        packageRule: product.packageRule,
        packageRestriction: product.packageRestriction,
        extras: product.extras,
        publicDepositPolicy: product.publicDepositPolicy,
        venueDepositPolicy: product.venueDepositPolicy,
        additionalresources: product.additionalresources,
        confirmation: product.confirmation,
        warning: product.warning,
        originatingid: product.originatingid,
        bookingscannotbemadewithinxminutes: product.bookingscannotbemadewithinxminutes,
        bookingscannotbemadeafterxminutes: product.bookingscannotbemadeafterxminutes,
        cannotmakebookingstoday: product.cannotmakebookingstoday,
        discounttype: product.discounttype,
        discountamount: product.discountamount,
        discountcode: product.discountcode,
        discountstart: product.discountstart,
        discountend: product.discountend,
        earlybirddiscount: product.earlybirddiscount,
        discountrangetype: product.discountrangetype,
        discountdaysbefore: product.discountdaysbefore,
        imageguid: product.imageguid,
        bookingtimeout: product.bookingtimeout,
        maxcalendarmonths: product.maxcalendarmonths
      }

      this.selectedPackages.push(newPackage);
    }
    else{
      let newId = v4();
      let newpeId = v4();

      let newPackage: cPackage = {
        id: product.id,
        packageId: product.packageId,
        name: product.name,
        eventid: product.eventid,
        description: product.description,
        imageurl: product.imageurl,
        message: product.message,
        disabled: product.disabled,
        venueID: this.VenueID,
        resources: product.resources,
        startTimes: product.startTimes,
        admissions: product.admissions,
        packageLength: product.packageLength,
        questionnaire: product.questionnaire,
        visibility: product.visibility,
        min:product.min,
        max:product.max,
        tags: product.tags,
        startDate: product.startDate,
        endDate: product.endDate,
        colour: product.colour,
        packageRule: product.packageRule,
        packageRestriction: product.packageRestriction,
        extras: product.extras,
        publicDepositPolicy: product.publicDepositPolicy,
        venueDepositPolicy: product.venueDepositPolicy,
        additionalresources: product.additionalresources,
        confirmation: product.confirmation,
        warning: product.warning,
        originatingid: product.originatingid,
        bookingscannotbemadewithinxminutes: product.bookingscannotbemadewithinxminutes,
        bookingscannotbemadeafterxminutes: product.bookingscannotbemadeafterxminutes,
        cannotmakebookingstoday: product.cannotmakebookingstoday,
        discounttype: product.discounttype,
        discountamount: product.discountamount,
        discountcode: product.discountcode,
        discountstart: product.discountstart,
        discountend: product.discountend,
        earlybirddiscount: product.earlybirddiscount,
        discountrangetype: product.discountrangetype,
        discountdaysbefore: product.discountdaysbefore,
        imageguid: product.imageguid,
        bookingtimeout: product.bookingtimeout,
        maxcalendarmonths: product.maxcalendarmonths
      }

      this.selectedPackages.push(newPackage);
    }

    this.packages = this.packages.filter(obj => obj.id !== product.id);
  }

  RemoveFromList(product){
    this.selectedPackages = this.selectedPackages.filter(obj => obj.id !== product.id);

    this.packages.push(product);

    var sortedArray:any[] = this.packages.sort((n1,n2) => {
      if (n1.name > n2.name) {
          return 1;
      }
  
      if (n1.name < n2.name) {
          return -1;
      }
    });

    this.packages = sortedArray;
  }

  updateData()
  {
    if(this.allPackages)
    {
      this.selectedPackages = [];
    }

    if(this.allPackages == false && this.packages.length == 0)
    {
      this.needSelectedPackages = true;
    }
    else
    {
      this.needSelectedPackages = false;
    }

    if(!this.needSelectedPackages)
    {
      if (this.DoValidCheck())
      {
        this.TaskType.packagelist = [];

        this.selectedPackages.forEach(element => {
          this.TaskType.packagelist.push(element.packageId);
        });

        if(this.id == "0")
        {
          this.http.post<cClass>(bmxurl + "/tasktype", this.TaskType).subscribe(response => {
            this.httpResponse = response
              if(this.httpResponse.succeeded)
              {
                this.messageService.add({severity:'success', summary:'Saved', detail:'Task Type successfully created'});
                this.router.navigateByUrl("/tasktypelist");
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Not Saved', detail:this.httpResponse.messages[0]});
              }
              }, error => {
                this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
            })
        }
        else
        {
          this.TaskType.disabled = false;
          
          this.http.put<cClass>(bmxurl + "/tasktype", this.TaskType).subscribe(response => {
          this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              this.messageService.add({severity:'success', summary:'Saved', detail:'Task Type successfully updated'});
              this.router.navigateByUrl("/tasktypelist");
            }
            else
            {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:this.httpResponse.messages[0]});
            }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
          })
        }
      }
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.TaskType.name == null || this.TaskType.name == "")
    {
      AllValid = false;
      this.taskNameValid = false;
    }
    else
    {
      this.taskNameValid = true;
    }

    if(this.TaskType.emailtemplate == null )
    {
      AllValid = false;
      this.taskEmailTemplateValid = false;
    }
    else
    {
      this.taskEmailTemplateValid = true;
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/tasktypelist");
  }
}
