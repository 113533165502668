<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed" class="p-3 flex flex-column flex-auto">
    <h3>Customers: </h3>
    
    <div style="clear:both;"></div>

    <div class="surface-card p-4 shadow-2 border-round">
        <p-table  #paymnentsTable 
        [columns]="cols"
        [value]="ListDataGlobal" sortField="name" 
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
        [globalFilterFields]="['name', 'bookingId', 'bookingIdHR', 'packageName']"
        responsiveLayout="scroll">
        <ng-template pTemplate="caption">
            <div class="flex">
            <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-excel"
                (click)="paymnentsTable.exportCSV()"
                class="mr-2"
                pTooltip="Export to CSV"
                tooltipPosition="bottom">
            </button>
            </div>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td>{{rowData.firstName}}</td>
                <td>{{rowData.lastName}}</td>
                <td>{{rowData.email}}</td>
                <td>{{rowData.address1}}</td>
                <td>{{rowData.address2}}</td>
                <td>{{rowData.townCity}}</td>
                <td>{{rowData.postCode}}</td>
                <td>{{rowData.mobile}}</td>
                <td>{{rowData.agreedToTandC}}</td>
                <td>{{rowData.agreedToEmailMarketing}}</td>
                <td>{{rowData.agreedToSMSMarketing}}</td>
            </tr>

        </ng-template>

    </p-table>
    </div>
</div>
