
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, AfterViewInit, Component, Inject, OnInit, ViewChild, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { MenuItem } from 'primeng/api/menuitem';
import { bmxurl } from 'src/globals';
import { cVenuesettings, venuesettings } from 'src/types/venuesettings';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';

import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { b2cPolicies } from './auth-config';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { jwtdata } from 'src/types/JWTdata';
import { venue_returndata } from 'src/types/venue_returndata';
import { DOCUMENT, getLocaleNumberSymbol } from '@angular/common';
import { businessVenue } from 'src/types/businessVenues';
import { GlobalService } from './_services/global.service';
import { user } from 'src/types/users';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { options } from '@fullcalendar/core/preact';
import { BusyService } from './_services/busy.service';
import { environment } from 'src/environments/environment';
import { pinchange_returndata } from 'src/types/pinchange_returndata';
import { pinchange } from 'src/types/pinchange';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {

  httpResponse: any;
  loginDisplay = false;
  Venue: any;
  vsVenueName: string;
  multiVenueUser: boolean;
  businessOwner: boolean;
  PasswordsMatch: boolean = true;
  showPasswordChange: boolean = false;
  OldPIN: string;
  NewPIN: string;
  RepeatNewPIN: string;

  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  ShowTimeOut: boolean = false;
  @Input() timeout: number = 600;
  bcitems: MenuItem[] = [];c
  home: MenuItem;
  ShowBC: boolean = false;
  MenuBuilder: [string, string];
  Users: user[];
  prodmode: boolean = false;
  version: string = environment.version;
  ShowChangePassword = false;
  BackendVersion: string = "";
  searchCriteria: string = "";
  UseCounters: boolean = false;

  EmailsRemaining: number = 0;
  showEmailWarning: boolean = false;

  SMSsRemaining: number = 0;
  showSMSWarning: boolean = false;

  showMenu: boolean = true;
  showHamburger: boolean = false;

  showSmallScreenHamburger: boolean = false;

  screenwidth: number = window.innerWidth;
  showSmallScreenMenu: boolean = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private router: Router,
    private messageService: MessageService,
    public auth: AuthService,
    @Inject(DOCUMENT) private doc: Document,
    private globalService: GlobalService,
    private idle: Idle, 
    keepalive: Keepalive, 
    cd: ChangeDetectorRef,
    private busyService: BusyService
  ) 
  {

    addEventListener("resize", (event) => {
      this.screenwidth = window.innerWidth;

      if(this.screenwidth <= 1100)
      {
        this.showMenu = false;
        this.showHamburger = false;

        this.showSmallScreenHamburger = true;
      }
      else
      {
        this.showMenu = true;

        this.showHamburger = true;
        this.showSmallScreenHamburger = false;
      }
    });

    if(this.screenwidth <= 1100)
      {
        this.showMenu = false;
        this.showHamburger = false;

        this.showSmallScreenHamburger = true;
      }
      else
      {
        this.showMenu = true;

        this.showHamburger = true;
        this.showSmallScreenHamburger = false;
      }


      localStorage.clear();

      if(localStorage.getItem("AllowTimeOut") != null)
      {
        if(localStorage.getItem("AllowTimeOut") == "true")
        {
          if(localStorage.getItem("idleTimeout") != null)
          {
            if(Number(localStorage.getItem("idleTimeout")) > 0)
            {
              this.timeout = Number(localStorage.getItem("idleTimeout"));
            }
          }
        }
      }

      localStorage.getItem("AllowTimeOut") == "true" ? true : false
      //this.UseCounters = localStorage.getItem("UseCounter") == "true" ? true : false

      idle.setIdle(this.timeout); 
      idle.setTimeout(30); 
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); 

      idle.onIdleStart.subscribe(() => {
        if(router.url != "/pinpad" && router.url != "/" && router.url != "business")
          this.ShowTimeOut = true;
      });

      idle.onIdleEnd.subscribe(() => {
        this.ShowTimeOut = false;
        this.countdown = null;
        cd.detectChanges(); 
      });
      idle.onTimeout.subscribe(() => {
        this.ShowTimeOut = false;
        this.reset();
        //localStorage.setItem("SmartUserID",null);

        this.globalService.setShowPasswordChange(false);

        if(localStorage.getItem("businessowner") == "TRUE")
        {
          localStorage.clear();
          auth.logout({ logoutParams: { returnTo: 'https://' + this.doc.location.host } });
        }
        else
        {
          this.router.navigateByUrl("/pinpad");
        }
      });

      idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

      keepalive.interval(15); 
  }

  items: MenuItem[];
  event: any;


  doEmail(){
    let mailText = "mailto:enquiries@smart-entertainment.co.uk?subject=New WannaBook Enquiry";
    window.location.href = mailText;
  }

  checkEmails()
  {
    //check emails remaining
    this.EmailsRemaining = 144;
    this.showEmailWarning = this.EmailsRemaining < 150 ? true : false;
  }

  displayMenu(){

    this.globalService.showSmallScreenMenu$.subscribe((value) => {
      this.showSmallScreenMenu = value;
    });

    
    //this.showSmallScreenMenu = !this.showSmallScreenMenu;
    this.globalService.setSmallScreenMenu(true);
  }

  checkSMSs()
  {
    //check emails remaining
    this.SMSsRemaining = 37;
    this.showSMSWarning = this.SMSsRemaining < 150 ? true : false;
  }

  buyMoreSMSs(){
    this.router.navigateByUrl("/buycredits");
  }

  buyMoreEmails(){
    this.router.navigateByUrl("/buycredits");
  }

  logout(){
    //ask for confirmation
    if (confirm("Are you sure you want to log out?")) { 
      this.auth.logout({ logoutParams: { returnTo: 'https://' + this.doc.location.host } })
    }
  }

  logEntry(inText: string){
    // this.http.get(bmxurl + "/log?message=" + inText).subscribe(response => {
    //   this.httpResponse = response
    //   if (this.httpResponse.succeeded)
    //   {

    //   }
    //   else
    //   {
    //     this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Version Number'});
    //   }
    // }, error => {
    //   this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Version Number, error:' + error.messages[0]});
    // });

  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    this.logEntry("App Started");

    this.globalService.ShowPasswordChange.subscribe((value) => {
      this.ShowChangePassword = value;
    });

    if (environment.production) {
      this.prodmode = true;
    }
    else
    {
      this.prodmode = false;
    }

    //get version
    if (environment.production) {
      this.http.get(bmxurl + "/version").subscribe(response => {
        this.httpResponse = response
        if (this.httpResponse.succeeded)
        {
          this.logEntry("Version Check: " + this.httpResponse.data.version);
          if(this.version != this.httpResponse.data.version)
          {
            alert("Versions don't match!");
            location.reload();  
          }
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Version Number'});
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Version Number, error:' + error.messages[0]});
      });
    }

    localStorage.removeItem("SmartVenueID");
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {

        if(this.router.url != "/pinpad" && this.router.url != "/" && this.router.url != "/business")
        {
          this.ShowBC = true;
        }
        else
        {
          this.ShowBC = false;
        }
      }
    });

    this.logEntry("Built Navigation");

    this.home = {icon: 'pi home'};

    const head = document.getElementsByTagName('head')[0];

    const script = document.createElement('script');

    head.insertBefore(script, head.firstChild);
    //#region 
    let AccessControl = "";
    this.auth.isAuthenticated$.subscribe((authed) => {
      if(authed)
      {
        this.auth.getAccessTokenSilently().subscribe(token => {
          const parts = token.split('.');
          const decoded = JSON.parse(atob(parts[1]));

          this.logEntry("Business ID: " + decoded["user.metadata"].businessid);
          this.logEntry("Business User ID: " + decoded["user.metadata"].businessuserid);
          this.logEntry("Venue Name: " + decoded["user.metadata"].venuename);
          this.logEntry("Business Owner: " + decoded["user.metadata"].businessowner);
          this.logEntry("Multi-venue User: " + decoded["user.metadata"].multivenueuser);
          
          let BusinessID = decoded["user.metadata"].businessid;
          let VenueID = decoded["user.metadata"].vid;
          //let VenueID = localStorage.getItem("SmartVenueID")!;

          localStorage.setItem("SmartVenueID", VenueID);

          AccessControl  = decoded["user.metadata"].accesscontrol;
          let userid = decoded["user.metadata"].businessuserid;
          let venuename = decoded["user.metadata"].venuename;
          let businessid = decoded["user.metadata"].businessid;
          let multivenueuser = decoded["user.metadata"].multivenueuser;

          localStorage.setItem("SmartUserID", userid);
          localStorage.setItem("SmartBusinessID", businessid);

          localStorage.setItem("SmartVenueName", venuename);
 
          let users: user[] =  (decoded["app.users"] as user[]);
          let venues: businessVenue[] = (decoded["app.venues"] as businessVenue[]);

          let businessowner = decoded["user.metadata"].businessowner;
          if(businessowner == "true")
          {
            localStorage.setItem("businessowner", "TRUE");
            this.businessOwner = true;
          }
          else
          {
            localStorage.setItem("businessowner", "FALSE");
            this.businessOwner = false;
          }

          //if (venues.length > 1)
          if(multivenueuser == "true")
          {
            localStorage.setItem("SmartMultiVenueUser", "TRUE");
            this.multiVenueUser = true;
          }
          else
          {
            localStorage.setItem("SmartMultiVenueUser", "FALSE");
            this.multiVenueUser = false;
          }
          
          localStorage.setItem("SmartBusinessVenues", JSON.stringify(venues));
          localStorage.setItem("SmartUsers", JSON.stringify(users));

          if((BusinessID != null || BusinessID != "") && VenueID == null) 
          {

            if(this.businessOwner)
            {
              this.logEntry("Business Owner so create menu");

              localStorage.setItem("SmartBusinessID", BusinessID);
              this.items = [{
                label: 'BUSINESS',
                items: [
                  {label: 'Business', icon: 'pi globe', routerLink: ['/business'], visible: true},
                  ]
              }];
              this.router.navigateByUrl("/business");

              this.globalService.setMenuItems(this.items);
            }
            else
            {

              this.logEntry("Non-Business Owner so go to pinpad");

              localStorage.setItem("SmartBusinessID", BusinessID);
              this.items = [{
                label: '',
                items: [
                 
                  ]
              }];

              this.globalService.setMenuItems(this.items);

              this.router.navigateByUrl("pinpad");
            }

          }
          else
            {

              localStorage.setItem("SmartBusinessID", BusinessID);
              this.items = [{
                label: '',
                items: []
              }];

              this.globalService.setMenuItems(this.items);

              this.router.navigateByUrl("pinpad");
            }

        });
      }
    });

    this.globalService.menuItems$.subscribe((value) => {
      this.items = value;
    });

    this.globalService.menuBCItem$.subscribe((value) => {
      
      let newitems = [{
        label: '',
        items: []
      }];

      newitems[0].items.push(value);

      this.bcitems = newitems;
    });

    this.globalService.venueName$.subscribe((value) => {
      this.vsVenueName = value;
    });

    this.globalService.useCounters$.subscribe((value) => {
      this.UseCounters = value;
    });


    this.http.get(bmxurl + "v").subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded){
        this.BackendVersion = this.httpResponse.data.version;
      }
      else{
        //this.messageService.add({severity:'error', summary:'Error', detail: 'Error getting version, error: ' + this.httpResponse.messages[0]});
      }

    }, error => {
      //this.messageService.add({severity:'error', summary:'Error', detail: 'Error getting version, error: ' + error.messages});
    });

    this.reset();

  }

  getSearchResults(){
    if(this.searchCriteria != "")
    {
      localStorage.removeItem("SearchResults");
      let crit = this.searchCriteria;

      crit = crit.trim();

      crit = crit.replace("/","-").replace("/","-");
      crit = encodeURIComponent(crit);

      this.searchCriteria = "";
      this.router.navigate(
        ['/search'],
        { queryParams: { crit: crit } }
      );
    }
  }

  slideInMenu(){
    //const leftMenu = document.getElementById("app-sidebar-1");
    //leftMenu.style.display = "block !important"
  }

  reset() {
    this.idle.watch();
    this.ShowTimeOut = false;
    this.countdown = null;
    this.lastPing = null;
  }

  backToVenues(){
    localStorage.removeItem("SmartVenueID");
    this.router.navigateByUrl("/business");
  }

  backToPin(){
    this.auth.getAccessTokenSilently({cacheMode: 'off'}).subscribe(token => {
      this.busyService.busy();
      const parts = token.split('.');
      const decoded = JSON.parse(atob(parts[1]));
      let BusinessID = decoded["user.metadata"].businessid;
      let VenueID = decoded["user.metadata"].vid;

      localStorage.setItem("SmartVenueID", VenueID);

      let userid = decoded["user.metadata"].businessuserid;
      let venuename = decoded["user.metadata"].venuename;
      let businessid = decoded["user.metadata"].businessid;
      let multivenueuser = decoded["user.metadata"].multivenueuser;

      localStorage.setItem("SmartUserID", userid);
      localStorage.setItem("SmartBusinessID", businessid);

      localStorage.setItem("SmartVenueName", venuename);

      let users: user[] =  (decoded["app.users"] as user[]);
      let venues: businessVenue[] = (decoded["app.venues"] as businessVenue[]);

      
      let businessowner = decoded["user.metadata"].businessowner;
      if(businessowner == "true")
      {
        localStorage.setItem("businessowner", "TRUE");
        this.businessOwner = true;
      }
      else
      {
        localStorage.setItem("businessowner", "FALSE");
        this.businessOwner = false;
      }
      
      //if (venues.length > 1)
      if(multivenueuser == "true")
      {
        localStorage.setItem("SmartMultiVenueUser", "TRUE");
      }
      else
      {
        localStorage.setItem("SmartMultiVenueUser", "FALSE");
      }
        
      localStorage.setItem("SmartBusinessVenues", JSON.stringify(venues));
      localStorage.setItem("SmartUsers", JSON.stringify(users));

      this.globalService.setVenueName(venuename);

      this.globalService.setMenuItems([]);
      
      this.auth.isAuthenticated$.subscribe((authed) => {
        if(authed)
        {
          this.router.navigateByUrl("/pinpad");
        }
      });

      this.busyService.idle();
      
    }),
    error => {
      this.busyService.idle();
    };

  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  manageUserPassword(){
    this.showPasswordChange = true;
  }

  doPINChange(){
    if(this.NewPIN == this.RepeatNewPIN)
    {
      let pinChange: pinchange = {
        oldpin: this.OldPIN,
        newpin: this.NewPIN
      }

      this.http.post<pinchange_returndata>(bmxurl + "/PinChange", pinChange).subscribe(response => {
        this.httpResponse = response
        if(this.httpResponse.succeeded == true)
        {
          this.messageService.add({severity:'success', summary:'Success', detail:'Your PIN has been changed.'});

          this.RepeatNewPIN = "";
          this.OldPIN = "";
          this.NewPIN = "";

          this.showPasswordChange = false;

          this.router.navigateByUrl("/home");
        }
        else{
          this.messageService.add({severity:'error', summary:'Error', detail:this.httpResponse.messages[0]});
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:error.messages[0]});
      })
    }
    else
    {
      this.PasswordsMatch = false;
    }
  }



}
