import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { class_returndata } from 'src/types/class_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cClass, classes } from 'src/types/class';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';
import { admission, cAdmission } from 'src/types/admission';
import { admission_returndata } from 'src/types/admission_returndata';

import { GlobalService } from '../_services/global.service';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-admissionedit',
  templateUrl: './admissionedit.component.html',
  styleUrls: ['./admissionedit.component.css']
})

export class AdmissioneditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  Admission: cAdmission;
  httpResponse: any;
  id: string;
  classes: cClass[];
  pageReady: boolean = false;
  stateOptions: any[];
  PageTitle: string = "Edit Admission";

  admissionNameValid: boolean = true;
  admissionBWTValid: boolean = true;
  admissionMaxValid: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) {
    this.stateOptions = [{ label: 'No', value: false }, { label: 'Yes', value: true }];
    
    this.Admission = {
      id: null,
      name: "",
      bookingwizardtext: "",
      fixedPrice: false,
      participant: true,
      venueid: this.VenueID,
      admissionid: null,
      noofpeople: 0,
      price: 0,
      admissionClass: null,
      min: 0,
      max: 0,
      index: 0,
      disabled: false,
      mustinclude: false,
      classid: null,
      eventtickettype: null,
      counter: [],
      uselinkeddiscounts: false,
      linkedadmissions: [],
      linkeddiscounts: [],
      uselinkedadmissions: false,
      maxlinkeddiscounts: 1,
      maxlinkedadmissions: 1,
      countstowardsmax:true,
      countstowardsmin: true,
      message: "",
      fixed: false,
      onlysetmax: false
    };

   }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("ADMISSIONS", user)){
          this.AccessAllowed = true;

          this.globalService.setBCItem(['Admission Edit','/admissionedit']);
    
          this.id = this.route.snapshot.paramMap.get('id');
      
          if(this.id != "0")
            {
            this.http.get(bmxurl + "admission/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as admission_returndata)
              if (this.httpResponse.succeeded)
              {
                this.Admission = {
                  id: (this.httpResponse.data as cAdmission).id,
                  name: (this.httpResponse.data as cAdmission).name,
                  bookingwizardtext: (this.httpResponse.data as cAdmission).bookingwizardtext,
                  fixedPrice: (this.httpResponse.data as cAdmission).fixedPrice,
                  participant: (this.httpResponse.data as cAdmission).participant,
                  venueid:  (this.httpResponse.data as cAdmission).venueid,
                  admissionid:  (this.httpResponse.data as cAdmission).admissionid,
                  noofpeople:  (this.httpResponse.data as cAdmission).noofpeople,
                  price:  (this.httpResponse.data as cAdmission).price,
                  admissionClass:  (this.httpResponse.data as cAdmission).admissionClass,
                  min:  (this.httpResponse.data as cAdmission).min,
                  max:  (this.httpResponse.data as cAdmission).max,
                  index:  (this.httpResponse.data as cAdmission).index,
                  disabled: (this.httpResponse.data as cAdmission).disabled,
                  mustinclude:  (this.httpResponse.data as cAdmission).mustinclude,
                  classid: (this.httpResponse.data as cAdmission).classid,
                  eventtickettype: (this.httpResponse.data as cAdmission).eventtickettype,
                  counter: (this.httpResponse.data as cAdmission).counter,
                  uselinkeddiscounts: (this.httpResponse.data as cAdmission).uselinkeddiscounts,
                  linkedadmissions: (this.httpResponse.data as cAdmission).linkedadmissions,
                  linkeddiscounts: (this.httpResponse.data as cAdmission).linkeddiscounts,
                  uselinkedadmissions: (this.httpResponse.data as cAdmission).uselinkedadmissions,
                  maxlinkeddiscounts: (this.httpResponse.data as cAdmission).maxlinkeddiscounts,
                  maxlinkedadmissions: (this.httpResponse.data as cAdmission).maxlinkedadmissions,
                  countstowardsmax:  (this.httpResponse.data as cAdmission).countstowardsmax,
                  countstowardsmin:  (this.httpResponse.data as cAdmission).countstowardsmin,
                  message: (this.httpResponse.data as cAdmission).message,
                  fixed: (this.httpResponse.data as cAdmission).fixed,
                  onlysetmax: (this.httpResponse.data as cAdmission).onlysetmax
                };
      
                this.http.get(bmxurl + "ClassList/" + this.VenueID + "/false").subscribe(response => {
                  this.classes = (response as class_returndata).data;
                }, error => {
                });
      
                this.pageReady = true;
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting admissions'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting classes, error:' + error.messages[0]});
              this.pageReady = false;
            });
              
          }
          else
          {
            this.http.get(bmxurl + "ClassList/" + this.VenueID + "/false").subscribe(response => {
              this.classes = (response as class_returndata).data;
            }, error => {
            });
            
            this.pageReady = true;
          }
        }

        this.CompletedLoading = true;
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  updateData()
  {
    if (this.DoValidCheck())
    {
      if(this.id !== "0")
      {
        this.Admission.disabled = false;

        this.http.put<admission>(bmxurl + "/admission", this.Admission).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Admission successfully updated'});
            this.router.navigateByUrl("/admission");
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        });
      }
      else
      {
        this.http.post<admission>(bmxurl + "/admission", this.Admission).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Admission successfully created'});
            this.router.navigateByUrl("/admission");
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages[0]});
        })
      }
    }
  }


  DoValidCheck()
  {
    let AllValid = true;

    if(this.Admission.name == null || this.Admission.name == "")
    {
      AllValid = false;
      this.admissionNameValid = false;
    }
    else
    {
      this.admissionNameValid = true;
    }

    if(this.Admission.bookingwizardtext == null || this.Admission.bookingwizardtext == "")
    {
      AllValid = false;
      this.admissionBWTValid = false;
    }
    else
    {
      this.admissionBWTValid = true;
    }

    if(this.Admission.max < this.Admission.min)
    {
      AllValid = false;
      this.admissionMaxValid = false;
    }
    else
    {
      this.admissionMaxValid = true;
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/admission");
  }
}
