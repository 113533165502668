import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { class_returndata } from 'src/types/class_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cClass, classes } from 'src/types/class';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';

import { users_returndata } from 'src/types/user_returndata';
import { cWarning } from 'src/types/warnings';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-warningedit',
  templateUrl: './warningedit.component.html',
  styleUrls: ['./warningedit.component.css']
})
export class WarningeditComponent  implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  Warning: cWarning;
  httpResponse: any;
  id: string;
  pageReady: boolean = true;

  warningNameValid: boolean = true;
  warningVenueValid: boolean = true;
  warningPublicValid: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  WantPublicMessage: boolean = false;
  WantVenueMessage: boolean = false;

  atLeastOneWarning: boolean = true;

  stateOptions: any;
  
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 

    this.stateOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
    
    this.Warning = {
      id: null,
      warningid: null,
      name: "",
      venueid: this.VenueID,
      disabled: false,
      venuemessage: "",
      publicmessage: ""
    }
  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("CLASSES", user)){
          this.AccessAllowed = true;

          this.id = this.route.snapshot.paramMap.get('id');
          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "warning/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as class_returndata)
              if (this.httpResponse.succeeded)
              {
                this.Warning = {
                  id: (this.httpResponse.data as cWarning).id,
                  warningid: (this.httpResponse.data as cWarning).warningid,
                  name: (this.httpResponse.data as cWarning).name,
                  venueid: (this.httpResponse.data as cWarning).venueid,
                  disabled: (this.httpResponse.data as cWarning).disabled,
                  venuemessage: (this.httpResponse.data as cWarning).venuemessage,
                  publicmessage: (this.httpResponse.data as cWarning).publicmessage
                };

                if(this.Warning.publicmessage != null && this.Warning.publicmessage != "")
                {
                  this.WantPublicMessage = true;
                }

                if(this.Warning.venuemessage != null && this.Warning.venuemessage != "")
                {
                  this.WantVenueMessage = true;
                }
      
                this.pageReady = true;
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting warning'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting warning, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  updateData()
  {
    if (this.DoValidCheck())
    {
      if(this.WantPublicMessage == false)
      {
        this.Warning.publicmessage = "";
      } 

      if(this.WantVenueMessage == false)
      {
        this.Warning.venuemessage = "";
      }

      if(this.id == "0")
      {
        this.http.post<cWarning>(bmxurl + "/warning", this.Warning).subscribe(response => {
          this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              this.messageService.add({severity:'success', summary:'Saved', detail:'Warning successfully updated'});
              this.router.navigateByUrl("/warnings");
            }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
          })
      }
      else
      {
        this.Warning.disabled = false;
        
        this.http.put<cWarning>(bmxurl + "/warning", this.Warning).subscribe(response => {
        this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Warning successfully updated'});
            this.router.navigateByUrl("/warnings");
          }
          }, error => {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.Warning.name == null || this.Warning.name == "")
    {
      AllValid = false;
      this.warningNameValid = false;
    }
    else
    {
      this.warningNameValid = true;
    }

    if((this.Warning.publicmessage == null || this.Warning.publicmessage == "") && this.WantPublicMessage == true)
    {
      AllValid = false;
      this.warningPublicValid = false;
    }
    else
    {
      this.warningPublicValid = true;
    }

    if((this.Warning.venuemessage == null || this.Warning.venuemessage == "") && this.WantVenueMessage == true)
    {
      AllValid = false;
      this.warningVenueValid = false;
    }
    else
    {
      this.warningVenueValid = true;
    }


    if(this.Warning.name != null && this.Warning.name != "" && (this.Warning.publicmessage == null || this.Warning.publicmessage == "") && (this.Warning.venuemessage == null || this.Warning.venuemessage == ""))
    {
      AllValid = false;
      this.atLeastOneWarning = false;
    }
    else
    {
      this.atLeastOneWarning = true;
    }
    return AllValid;
  }

  setVenueMessage()
  {
    if(this.WantVenueMessage == false)
    {
      this.Warning.venuemessage = "";
    }
  }

  setPublicMessage()
  {
    if(this.WantPublicMessage == false)
    {
      this.Warning.publicmessage = "";
    }
  }

  returnToList()
  {
    this.router.navigateByUrl("/warnings");
  }
}
