import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../_services/global.service';
import { Router } from '@angular/router';
import { BusyService } from '../_services/busy.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { bmxurl, getAccess } from 'src/globals';
import { users_returndata } from 'src/types/user_returndata';
import { voucher_returndata } from 'src/types/voucher_returndata';
import { LocalStorage } from '@ng-idle/core';

@Component({
  selector: 'app-voucherlist',
  templateUrl: './voucherlist.component.html',
  styleUrls: ['./voucherlist.component.css']
})

export class VoucherlistComponent implements OnInit {

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  ListVouchers: any = [];
  httpResponse: any;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;
  isDisabled: boolean = true;
  selectedItems: any;
  showVoucherHistory: boolean = false;
  displayVoucherCode: string = "";
  CurrentVoucherValue = 0;

  voucherList: any = [] = [];
  fullVoucherList: any = [] = [];

  constructor(private http: HttpClient, 
    private router: Router, 
    private messageService: MessageService, 
    private busyService: BusyService,
    private globalService: GlobalService) {

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("VOUCHERS", user)){
          this.AccessAllowed = true;
        }

        this.CompletedLoading = true;
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });
   }

  ngOnInit() {

    this.globalService.setSmallScreenMenu(false);
    
    this.busyService.busy();

    this.loadgrid(false);
  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  sendEmailConfirmationById(VoucherID: string){
    this.http.get<any>(bmxurl + "sendvoucher/" + this.VenueID + "/" + VoucherID).subscribe(response => {
      if(response.succeeded)
      {
        this.messageService.add({severity:'success', summary:'Success', detail:'Email Message sent.'})
      }
      else{
        this.messageService.add({severity:'error', summary:'Failure', detail: response.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail: 'An error occurred whilst trying to send the Email'});
    })
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "Voucher/" + this.VenueID + "/" + element.voucherid).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.loadgrid(false);
      } 
      else{
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error disabling Vouchers, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error disabling Vouchers, error: ' + error.messages});
    })
    });
    
  }

  viewBooking(id, voucherID)
  {
    localStorage.setItem("VoucherID", voucherID);
    localStorage.setItem("voucherScreen", "TRUE");

    this.router.navigate(['/editbooking', id]);
  }

  disableData(id)
  {
    this.http.delete(bmxurl + "Voucher/" + this.VenueID + "/" + id).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded == true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Voucher successfully disabled'});
        this.router.navigateByUrl("/voucherlist");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Class'});
    })
  }
  
  editData(id)
  {
    this.router.navigate(['/voucheredit', id]);
  }

  showHistory(id)
  {
    this.voucherList = this.fullVoucherList.filter(x => x.voucherid == id);

    this.CurrentVoucherValue = this.voucherList.reduce((acc, obj) => acc + obj.value, 0);

    if (this.voucherList != null && this.voucherList.length > 0)
    {
      this.displayVoucherCode = this.voucherList[0].vouchercode;
    }

    this.showVoucherHistory = true;
    this.displayVoucherCode = ""
  }

  close()
  {
    this.showVoucherHistory = false;
  }

  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "VoucherList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        //filter list by ischild
        this.ListVouchers = (response as voucher_returndata).data.filter(x => x.ischild == false);
        this.fullVoucherList = (response as voucher_returndata).data;

        if (localStorage.getItem("voucherScreen") == "TRUE")
        {
          this.showHistory(localStorage.getItem("VoucherID")!);
          localStorage.removeItem("voucherScreen");
          localStorage.removeItem("VoucherID");
        }

        if ((response as voucher_returndata).messages.length > 0)
        {
          (response as voucher_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }

        this.busyService.idle();
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error getting Vouchers, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Vouchers, error: ' + error.messages});
    });
  }
}
