import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reportpage',
  templateUrl: './reportpage.component.html',
  styleUrls: ['./reportpage.component.css']
})
export class ReportpageComponent {

  ReportInfo: string = "";
  ShowPaymentReportButton: boolean = false;
  ShowCustomerReportButton: boolean = false;
  ShowExtrasReportButton: boolean = false;
  ShowBQReportButton: boolean = false;
  ShowBookingListReportButton: boolean = false;
  ShowSalesReportButton: boolean = false

  constructor(
    private router: Router,
  ) 
  {}

  doPaymentsReport(){
    this.router.navigateByUrl("/paymentreport");
  }

  showPaymentText()
  {
    this.ShowReportButton("Payments");
    this.ReportInfo = "<h4>Payments Report</h4><br>The Payment Report shows all payments made between the dates chosen.  <BR><BR>Reports can be exported to CSV/Excel format by clicking on the export button at the top of the report.";
  }

  doCustomerReport(){
    this.router.navigateByUrl("/customerreport");
  }

  showCuwtomerText(){
    this.ShowReportButton("Customers");
    this.ReportInfo = "<h4>Customer Report</h4><br>The Customer Report shows a list of all customers who have ever visited the venue.<br><br>Reports can be exported to CSV/Excel format by clicking on the export button at the top of the report.";
  }

  doExtrasReport(){
    this.router.navigateByUrl("/extrasreport");
  }

  showExtrasText(){
    this.ShowReportButton("Extras");
    this.ReportInfo = "<h4>Extras Report</h4><br>The Extras Report shows all Extras purchased by customers between the dates chosen. <br><br>Reports can be exported to CSV/Excel format by clicking on the export button at the top of the report.";
  }

  doBQReport(){
    this.router.navigateByUrl("/bookinganswersreport");
  }

  showBQText(){
    this.ShowReportButton("BQ");
    this.ReportInfo = "<h4>Booking Questions Report</h4><br>The Booking Questions Report shows all booking questions for bookings between the dates chosen.<br><br>Reports can be exported to CSV/Excel format by clicking on the export button at the top of the report.";
  }

  doBookingListReport(){
    this.router.navigateByUrl("/bookinglistreport");
  }

  showBLText(){
    this.ShowReportButton("BookingList");
    this.ReportInfo = "<h4>Booking List Report</h4><br>The Booking List Report shows all bookings between the dates chosen.  The report gives you a break down of the admissions, extras and allergies for the bookings.<br><br>Reports can be exported to CSV/Excel format by clicking on the export button at the top of the report.";
  }

  doSalesReport(){
    this.router.navigateByUrl("/salesreport");
  }

  showSalesText(){
    this.ShowReportButton("Sales");
    this.ReportInfo = "<h4>Sales Report</h4><br>The Sales Report gives you a list of all bookings sub totaled by package.<br><br>Reports can be exported to CSV/Excel format by clicking on the export button at the top of the report.";
  }

  ShowReportButton(requiredReport: string){
    this.ShowPaymentReportButton = false;
    this.ShowCustomerReportButton = false;
    this.ShowExtrasReportButton = false;
    this.ShowBQReportButton = false;
    this.ShowBookingListReportButton = false;
    this.ShowSalesReportButton = false;

    if (requiredReport == "Payments"){
      this.ShowPaymentReportButton = true;
    }

    if (requiredReport == "Customers"){
      this.ShowCustomerReportButton = true;
    }

    if (requiredReport == "Extras"){
      this.ShowExtrasReportButton = true;
    }

    if (requiredReport == "BQ"){
      this.ShowBQReportButton = true;
    } 

    if (requiredReport == "BookingList"){
      this.ShowBookingListReportButton = true;
    }

    if (requiredReport == "Sales"){
      this.ShowSalesReportButton = true;
    }
  }
}


