
<div *ngIf="ListBookings" class="p-3 flex flex-column flex-auto">
    <h3 style="width:100%; float:left;margin-bottom:15px;">Bookings for {{displayDate}}</h3>

    <div style="float: left; margin-bottom:20px;">
        <div style="float:left; width: 300px;text-align: left;">
            <button pButton type="button"  pTooltip="Return to list"  style="height: 50px; margin-right: 5px;width: 210px; float:left;" (click)="returnToList();" class="p-button-sm p-element p-ripple p-button p-component">
                <span class="pi pi-angle-left" style="margin-right:8px;"></span>
                <span>Return to At a Glance</span>
            </button>
        </div>
<!-- 
        <div style="float:right; width: 300px;text-align: right;">
            <button pTooltip="Bookings in progress" pButton type="button" style="height: 50px; margin-right:10px;" class="p-button-sm p-element p-ripple p-button-warning p-button p-component" (click)="showInProgress()">
                <span class="pi pi-clock" style="margin-right:8px; font-size: 16px;"></span>
                <span style="font-size:17px;">{{bookingsInProgress}}</span>
            </button>
            <button pTooltip="Bookings made today" pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-success p-button p-component" (click)="unshowInProgress()">
                <span class="pi pi-check-circle" style="margin-right:8px; font-size: 16px;"></span>
                <span style="font-size:17px;">{{totalBookingsToday}}</span>
            </button>
        </div> -->
    </div>

    <div style="width:100%">
        
        <div style="float:right; margin-bottom: 20px;">
            <span class="p-input-icon-left ml-auto" style="margin-right:20px; ">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="resourceTable.filterGlobal($event.target.value, 'contains')" style="height: 50px;padding-left:33px;" />
            </span>
        </div>
    </div>

    <div class="surface-card p-4 shadow-2 border-round">
    <p-table  #resourceTable 
    [columns]="cols"
    [value]="ListBookings" sortField="startTime" [paginator]="true" [rows]="10" 
    [showCurrentPageReport]="true" responsiveLayout="scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
    [rowsPerPageOptions]="[10,25,50]" 
    [globalFilterFields]="['name', 'bookingId', 'bookingIdHR', 'packageName']"
    [(selection)]="selectedItems"
    responsiveLayout="scroll"
    [selectionPageOnly]="true"
    sortField="name" [sortOrder]="1">
    <ng-template pTemplate="caption">
        <div class="flex">
        <button
            type="button"
            pButton
            pRipple
            icon="pi pi-file-excel"
            (click)="resourceTable.exportCSV()"
            class="mr-2"
            pTooltip="Export to CSV"
            tooltipPosition="bottom">
        </button>
        </div>
    </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th width="1%">
                    &nbsp;
                </th>
                <th width="1%">
                    &nbsp;
                </th>
                <th pSortableColumn="bookingId" width="8%">Sales ID
                    <p-sortIcon [field]="bookingIdHR"></p-sortIcon>
                </th>
                <th pSortableColumn="startTime" width="10%">Start Date
                    <p-sortIcon [field]="startTime"></p-sortIcon>
                </th>
                <th pSortableColumn="startTime" width="18%">Start
                    <p-sortIcon [field]="startTime"></p-sortIcon>
                </th>
                <th pSortableColumn="name" width="3%">Name
                    <p-sortIcon [field]="name"></p-sortIcon>
                </th>
                <th pSortableColumn="email" width="3%">Email
                    <p-sortIcon [field]="email"></p-sortIcon>
                </th>
                <th pSortableColumn="phone" width="3%">Phone
                    <p-sortIcon [field]="phone"></p-sortIcon>
                </th>
                <th pSortableColumn="packageName" width="15%">Package
                    <p-sortIcon [field]="packageName"></p-sortIcon>
                </th>
                <th pSortableColumn="guests" width="6%">Guests
                    <p-sortIcon [field]="guests"></p-sortIcon>
                </th>
                <th pSortableColumn="total" width="5%">Total
                    <p-sortIcon [field]="total"></p-sortIcon>
                </th>
                <th pSortableColumn="outstanding" width="5%">O/S
                    <p-sortIcon [field]="outstanding"></p-sortIcon>
                </th>

                <th width="20%">
                    Extras
                </th>

                <th width="5%">
                    Allergy
                </th>
                <th *ngIf="useCounters" width="5%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr *ngIf="!data.inProgress && !inProgress  && data.allergy == ''" onmouseover="this.originalstyle=this.style.backgroundColor;this.style.backgroundColor='#dddddd'" onmouseout="this.style.backgroundColor=this.originalstyle;" [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                <td (click)="editdatanew(data.bookingId)" >
                    <span *ngIf="data.publicBooking" class="pi pi-user"></span> 
                </td>
                <td (click)="editdatanew(data.bookingId)">
                    <span style="color:red" *ngIf="data.notes != '' && data.notes != null" class="pi pi-exclamation-circle" pTooltip="{{data.notes}}"></span> 
                </td>
                <td (click)="editdatanew(data.bookingId)">{{data.bookingIdHR}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.startTime | date:'dd/MM/yy'}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.startTime | date:'HH:mm' : 'UTC'}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.name}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.email}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.phone}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.packageName}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.guests}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.total | currency: CurrencySymbol}}</td>
                <td (click)="editdatanew(data.bookingId)" *ngIf="data.outstanding <=0">{{data.outstanding | currency: CurrencySymbol}}</td>
                <td (click)="editdatanew(data.bookingId)" *ngIf="data.outstanding >0"><span style="color: Red; font-weight: bold;">{{data.outstanding | currency: CurrencySymbol}}</span></td>
                <td (click)="editdatanew(data.bookingId)">{{data.extraslist}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.allergy}}</td>

                <td *ngIf="useCounters">

                    <button *ngIf="!data.checkedin" pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" style="width:133px;" (click)="admitall(data.bookingId)">
                        <span class="pi pi-sign-in" style="margin-right:8px;"></span>
                        <span>Admit All</span>
                    </button>

                    <button *ngIf="data.checkedin" pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" style="width:133px;" (click)="leaveall(data.bookingId)">
                        <span class="pi pi-sign-out" style="margin-right:8px;"></span>
                        <span>Leave All</span>
                    </button>
            
                </td>
            </tr>


            <tr *ngIf="!data.inProgress && !inProgress && data.allergy != ''" onmouseover="this.originalstyle=this.style.backgroundColor;this.style.backgroundColor='#dddddd'" onmouseout="this.style.backgroundColor=this.originalstyle;" [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#FFFFB2'}">
                <td (click)="editdatanew(data.bookingId)" >
                    <span *ngIf="data.publicBooking" class="pi pi-user"></span> 
                </td>
                <td (click)="editdatanew(data.bookingId)">
                    <span style="color:red" *ngIf="data.notes != '' && data.notes != null" class="pi pi-exclamation-circle" pTooltip="{{data.notes}}"></span> 
                </td>
                <td (click)="editdatanew(data.bookingId)">{{data.bookingIdHR}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.startTime | date:'dd/MM/yy'}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.startTime | date:'HH:mm' : 'UTC'}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.name}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.email}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.phone}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.packageName}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.guests}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.total | currency: CurrencySymbol}}</td>
                <td (click)="editdatanew(data.bookingId)" *ngIf="data.outstanding <=0">{{data.outstanding | currency: CurrencySymbol}}</td>
                <td (click)="editdatanew(data.bookingId)" *ngIf="data.outstanding >0"><span style="color: Red; font-weight: bold;">{{data.outstanding | currency: CurrencySymbol}}</span></td>
                <td (click)="editdatanew(data.bookingId)">{{data.extraslist}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.allergy}}</td>
                <td *ngIf="useCounters">

                    <button *ngIf="!data.checkedin" pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" style="width:133px;" (click)="admitall(data.bookingId)">
                        <span class="pi pi-sign-in" style="margin-right:8px;"></span>
                        <span>Admit All</span>
                    </button>

                    <button *ngIf="data.checkedin" pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" style="width:133px;" (click)="leaveall(data.bookingId)">
                        <span class="pi pi-sign-out" style="margin-right:8px;"></span>
                        <span>Leave All</span>
                    </button>
            
                </td>
            </tr>
        </ng-template>
    </p-table>
        <div>
            <div class="field mb-4 col-12">
                <label for="" class="font-medium">Send this email to all bookings in this time slot: </label><br>
                <p-dropdown [options]="EmailTemplates" [style]="{'width': '300px', 'float': 'left'}"  placeholder="Select a template" [showClear]="true" [(ngModel)]="selectedEmailTemplate" optionLabel="name"></p-dropdown>
            </div>
            <div style="width: 100px; float: left; margin-top: -32px; margin-left: 10px;">
                <p-button icon="pi pi-send" label="Send" styleClass="p-button p-button-success" (click)="sendEmailToAllBookings()"></p-button>
            </div>
        </div>
        <div *ngIf="!templateValid" class="text-danger" Style="clear:both; width:100%"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select a Template.</div>
    </div>
</div>
