import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { class_returndata } from 'src/types/class_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cClass, classes } from 'src/types/class';
import { returnmessage } from 'src/types/returnmessage';
import { MessageService } from 'primeng/api';

import { users_returndata } from 'src/types/user_returndata';
import { business } from 'src/types/business';
import { businessC, userC, venueC } from 'src/types/businessc';
import { v4 as uuidv4  } from 'uuid';
import { venue } from 'src/types/venue';
import { usertype_returndata } from 'src/types/usertypes_returndata';
import { accesscontrol } from 'src/types/venueuser';
import { venuesettings } from 'src/types/venuesettings';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-addnewvenuetobusiness',
  templateUrl: './addnewvenuetobusiness.component.html',
  styleUrls: ['./addnewvenuetobusiness.component.css']
})
export class AddnewvenuetobusinessComponent  implements OnInit {

  //#region variables
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  business: businessC;
  venue: venue;
  venuesmall: venueC;

  httpResponse: any;
  id: string;
  pageReady: boolean = true;

  businessnamevalid: boolean = true;
  businessAddress1valid: boolean = true;
  businessTownvalid: boolean = true;
  businessCountyvalid: boolean = true;
  businessPostcodevalid: boolean = true;
  businessTelephonevalid: boolean = true;
  businessEmailvalid: boolean = true;

  venuenamevalid: boolean = true;
  prefixnamevalid: boolean = true;

  venueAddress1valid: boolean = true;
  venueTownvalid: boolean = true;
  venueCountyvalid: boolean = true;
  venuePostcodevalid: boolean = true;
  venueTelephonevalid: boolean = true;
  venueEmailvalid: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  
  businessUser: userC;
  masterKeyUser: userC;

  businessUserPIN: string = "";
  masterKeyUserPIN: string = "";

  buUserName: string = "";
  buPassword: string = "";
  buLoginName: string = "";

  mkUserName: string = "";
  mkPassword: string = "";
  mkLoginName: string = "";

  NewVenueID: string = "";
  NewBusinessID: string = "";

  VUAccessControl: any;

  usertypes: accesscontrol[];
  BusinessMasterKey: any;

  DecodedPIN: string = "";
  displayTempPIN: boolean = false;

  createbusinessowner: boolean = true;
  yesno: any;

  HTMLbmxurl: string = "";
  FileChosen: string = "";
  //#endregion

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 
    
    this.yesno = [{label: 'No', value: false}, {label: 'Yes', value: true}];

    this.HTMLbmxurl = bmxurl;

    this.NewBusinessID = uuidv4();
    this.NewVenueID = uuidv4();
    
    this.business = {
      id: null,
      businessid: null,
      Name: "Business 1",
      Address1: "Address 1",
      Address2:  "Address 2",
      Town: "Town 2",
      County: "County 2",
      Postcode: "Postcode 2",
      Telephone: "Telephone 2",
      Email: "Email 1",
      Lat: 0,
      Lon: 0,
      Disabled: false,
      users: [],
      venues: []
    }

    this.venuesmall = {
      venueid: null,
      name: "",
      prefix: ""
    }

    this.venue = {
      id: null,
      venueId: null,
      businessid: null,
      Name: "Venue 1",
      Address1: "Address 1",
      Address2:  "Address 2",
      Town: "Town 1",
      County:  "County 1",
      Postcode:  "Postcode 1",
      Telephone: "00000000000",
      Email:  "Email 1",
      Lat:  0,
      Lon: 0,
      startTime: "09:00",
      endTime: "22:00",
      capacity: 100,
      VCapacityIncludesParticipant: true,
      Disabled: false,
      prefix: "THX"
    }

    this.mkUserName = "masterkey@smart-entertainment.co.uk";
    this.mkLoginName = "MasterKey";
    this.mkPassword = "password123!!";

  }

  ngOnInit(): void {
    this.globalService.setSmallScreenMenu(false);
    
    let userid = localStorage.getItem("SmartUserID");
  }

  onErrorFileUpload(event){
    this.messageService.add({ severity: 'error', summary: 'Image not uploaded', detail: 'Could not upload image: ' + event.error });
  }

  genRandomNum()
  {
    return Math.floor((Math.random()*10000)+1).toString();
  }

  uploadedFile(event){
    this.FileChosen = event.originalEvent.body.data;
  }

  returnToUsers()
  {
    this.displayTempPIN = false;
  }

  updateData()
  {
    if (this.DoValidCheck())
    {
      this.http.get(bmxurl + "UserTypeList").subscribe(response => {
      this.httpResponse = (response as usertype_returndata)
      if (this.httpResponse.succeeded)
      {
        this.usertypes = (this.httpResponse.data as accesscontrol[]);

        this.usertypes.forEach(element => {
          if (element.name == "Venue Administrator")
          {
            this.VUAccessControl = element;
          }
        });

        this.businessUser = {
          auth0Username: this.buUserName,
          auth0Password: this.buPassword,
          businessid: this.NewBusinessID,
          userid: uuidv4(),
          name: this.buLoginName,
          venues: [],
          venueid: this.NewVenueID,
          accesscontrol: this.VUAccessControl,
          disabled: false,
          pin: null,
          temppin: true
        }
  
        this.masterKeyUser = {
          auth0Username: this.mkUserName,
          auth0Password: this.mkPassword,
          businessid: this.NewBusinessID,
          userid: uuidv4(),
          name: this.mkLoginName,
          venues: [],
          venueid: this.NewVenueID,
          accesscontrol: this.VUAccessControl,
          disabled: false,
          pin: null,
          temppin: true
        }

          //Create Master Key User
          this.http.post<any>(bmxurl + "/createuserm", this.masterKeyUser).subscribe(response => {
            this.httpResponse = response
              if(this.httpResponse.succeeded)
              {

                this.DecodedPIN = this.httpResponse.data.pindecoded;
                //this.messageService.add({severity:'success', summary:'PIN Number', detail:'Master Key User PIN: ' + this.DecodedPIN});
                this.displayTempPIN = true;
                this.masterKeyUserPIN = this.httpResponse.data.pin;
                this.messageService.add({severity:'success', summary:'Saved', detail:'Master Key User successfully created'});

                this.BusinessMasterKey = {
                  "userid": this.masterKeyUser.userid,
                  "name": this.masterKeyUser.name,
                  "venues": [],
                  "accesscontrol": this.masterKeyUser.accesscontrol,
                  "disabled": false,       
                  "pin": this.masterKeyUserPIN,
                  "temppin": true
                }

                this.BusinessMasterKey.venues = [];
                this.BusinessMasterKey.venues.push(this.venuesmall);

                //Create Venue
                this.venue.venueId = this.NewVenueID;
                this.venue.businessid = this.NewBusinessID;
                this.venue.id = uuidv4();
      
                this.http.post<cClass>(bmxurl + "/venuecreate", this.venue).subscribe(response => {
                  this.httpResponse = response
                    if(this.httpResponse.succeeded)
                    {
                      this.messageService.add({severity:'success', summary:'Saved', detail:'Venue successfully updated'});
                      
                      //create venue settings

                      let venuesettings = {
                        id: uuidv4(),
                        venueid: this.NewVenueID,
                        venuesettings: []
                      }

                      venuesettings.venuesettings.push({name: "AvailabilityDiagnostics", value: "true"});
                      venuesettings.venuesettings.push({name: "MinimalDetails", value: "true"});
                      venuesettings.venuesettings.push({name: "GTMCode", value: "GTM-WG4H3RG"});
                      venuesettings.venuesettings.push({name: "PublicImgURL", value: this.FileChosen});
                      venuesettings.venuesettings.push({name: "EMailSMSConfirmation", value: "Email"});
                      venuesettings.venuesettings.push({name: "DefaultInterval", value: "20"});
                      venuesettings.venuesettings.push({name: "PublicImageWidth", value: "250"});
                      venuesettings.venuesettings.push({name: "PublicImageheight", value: "100"});
                      venuesettings.venuesettings.push({name: "idleTimeout", value: "10"});
                      venuesettings.venuesettings.push({name: "AllowTimeOut", value: "true"});
                      venuesettings.venuesettings.push({name: "UseCounters", value: "false"});
                      venuesettings.venuesettings.push({name: "SummaryItinerary", value: "true"});
                      venuesettings.venuesettings.push({name: "DefaultColour", value: "#a8198e"});
                      venuesettings.venuesettings.push({name: "RyftSubAccountID", value: ""});
                      venuesettings.venuesettings.push({name: "GoogleTagManagerID", value: ""});
                      venuesettings.venuesettings.push({name: "returnPage", value: ""});
                      venuesettings.venuesettings.push({name: "TimeZone", value: "true"});
                      venuesettings.venuesettings.push({name: "Currency", value: "GBP"});
                      venuesettings.venuesettings.push({name: "PaymentLinkEmailTemplate", value: ""});
                      venuesettings.venuesettings.push({name: "VoucherEmailTemplate", value: ""});
                      venuesettings.venuesettings.push({name: "BookingsCannotBeMadeAfterXMinutes", value: "0"});
                      venuesettings.venuesettings.push({name: "BookingsCannotBeMadeWithinXMinutes", value: "0"});
                      venuesettings.venuesettings.push({name: "CannotMakeBookingsToday", value: "true"});


                      this.http.post<cClass>(bmxurl + "/venuesettings", venuesettings).subscribe(response => {
                        this.httpResponse = response
                          if(this.httpResponse.succeeded)
                          {
                            this.messageService.add({severity:'success', summary:'Saved', detail:'Venue settings successfully updated'});
                          }
                          else
                          {
                            this.messageService.add({severity:'error', summary:'Not Saved', detail:this.httpResponse.messages[0] });
                          }
                          }, error => {
                            this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
                        })


                      //Create CSS
                      let css = {
                        "venueid": this.NewVenueID,
                        "id": uuidv4(),
                        "css": ".p-button {background-color: ##DefaultColour##; border-color: ##DefaultColour## !important} .p-button:enabled:hover {background-color: ##DefaultColour##; border-color: ##DefaultColour##} .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight) {border-color: #dddddd !important; background-color: white !important;} .p-button-text:enabled {border-color: white !important; background-color:white !important} .p-highlight{background-color:##DefaultColour## !important;"
                      }
                      
                      this.http.post<cClass>(bmxurl + "/css", css).subscribe(response => {
                        this.httpResponse = response
                          if(this.httpResponse.succeeded)
                          {
                            this.messageService.add({severity:'success', summary:'Saved', detail:'CSS successfully updated'});
                          }
                          else
                          {
                            this.messageService.add({severity:'error', summary:'Not Saved', detail:this.httpResponse.messages[0] });
                          }
                          }, error => {
                            this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
                        })

                                    //Create host entry
                                    let host = {
                                      "id": uuidv4(),
                                      "hostname": this.venue.Name.replace(' ','').toLowerCase(),
                                      "venueid": this.NewVenueID,
                                      "venuename": this.venue.Name
                                    }
          
                                    this.http.post<cClass>(bmxurl + "/host", host).subscribe(response => {
                                      this.httpResponse = response
                                        if(this.httpResponse.succeeded)
                                        {
                                          this.messageService.add({severity:'success', summary:'Saved', detail:'Host successfully updated'});
                                        }
                                        else
                                        {
                                          this.messageService.add({severity:'error', summary:'Not Saved', detail:this.httpResponse.messages[0] });
                                        }
                                        }, error => {
                                          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
                                      })


                      //Create Business
                      this.venuesmall.venueid = this.NewVenueID;
                      this.venuesmall.name = this.venue.Name;
                      this.venuesmall.prefix = this.venue.prefix;
            
                      this.business.id = uuidv4();;
                      this.business.businessid = this.NewBusinessID;
            
                      this.business.venues = [];
                      this.business.venues.push(this.venuesmall);
            
                      this.business.users = [];
            
                      //this.business.users.push(this.BusinessBusinesOwner);
                      this.business.users.push(this.BusinessMasterKey);
            
                      this.http.post<cClass>(bmxurl + "/business", this.business).subscribe(response => {
                        this.httpResponse = response
                          if(this.httpResponse.succeeded)
                          {
                            this.messageService.add({severity:'success', summary:'Saved', detail:'Business successfully updated'});
                            this.router.navigateByUrl("/create");
                          }
                          }, error => {
                            this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
                        })

                    }
                    else
                    {
                      this.messageService.add({severity:'error', summary:'Not Saved', detail:this.httpResponse.messages[0] });
                    }
                    }, error => {
                      this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
                  })
                
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Not Saved', detail:this.httpResponse.messages[0] });
              }
              }, error => {
                this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
            })
        }        
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting user types'});
          this.pageReady = false;
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting user types, error:' + error.messages[0]});
        this.pageReady = false;
      });



    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.venue.Name == null || this.venue.Name == "")
    {
      AllValid = false;
      this.venuenamevalid = false;
    }
    else
    {
      this.venuenamevalid = true;
    }

    if(this.venue.prefix == null || this.venue.prefix == "")
    {
      AllValid = false;
      this.prefixnamevalid = false;
    }
    else
    {
      this.prefixnamevalid = true;
    }

    if(this.venue.Address1 == null || this.venue.Address1 == "")
    {
      AllValid = false;
      this.venueAddress1valid = false;
    }
    else
    {
      this.venueAddress1valid = true;
    }

    if(this.venue.Town == null || this.venue.Town == "")
    {
      AllValid = false;
      this.venueTownvalid = false;
    }
    else
    {
      this.venueTownvalid = true;
    }

    if(this.venue.County == null || this.venue.County == "")
    {
      AllValid = false;
      this.venueCountyvalid = false;
    }
    else
    {
      this.venueCountyvalid = true;
    }

    if(this.venue.Postcode == null || this.venue.Postcode == "")
    {
      AllValid = false;
      this.venuePostcodevalid = false;
    }
    else
    {
      this.venuePostcodevalid = true;
    }

    if(this.venue.Telephone == null || this.venue.Telephone == "")
    {
      AllValid = false;
      this.venueTelephonevalid = false;
    }
    else
    {
      this.venueTelephonevalid = true;
    }

    if(this.venue.Email == null || this.venue.Email == "")
    {
      AllValid = false;
      this.venueEmailvalid = false;
    }
    else
    {
      this.venueEmailvalid = true;
    }


    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/create");
  }
}