import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { class_returndata } from 'src/types/class_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cClass, classes } from 'src/types/class';
import { returnmessage } from 'src/types/returnmessage';
import { MessageService } from 'primeng/api';

import { accesscontrol, venueuser } from 'src/types/venueuser';
import { usertype_returndata } from 'src/types/usertypes_returndata';
import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';


@Component({
  selector: 'app-useredit',
  templateUrl: './useredit.component.html',
  styleUrls: ['./useredit.component.css']
})

export class UsereditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  User: venueuser;
  httpResponse: any;
  id: string;
  pageReady: boolean = true;
  usertypes: accesscontrol[];
  displayTempPIN: boolean = false;
  TempPIN: string = "";

  userNameValid: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 
    //Create new user here
    this.User = {
      id: null,
      userid: null,
      name: "",
      venueid: this.VenueID,
      accesscontrol: null,
      disabled: false,
      pin: ""
    }

  }

  resetPIN()
  {
    this.http.get(bmxurl + "pinreset/" + this.VenueID + "/" + this.User.userid).subscribe(response => {
      this.httpResponse = (response as any)
      if (this.httpResponse.succeeded)
      {
        this.TempPIN = this.httpResponse.data.pindecoded;
        this.displayTempPIN = true;
      }
      else
      {
        this.TempPIN = "";
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting user'});
        this.pageReady = false;
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting user, error:' + error.messages[0]});
      this.pageReady = false;
    });

    

  }
  
  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("USERMANAGEMENT", user)){
          this.AccessAllowed = true;
          this.id = this.route.snapshot.paramMap.get('id');
    
          this.http.get(bmxurl + "UserTypeList").subscribe(response => {
            this.httpResponse = (response as usertype_returndata)
            if (this.httpResponse.succeeded)
            {
              this.usertypes = (this.httpResponse.data as accesscontrol[]);
            }
            else
            {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting user types'});
              this.pageReady = false;
            }
          }, error => {
            this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting user types, error:' + error.messages[0]});
            this.pageReady = false;
          });
         
          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "User/" + this.id).subscribe(response => {
              this.httpResponse = (response as class_returndata)
              if (this.httpResponse.succeeded)
              {
                this.User = {
                  id: (this.httpResponse.data as venueuser).id,
                  userid: (this.httpResponse.data as venueuser).userid,
                  name: (this.httpResponse.data as venueuser).name,
                  venueid: (this.httpResponse.data as venueuser).venueid,
                  accesscontrol:(this.httpResponse.data as venueuser).accesscontrol,
                  disabled: (this.httpResponse.data as venueuser).disabled,
                  pin: (this.httpResponse.data as venueuser).pin
                };
      
                this.pageReady = true;
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting user'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting user, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
          
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  returnToUsers()
  {
    this.displayTempPIN = false;
    this.router.navigateByUrl("/users");
  }

  updateData()
  {
    if (this.DoValidCheck())
    {
      if(this.id == "0")
      {
        this.http.post<any>(bmxurl + "/User", this.User).subscribe(response => {
          this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              this.TempPIN = this.httpResponse.data.pindecoded;
              this.displayTempPIN = true;

              this.messageService.add({severity:'success', summary:'Saved', detail:'User successfully updated'});
            }
            else
            {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:this.httpResponse.messages[0] });
            }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
          })
      }
      else
      {
        this.User.disabled = false;
        
        this.http.put<any>(bmxurl + "/User", this.User).subscribe(response => {
        this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'User successfully updated'});
            this.router.navigateByUrl("/users");
          }
          }, error => {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.User.name == null || this.User.name == "")
    {
      AllValid = false;
      this.userNameValid = false;
    }
    else
    {
      this.userNameValid = true;
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/users");
  }
}

