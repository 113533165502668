import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { bmxurl, getAccess } from 'src/globals';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { users_returndata } from 'src/types/user_returndata';
import { bookingquestion_returndata } from 'src/types/bookingquestion_returndata';
import { bookingquestionnaire_returndata } from 'src/types/bookingquestionnaire_returndata';
import { bookingquestionnaire } from 'src/types/bookingquestionnaire';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-bookingquestionnaire',
  templateUrl: './bookingquestionnaire.component.html',
  styleUrls: ['./bookingquestionnaire.component.css']
})
export class BookingquestionnaireComponent implements OnInit {
    ListBookingQuestionnaire: any;
    bmxurl: string;
    VenueID: string = localStorage.getItem("SmartVenueID")!;
    httpResponse: any;
    DisabledBookingQuestionnaire: any;
    checked: boolean = true;
    isDisabled: boolean = true;
    selectedItems: any;
  
    AccessAllowed: boolean = false;
    CompletedLoading: boolean = false;
  
  
    constructor(
      private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 
  
      }
  
    ngOnInit(): void {
      this.globalService.setSmallScreenMenu(false);
  
      localStorage.removeItem("screenMemoryBookingss");
      localStorage.removeItem("screenMemoryEvents");
      
      let userid = localStorage.getItem("SmartUserID");
  
      this.http.get(bmxurl + "User/" + userid).subscribe(response => {
        this.httpResponse =  (response as users_returndata)
        if(this.httpResponse.succeeded)
        {
          let user = this.httpResponse.data;
  
          if(getAccess("BOOKINGQUESTIONNAIRE", user)){
            this.AccessAllowed = true;
            this.loadgrid(false);
          }
  
          this.CompletedLoading = true;
  
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
      });
  
    }
  
    toggleDisabled(){
      this.loadgrid(!this.isDisabled);
    }
  
    disableData(BookingQuestionnaireID)
    {
      this.http.delete(bmxurl + "questionnaire/" + this.VenueID + "/" + BookingQuestionnaireID).subscribe(response => {
        this.httpResponse = response
        if(this.httpResponse.succeeded == true)
        {
          this.messageService.add({severity:'success', summary:'Disabled', detail:'Booking Questionnaire successfully disabled'});
          this.router.navigateByUrl("/bookinguestionnaire");
  
          this.loadgrid(false);
        }
        else{
          this.messageService.add({severity:'error', summary:'Not Disabled', detail:this.httpResponse.messages[0]});
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Booking Questionnaire'});
      })
    }
    
    loadgrid(showdisabled: boolean)
    {
      this.http.get(bmxurl + "questionnairelist/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.httpResponse = response
        if(this.httpResponse.succeeded)
        {
          this.ListBookingQuestionnaire = (response as bookingquestionnaire_returndata);
  
          if ((response as bookingquestionnaire_returndata).messages.length > 0)
          {
            (response as bookingquestionnaire_returndata).messages.forEach(error => {
              this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
            });
          }
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error getting Booking Questionnaire, error: ' + this.httpResponse.messages[0]});
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Booking Questionnaire, error: ' + error.messages});
      });
    }
  
    editData(EditData)
    {
      let BookingQuestionnaireID = EditData;
      this.router.navigateByUrl("/bookingquestionnaireedit/" + BookingQuestionnaireID);
    }
  
    disableMulti()
    {
      this.selectedItems.forEach(element => {
  
      this.http.delete(bmxurl + "questionnaire/" + this.VenueID + "/" + element.bookingquestionnaireid).subscribe(response => {
        this.httpResponse = response
        if(this.httpResponse.succeeded)
        {
          this.loadgrid(false);
        } 
        else{
          this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error disabling Booking Questionnaires, error: ' + this.httpResponse.messages[0]});
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error disabling Booking Questionnaires, error: ' + error.messages});
      })
      });
      
    }

    copydata(id)
    {
      this.http.get(bmxurl + "questionnaire/" + this.VenueID + "/" + id).subscribe(response => {
        this.httpResponse = (response as bookingquestionnaire_returndata)
        if (this.httpResponse.succeeded)
        {

          let bookingQuestionnaire = {
            id: null,
            bookingId: null,
            questionnaireid: null,
            venueid: (this.httpResponse.data as bookingquestionnaire).venueid,
            name: (this.httpResponse.data as bookingquestionnaire).name,
            bookingquestions: (this.httpResponse.data as bookingquestionnaire).bookingquestions,
            disabled: (this.httpResponse.data as bookingquestionnaire).disabled,
            originatingid: (this.httpResponse.data as bookingquestionnaire).questionnaireid
          };


          this.http.post<bookingquestionnaire>(bmxurl + "/questionnaire", bookingQuestionnaire).subscribe(response => {
            this.httpResponse = response
              if(this.httpResponse.succeeded)
              {
                this.messageService.add({severity:'success', summary:'Saved', detail:'Booking Questionnaire successfully copied'});

                this.loadgrid(false);
              }
              }, error => {
                this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
            })
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Questionnaire'});
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Questionnaire, error:' + error.messages[0]});
      });

    }
  
    
    }
  