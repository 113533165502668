<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
  </div>

<p-dialog header="Confirmation Preview" [(visible)]="showPrintConfirmations" [style]="{width: '75vw', height: '75vh'}" [baseZIndex]="10000" [modal]="true">
    <div style="margin-top:5px;padding:0px !important">
    <div class="field mb-4 col-12" id="confToPrint">
        <div style="width:100%" [innerHTML]="confHTML | safe: 'html'">
        </div>
    </div>
  </div>
  <ng-template pTemplate="footer" id="noprint" class="noprint">
    <!--<p-button icon="pi pi-print" label="Print" styleClass="p-button p-button-success" (click)="PrintConfirmation()"></p-button>
    &nbsp;-->
    <p-button icon="pi pi-times" label="Close" styleClass="p-button p-button-success" (click)="showPrintConfirmations = false;"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Venue Closing" [(visible)]="showVCWarning" [style]="{width: '50vw', height: '50vh'}" [baseZIndex]="10000" [modal]="true">
    <div class="field mb-4 col-12">
        <div [innerHTML]="VCWarningMessage">
        </div>
    </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" label="Continue" styleClass="p-button p-button-success" (click)="VCContinue()"></p-button>
  </ng-template>
  
</p-dialog>

<p-dialog header="Deferral Reason" [(visible)]="showDeferReason" [style]="{width: '50vw', height: '50vh'}" [baseZIndex]="10000" [modal]="true">
    <div class="field mb-4 col-12">
        <div>
            <textarea pInputText type="text" style="width:100%; height: 15vw;" [(ngModel)]="deferNotes"></textarea>
        </div>
    </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-pencil" label="Save Note" styleClass="p-button p-button-success" (click)="doReferralNote()"></p-button>
  </ng-template>
  
</p-dialog>


<p-dialog header="Warning" [(visible)]="showWarning" [style]="{width: '50vw', height: '50vh'}" [baseZIndex]="10000" [modal]="true">
    <div class="field mb-4 col-12">
        <div [innerText]="WarningMessage">
        </div>
    </div>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" label="I agree" styleClass="p-button p-button-success" (click)="WarningContinue()"></p-button>
  </ng-template>
  
</p-dialog>


<div *ngIf="!BookingComplete && AccessAllowed" style="padding: 1rem;">
    <h3 style="float:left;">{{makeABookingHeader}}</h3>
    <div style="float:right;margin-bottom:10px;">
        <p-button class="p-button" [style]="{'margin': '0px', 'padding' : '0px'}" label="Click" icon="pi pi-times" iconPos="left" label="Start Again" (click)="returnHome()"></p-button>
    </div>
    <div style="clear:both;"></div>

    <div *ngIf="packages">
    <p-accordion>
        <p-accordionTab [(selected)]="dateSelected" [disabled]="dateActive" >
            <p-header style="width:100%">
                <div style="width:100%">
                    <span class="pi pi-calendar" style="margin-right: 10px;" [dateFormat]="dd/mm/yy"></span>{{CalendarHeader}}
                </div>
            </p-header>


        <div class="surface-card p-4 shadow-2 border-round">
        <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
                <label for="ddlPackage" style="float:left; width:100%" class="font-medium">Date</label>
                <div style="max-width: 500px;float:left;">
                    <p-calendar [selectOtherMonths]="true" firstDayOfWeek="1" [(ngModel)]="SelectedDate" dateFormat="dd/mm/yy" [showIcon]="true" (onSelect)="doneDate()"></p-calendar>  
                </div>
                <div style="width:525px; float:left; margin-left:10px;">
                    <button pButton type="button" style="width:120px;" class="p-element p-ripple p-button p-component" (click)="switchToToday()">
                        <span>Today</span>
                    </button>
                    &nbsp;
                    <button pButton type="button" style="width:120px;" class="p-element p-ripple p-button p-component" (click)="switchTomorrow()">
                        <span>Tomorrow</span>
                    </button>
                    &nbsp;
                    <button pButton type="button" style="width:120px;" class="p-element p-ripple p-button p-component" (click)="switchToSaturday()">
                        <span>Saturday</span>
                    </button>
                    &nbsp;
                    <button pButton type="button" style="width:120px;" class="p-element p-ripple p-button p-component" (click)="switchToSunday()">
                        <span>Sunday</span>
                    </button>
                </div>
            </div>
        </div>
        </div>

        </p-accordionTab>

        <p-accordionTab [(selected)]="packageSelected" [disabled]="packageActive">
            <p-header style="width:100%">
                <div style="width:100%">
                    <span class="pi pi-box" style="margin-right: 10px;"></span>{{PackageHeader}}
                </div>
            </p-header>
            <div class="surface-card p-4 shadow-2 border-round">
        <div class="grid formgrid p-fluid">
            Select the Package groups you are interested in below
            <div style="width:100%">
                <span *ngFor="let pg of packageGroups" style="padding: 10px; float: left;">
                    <p-toggleButton [(ngModel)]="checked[pg.packagegroupid]" onLabel="{{pg.packagegroupname}}" offLabel="{{pg.packagegroupname}}" (onChange) = "changedPGSelection(pg)"></p-toggleButton>
                </span>
            </div>

            <div style="clear: both;"></div>

            <div class="surface-card p-4 shadow-2 border-round packageCard" *ngFor="let p of packages">
                <div id="image">
                    <img src="{{p.imageurl}}" style="width:100%">
                </div>
                <div *ngIf="IsDiscountValid(p)" style="width: 100%;background-color: #d30000;color: white;text-align: center;padding: 15px;font-weight: bold;">
                    <span>
                        <img src="assets/images/littlebird.png" style="width: 50px;padding-right: 10px;">
                    </span>
                    <span>
                        You are entitled to an early bird discount
                    </span>
                </div>
                <div style="font-weight: bold; padding: 20px;font-size:larger;">{{ p.name }}</div>
                <div  id="dv{{p.packageId}}collapse" style="padding-left: 20px; padding-right: 20px;height:100px;" [innerHTML]="p.description != null ? (p.description.length < 100 ? p.description : (p.description.substring(0,100)) + '...') : ''">  
                </div>

                <div id="dv{{p.packageId}}expanded" hidden="true" style="padding-left: 20px; padding-right: 20px;height:100%;" [innerHTML]="p.description">  
                </div>

                <div style="height: 120px;">

                </div>

                <div style="position: absolute; bottom: 0px;width:100%;padding: 20px;text-align: right;">
                    <button *ngIf="p.description.length > 99" pButton type="button" value="Show More..." id="dv{{p.packageId}}toggleButton" style="width:178px;" class="p-button-secondary p-button-text" (click)="toggleMore('dv'+p.packageId)">
                        <span>Show More...</span>
                    </button>
                    <div>
                        <p-button label="Book this Package" icon="pi pi-check" (click)="donePackage(p)">
                        </p-button>
                    </div>
                </div>
            </div>

        </div>
        </div>
        </p-accordionTab>

        <p-accordionTab [(selected)]="admissionsSelected" [disabled]="admissionsActive">
            <p-header style="width:100%">
                <div style="width:100%">
                    <span class="pi pi-users" style="margin-right: 10px;"></span> {{AdmissionHeader}}
                </div>
            </p-header>
            <div class="surface-card p-4 shadow-2 border-round">

            <!--<div style="color:red; font-weight:bold;">(FOR TESTING)Total Discounts: {{totalDiscount | currency:CurrencySymbol}}</div> -->

            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                <div *ngIf="totalAdmissionInRange">
                    <b>Total Admissions:</b> {{totalAdmissions}}
                    <br/><br/>
                </div>
                <div *ngIf="SelectedPackage != null && !totalAdmissionInRange && totalAdmissions < SelectedPackage.min" style="color: red;">
                    <b>Total Admissions:</b> {{totalAdmissions}} - Please select at least {{SelectedPackage.min}} admissions.
                    <br/><br/>
                </div>
                <div *ngIf="SelectedPackage != null && !totalAdmissionInRange && totalAdmissions > SelectedPackage.max" style="color: red;">
                    <b>Total Admissions:</b> {{totalAdmissions}} - Please select {{SelectedPackage.max}} admissions or less.
                    <br/><br/>
                </div>
                <div class="packageSelection clr" *ngFor="let a of (SelectedAdmissions)">
                    <div *ngIf="a.mustinclude" class="PackageSelectLabel" style="margin-bottom: 8px;">
                        {{a.bookingwizardtext}} *:
                    </div>
                    <div *ngIf="!a.mustinclude" class="PackageSelectLabel" style="margin-bottom: 8px;">
                        {{a.bookingwizardtext}}:
                    </div>

                    <div class="packageSelect" style="max-width: 500px; margin-bottom:20px;">
                    <p-inputNumber [(ngModel)]="SelectedAdmissions[a.index].noofpeople" [disabled]="a.fixed" [id]="'ad'+a.name" [showButtons]="true" max="{{a.max}}" min="{{a.min}}" buttonLayout="horizontal" spinnerMode="horizontal" (onInput)="sumAdmissions()" (onBlur)="sumAdmissions()"></p-inputNumber>
                    </div>
                </div>
                <div style="clear:both;">
                    <button pButton type="button" style="height: 50px; width: 258px;margin-top:20px;" class="p-button p-button" (click)="doneAdmissions()">
                        <span>Select these Admissions</span>
                        <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                    </button>
                </div>
                <br />
                * denotes that you must have at least one of these.
                </div>
            </div>
        </div>

        </p-accordionTab>

        <p-accordionTab *ngIf="includesComplexResources" [(selected)]="partyroomsSelected" [disabled]="partyroomsActive">
            <p-header style="width:100%">
                <div style="width:100%">
                    <span class="pi pi-sitemap" style="margin-right: 10px;"></span> {{SelResourceHeader}}
                </div>
            </p-header>
            <div class="surface-card p-4 shadow-2 border-round">

                <div class="grid formgrid p-fluid">
                    <div class="field mb-4 col-12">
                        <div class="packageSelection clr" *ngFor="let a of (SelectedResources)">
                            <div *ngIf="a.mode == 'COMPLEX' && a.selectionmode != 'SYSTEMANY' && a.selectionmode != 'SYSTEMLIST' && a.selectionmode != ''"  class="PackageSelectLabel" style="margin-bottom: 8px;">
                                <div style="margin-bottom:10px;" *ngIf="!a.optional"><b>Please select one of these {{a.name}}:</b></div>
                                
                                <div style="margin-bottom:10px;" *ngIf="a.optional">
                                    <div style="width:55px; float:left;"><p-inputSwitch [(ngModel)]="SelectedSeletableResourceBool[a.orderindex]"></p-inputSwitch></div>
                                    <div *ngIf="!a.isconstructed == true" style="width:400px;float:left"><b>Would you like a {{a.name}}?</b></div>
                                    <div *ngIf="a.isconstructed == true" style="width:400px;float:left"><b>Would you like to choose a {{a.name}}?</b></div>
                                    <div style="clear:both;"></div>
                                </div>
    
                                <div *ngIf="a.optional && SelectedSeletableResourceBool[a.orderindex] && a.isconstructed == true" >
                                    <div class="packageSelection clr" *ngFor="let r of (a.resources)">
                                        <p-radioButton style="margin-top:5px; margin-bottom: 5px; margin-left: 10px" name="{{a.groupname}}" value="{{r.id}}" label="{{r.name}}" [(ngModel)]="SelectedSeletableResource[a.orderindex]"></p-radioButton>
                                    </div>
                                    <div style="clear:both;"></div>
                                </div>

                                <div *ngIf="!a.optional" >
                                    <div class="packageSelection clr" *ngFor="let r of (a.resources)">
                                        <p-radioButton style="margin-top:5px; margin-bottom: 5px; margin-left: 10px" name="{{a.groupname}}" value="{{r.id}}" label="{{r.name}}" [(ngModel)]="SelectedSeletableResource[a.orderindex]"></p-radioButton>
                                    </div>
                                </div>
                                <div *ngIf="!validselection[a.orderindex] && validselection[a.orderindex] != null" class="text-danger" Style="margin-left:10px;">
                                    <div style="clear:both;"></div>
                                    <span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select from the list above.
                                </div>

                                <div *ngIf="a.quantityselector == 'CUSTOM' && a.optional" Style="margin-left:10px;">
                                    <div class="PackageSelectLabel" style="margin-bottom: 8px;">
                                        How many of the above resource would you like?
                                    </div>
                                    <div class="packageSelect" style="max-width: 500px; margin-bottom:20px;">
                                    <p-inputNumber [(ngModel)]="a.quantity" [showButtons]="true" max="100" min="1" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                                    </div>
                                </div>

                                <hr>
                            </div>

                            <div *ngIf="a.mode == 'COMPLEX'
                             && a.optional && a.selectionmode == ''"  class="PackageSelectLabel" style="margin-bottom: 8px;">
                                <div style="margin-bottom:10px;" *ngIf="!a.optional"><b>Please select one of these {{a.name}}:</b></div>
                                
                                <div style="margin-bottom:10px;" *ngIf="a.optional">
                                    <div style="width:55px; float:left;"><p-inputSwitch [(ngModel)]="SelectedSeletableResourceBool[a.orderindex]"></p-inputSwitch></div>
                                    <div *ngIf="!a.isconstructed == true" style="width:400px;float:left"><b>Would you like a {{a.name}}?</b></div>
                                    <div *ngIf="a.isconstructed == true" style="width:400px;float:left"><b>Would you like to choose a {{a.name}}?</b></div>
                                    <div style="clear:both;"></div>
                                </div>

                                <div *ngIf="SelectedSeletableResourceBool[a.orderindex] && a.quantityselector == 'CUSTOM'" Style="margin-left:10px;">
                                    <div class="PackageSelectLabel" style="margin-bottom: 8px;">
                                        How many of the above resource would you like?
                                    </div>
                                    <div class="packageSelect" style="max-width: 500px; margin-bottom:20px;">
                                    <p-inputNumber [(ngModel)]="a.quantity" [showButtons]="true" max="100" min="1" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                                    </div>
                                </div>

                                <hr>
                            </div>
                        </div>

                    <div style="clear:both;">
                        <button pButton type="button" style="height: 50px; width: 258px;margin-top:20px;" class="p-button p-button" (click)="donePartyRooms()">
                            <span>Add this Selection</span>
                            <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        </p-accordionTab>

        <p-accordionTab [(selected)]="timeSelected" [disabled]="timeActive">
            <p-header style="width:100%">
                <div style="width:100%">
                    <span class="pi pi-clock" style="margin-right: 10px;"></span> {{TimeHeader}}
                </div>
            </p-header>
            <div class="surface-card p-4 shadow-2 border-round">  
                
            <div *ngIf="!disgnosticsMode">    
                <div *ngFor="let st of (startTimesFiltered)" class="times">
                    <!--<p-button style="margin:0px;" class="p-button" label="Click" icon="pi pi-clock" iconPos="left" label="Move booking to {{ st.time | date:'HH:mm' : 'UTC' }}" (click)="bookSlot(st)"></p-button>-->
                    <button style="padding: 25px 43px 25px 43px;font-weight:bold;" class="p-button" (click)="bookSlot(st)">
                        <span class="pi pi-clock" style="margin-right:8px;"></span>
                        {{ st.time | date:'HH:mm' : 'UTC'}}
                    </button>
                </div>
            </div>

            <div *ngIf="disgnosticsMode">  
                <div *ngFor="let st of (startTimes)">
                    <!--<input type=button class='bookButtonND' value="{{ st.time | date:'HH:mm' }}" id='btnbook' (click)="bookSlot(st.time)">-->
                    <div style="width:100%; margin-bottom: 10px;">
                       
                        <div *ngIf='st.primaryResource == true && st.timeAvailable == true'>
                            <div style="float:left;">
                                <button style="padding: 25px 43px 25px 43px;;font-weight:bold;" class="p-button" (click)="bookSlot(st)">
                                    <span class="pi pi-clock" style="margin-right:8px;"></span>
                                    {{ st.time | date:'HH:mm' : 'UTC'}}
                                </button>
                            </div>
                        </div>

                        <div *ngIf='st.primaryResource == true && st.timeAvailable == false' style="width:100%;">
                            <div style="float:left;">
                                <!--<p-button style="margin:0px;" class="p-button" label="Click" disabled="true" icon="pi pi-clock" iconPos="left" label="{{ st.time | date:'HH:mm' : 'UTC' }}"></p-button>-->
                                <button style="padding: 25px 43px 25px 43px;;font-weight:bold;color:#bbbbbb" class="p-button" [disabled]="true">
                                    <span class="pi pi-clock" style="margin-right:8px;"></span>
                                    {{ st.time | date:'HH:mm' : 'UTC'}}
                                </button>
                            </div>
                        </div>

                        <div  *ngIf='st.primaryResource == true' style="font-size: 17px;margin-top: 25px;width: 200px;float: left;margin-left: 20px;">Quantity Remaining: {{ st.quantityRemaining }}</div>
                        
                        <div *ngIf='st.primaryResource == true && st.reason != ""' style="font-size: 17px;margin-top: 15px;width: 400px;float: left;margin-left: 20px;background-color: pink;padding:10px;border-radius:40px;text-align:center;">Reason: {{ st.reason }}</div>
                        <div *ngIf='st.primaryResource == true && st.reason == ""' style="font-size: 17px;margin-top: 15px;width: 400px;float: left;margin-left: 20px;background-color: #91ff91;padding:10px;border-radius:40px;text-align:center;">No issues found</div>
                    
                    </div>
                    <div style="clear:both;"></div>
                </div>
            </div>
            
            <div style="clear: both; height:75px;"></div>

            <div *ngIf="startTimesFiltered === null || startTimesFiltered === undefined || startTimesFiltered.length == 0" style="margin-top: -50px; margin-bottom: 68px;">There are no times available that match your criteria.</div>
        </div>
        </p-accordionTab>

        <p-accordionTab *ngIf="SelectedPackage && SelectedPackage.questionnaire" [(selected)]="bookingQuestionsSelected" [disabled]="bookingQuestionsActive">
            <p-header style="width:100%">
                <div style="width:100%">
                    <span class="pi pi-question-circle" style="margin-right: 10px;"></span> Booking Questions
                </div>
            </p-header>
            <div class="surface-card p-4 shadow-2 border-round">
                <div class="grid formgrid p-fluid">
                    <div *ngFor="let bq of SelectedPackage.questionnaire.bookingquestions" class="field mb-4 col-12">
                        <div *ngIf="bq.questiontype=='FREETEXT' && bq.answers != null">
                            <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                            <input id="bq{{bq.id}}" type="text" [(ngModel)]="bq.answers.freetextanswer" pInputText>
                            <div *ngIf="!mustanswerfreetext[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer this question.</div>
                        </div>
                        
                        <div *ngIf="bq.questiontype=='BIRTHDAY' && bq.answers != null">
                            <div style="width:100%; float:left;"><label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label></div>                      
                            <div *ngFor="let ibq of birthdayitems" style="width: 100%;">
                                <div style="width:100%">
                                    <div>
                                        <div style="min-width: 300px; width:31%; float:left; margin-right:1%;margin-bottom:10px;margin-top: 5px;">
                                            Child's Name<br>
                                            <input id="bq{{bq.id}}" type="text" [(ngModel)]="ibq.name" pInputText>
                                        </div>
                                        
                                        <div *ngIf="bq.showage" style="min-width: 300px; width:31%; float:left; margin-right:1%;margin-bottom:10px;margin-top: 5px;">
                                            Child's Age<br>
                                            <!-- <p-inputNumber [showButtons]="true" [(ngModel)]="ibq.age"  buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber> -->
                                            <p-dropdown [showClear]="true" [options]="dobage" [(ngModel)]="ibq.age" placeholder="Select age" optionLabel="name" optionValue="value"></p-dropdown>
                                        </div>
                                        
                                        <div *ngIf="bq.showdob" style="min-width: 300px; width:31%; float:left;margin-bottom:10px;margin-top: 5px;">
                                            Child's Birthdate<br>
                                            <p-dropdown [style]="{'float': 'left', 'width': '96px' }" optionLabel="name" optionValue="value" [options]="caldays" [(ngModel)]="ibq.dobday" placeholder="--"></p-dropdown>
                                            <span style="float:left;margin-top: 12px;">&nbsp;/&nbsp;</span>
                                            <p-dropdown [style]="{'float': 'left', 'width': '96px' }"  optionLabel="name" optionValue="value" [options]="calmonths" [(ngModel)]="ibq.dobmonth" placeholder="--"></p-dropdown>
                                            <span style="float:left;margin-top: 12px;">&nbsp;/&nbsp;</span>
                                            <p-dropdown [style]="{'float': 'left', 'width': '115px' }"  optionLabel="name" optionValue="value" [options]="calyears" [(ngModel)]="ibq.dobyear" placeholder="----"></p-dropdown>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <div *ngIf="!DOBIsValid" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Please enter a valid date.</div>
                                        
                                    <div style="width:100%">
                                        <div *ngIf="bq.showgender" style="min-width: 300px; width:48%; float:left; margin-right:1%;margin-bottom:10px;margin-top: 5px;">
                                            Child's Gender<br>
                                            <p-selectButton id="useclasses" [style]="{'min-width' : '300px'}" [options]="stateGenderOptions" [(ngModel)]="ibq.gender" ></p-selectButton>
                                        </div>
                                        
                                        <div *ngIf="bq.showinterests" style="min-width: 300px; width:48%; float:left; margin-right:1%;margin-bottom:10px;margin-top: 5px;">
                                            Child's Interests<br>
                                            <input id="bq{{bq.id}}" type="text" [(ngModel)]="ibq.interests" pInputText>
                                        </div>
                                    </div>
                                    <div *ngIf="birthdayitems.length > 1 && !bq.linkedtoparticipants" style="width: 4%;margin-top: 29px;margin-left: 7px;float: left;">
                                        <button pButton type="button" style="height: 50px; width: 58px;" class="p-button-danger p-button" (click)="deleteBirthdayRow(ibq.id)">
                                            <span class="pi pi-trash"></span>
                                        </button>
                                    </div>
                                </div>
                               
                                <div style="clear:both;"></div>
                            </div>
                            <div style="clear:both;"></div>
                            <div style="width:100%" *ngIf="!bq.linkedtoparticipants">
                                <button pButton type="button" style="height: 50px; width: 225px;margin-top:20px;" class="p-button p-button" (click)="addBirthdayRow()">
                                    <span>Add another child</span>
                                    <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                                </button>
                            </div>
                             <div *ngIf="!mustanswerbirthdays[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must provide all details for each child.</div>
                        </div>
                        
                        <div *ngIf="bq.questiontype=='ALLERGY' && bq.answers != null">
                            <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                            <p-selectButton id="allergy" [style]="{'width': '100%', 'max-width' : '230px'}" [options]="stateOptionAllergy" [(ngModel)]="bq.answers.allergyanswer.allergypresent"></p-selectButton>
                            
                            <!--<p-dropdown [options]="allergyOptions" [(ngModel)]="bq.answers.allergyanswer.allergypresent" placeholder="Choose an answer" [showClear]="true" optionLabel="name" optionValue="value"></p-dropdown>-->
                            
                            <div *ngIf="bq.answers.allergyanswer.allergypresent == null" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must provide an answer to whether an allergy is present or not..</div>
                            
                            <div *ngIf="bq.answers.allergyanswer.allergypresent==true">
                                <label for="bq{{bq.id}}" style="margin-top:20px;" class="font-medium">Please specify any allergy details below</label>
                                <input id="bq{{bq.id}}" type="text" [(ngModel)]="bq.answers.allergyanswer.allergytext" pInputText>
                            </div>
                            <div *ngIf="!mustanswerallergies[bq.listorder] && bq.answers.allergyanswer.allergypresent==true" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must provide allergy details.</div>
                        </div>
                        
                        <div *ngIf="bq.questiontype=='MULTICHOICESINGLE' && bq.answers != null">
                            <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                            <div>
                                <p-dropdown [options]="bq.listitems" placeholder="Please select an option" optionLabel="listitemtext" [(ngModel)]="bq.answers.multichoicesingleanswer"></p-dropdown>
                            </div>
                            <div *ngIf="!mustanswermultichoicesingleanswer[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer this question and choose an item.</div>
                        </div>
                        
                        <div *ngIf="bq.questiontype=='MULTICHOICEMULTIPLE' && bq.answers != null">
                            <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                            <div>
                                <p-multiSelect [options]="bq.listitems" placeholder="Please select one or more options" optionLabel="listitemtext" [(ngModel)]="bq.answers.multichoicemultianswer"></p-multiSelect>
                            </div>
                            <div *ngIf="!mustanswermultichoicemultianswer[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer this question and choose one or more items.</div>
                        </div>
                        
                        <div *ngIf="bq.questiontype=='DATE' && bq.answers != null">
                            <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                            <p-calendar [selectOtherMonths]="true" firstDayOfWeek="1" [(ngModel)]="bq.answers.dateanswer" dateFormat="dd/mm/yy" [showIcon]="true"></p-calendar>  
                            <div *ngIf="!mustanswerdate[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer this question and supply a date.</div>
                        </div>
                        
                        <div *ngIf="bq.questiontype=='NUMBER' && bq.answers != null">
                            <label for="bq{{bq.id}}" class="font-medium">{{bq.questiontext}}</label>
                            <p-inputNumber [showButtons]="true" buttonLayout="horizontal" [(ngModel)]="bq.answers.numberanswer" spinnerMode="horizontal"></p-inputNumber>
                            <div *ngIf="!mustanswernumber[bq.listorder]" class="text-danger" Style="margin-left:10px;margin-top:10px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer this question and supply a number.</div>
                        </div>
                        <hr style="margin-bottom:0px;margin-top:20px">
                    </div>
                </div>

                <div style="clear:both;">
                    <button pButton type="button" style="height: 50px; width: 155px;margin-top:20px;" class="p-button p-button" (click)="doneBookingQuestions()">
                        <span>Next</span>
                        <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                    </button> &nbsp;
                </div>
            </div>

        </p-accordionTab>

        <p-accordionTab *ngIf="PackageHasExtras" [(selected)]="extraSelected" [disabled]="extraActive">
            <p-header style="width:100%">
                <div style="width:100%">
                    <span class="pi pi-star" style="margin-right: 10px;"></span> {{ExtrasHeader}}
                </div>
            </p-header>

            <div class="col-12">
                <label for="agegroupname" class="font-medium">Please select any extras, you would like to add to your booking</label>
  
                <p-table [value]="packageExtras">
                  <ng-template pTemplate="header">
                      <tr>
                          <th style="width:90%">Extra</th>
                          <th style="width:10%">Price</th>
                          <th style="width:20%">Quantity</th>
                          <th style="width:20%">Cost</th>
                      </tr>
                  </ng-template>
                      <ng-template pTemplate="body" let-product let-index>
                        <tr>
                            <td>{{product.name}}<br/>
                            <span style="font-size: 12px;">{{product.description}}</span>
                            </td>
                            <td>{{product.price | currency:CurrencySymbol}}</td>
                            <td><p-inputNumber [(ngModel)]="product.qty" [showButtons]="true" max="{{product.max}}" min="{{product.min}}" buttonLayout="horizontal" spinnerMode="horizontal" (onInput)="sumExtras()" (onBlur)="sumExtras()"></p-inputNumber></td>
                            <td>{{(product.qty * product.price) | currency:CurrencySymbol}}</td>
                        </tr>
                        <tr style="border:none;" *ngIf="(product.question!='' && product.question != null) && product.qty > 0">
                            <td style="border:none;" colspan="4">
                                {{product.question}}
                            </td>
                        </tr>
                        <tr style="border:none;" *ngIf="(product.question!='' && product.question != null) && product.qty > 0">
                            <td style="border-top:none;" colspan="4">
                                <input style="width: 100%" type="text" [(ngModel)]="product.answer" pInputText>
                            </td>
                        </tr>
                      </ng-template>
                    </p-table>
                    <div *ngIf="!allQuestionsValid" class="text-danger" Style="margin-left:10px; margin-top: 15px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must answer all questions.</div>
                    <div style="width: 100%;float: right;text-align: right;padding-top: 10px;padding-right: 16px;font-weight: bold;">Total: {{TotalExtras | currency:CurrencySymbol}}</div>
                <div style="clear:both;">
                    <button pButton type="button" style="height: 50px; width: 258px;margin-top:20px;" class="p-button p-button" (click)="doneExtras()">
                        <span>Next</span>
                        <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                    </button>
                </div> 
              </div>

        </p-accordionTab>

        <p-accordionTab  [(selected)]="customerSelected" [disabled]="customerActive">
            <p-header style="width:100%">
                <div style="width:100%">
                    <span class="pi pi-user" style="margin-right: 10px;"></span> {{CustomerHeader}}
                </div>
            </p-header>
            <div style="margin-bottom: 40px;">
                <span class="p-input-icon-left ml-auto" style="margin-right:20px; float:left;"> 
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" [(ngModel)]="searchCriteria" buttonLayout="horizontal" placeholder="Lastname/Mobile/Email" style="height: 50px;padding-left:33px;" />
                </span>
                
                <button pButton type="button" style="float:left;height: 50px;" class="p-button-sm p-element p-ripple p-button p-component" (click)="searchCustomer()">
                <span class="pi pi-search" style="margin-right:8px;"></span>
                <span i18>Search</span>
                </button>
                <!-- <p style="float: left;width: 376px;font-size: 15px;color: #ccc;margin-top: 10px;margin-left: 10px;">(Lastname/Mobile Number/Email)</p>
                 -->

                <button pButton type="button" style="float:left;height: 50px; margin-left:10px;" class="p-button-sm p-element p-ripple p-button p-component" (click)="doWalkin()">
                    <span class="pi pi-sign-in" style="margin-right:8px;"></span>
                    <span i18>Walk in</span>
                </button>

                <div style="clear:both;"></div>

                <div style="margin-top:20px;" class="surface-card p-4 shadow-2 border-round" *ngIf="customersResult">
                <p-table [value]="customersResult.data">
                  <ng-template pTemplate="header">
                    <tr style="color:Black;background-color:White;font-weight:bold;">
                      <th scope="col">Name</th>
                      <!-- <th scope="col">Address</th> -->
                      <th scope="col">Mobile</th>
                      <th scope="col">Email Address</th>
                      <th width="5%">&nbsp;</th>
                      <th width="5%">&nbsp;</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-data>
                    <tr (click)="addselectedCustomer(data)" onmouseover="this.originalstyle=this.style.backgroundColor;this.style.backgroundColor='#dddddd'" onmouseout="this.style.backgroundColor=this.originalstyle;" style="background-color: rgb(255, 255, 255); cursor: pointer;">
                        <td>{{data.fullName}}</td>
                        <!-- <td>{{data.address1}}</td> -->
                        <td>{{data.mobile}}</td>
                        <td>{{data.email}}</td>
                        <!--<td>
                            <button pButton pRipple type="button" class="p-button p-element p-button-danger" (click)="addselectedCustomer(data)">
                            <span class="pi pi-check" style="margin-right:8px;"></span>
                            Select</button>
                        </td>-->
                        <td>
                            <button pButton pRipple type="button" class="p-button p-element p-button-success" (click)="applyCustomer(data)">
                            <span class="pi pi-pencil" style="margin-right:8px;"></span>
                            Edit</button>
                        </td>
                    </tr>  
                  </ng-template>  
                </p-table>
                <button *ngIf="customersResult" style="margin-top:15px;" pButton pRipple type="button" class="p-button p-element p-button-success" (click)="addCustomer(data)">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Add Customer</button>
                </div>
            </div>

            <div *ngIf="ShowCustomerDetails" class="surface-card p-4 shadow-2 border-round">

                <div class="grid formgrid p-fluid">

                    <div *ngIf="IsToday()" class="field mb-4 col-12">
                        <p-selectButton id="exclusive" [style]="{'width': '100%', 'max-width' : '600px'}" [options]="stateOptions" [(ngModel)]="ProvideMinimalPersonalData" ></p-selectButton>
                    </div>

                    <div *ngIf="!IsToday()" class="field mb-4 col-12">
                        <p-selectButton id="exclusive" [style]="{'width': '100%', 'max-width' : '600px'}" [options]="stateOptionsMin" [(ngModel)]="ProvideMinimalPersonalData" ></p-selectButton>
                    </div>
                    
                    <div *ngIf="ProvideMinimalPersonalData == 'FULL' || ProvideMinimalPersonalData == 'LIMITED'" class="field mb-4 col-12" >
                        <label for="firstName" class="font-medium">First Name</label>
                        <input id="firstName" type="text" [(ngModel)]="firstName" pInputText autocomplete="given-name">
                    </div>
                    <div *ngIf="!firstNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a first name.</div>

                    <div class="field mb-4 col-12">
                        <label for="lastname" class="font-medium">Last Name</label>
                        <input id="lastname" type="text" [(ngModel)]="lastName" pInputText autocomplete="family-name">
                    </div>
                    <div *ngIf="!lastNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a last name.</div>

                    <div class="field mb-4 col-12" *ngIf="ProvideMinimalPersonalData == 'FULL'">
                        <label for="address1" class="font-medium">Address 1</label>
                        <input id="address1" type="text" [(ngModel)]="address1" pInputText autocomplete="address-line1">
                    </div>
                    <div *ngIf="!Address1Valid && ProvideMinimalPersonalData == 'FULL'" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an address.</div>

                    <div class="field mb-4 col-12" *ngIf="ProvideMinimalPersonalData == 'FULL'">
                        <label for="address2" class="font-medium">Address 2</label>
                        <input id="address2" type="text" [(ngModel)]="address2" pInputText autocomplete="address-line2">
                    </div>
                
                    <div class="field mb-4 col-12" *ngIf="ProvideMinimalPersonalData == 'FULL'">
                        <label for="town" class="font-medium">Town / City</label>
                        <input id="town" type="text" [(ngModel)]="town" pInputText autocomplete="address-line3">
                    </div>
                    <div *ngIf="!TownValid && ProvideMinimalPersonalData == 'FULL'" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a town/city.</div>

                    <div class="field mb-4 col-12" *ngIf="ProvideMinimalPersonalData == 'FULL'">
                        <label for="postcode" class="font-medium">Post Code</label>
                        <input id="postcode" type="text" [(ngModel)]="postCode" pInputText autocomplete="postal-code">
                    </div>
                    <div *ngIf="!PostCodeValid && ProvideMinimalPersonalData == 'FULL'" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a postcode.</div>
                        
                    <div *ngIf="ProvideMinimalPersonalData == 'FULL' || ProvideMinimalPersonalData == 'LIMITED'" style="width:100%">
                        <div class="field mb-4 col-12">
                            <label for="email" class="font-medium">Email</label>
                            <input id="email" type="email" [(ngModel)]="email" pInputText autocomplete="email">
                        </div>
                     
                        <div *ngIf="!EmailValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an email address.</div>
                        <div *ngIf="!EmailFormatValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a valid email address.</div>
                    </div>
                    <div *ngIf="ProvideMinimalPersonalData == 'FULL' || ProvideMinimalPersonalData == 'LIMITED'" style="width:100%">
                        <div class="field mb-4 col-12">
                            <label for="mobile" class="font-medium">Mobile</label>
                            <input id="mobile" type="tel" pattern="^(\+44\s?|0)7\d{9}$" [(ngModel)]="mobile" pInputText autocomplete="mobile">
                        </div>

                        <div *ngIf="!MobileValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a mobile number.</div>
                        <div *ngIf="!MobileFormatValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a valid mobile number.<div style="clear:both;"></div></div>
                    </div>
                    <div style="clear:both;width: 100%;height: 20px;"></div>
                    <button pButton type="button" style="height: 50px; width: 221px;margin-top:0px" class="p-button p-button" (click)="doneCustomer()">
                        <span>Add this Customer</span>
                        <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                    </button>

                </div>
            </div>


        </p-accordionTab>

        <p-accordionTab [(selected)]="notesSelected" [disabled]="notesActive">
            <p-header style="width:100%">
                <div style="width:100%">
                    <span class="pi pi-envelope" style="margin-right: 10px;"></span> Notes
                </div>
            </p-header>
            <div class="surface-card p-4 shadow-2 border-round">
                <div class="grid formgrid p-fluid">
                    <div class="field mb-4 col-12">
                        <label for="notesName" class="font-medium">Notes</label>
                        <textarea pInputTextarea [(ngModel)]="NewBooking.notes" id="notesName" type="text" ></textarea>
                    </div>
                </div>

                <div style="clear:both;">
                    <button pButton type="button" style="height: 50px; width: 155px;margin-top:20px;" class="p-button p-button" (click)="doneNotes()">
                        <span>Next</span>
                        <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                    </button> &nbsp;
                </div>
            </div>

        </p-accordionTab>

        <p-accordionTab [(selected)]="paymentSelected" [disabled]="paymentActive">
            <p-header style="width:100%">
                <div style="width:100%">
                    <span class="pi pi-money-bill" style="margin-right: 10px;"></span> {{PaymentHeader}}
                </div>
            </p-header>
            <div class="surface-card p-4 shadow-2 border-round">

                <div class="grid formgrid p-fluid">
                    <div style="font-weight:bold; font-size:20px; margin-bottom:20px;">
                        Value of Booking: {{(NewBooking.totaladmissions) |  currency:CurrencySymbol}} 
                        <span *ngIf="NewBooking.totaldiscounts > 0">(- Discount: {{NewBooking.totaldiscounts | currency:CurrencySymbol}})</span>
                        <span *ngIf="NewBooking.totalbookingfees> 0"> + Booking Fee: {{NewBooking.totalbookingfees | currency:CurrencySymbol}}</span>
                        <span *ngIf="NewBooking.totalextras> 0"> + Extras Value: {{NewBooking.totalextras | currency:CurrencySymbol}}</span>
                        <span> (Total Value: {{(NewBooking.total) | currency:CurrencySymbol}})</span>
                    </div>
                    
                    <div *ngIf="BookingPayments.length > 0" class="surface-card p-4 shadow-2 border-round" style="width:100%; margin-bottom: 20px;">
                        <label for="extras" class="font-medium">Payments already made</label>   
                        <div>
                            <p-table [value]="BookingPayments">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th></th>
                                        <th style="width:90%">Date</th>
                                        <th style="width:10%">Method</th>
                                        <th style="width:20%">Amount</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-payment let-index>
                                    <tr>
                                        <td></td>
                                        <td>{{payment.paymentDate | date:'dd/MM/yyyy'}}</td>
                                        <td>{{payment.method}}</td>
                                        <td>{{(payment.amount) | currency:CurrencySymbol}}</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                <tr>
                                    <td></td>
                                    <td>&nbsp;</td>
                                    <td>Total:</td>
                                    <td>{{totalPayments | currency:CurrencySymbol}}</td>
                                </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <div *ngIf="NewBooking.payments == null">
                            No payments have been made against this booking
                        </div>
                    </div>

                    <div class="field mb-4 col-12">
                        <label for="venueclosingname" class="font-medium">Payment Value</label>
                        <p-inputNumber [(ngModel)]="validPaymentAmount" mode="currency" currency="{{CurrencySymbol}}" locale="en-GB" [min]="0" [max]="9999"></p-inputNumber>
                    </div>
                    <div *ngIf="!paymentValueValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a payment amount.</div>
                    <div *ngIf="!paymentValueExceededValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You cannot pay more than the booking value.</div>
                    <div *ngIf="!paymentDepositValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Your payment must be at least {{MinimumValidPayment | currency:CurrencySymbol}}.</div>

                    <!--<div class="field mb-4 col-12">
                        <label for="paymentType" class="font-medium">Payment Type</label>
                        <p-dropdown [options]="PaymentTypes" [(ngModel)]="selectedPayment" optionLabel="name" placeholder="Select a Type" [showClear]="true"></p-dropdown>
                    </div>
                    <div *ngIf="!paymentTypeValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select a payment type.</div>-->
                </div>

                <div *ngIf="showDiscount" class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px;">
                    <div class="field mb-4 col-12">

                        <p-selectButton id="allergy" [style]="{'width': '100%', 'max-width' : '430px', 'margin-bottom' : '20px'}" [options]="stateOptionOpenDiscount" [(ngModel)]="openDiscount"></p-selectButton>

                        <div *ngIf="openDiscount">
                            <label for="discountCode" class="font-medium">Enter Discount Amount</label><br>
                            <p-inputNumber [(ngModel)]="chosenDiscountAmount" max="{{validPaymentAmount}}" min="0.01"  mode="currency" currency={{CurrencySymbol}} ></p-inputNumber>
                        </div>

                        <div *ngIf="!openDiscount">
                            <label for="discountCode" class="font-medium">Enter Discount Code</label><br>
                            <input id="discountCode" [(ngModel)]="discountCode" pInputText>
                        </div>
                    </div>
                    <button *ngIf="openDiscount" pButton type="button" style="height: 50px; width: 221px;margin-top:0px" class="p-button p-button" (click)="doneOpenDiscount()">
                        <span>Apply Discount</span>
                        <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                    </button>

                    <button *ngIf="!openDiscount" pButton type="button" style="height: 50px; width: 221px;margin-top:0px" class="p-button p-button" (click)="doneDiscount()">
                        <span>Apply Discount</span>
                        <span class="pi pi-chevron-right" style="margin-left:8px;"></span>
                    </button>
                </div>

                <div *ngIf="showVoucher" class="surface-card p-4 shadow-2 border-round" style="margin-bottom:20px;">
                    <div class="field mb-4 col-12" style="float:left;">
                        <label for="voucherCode" class="font-medium">Enter Gift Voucher</label><br>
                        <input id="voucherCode" [(ngModel)]="voucherCode" pInputText>
                        <br>
                        <label for="voucherPIN" class="font-medium">Enter PIN</label><br>
                        <input id="voucherPIN" [(ngModel)]="voucherpin" pInputText [maxlength]="4" type="password">
                        <div style="clear:both; width:100%"></div>
                    </div>
                    <div>
                        <button pButton type="button" style="height: 50px; width: 221px;margin-top:0px" class="p-button p-button" (click)="doneVoucher()">
                            <span>Apply</span>
                        </button>
                    </div>
                    <div style="clear:both; width:100%"></div>
                </div>   
                
                <button *ngFor="let p of PaymentTypes" [disabled]="paymentButtonLocked" pButton type="button" style="height: 50px; width: 221px;margin-top:0px;margin-right:15px;margin-bottom:10px;" class="p-button p-button" (click)="donePayment(p.id, p.name)">
                    <span *ngIf="p.name=='Cash'" class="pi pi-pound" style="margin-right:8px;"></span>
                    <span *ngIf="p.name=='Card'" class="pi pi-credit-card" style="margin-right:8px;"></span>
                    <span *ngIf="p.name!='Cash' && p.name!='Card'" class="pi pi-money-bill" style="margin-right:8px;"></span>
                    <span>Pay With {{p.name}}</span>
                </button>
                <button *ngIf="!showDiscount" pButton type="button" style="height: 50px; width: 221px;margin-top:0px;margin-right:15px;margin-bottom:10px;" class="p-button p-button-success" (click)="displayDiscount()">
                    <span class="pi pi-arrow-circle-down" style="margin-right:8px;"></span>
                    <span>Enter Discount</span>
                </button>
                <button *ngIf="showDiscount" pButton type="button" style="height: 50px; width: 221px;margin-top:0px;margin-right:15px;margin-bottom:10px;" class="p-button p-button-success" (click)="displayDiscount()">
                    <span class="pi pi-arrow-circle-left" style="margin-right:8px;"></span>
                    <span>Hide Discount</span>
                </button>

                <button *ngIf="!showVoucher" pButton type="button" style="height: 50px; width: 221px;margin-top:0px;margin-right:15px;margin-bottom:10px;" class="p-button p-button-success" (click)="displayVoucher()">
                    <span class="pi pi-ticket" style="margin-right:8px;"></span>
                    <span>Enter Gift Voucher</span>
                </button>
                <button *ngIf="showVoucher" pButton type="button" style="height: 50px; width: 221px;margin-top:0px;margin-right:15px;margin-bottom:10px;" class="p-button p-button-success" (click)="displayVoucher()">
                    <span class="pi pi-ticket" style="margin-right:8px;"></span>
                    <span>Hide Gift Voucher</span>
                </button>

                <button *ngIf="NewBooking.discounts" pButton type="button" style="height: 50px; width: 221px;margin-top:0px;margin-right:15px;margin-bottom:10px;" class="p-button p-button-danger" (click)="removeDiscount()">
                    <span class="pi pi-arrow-circle-up" style="margin-right:8px;"></span>
                    <span>Remove Discount</span>
                </button>
                <button pButton type="button" style="height: 50px; width: 221px;margin-top:0px;margin-right:15px;margin-bottom:10px;" class="p-button p-button-danger" (click)="deferPayment()">
                    <span class="pi pi-clock" style="margin-right:8px;"></span>
                    <span>Defer Payment</span>
                </button>
                <br>
                <button *ngIf="!showDiscount" pButton type="button" style="height: 50px; width: 221px;margin-top:0px;margin-right:15px;margin-bottom:10px;" class="p-button p-button-warning" (click)="sendPaymentLink()">
                    <span class="pi pi-envelope" style="margin-right:8px;"></span>
                    <span>Send Payment Link</span>
                </button>
            </div>
        </p-accordionTab>
    </p-accordion>
    </div>
</div>

<div *ngIf="BookingComplete">
    <div class="p-3 flex flex-column flex-auto">
        <h3 style="margin-bottom:20px;" i18n>Booking Summary</h3>
    
        <!--<ng-content select="selector"></ng-content><div *ngIf="BookingSummary">
            <app-summary [BookingSummary]="BookingSummary" ></app-summary>
        </div>-->

        <div *ngIf="BookingSummary">
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-user" style="margin-right: 10px;"></span><b>Customer</b> : {{BookingSummary.customerAddress}}
            </div>
            <div *ngIf="BookingSummary.outstanding > 0" style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Outstanding</b> : <span style="color: red; font-weight: bold;">{{BookingSummary.outstanding | currency:CurrencySymbol }}</span>
            </div>
            <div *ngIf="BookingSummary.outstanding <= 0" style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Outstanding</b> : {{BookingSummary.outstanding | currency:CurrencySymbol }}
            </div>
            
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-users" style="margin-right: 10px;"></span><b>Admissions</b> : {{BookingSummary.admissions}}
            </div>
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>Start Date/Time</b> : {{BookingSummary.startTime | date:'dd/MM/yyyy HH:mm' : 'UTC'}}
            </div>
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-box" style="margin-right: 10px;"></span><b>Package</b> : {{BookingSummary.packageName}}
            </div>
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>Sales ID</b> : {{BookingSummary.bookingIdHR}}
            </div>

            <!--<div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>End Date/Time</b> : {{addMs(BookingSummary.endTime, 1)| date:'dd/MM/yyyy HH:mm' : 'UTC'}}
            </div>
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>Arrival Date/Time</b> : {{BookingSummary.arrival| date: 'dd/MM/yyyy HH:mm' : 'UTC'}}
            </div>-->

            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Sales Value</b> : {{BookingSummary.total | currency:CurrencySymbol }}
            </div>

            <div *ngIf="BookingSummary.totaldiscounts > 0" style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Discounts</b> : {{BookingSummary.totaldiscounts | currency:CurrencySymbol }}
            </div>

            <div *ngIf="BookingSummary.totalbookingfees > 0" style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Booking Fee</b> : {{BookingSummary.totalbookingfees | currency:CurrencySymbol }}
            </div>

            <div *ngIf="BookingSummary.outstanding > 0" style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Paid</b> : <span>{{BookingSummary.paid | currency:CurrencySymbol }}</span>
            </div>
            <div *ngIf="BookingSummary.outstanding <= 0" style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Paid</b> : {{BookingSummary.paid | currency:CurrencySymbol }}
            </div>

            <div style="width:100%; margin-bottom: 15px;">
            
            <div *ngIf="SummaryItinerary">
                <span class="pi pi-list" style="margin-right: 10px;"></span><b>Itinerary</b>:

                <div style="margin-top:5px; margin-bottom:10px;">
                    <table [cellPadding]="3" >

                        <tr style="border-bottom: 1px solid #dddddd" *ngFor="let itin of (NewBooking.itineraryEX)">
                            <td>&nbsp;</td>
                            <td>
                                <b>{{itin.resource}}</b>
                            </td>
                            <td>
                                {{itin.starttime}}
                            </td>
                            <td >
                                {{itin.people}}&nbsp;people
                            </td>

                        </tr>

                        <tr style="border-bottom: 1px solid #dddddd">
                            <td>&nbsp;</td>
                            <td>
                                <b>End:</b>
                            </td>
                            <td>
                                {{addMs(BookingSummary.endTime, 1)| date:'HH:mm' : 'UTC'}}
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div *ngIf="BookingSummary.extras != ''" style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-star" style="margin-right: 10px;"></span><b>Extras</b> : {{BookingSummary.extras}}
            </div>


            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-envelope" style="margin-right: 10px;"></span><b>Email</b> : {{BookingSummary.customerEmail}}
                <button pButton type="button" style="height: 25px; margin-right: 5px;width: 25px; float:right;" class="p-button-sm p-element p-ripple p-button p-component" (click)="sendanemail(bookingFromClick.bookingdata.bookingid)">
                    <span class="pi pi-envelope" style="margin-left: -8px; font-size: 15px;"></span>
                </button>
            </div>
            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-mobile" style="margin-right: 10px;"></span><b>Mobile</b> : {{BookingSummary.customerMobile}}
                <button pButton type="button" style="height: 25px; margin-right: 5px;width: 25px; float:right;" class="p-button-sm p-element p-ripple p-button p-component" (click)="makeacall(bookingFromClick.bookingdata.bookingid)">
                    <span class="pi pi-mobile" style="margin-left: -8px; font-size: 15px;"></span>
                </button>
            </div>

            <div style="width:100%; margin-bottom: 15px;">
                <span class="pi pi-file" style="margin-right: 10px;"></span><b>Notes</b> : {{BookingSummary.notes}}
            </div>
        </div>
    </div>

<!--<div class="surface-card p-4 shadow-2 border-round">-->
            <div style="margin-top: 25px;">
                <button *ngIf="BookingSummary.customerEmail != ''" pButton pRipple type="button" class="p-button-success p-element p-button w-auto mt-3" (click)="sendEmailConfirmation()">
                <span class="pi pi-envelope" style="margin-right:8px;"></span>
                Send Confirmation Email</button>
                &nbsp;
                <button *ngIf="BookingSummary.customerMobile != ''" disabled="true" pButton pRipple type="button" class="p-button-success p-element p-button w-auto mt-3" (click)="sendSMSConfirmation()">
                <span class="pi pi-mobile" style="margin-right:8px;"></span>
                Send Confirmation Text</button>
                &nbsp;
                <button pButton pRipple type="button" class="p-button-info p-element p-button w-auto mt-3" (click)="printConfirmation()">
                <span class="pi pi-print" style="margin-right:8px;"></span>
                Print Confirmation</button>
                &nbsp;
                <button pButton pRipple type="button" class="p-button p-element p-button w-auto mt-3" (click)="returnHome()">
                <span class="pi pi-calendar-plus" style="margin-right:8px;"></span>
                Make Another Booking</button>
                &nbsp;
                <button *ngIf="Checkfortoday()" pButton pRipple type="button" class="p-button p-button-success p-element p-button w-auto mt-3" (click)="admitall()">
                <span class="pi pi-sign-in" style="margin-right:8px;"></span>
                Admit this Booking</button>
            </div>
<!--</div>-->
    </div>
</div>

