import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../../app.component';
import { bmxurl, ConvertDateToDB } from 'src/globals';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BusyService } from '../../_services/busy.service';
import { AuthService } from '@auth0/auth0-angular';
import { GlobalService } from '../../_services/global.service';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';
import { Subscription } from 'rxjs';
import { users_returndata } from 'src/types/user_returndata';
import { booking_returndata } from 'src/types/booking_returndata';
import { tag_returndata } from 'src/types/tag_returndata';
import { tag } from 'src/types/tag';

@Component({
  selector: 'app-salesreport',
  templateUrl: './salesreport.component.html',
  styleUrls: ['./salesreport.component.css']
})

export class SalesreportComponent implements OnInit {
  ListBookings: any[];
  ListBookingsGlobal: any[];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  ReportResponse: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  httpResponse: any;
  navSubscription: Subscription = null;

  AccessAllowed: boolean = true;
  CompletedLoading: boolean = false;
  CurrencySymbol: string = "#";

  TotalAmount: number = 0;
  TotalOS: number = 0;
  TotalGuests: number = 0;
  TotalDiscounts: number = 0;


  cols: any[] = [];

  distinctMethods: any[] = [];
  criteria: boolean = false;
  
  FromDate: Date = new Date();
  ToDate: Date = new Date();

  CreateUpdateResponse: any;
  
  ListData: any[] = [];
  ListDataFormatted: any[] = [];
  distinctPackages: any[] = [];

  divClass: string[] = [];
  divTotal: boolean[] = [];

  chosentags: tag[] = [];
  tags: tag[] = [];

  
  constructor(
    private http: HttpClient, 
    private appComp: AppComponent, 
    private router: Router, 
    private messageService: MessageService, 
    private busyService: BusyService,
    private auth: AuthService,
    private globalService: GlobalService) {
      
    let userid = localStorage.getItem("SmartUserID");

    }

  ngOnDestroy() {

  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
          this.httpResponse =  (response as venuesettings_returndata)
          if(this.httpResponse.succeeded)
          {
            //Set Venue Settings ID
            localStorage.setItem("VenueSettingsID", this.httpResponse.data.id);
    
            this.httpResponse.data.venueSettings.forEach(element => {
              localStorage.setItem(element.name, element.value);
            });

            //this.loadgrid();

            this.CurrencySymbol = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;
          }
          else{
            this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
        });
      }
    });

    this.cols = [
      { field: 'startTime', header: 'Date' },
      { field: 'startTime', header: 'Start Time' },
      { field: 'packageName', header: 'Package' },
      { field: 'bookingIdHR', header: 'Sales ID' },
      { field: 'name', header: 'Name' },
      { field: 'discount', header: 'Discount' },
      { field: 'totaldiscount', header: 'Discount Value' },
      { field: 'guests', header: 'Guests' },
      { field: 'total', header: 'Total' },
      { field: 'outstanding', header: 'Outstanding' },
      ];

      this.loadTags(false);
  }

  editdata(ID){
    this.router.navigateByUrl("/editbooking/" + ID);
  }

    loadTags(showdisabled: boolean) {
      this.http.get(bmxurl + "TagList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.tags = (response as tag_returndata).data;
        console.log(this.tags);
      }, error => {
      });
    }
  

  loadgrid()
  {

    this.TotalAmount = 0;
    this.TotalOS  = 0;
    this.TotalGuests = 0;
    this.TotalDiscounts = 0;

    this.busyService.busy();

    let searchCrit = {
      venueid: this.VenueID,
      startdate: ConvertDateToDB(this.FromDate),
      enddate: ConvertDateToDB(this.ToDate),
      tags: this.chosentags,
      disabled: false,
      created: true
    }

    this.http.post(bmxurl + "/BookingList",  searchCrit).subscribe(response => {
      try{
        this.CreateUpdateResponse = (response as booking_returndata);

        //Get VenueDate
        this.ListBookingsGlobal = this.CreateUpdateResponse.data;

        this.ListBookings = this.ListBookingsGlobal.filter(obj => obj.inProgress == false);
        this.ListBookings.forEach(element => {

          this.TotalAmount += element.total;
          this.TotalOS += element.outstanding;
          this.TotalGuests += element.guests;
          this.TotalDiscounts += element.totaldiscount;

          let extraslist: string = "";

          if(element.extras != null)
            {
              if(element.extras.length > 0)
              { 
                element.extras.forEach(extra => {
                  if (extra.qty > 0)
                    extraslist += extra.name + " (" + extra.qty + "), ";
                });
              }

              if(extraslist.length > 2)
                element.extraslist = extraslist.substring(0, extraslist.length - 2);
            }
        });

        this.ListData = this.ListBookings;

        //load divclass with chevron-right
        for (let index = 0; index < this.ListData.length; index++) {
          this.divClass.push("pi pi-chevron-down");
          this.divTotal.push(false);
        }

        this.distinctPackages = this.ListBookings.map((item) => item.packageName).filter((value, index, self) => self.indexOf(value) === index);
        this.distinctPackages.sort();

        let DivID:number = 0;

        let dataByPackage = [];
        this.distinctPackages.forEach(method => {
          dataByPackage.push({DID: DivID, method: method, 
            data: this.ListBookings.filter((item) => item.packageName == method), 
            total: this.ListBookings.filter((item) => item.packageName == method).reduce((a, b) => a + b.total, 0),
            ostotal: this.ListBookings.filter((item) => item.packageName == method).reduce((a, b) => a + b.outstanding, 0),
            guesttotal: this.ListBookings.filter((item) => item.packageName == method).reduce((a, b) => a + b.guests, 0),
            totaltotdiscount: this.ListBookings.filter((item) => item.packageName == method).reduce((a, b) => a + b.totaldiscount, 0)}),
            DivID++;
        });


        this.ListDataFormatted = dataByPackage;

        this.busyService.idle();
      }
      catch (error){
        this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'Failed to get bookings in loadgrid, possible data mismatch!'});
      }
      finally{
        this.busyService.idle();
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'An error occurred getting Bookings'});
      this.busyService.idle();
    });
  }

  doneDate(){
    this.loadgrid()
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  dateChange(num)
  {
    this.FromDate = this.FromDate;
    this.ToDate = this.addDays(new Date(this.ToDate), num);

    this.doneDate();
  }

  collapseDiv(collapsibleDiv, divID){

    var cDiv = document.getElementById(collapsibleDiv);

    if(cDiv.style.display == "none")
    {
      cDiv.style.display = "block";
      this.divClass[divID] = "pi pi-chevron-down";
      this.divTotal[divID] = false;
    }
    else
    {
      cDiv.style.display = "none";
      this.divClass[divID] = "pi pi-chevron-right";
      this.divTotal[divID] = true;
    }

  }

  exportData()
  {
  }

}