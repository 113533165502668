import { Component, ElementRef, OnInit, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../../PackageClasses';
import { AppComponent } from '../../app.component';
import { packagerule_returndata } from 'src/types/packagerule_returndata';
import { bmxurl } from 'src/globals';
import { getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { packagerule } from 'src/types/packagerule';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { booking_returndata } from 'src/types/booking_returndata';
import { BusyService } from '../../_services/busy.service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AuthService } from '@auth0/auth0-angular';
import { GlobalService } from '../../_services/global.service';
import { MenuItem } from 'primeng/api/menuitem';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';
import { cBookingfee } from 'src/types/bookingfee';
import { bookingfee_returndata } from 'src/types/bookingfee_returndata';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { users_returndata } from 'src/types/user_returndata';
import { cColumninfo } from 'src/types/columninfo';

@Component({
  selector: 'app-customerreport',
  templateUrl: './customerreport.component.html',
  styleUrls: ['./customerreport.component.css']
})
export class CustomerreportComponent  implements OnInit {
  ListData: any[];
  ListDataGlobal: any[];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  ReportResponse: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  FromDate: Date = new Date();
  ToDate: Date = new Date();
  httpResponse: any;
  navSubscription: Subscription = null;

  AccessAllowed: boolean = true;
  CompletedLoading: boolean = false;
  CurrencySymbol: string = "#";

  TotalAmount: number = 0;

  cols: any[] = [];
  
  constructor(
    private http: HttpClient, 
    private appComp: AppComponent, 
    private router: Router, 
    private messageService: MessageService, 
    private busyService: BusyService,
    private auth: AuthService,
    private globalService: GlobalService) {
      
    let userid = localStorage.getItem("SmartUserID");

    }

  ngOnDestroy() {

  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
          this.httpResponse =  (response as venuesettings_returndata)
          if(this.httpResponse.succeeded)
          {
            //Set Venue Settings ID
            localStorage.setItem("VenueSettingsID", this.httpResponse.data.id);
    
            this.httpResponse.data.venueSettings.forEach(element => {
              localStorage.setItem(element.name, element.value);
            });

            this.loadgrid();

            this.CurrencySymbol = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;
          }
          else{
            this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
        });
      }
    });

     this.cols = [

      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' },
      { field: 'email', header: 'Email' },
      { field: 'address1', header: 'Address 1' },
      { field: 'address2', header: 'Address 2' },
      { field: 'townCity', header: 'Town' },
      { field: 'postCode', header: 'Post Code' },
      { field: 'mobile', header: 'Mobile' },
      { field: 'agreedToTandC', header: 'Agreed T&C' },
      { field: 'agreedToEmailMarketing',header: 'Agreed Email' },
      { field: 'agreedToSMSMarketing', header: 'Agreed SMS' },

    ];
  }

  editdata(ID){
    this.router.navigateByUrl("/editbooking/" + ID);
  }

  loadgrid()
  {
    this.busyService.busy();
    this.http.get(bmxurl + "/ReportCustomer/" + this.VenueID).subscribe(response => {
      try{
        this.ReportResponse = (response as any);

        this.ListDataGlobal = this.ReportResponse.data;

        this.ListDataGlobal.forEach(element => {
          this.TotalAmount += element.amount;
        });
      }
      catch (error){
        this.messageService.add({severity:'error', summary:'Error getting customers', detail: 'Failed to get customers in loadgrid, possible data mismatch!'});
      }
      finally{
        this.busyService.idle();
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error getting customers', detail: 'An error occurred getting customers'});
      this.busyService.idle();
    });
  }

  doneDate(){
    this.loadgrid()
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  dateChange(num)
  {
    this.FromDate = this.FromDate;
    this.ToDate = this.addDays(new Date(this.ToDate), num);

    this.doneDate();
  }

  exportData()
  {
  }

}