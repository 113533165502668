import { Component, OnInit } from '@angular/core';
import { bmxurl, getAccess } from 'src/globals';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { cashup_returndata } from 'src/types/cashup_returndata';
import { cashup, csPayment } from 'src/types/cashup';
import { ActivatedRoute, Router } from '@angular/router';
import { cashupttotals } from 'src/types/cashuptotals';
import { cashuptotals_returndata } from 'src/types/cashuptotals_returndata';
import { cashupheader } from 'src/types/cashupheader';
import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-cashup',
  templateUrl: './cashup.component.html',
  styleUrls: ['./cashup.component.css']
})
export class CashupComponent implements OnInit {

  id: string = "0";
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  cashupdata: cashup[];
  variance: any[] = [];
  actual:any[] = [];
  notes:string;
  totalExpected: number = 0;
  totalActual: number = 0;
  totalVariance: number = 0;
  historicReports: any;
  paymentList: boolean[] = [];
  selectedDate: any;
  allPayments: any[];
  showData: boolean = false;
  cols: any[];
  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  //CurrencySymbol: string = localStorage.getItem("Currency")!;
  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

  constructor(private route: ActivatedRoute, private http: HttpClient, private messageService: MessageService, private router: Router, private globalService: GlobalService) { 

  }


  toggle(){
    this.showData = !this.showData;
  }
  
  getCUData(id)
  {
    this.http.get(bmxurl + "/CashupItem/" + id).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.cashupdata = this.httpResponse.data;
        this.totalExpected = this.httpResponse.data.expected;
        this.totalActual = this.httpResponse.data.actual;
        this.totalVariance = this.httpResponse.data.variance;
        this.notes = this.httpResponse.data.notes;
        this.allPayments = this.httpResponse.data.payments;
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'Cannot get report data: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail: 'Cannot get report data: ' + error.messages});
    });
  }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    this.id = this.route.snapshot.paramMap.get('id');

    let userid = localStorage.getItem("SmartUserID");


    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("CASHUP", user)){
          this.AccessAllowed = true;
        
        if(this.id == "0")
          {
                this.http.get(bmxurl + "cashup/" + this.VenueID).subscribe(response => {
                  this.httpResponse = (response as cashup_returndata)
                  if(this.httpResponse.succeeded)
                  {
                    this.cashupdata = this.httpResponse.data;

                    let cuIndex = 0;
                    this.cashupdata.forEach(element => {
                        this.variance[cuIndex] = 0;
                        this.actual[cuIndex] = 0;
                        this.paymentList[cuIndex] = false;

                        element.index = cuIndex;
                        element.paymenttotal = this.SumPayments(element.payments)
                        cuIndex++;

                        this.totalExpected += element.paymenttotal;
                    });
                  }
                  else
                  {
                    this.messageService.add({severity:'error', summary:'Error', detail:'Cannot get cash up details: ' + this.httpResponse.messages[0]});
                  }
                }, error => {
                  this.messageService.add({severity:'error', summary:'Error', detail: 'Cannot get cash up details: ' + error.messages});
                });

                this.cols = [
                { field: 'paymentId', header: 'Payment ID' },
                { field: 'paymentDate', header: 'Payment Date</th>' },
                { field: 'bookingIdHR', header: 'Sales ID' },
                { field: 'method', header: 'Payment Type' },
                { field: 'amount', header: 'Amount' },
                { field: 'isOnline', header: 'Online' },
                { field: 'isRefund', header: 'Refund' },
                { field: 'refundreason', header: 'Refund Reason' },
                { field: 'amount', header: 'Notes' }
                ];
          }
          else
          {
            this.getCUData(this.id);
            this.cols = [
              { field: 'paymentId', header: 'Payment ID' },
              { field: 'paymentDate', header: 'Payment Date</th>' },
              { field: 'bookingIdHR', header: 'Sales ID' },
              { field: 'method', header: 'Payment Type' },
              { field: 'amount', header: 'Amount' },
              { field: 'isOnline', header: 'Online' },
              { field: 'isRefund', header: 'Refund' },
              { field: 'refundreason', header: 'Refund Reason' },
              { field: 'amount', header: 'Notes' }
              ];
          }
        }

        this.CompletedLoading = true;
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });
  }

  SumPayments(payments){
    let total: number = 0;
    payments.forEach(element => {
      total += element.amount;
    });

    return total;
  }

  CalcValue(cashUpRow)
  {
    this.variance[cashUpRow.index] = (cashUpRow.paymenttotal - Number(this.actual[cashUpRow.index])) * -1;

    //calc totals

    this.totalActual = 0;
    this.totalVariance = 0;

    this.variance.forEach(element => {
      this.totalVariance += element;
    });

    this.actual.forEach(element => {
      this.totalActual += element;
    });
  }

  returnHome(){
    this.router.navigateByUrl("/cashuplist");
  }

  saveReport(){
    let cashupHeader: cashupheader = {
      id: null,
      datetime: new Date(),
      venueid: this.VenueID,
      notes: this.notes,
      income: [],
      expected: 0,
      actual: 0,
      variance: 0,
      payments: []
    };

    let allPayments: csPayment[] = [];
    let thiscashup: cashupttotals;
    this.cashupdata.forEach(element => {
      if(element.payments[0] != null)
      {
        thiscashup = {
          datetime: new Date(),
          index: element.index,
          method: element.name,
          paymenttype: element.name,
          expected: element.paymenttotal,
          actual: this.actual[element.index],
          variance: this.variance[element.index]
        }

        element.payments.forEach(element => {
          allPayments.push(element);
        
        });
      }
      else
      {
        thiscashup = {
          datetime: new Date(),
          index: element.index,
          method: element.name,
          paymenttype: element.name,
          expected: element.paymenttotal,
          actual: this.actual[element.index],
          variance: this.variance[element.index]
        }
      }
      cashupHeader.income.push(thiscashup);
    });

    cashupHeader.payments = allPayments;

    //post the result.

    cashupHeader.expected = this.totalExpected;
    cashupHeader.actual = this.totalActual;
    cashupHeader.variance = this.totalVariance;

    this.http.post<any>(bmxurl + "/cashuptotals", cashupHeader).subscribe(response => {
      this.httpResponse =(response as cashuptotals_returndata)
      if(this.httpResponse.succeeded)
      {
        this.messageService.add({severity:'success', summary:'Saves', detail: 'Cash-up Report has been saved successfully'});
        this.router.navigateByUrl("/home");
      }
      else{
        this.messageService.add({severity:'error', summary:'Error', detail: 'Could not save the Cash-up Report'});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail: 'Could not save the Cash-up Report'});
    })
  }

  expand(cur)
  {
    this.paymentList[cur.index] = !this.paymentList[cur.index];
  }

}
