import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { class_returndata } from 'src/types/class_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cClass, classes } from 'src/types/class';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';

import { users_returndata } from 'src/types/user_returndata';
import { bookingquestion } from 'src/types/bookingquestion';
import { v4 as uuidv4  } from 'uuid';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-bookingquestionedit',
  templateUrl: './bookingquestionedit.component.html',
  styleUrls: ['./bookingquestionedit.component.css']
})
export class BookingquestioneditComponent {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  bookingQuestion: bookingquestion;
  httpResponse: any;
  id: string;
  pageReady: boolean = false;
  AccessAllowed: boolean = true;

  questiontextNameValid: boolean = true;
  questionTypeValid: boolean = true;

  CompletedLoading: boolean = true;
  QuestionTypes: any[] = [];
  include: any[] = [];

  birthdayRequired: boolean = false;
  AnswerText: string = "";
  answertextNameValid: boolean = true;

  constructor(
    private route: ActivatedRoute,private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 
      //Create new Booking Question
      this.bookingQuestion = {
        id: null,
        bookingquestionid: null,
        questiontext: "",
        listorder: 0,
        listitems: [],
        questiontype: "",
        warningtext: "",
        mandatory: false,
        disabled: false,
        venueid: this.VenueID,
        showname : true,
        showage: false,
        showdob: false,
        showgender: false,
        showinterests: false,
        linkedtoparticipants: false,
        answers: null
      }

      this.include = [{ label: 'Yes', value: true }, { label: 'No', value: false }];      
      
      //Add Questions Types
      let qBirthday = {
        "name": "Birthday Personal Details",
        'questiontype': 'BIRTHDAY',
      }
      this.QuestionTypes.push(qBirthday);

      let qAllergy = {
        "name": "Allergy Information",
        'questiontype': 'ALLERGY',
      }
      this.QuestionTypes.push(qAllergy);

      let qMultiChoiceSingle = {
        "name": "Multi-choice single choice",
        'questiontype': 'MULTICHOICESINGLE',
      }
      this.QuestionTypes.push(qMultiChoiceSingle);

      let qMultiChoiceMultiple = {
        "name": "Multi-choice multiple choice",
        'questiontype': 'MULTICHOICEMULTIPLE',
      }
      this.QuestionTypes.push(qMultiChoiceMultiple);

      let qFreeText = {
        "name": "Free Text",
        'questiontype': 'FREETEXT',
      }
      this.QuestionTypes.push(qFreeText);

      let qDate = {
        "name": "Date",
        'questiontype': 'DATE',
      }
      this.QuestionTypes.push(qDate);

      let qNumber = {
        "name": "Number",
        'questiontype': 'NUMBER',
      } 
      this.QuestionTypes.push(qNumber);
    }

  ngOnInit() {

    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");


    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("BOOKINGQUESTIONS", user)){
          this.AccessAllowed = true;

          this.loaddata();
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  loaddata()
  {
    this.id = this.route.snapshot.paramMap.get('id');
    if(this.id != "0")
    {
      this.pageReady = false;
      this.http.get(bmxurl + "bookingquestion/" + this.VenueID + "/" + this.id).subscribe(response => {
        this.httpResponse = (response as class_returndata)
        if (this.httpResponse.succeeded)
        {
          this.bookingQuestion = {
            id: (this.httpResponse.data as cClass).id,
            bookingquestionid: (this.httpResponse.data as bookingquestion).bookingquestionid,
            questiontext: (this.httpResponse.data as bookingquestion).questiontext,
            listorder: (this.httpResponse.data as bookingquestion).listorder,
            listitems: (this.httpResponse.data as bookingquestion).listitems,
            questiontype:(this.httpResponse.data as bookingquestion).questiontype,
            warningtext: (this.httpResponse.data as bookingquestion).warningtext,
            mandatory: (this.httpResponse.data as bookingquestion).mandatory,
            disabled: (this.httpResponse.data as bookingquestion).disabled,
            venueid: (this.httpResponse.data as bookingquestion).venueid,
            showname: (this.httpResponse.data as bookingquestion).showname,
            showdob: (this.httpResponse.data as bookingquestion).showdob,
            showage: (this.httpResponse.data as bookingquestion).showage,
            showgender: (this.httpResponse.data as bookingquestion).showgender,
            showinterests: (this.httpResponse.data as bookingquestion).showinterests,
            linkedtoparticipants: (this.httpResponse.data as bookingquestion).linkedtoparticipants,
            answers: null
          };

          this.pageReady = true;
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Question'});
          this.pageReady = false;
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Question, error:' + error.messages[0]});
        this.pageReady = false;
      });
    }
    else
    {
      this.pageReady = true;
    }
  }

  DoAnswerValidCheck(){
    if(this.AnswerText == null || this.AnswerText == "")
    {
      this.answertextNameValid = false;
    }
    else
    {
      this.answertextNameValid = true;
    }
  }

  checkQuestionType()
  {
    if(this.bookingQuestion.questiontype == null || this.bookingQuestion.questiontype == "")
    {
      this.questionTypeValid = false;
      return false;
    }
    else
    {
      this.questionTypeValid = true;
      return true;
    }
  }

  updateData()
  {
    let AllValid = true;
    if (this.DoValidCheck())
    {
      AllValid = this.checkQuestionType();

      if(AllValid == true)
      {
        if(this.id == "0")
        {
          this.http.post<bookingquestion>(bmxurl + "/bookingquestion", this.bookingQuestion).subscribe(response => {
            this.httpResponse = response
              if(this.httpResponse.succeeded)
              {
                this.messageService.add({severity:'success', summary:'Saved', detail:'Booking Question successfully updated'});
                this.router.navigateByUrl("/bookingquestions");
              }
              }, error => {
                this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
            })
        }
        else
        {
          this.bookingQuestion.disabled = false;
          
          this.http.put<bookingquestion>(bmxurl + "/bookingquestion", this.bookingQuestion).subscribe(response => {
          this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              this.messageService.add({severity:'success', summary:'Saved', detail:'Booking Question successfully updated'});
              this.router.navigateByUrl("/bookingquestions");
            }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
          })
        }
      }
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.bookingQuestion.questiontext == null || this.bookingQuestion.questiontext == "")
    {
      AllValid = false;
      this.questiontextNameValid = false;
    }
    else
    {
      this.questiontextNameValid = true;
    }

    return AllValid;
  }

  addAnswer(){
    this.DoAnswerValidCheck();

    if(this.answertextNameValid)
    {
      let UUID = uuidv4();
      this.bookingQuestion.listitems.push({id: UUID, listitemtext: this.AnswerText});

      this.AnswerText = "";
    }
  }

  returnToList()
  {
    this.router.navigateByUrl("/bookingquestions");
  }

  disableData(BookingQuestionListID)
  {
    this.bookingQuestion.listitems = this.bookingQuestion.listitems.filter(item => item.id !== BookingQuestionListID);
  }

}
