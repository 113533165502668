import { Component, OnInit } from '@angular/core';
import { v4 as uuidv4  } from 'uuid';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-imageuploadtest',
  templateUrl: './imageuploadtest.component.html',
  styleUrls: ['./imageuploadtest.component.css']
})
export class ImageuploadtestComponent implements OnInit {

  VenueID: string = localStorage.getItem("SmartVenueID")!;
  Identifier: string = uuidv4();
  constructor(private globalService: GlobalService) { }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);
    
  }

  uploadedFile(event){
    alert(event.originalEvent.body.data);
  }

  onErrorFileUpload(event){
    alert("error!");
  }
}


