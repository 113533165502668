import config from '../../auth_config.json';

const { domain, clientId, authorizationParams: { audience }, apiUri, errorPath } = config as {
  domain: string;
  clientId: string;
  authorizationParams: {
    audience?: string;
  },
  apiUri: string;
  errorPath: string;
};

export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  VenueName: "",
  apitarget: "https://dev-api.wannabook.online/",
  servicestarget: "https://dev-services.wannabook.online",

  domain: "dev-3md2d2gxrc7nhoyk.us.auth0.com",
  clientId: "6N1oe7tcMksroM12uA6gY37QzmeuNCci",
  version: "1.0.238",
  auth: {
    domain,
    clientId,
    authorizationParams: {
      ...(audience && audience !== 'YOUR_API_IDENTIFIER' ? { audience } : null),
      redirect_uri: window.location.origin,
    },
    errorPath,
  },
  httpInterceptor: {
    allowedList: [`*`],
  },
};
