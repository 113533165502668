import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { extra_returndata } from 'src/types/extra_returndata';
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { cExtra, extra } from 'src/types/extra';
import { tag } from 'src/types/tag';
import { tag_returndata } from 'src/types/tag_returndata';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { isNgContainer } from '@angular/compiler';
import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-Extra',
  templateUrl: './extra.component.html',
  styleUrls: ['./extra.component.css']
})
export class extraComponent implements OnInit {
  ListExtras: cExtra[];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  Extra: any;
  DisabledExtra: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService, private globalService: GlobalService) {

    }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("EXTRAS", user)){
          this.AccessAllowed = true;

          this.loadgrid(false);
        }

        this.CompletedLoading = true;


      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(ExtraID)
  {
    this.http.delete(bmxurl + "Extra/" + this.VenueID + "/" + ExtraID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Extra successfully disabled'});
        this.router.navigateByUrl("/Extra");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Extra'});
    })
  }

  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "ExtraList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.ListExtras = ((response as extra_returndata).data as cExtra[]);

        if ((response as extra_returndata).messages.length > 0)
        {
          (response as extra_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error getting Extras, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail: 'Error getting Extras, error: ' + error.messages});
    });
  }

  editData(EditData)
  {
    let ExtraID = EditData;
    this.router.navigateByUrl("/extraedit/" + ExtraID);
  }

  disableMulti()
  {
    let AllOk: boolean = true;

    this.selectedItems.forEach(element => {

      this.http.delete(bmxurl + "Extra/" + this.VenueID + "/" + element.extraId).subscribe(response => {
        this.httpResponse = response
        if(!this.httpResponse.succeeded)
        {
          AllOk = false;
        }
      }, error => {
        AllOk = false;
      })
    });

    if(AllOk)
    { 
      this.http.get(bmxurl + "ExtraList/" + this.VenueID + "/false").subscribe(response => {
        this.httpResponse = response
        if(this.httpResponse.succeeded)
        {
          this.ListExtras =  ((response as extra_returndata).data as cExtra[]);
          //this.messageService.add({severity:'success', summary:'Disabled', detail:' Extras disabled'});
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error getting Extras, error: ' + this.httpResponse.messages[0]});
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail: 'Error getting Extras, error: ' + error.messages});
      });
    }
    else
    {
      this.messageService.add({severity:'error', summary:'Error', detail: 'One or more of the Extras failed to be disabled'});
    }

  }
  }
