import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { packagerule_returndata } from 'src/types/packagerule_returndata';
import { Router, RouterLink } from '@angular/router';
import { cPackageRule, packagerule } from 'src/types/packagerule';
import { returnmessage } from 'src/types/returnmessage';
import { MessageService } from 'primeng/api';
import { cPackage } from 'src/types/package';

import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';


@Component({
  selector: 'app-packageruleedit',
  templateUrl: './packageruleedit.component.html',
  styleUrls: ['./packageruleedit.component.css']
})

export class PackageruleeditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  PackageRule: cPackageRule;
  httpResponse: any;
  id: string;
  stateOptions: any[];
  stateOptions2: any[];
  pacakgeRuleNameValid: boolean = true;
  pageReady: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) {
    this.stateOptions = [{ label: 'Duration', value: false }, { label: 'Start Times', value: true }];

    this.stateOptions2 = [{ label: 'Yes', value: true }, { label: 'No', value: false }];

    this.PackageRule = {
      id: null,
      packageTypeID: null, 
      name: "",
      maxConcurrent: 0,
      restrictExactSimiltaneousStartTimes: false,
      onlyRunThisPackageAtSameTime: false,
      disabled: false,
      venueid: this.VenueID,
      restrictGlobal: true,
      colour: ""
    }
  }
  
  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);
    let userid = localStorage.getItem("SmartUserID");

    this.id = this.route.snapshot.paramMap.get('id');
    
    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("PACKAGERULES", user)){
          this.AccessAllowed = true;
          
        this.http.get(bmxurl + "PackageType/" + this.VenueID + "/" + this.id).subscribe(response => {
          this.httpResponse = (response as packagerule_returndata)
          if (this.httpResponse.succeeded)
          {
            this.PackageRule = {
              id: (this.httpResponse.data as cPackageRule).id,
              packageTypeID: (this.httpResponse.data as cPackageRule).packageTypeID,
              name: (this.httpResponse.data as cPackageRule).name,
              maxConcurrent: (this.httpResponse.data as cPackageRule).maxConcurrent,
              restrictExactSimiltaneousStartTimes: (this.httpResponse.data as cPackageRule).restrictExactSimiltaneousStartTimes,
              onlyRunThisPackageAtSameTime: (this.httpResponse.data as cPackageRule).onlyRunThisPackageAtSameTime,
              restrictGlobal: (this.httpResponse.data as cPackageRule).restrictGlobal,
              disabled: (this.httpResponse.data as cPackageRule).disabled,
              colour: (this.httpResponse.data as cPackageRule).colour,
              venueid: (this.httpResponse.data as cPackageRule).venueid
            };
    
            this.pageReady = true;
          }
          else
          {
            this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Package Rules'});
            this.pageReady = false;
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Package Rules, error:' + error.messages[0]});
          this.pageReady = false;
        });
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });
  }

  updateData() {
    if (this.DoValidCheck()) {
      if (this.id == "0") {
        this.http.post<packagerule>(bmxurl + "PackageType", this.PackageRule).subscribe(response => {
          this.httpResponse = response
          if (this.httpResponse.succeeded == true) {
            this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Package Rule successfully created' });
            this.returnToList();
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Not Saved', detail: error.messages[0] });
        })
      }
      else {
        this.PackageRule.disabled = false;

        this.http.put<packagerule>(bmxurl + "PackageType", this.PackageRule).subscribe(response => {
          this.httpResponse = response
          if (this.httpResponse.succeeded == true) {
            this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Package Rule successfully updated' });
            this.returnToList();
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Not Saved', detail: error.messages });
        })
      }
    }
  }

  DoValidCheck() {
    let AllValid = true;

    if (this.PackageRule.name == null || this.PackageRule.name == "") {
      AllValid = false;
      this.pacakgeRuleNameValid = false;
    }
    else {
      this.pacakgeRuleNameValid = true;
    }

    return AllValid;
  }

  returnToList() {
    this.router.navigateByUrl("/packagerule");
  }
}
