import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { class_returndata } from 'src/types/class_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cClass, classes } from 'src/types/class';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';

import { users_returndata } from 'src/types/user_returndata';
import { bookingquestion } from 'src/types/bookingquestion';
import { v4 as uuidv4  } from 'uuid';
import { bookingquestionnaire_returndata } from 'src/types/bookingquestionnaire_returndata';
import { bookingquestionnaire } from 'src/types/bookingquestionnaire';
import { bookingquestion_returndata } from 'src/types/bookingquestion_returndata';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-bookingquestionnaireedit',
  templateUrl: './bookingquestionnaireedit.component.html',
  styleUrls: ['./bookingquestionnaireedit.component.css']
})
export class BookingquestionnaireeditComponent  {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  bookingQuestionnaire: bookingquestionnaire;
  httpResponse: any;
  id: string;
  pageReady: boolean = false;
  AccessAllowed: boolean = true;

  questionnaireNameValid: boolean = true;

  CompletedLoading: boolean = true;

  AvailableQuestions: any[] = [];
  SelectedQuestions: any[] = [];
  constructor(
    private route: ActivatedRoute,private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService) { 
      //Create new Booking Question
      this.bookingQuestionnaire = {
        id: null,
        bookingid: null,
        questionnaireid: null,
        venueid: this.VenueID,
        name: "",
        bookingquestions: [],
        disabled: false,
      }
    }

  ngOnInit() {
    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("BOOKINGQUESTIONNAIRE", user)){
          this.AccessAllowed = true;

          this.getQuestions();
          this.loaddata();
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });
  }

  getQuestions()
  {
    this.http.get(bmxurl + "bookingquestionlist/" + this.VenueID + "/" + false).subscribe(response => {
      this.httpResponse = (response as bookingquestion_returndata)
      if (this.httpResponse.succeeded)
      {
        this.AvailableQuestions = this.httpResponse.data;

        var sortedArray: any[] = this.AvailableQuestions.sort((n1,n2) => {
          if (n1.questiontext > n2.questiontext) {
              return 1;
          }
      
          if (n1.questiontext < n2.questiontext) {
              return -1;
          }
        });
    
        this.AvailableQuestions = sortedArray;

      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Questions'});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Questions, error:' + error.messages[0]});
    });
  }

  loaddata()
  {
    this.id = this.route.snapshot.paramMap.get('id');
    if(this.id != "0")
    {
      this.pageReady = false;
      this.http.get(bmxurl + "questionnaire/" + this.VenueID + "/" + this.id).subscribe(response => {
        this.httpResponse = (response as bookingquestionnaire_returndata)
        if (this.httpResponse.succeeded)
        {
          this.bookingQuestionnaire = {
            id: (this.httpResponse.data as bookingquestionnaire).id,
            bookingid: (this.httpResponse.data as bookingquestionnaire).bookingid,
            questionnaireid: (this.httpResponse.data as bookingquestionnaire).questionnaireid,
            venueid: (this.httpResponse.data as bookingquestionnaire).venueid,
            name: (this.httpResponse.data as bookingquestionnaire).name,
            bookingquestions: (this.httpResponse.data as bookingquestionnaire).bookingquestions,
            disabled: (this.httpResponse.data as bookingquestionnaire).disabled,
          };

          this.SelectedQuestions = this.bookingQuestionnaire.bookingquestions;

          //remove selected questions from available questions
          for (let i = 0; i < this.SelectedQuestions.length; i++) {
            this.AvailableQuestions = this.AvailableQuestions.filter(obj => obj.bookingquestionid !== this.SelectedQuestions[i].bookingquestionid);
          }

          this.pageReady = true;
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Question'});
          this.pageReady = false;
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Question, error:' + error.messages[0]});
        this.pageReady = false;
      });
    }
    else
    {
      this.pageReady = true;
    }
  }

  updateData()
  {
    this.bookingQuestionnaire.bookingquestions = this.SelectedQuestions;

    let AllValid = true;
    if (this.DoValidCheck())
    {
      //Check questionnaire has questions
      if(this.bookingQuestionnaire.bookingquestions.length == 0)
      {
        AllValid = false;
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Booking Questionnaire must have questions'});
      }

      let index = 0;
      
      if(AllValid == true)
      {
        this.bookingQuestionnaire.bookingquestions.forEach(element => {
          element.listorder = index;
          index++;
        });

        if(this.id == "0")
        {
          this.http.post<bookingquestionnaire>(bmxurl + "/questionnaire", this.bookingQuestionnaire).subscribe(response => {
            this.httpResponse = response
              if(this.httpResponse.succeeded)
              {
                this.messageService.add({severity:'success', summary:'Saved', detail:'Booking Questionnaire successfully updated'});
                this.router.navigateByUrl("/bookingquestionnaire");
              }
              }, error => {
                this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
            })
        }
        else
        {
          this.bookingQuestionnaire.disabled = false;
          
          this.http.put<bookingquestionnaire>(bmxurl + "/questionnaire", this.bookingQuestionnaire).subscribe(response => {
          this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              this.messageService.add({severity:'success', summary:'Saved', detail:'Booking Questionnaire successfully updated'});
              this.router.navigateByUrl("/bookingquestionnaire");
            }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
          })
        }
      }
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.bookingQuestionnaire.name == "")
    {
      AllValid = false;
      this.questionnaireNameValid = false;
    }
    else
    {
      this.questionnaireNameValid = true;
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/bookingquestionnaire");
  }

  disableData(BookingQuestionListID)
  {
    //delete booknig questionnaire
    this.http.delete<returnmessage>(bmxurl + "/bookingquestionnaire/" + BookingQuestionListID).subscribe(response => {
      this.httpResponse = response
        if(this.httpResponse.succeeded)
        {
          this.messageService.add({severity:'success', summary:'Saved', detail:'Booking Questionnaire successfully deleted'});
          this.loaddata();
        }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
      })
  }

  AddToList(product){
    if(this.SelectedQuestions == null)
    {
      this.SelectedQuestions = [];
      let newQuestion: bookingquestion = {
        id: product.id,
        bookingquestionid: product.bookingquestionid,
        questiontext: product.questiontext,
        listorder: product.listorder,
        listitems: product.listitems,
        questiontype:product.questiontype,
        warningtext: product.warningtext,
        mandatory: product.mandatory,
        disabled: product.disabled,
        venueid: product.venueid,
        showname: product.showname,
        showdob: product.showdob,
        showage: product.showage,
        showgender: product.showgender,
        showinterests: product.showinterests,
        linkedtoparticipants: product.linkedtoparticipants,
        answers: null
      }

      this.SelectedQuestions.push(newQuestion);
    }
    else{
      let newQuestion: bookingquestion = {
        id: product.id,
        bookingquestionid: product.bookingquestionid,
        questiontext: product.questiontext,
        listorder: product.listorder,
        listitems: product.listitems,
        questiontype:product.questiontype,
        warningtext: product.warningtext,
        mandatory: product.mandatory,
        disabled: product.disabled,
        venueid: product.venueid,
        showname: product.showname,
        showdob: product.showdob,
        showage: product.showage,
        showgender: product.showgender,
        showinterests: product.showinterests,
        linkedtoparticipants: product.linkedtoparticipants,
        answers: null
      }

      this.SelectedQuestions.push(newQuestion);
    }

    this.AvailableQuestions = this.AvailableQuestions.filter(obj => obj.bookingquestionid !== product.bookingquestionid);
  }

  RemoveFromList(product){
    //remove question from selectedquestions
    this.SelectedQuestions = this.SelectedQuestions.filter(obj => obj.bookingquestionid !== product.bookingquestionid);

    this.AvailableQuestions.push(product);

    var sortedArray: any[] = this.AvailableQuestions.sort((n1,n2) => {
      if (n1.questiontext > n2.questiontext) {
          return 1;
      }
  
      if (n1.questiontext < n2.questiontext) {
          return -1;
      }
    });

    this.AvailableQuestions = sortedArray;
  }

}

