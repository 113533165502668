import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { package_returndata } from 'src/types/package_returndata';
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { cPackage, packageObj } from 'src/types/package';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { readiness_returndata } from 'src/types/readiness_returndata';
import { users_returndata } from 'src/types/user_returndata';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-Package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.css']
})
export class packageComponent implements OnInit {
  ListPackage: any;
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateUpdateResponse: any;
  Package: any;
  DisabledPackage: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  PackageObj: cPackage;
  httpResponse: any;
  id: string;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService, private globalService: GlobalService) {
      //this.isRowSelectable = this.isRowSelectable.bind(this);
    }

  ngOnInit(): void {

    this.globalService.setSmallScreenMenu(false);

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("PACKAGE", user)){
          this.AccessAllowed = true;
          this.loadgrid(false);
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });
  }

  copydata(id)
  {
      this.http.get(bmxurl + "package/" + this.VenueID + "/" + id).subscribe(response => {
        this.httpResponse = response;
        this.PackageObj = {
          id: null,
          packageId: null,
          name: (this.httpResponse.data as cPackage).name,
          eventid: (this.httpResponse.data as cPackage).eventid,
          description: (this.httpResponse.data as cPackage).description,
          imageurl: (this.httpResponse.data as cPackage).imageurl,
          message: (this.httpResponse.data as cPackage).message,
          disabled: (this.httpResponse.data as cPackage).disabled,
          venueID: (this.httpResponse.data as cPackage).venueID,
          resources: (this.httpResponse.data as cPackage).resources,
          startTimes: (this.httpResponse.data as cPackage).startTimes,
          admissions: (this.httpResponse.data as cPackage).admissions,
          packageLength: (this.httpResponse.data as cPackage).packageLength,
          questionnaire: (this.httpResponse.data as cPackage).questionnaire,
          min: (this.httpResponse.data as cPackage).min,
          max: (this.httpResponse.data as cPackage).max,
          tags: (this.httpResponse.data as cPackage).tags,
          startDate: new Date((this.httpResponse.data as cPackage).startDate),
          visibility: (this.httpResponse.data as cPackage).visibility,
          endDate: new Date((this.httpResponse.data as cPackage).endDate),
          colour: (this.httpResponse.data as cPackage).colour,
          packageRule: (this.httpResponse.data as cPackage).packageRule,
          packageRestriction: (this.httpResponse.data as cPackage).packageRestriction,
          extras: (this.httpResponse.data as cPackage).extras,
          publicDepositPolicy: (this.httpResponse.data as cPackage).publicDepositPolicy,
          venueDepositPolicy: (this.httpResponse.data as cPackage).venueDepositPolicy,
          additionalresources:  (this.httpResponse.data as cPackage).additionalresources,
          confirmation:  (this.httpResponse.data as cPackage).confirmation,
          warning:  (this.httpResponse.data as cPackage).warning,
          originatingid: (this.httpResponse.data as cPackage).packageId,
          bookingscannotbemadewithinxminutes: (this.httpResponse.data as cPackage).bookingscannotbemadewithinxminutes,
          bookingscannotbemadeafterxminutes: (this.httpResponse.data as cPackage).bookingscannotbemadeafterxminutes,
          cannotmakebookingstoday: (this.httpResponse.data as cPackage).cannotmakebookingstoday,
          discounttype: (this.httpResponse.data as cPackage).discounttype,
          discountamount: (this.httpResponse.data as cPackage).discountamount,
          discountcode: (this.httpResponse.data as cPackage).discountcode,
          discountstart: (this.httpResponse.data as cPackage).discountstart,
          discountend: (this.httpResponse.data as cPackage).discountend,
          earlybirddiscount: (this.httpResponse.data as cPackage).earlybirddiscount,
          discountrangetype: (this.httpResponse.data as cPackage).discountrangetype,
          discountdaysbefore: (this.httpResponse.data as cPackage).discountdaysbefore,
          imageguid: (this.httpResponse.data as cPackage).imageguid,
          bookingtimeout: (this.httpResponse.data as cPackage).bookingtimeout
        }

        //Got Package, now resave it.
        this.http.post<packageObj>(bmxurl + "/package", this.PackageObj).subscribe(response => {
          this.httpResponse = response
          if (this.httpResponse.succeeded) {
            this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Package successfully copied' });
            this.editData(this.httpResponse.data.packageId)
          }
          else
          {
            this.messageService.add({ severity: 'error', summary: 'Error Copying Package', detail: this.httpResponse.messages[0] });
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Error Copying Package', detail: error.messages });
        })


      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error Copying Package'});
      });
      
  }

  addNewPackage(){

    let readiness;
    let allowPackageAdd = false;

    this.http.get(bmxurl + "readiness/" + this.VenueID).subscribe(response => {
      if((response as readiness_returndata).succeeded == true)
      {
        readiness = (response as readiness_returndata).data;
        if(readiness.admissions == true && readiness.confirmations == true && readiness.deposits == true && readiness.resources == true && readiness.venuestarttimes == true)
        {
          this.router.navigateByUrl("/packageedit/0");
        }
        else{
          if(readiness.admissions == false)
            this.messageService.add({severity:'error', summary:'Error', detail:'You must create admissions before you can create a package'});
          if(readiness.confirmations == false)
            this.messageService.add({severity:'error', summary:'Error', detail:'You must create confirmations before you can create a package'});
          if(readiness.deposits == false)   
            this.messageService.add({severity:'error', summary:'Error', detail:'You must create deposits before you can create a package'});
          if(readiness.resources == false)
            this.messageService.add({severity:'error', summary:'Error', detail:'You must create resources before you can create a package'});
          if(readiness.venuestarttimes == false)
            this.messageService.add({severity:'error', summary:'Error', detail:'You must create venuestarttimes before you can create a package'});
        }
      }
    }, error => {
    });
  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(PackageID)
  {
    this.http.delete(bmxurl + "Package/" + this.VenueID + "/" + PackageID).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Package successfully disabled'});
        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Package'});
    })
  }

  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "packagelist/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      if((response as package_returndata).succeeded == true)
      {
        this.ListPackage = (response as package_returndata).data.filter((item: any) => item.eventid == null);

        if ((response as package_returndata).messages.length > 0)
        {
          (response as package_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }
      }
    }, error => {
    });
  }

  editData(EditData)
  {
    let PackageID = EditData;
    this.router.navigateByUrl("/packageedit/" + PackageID);
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "Package/" + this.VenueID + "/" + element.PackageID).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded)
      {
        this.loadgrid(false);
      }
    }, error => {

    })
    });

  }


  }
