import { Component } from '@angular/core';
import { BusyService } from '../_services/busy.service';
import { GlobalService } from '../_services/global.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { bmxurl, getAccess } from 'src/globals';
import { cVoucher } from 'src/types/voucher';
import { users_returndata } from 'src/types/user_returndata';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { voucher_returndata } from 'src/types/voucher_returndata';

@Component({
  selector: 'app-voucheredit',
  templateUrl: './voucheredit.component.html',
  styleUrls: ['./voucheredit.component.css']
})
export class VouchereditComponent {

  NameValid: boolean = true;
  EmailValid: boolean = true;
  EmailFormatValid: boolean = true;

  RecipientNameValid: boolean = true;
  RecipientEmailValid: boolean = true;
  RecipientEmailFormatValid: boolean = true;
  
  messageValid: boolean = true;
  stateOptions: any[];
  Voucher: cVoucher;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  pageReady: boolean = true;
  id: string;
  CurrencySymbol = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

  constructor(private http: HttpClient, 
    private route: ActivatedRoute,
    private router: Router, 
    private messageService: MessageService, 
    private busyService: BusyService,
    private globalService: GlobalService) { 

      this.stateOptions = [{ label: 'No', value: false }, { label: 'Yes', value: true }];
    }

    updateData()
    {
      if (this.DoValidCheck())
      {
        this.Voucher.disabled = false;
        
        this.http.put<cVoucher>(bmxurl + "/Voucher", this.Voucher).subscribe(response => {
        this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Voucher successfully updated'});
            this.router.navigateByUrl("/voucherlist");
          }
          else
          {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:this.httpResponse.messages[0]});
            this.router.navigateByUrl("/voucherlist");
          }
          }, error => {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }

  ngOnInit() {

    this.globalService.setSmallScreenMenu(false);

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("VOUCHERS", user)){
          this.AccessAllowed = true;

          this.id = this.route.snapshot.paramMap.get('id');
          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "Voucher/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as voucher_returndata)
              if (this.httpResponse.succeeded)
              {
                this.Voucher = {
                  id: (this.httpResponse.data as cVoucher).id,
                  venueid: (this.httpResponse.data as cVoucher).venueid,
                  voucherid: (this.httpResponse.data as cVoucher).voucherid,
                  vouchercode: (this.httpResponse.data as cVoucher).vouchercode,
                  voucherpin: (this.httpResponse.data as cVoucher).voucherpin,
                  emailaddress: (this.httpResponse.data as cVoucher).emailaddress,
                  customername: (this.httpResponse.data as cVoucher).customername,
                  alternativerecipient: (this.httpResponse.data as cVoucher).alternativerecipient,
                  recipientsname: (this.httpResponse.data as cVoucher).recipientsname,
                  recipientsemailsaddress: (this.httpResponse.data as cVoucher).recipientsemailsaddress,
                  message: (this.httpResponse.data as cVoucher).message,
                  disabled:(this.httpResponse.data as cVoucher).disabled,
                  created: new Date((this.httpResponse.data as cVoucher).created),
                  expiry: new Date((this.httpResponse.data as cVoucher).expiry),
                  voucherexpiry: (this.httpResponse.data as cVoucher).voucherexpiry,
                  initialvalue:(this.httpResponse.data as cVoucher).initialvalue,
                  customamount:(this.httpResponse.data as cVoucher).customamount,
                  balance: (this.httpResponse.data as cVoucher).balance,
                  value: (this.httpResponse.data as cVoucher).value,
                  payments: (this.httpResponse.data as cVoucher).payments,
                  ischild: (this.httpResponse.data as cVoucher).ischild,
                  bookingid: (this.httpResponse.data as cVoucher).bookingid,
                  bookingidhr: (this.httpResponse.data as cVoucher).bookingidhr
                };
      
                this.pageReady = true;
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting classes'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting classes, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });
  }

  returnToList()
  {
    this.router.navigateByUrl("/voucherlist");
  }


  viewBooking(ID: string)
  {
    this.router.navigateByUrl("/bookingedit/" + ID);
  }


  DoValidCheck(){
    let AllValid = true;

    if(this.Voucher.customername == "")
    {
      AllValid = false;
      this.NameValid = false;
    }
    else
    {
      this.NameValid = true;
    }

    if(this.Voucher.emailaddress == "")
    {
      AllValid = false;
      this.EmailValid = false;
    }
    else
    {
      this.EmailValid = true;
    }

    if (this.Voucher.emailaddress != "") {
      if (!this.validateEmail(this.Voucher.emailaddress)) {
        AllValid = false;
        this.EmailFormatValid = false;
      }
      else {
        this.EmailFormatValid = true;
      }
    }

    if(this.Voucher.alternativerecipient)
    {
      if(this.Voucher.recipientsname == "")
      {
        AllValid = false;
        this.RecipientNameValid = false;
      }
      else
      {
        this.RecipientNameValid = true;
      }
  
      if(this.Voucher.recipientsemailsaddress == "")
      {
        AllValid = false;
        this.RecipientEmailValid = false;
      }
      else
      {
        this.RecipientEmailValid = true;
      }

      if (this.Voucher.recipientsemailsaddress != "") {
        if (!this.validateEmail(this.Voucher.recipientsemailsaddress)) {
          AllValid = false;
          this.RecipientEmailValid = false;
        }
        else {
          this.RecipientEmailValid = true;
        }
      }

      if(this.Voucher.message == "")
      {
        AllValid = false;
        this.messageValid = false;
      }
      else
      {
        this.messageValid = true;
      }
    }

    return AllValid;
  }

  validateEmail(email){ 
    var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; 
    return re.test(email); 
  }


}